import React, {forwardRef, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Radio} from "@ddm-design-system/radio";
import {ErrorTextInput} from "@ddm-design-system/textinput";
import {Divider} from "@ddm-design-system/divider";
import {ExpandablePanel} from "@ddm-design-system/expandable-panel";
import {useDispatch, useSelector} from "react-redux";
import {Body} from "@ddm-design-system/typography";
import {PrimaryButton} from "@ddm-design-system/button";
import {ILocationData, ILocationOpeningHour, IManualLocationDataInput, IOutlet} from "../../store/outlet/types";
import {IAppState} from "../../store";
import {IPlaceSuggestion} from "../../services/places";
import useContent from "../../hooks/useContent";
import useDebounce from "../../hooks/useDebounce";
import {
  getOutletLocationData,
  setOutletGoogleLocationData,
  setOutletManualLocationData
} from "../../store/outlet/actions";
import {getOutletLocationDataById} from "../../store/outlet/selectors";
import PlacesInput from "./PlacesInput";
import OutletOpeningHours from "./OutletOpeningHours";
import {AnalyticsContext} from "../../services/analytics";
import {isDifferentHours} from "../../helpers";
import OutletOwnerCard from "./OutletOwnerCard";

interface IProps {
  outlet: IOutlet;
  locationData: ILocationData;
  loading: boolean;
}

type Option = "GOOGLE" | "MANUAL";

const WithLocationData = forwardRef<HTMLDivElement, { outlet: IOutlet }>(({outlet}, ref) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ILocationData>();
  const locationData = useSelector((state: IAppState) =>
    getOutletLocationDataById(state, outlet.id)
  );
  const [loading, setLoading] = useState(!data);
  useEffect(() => {
    if (!data && locationData) {
      setData(locationData);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  useEffect(() => {
    dispatch(getOutletLocationData(outlet.id));
  }, [dispatch, outlet]);

  return (
    <OutletInfoCard
      ref={ref}
      loading={loading}
      outlet={outlet}
      locationData={(data && locationData) ?? {address: "", openingHoursList: []}}
    />
  );
});

const OutletInfoCard = forwardRef<HTMLDivElement, IProps>(
  ({outlet, locationData, loading}, ref) => {
    const dispatch = useDispatch();
    const analytics = useContext(AnalyticsContext);
    const {managerAppSettings: content, managerAppCommon: contentCommon} = useContent();
    const [locationDataTemp, setLocationDataTemp] = useState(locationData);
    const [option, setOption] = useState<Option>(
      locationData.googleBusinessId ? "GOOGLE" : "MANUAL"
    );
    const [place, setPlace] = useState<IPlaceSuggestion>();
    const [manualPlace, setManualPlace] = useState<IManualLocationDataInput>({
      address: locationData.address ?? "",
      locationOpeningHours: locationData?.openingHoursList
    });
    const [defaultPlace, setDefaultPlace] = useState(locationData.googleBusinessId);
    const placeToSave = useDebounce(place, 1000);
    const manualPlaceToSave = useDebounce(manualPlace, 1000);
    const incomplete = useMemo(
      () =>
        (option === "GOOGLE" && !(defaultPlace || place)) ||
        (option === "MANUAL" && !manualPlace.address),
      [option, defaultPlace, place, manualPlace]
    );
    const readyToSaveGoogle = useMemo(
      () =>
        option === "GOOGLE" &&
        placeToSave &&
        placeToSave?.place_id !== locationData.googleBusinessId,
      [placeToSave, locationData, option]
    );
    const readyToSaveManual = useMemo(
      () =>
        option === "MANUAL" &&
        !!manualPlaceToSave.address &&
        (manualPlaceToSave.address !== locationData.address ||
          isDifferentHours(locationData.openingHoursList, manualPlaceToSave.locationOpeningHours)),
      [manualPlaceToSave, locationData, option]
    );
    useEffect(() => {
      setLocationDataTemp(locationData);
    }, [locationData]);

    useEffect(() => {
      setManualPlace({
        address: locationData.address ?? "",
        locationOpeningHours: locationData?.openingHoursList
      });

      setOption(locationData.googleBusinessId ? "GOOGLE" : "MANUAL");
    }, [locationData]);

    useEffect(() => {
      setDefaultPlace(locationDataTemp.googleBusinessId);
    }, [locationDataTemp]);

    const saveGoogle = useCallback(() => {
      analytics.logEvent("SET_LOCATION_DATA", "GOOGLE");
      dispatch(setOutletGoogleLocationData(outlet.id, outlet.name, placeToSave?.place_id || ""));
    }, [analytics, placeToSave, dispatch, outlet]);

    const saveManual = useCallback(() => {
      analytics.logEvent("SET_LOCATION_DATA", "MANUAL");
      dispatch(setOutletManualLocationData(outlet.id, outlet.name, manualPlaceToSave));
    }, [analytics, manualPlaceToSave, dispatch, outlet]);

    const updateHours = useCallback(
      (locationOpeningHours: ILocationOpeningHour[]) => {
        if (isDifferentHours(manualPlace.locationOpeningHours, locationOpeningHours)) {
          setManualPlace({...manualPlace, locationOpeningHours});
        }
      },
      [manualPlace]
    );
    const saveData = useCallback(() => {
      if (readyToSaveGoogle) {
        saveGoogle();
      }
      if (readyToSaveManual) {
        saveManual();
      }
    }, [readyToSaveManual, readyToSaveGoogle, saveGoogle, saveManual]);

    return (
      <div data-test-id="outlet-info-card">
        {/* @ts-ignore */}
        <ExpandablePanel
          ref={ref}
          initialExpanded={false}
          className="border-b border-solid border-grey-grey50 xs:p-0"
          title={outlet.name}
          renderHeaderDetails={
            !loading
              ? () => (
                <Body
                  className={`flex-grow ${
                    incomplete ? "text-alert-alert100" : "text-grey-grey100"
                  }`}
                >
                  {
                    content[
                      `manager_app_settings_outlet_info_${option.toLowerCase()}${
                        incomplete ? "_incomplete" : ""
                      }`
                      ]
                  }
                </Body>
              )
              : undefined
          }
        >
          <div className="flex flex-col w-full">
            <OutletOwnerCard outlet={outlet} />
            {!loading && (
              <>
                <div data-test-id="outlet-address-card">
                  <div className="flex-[1] flex-row flex justify-between md:flex-wrap">
                    <div className="!flex-[1] !px-lg !pb-lg md:!p-0 md:!pb-xl md:!flex-[100%]">
                      <Radio
                        onChange={() => ({})}
                        className="mb-lg"
                        value="GOOGLE"
                        label={content.manager_app_settings_outlet_info_connect_google}
                        onValueSelect={() => setOption("GOOGLE")}
                        selectedValue={option}
                        data-test-id="outlet-address-google"
                      />
                      <div
                        className={
                          option === "GOOGLE"
                            ? "opacity-100 pointer-events-auto"
                            : "opacity-[0.15] pointer-events-none"
                        }
                      >
                        <PlacesInput
                          error={
                            option === "GOOGLE" &&
                            !(place?.structured_formatting.main_text || defaultPlace)
                              ? content.settings_error_required
                              : false
                          }
                          value={
                            place?.structured_formatting.main_text ??
                            (defaultPlace
                              ? locationData.googleBusinessName || outlet.name
                              : undefined)
                          }
                          label={content.manager_app_settings_outlet_info_google_label}
                          placeholder={content.manager_app_settings_outlet_info_google_my}
                          selectedPlace={place ?? null}
                          onPlaceSelected={(newPlace: any) => {
                            setPlace(newPlace ?? undefined);
                            setDefaultPlace(undefined);
                          }}
                          onChangeText={(text: string) => {
                            if (text && text !== outlet.name) {
                              setDefaultPlace(undefined);
                            }
                          }}
                        />
                        {option === "GOOGLE" &&
                          (place?.structured_formatting.main_text || defaultPlace) && (
                            <OutletOpeningHours location={locationData} readOnly />
                          )}
                      </div>
                    </div>
                    <Divider orientation="vertical" className="mx-xs md:hidden" />
                    <div className="!flex-[1] !px-lg !pb-lg md:!p-0 md:!pb-xl md:!flex-[100%]">
                      <Radio
                        onChange={() => ({})}
                        className="mb-lg"
                        value="MANUAL"
                        label={content.manager_app_settings_outlet_info_manual_address}
                        onValueSelect={() => setOption("MANUAL")}
                        selectedValue={option}
                        data-test-id="outlet-address-manual"
                      />
                      <div
                        className={
                          option === "MANUAL"
                            ? "opacity-100 pointer-events-auto"
                            : "opacity-[0.15] pointer-events-none"
                        }
                      >
                        <ErrorTextInput
                          error={
                            incomplete && option === "MANUAL"
                              ? content.settings_error_required
                              : false
                          }
                          value={manualPlace.address}
                          label={content.manager_app_settings_outlet_info_google_manual}
                          onChange={event =>
                            setManualPlace({...manualPlace, address: event.target.value})
                          }
                          style={{
                            width: "90%"
                          }}
                          data-test-id="outlet-address-input"
                        />
                        <OutletOpeningHours
                          location={locationDataTemp}
                          onChangeOpeningHours={updateHours}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end p-lg">
                  <PrimaryButton
                    disabled={!readyToSaveGoogle && !readyToSaveManual}
                    onClick={saveData}
                  >
                    {contentCommon.manager_app_save}
                  </PrimaryButton>
                </div>
              </>
            )}
          </div>
        </ExpandablePanel>
      </div>
    );
  }
);

export default WithLocationData;
