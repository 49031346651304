/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from "react";
import { Icon } from "@ddm-design-system/icon";
import { Description } from "@ddm-design-system/typography";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import { ECleaningUnitButtons, IControlUnit } from "../../../../store/installation/types";
import { requestButtonPress } from "../../../../store/installation/actions";
import useContent from "../../../../hooks/useContent";
import CUInfoSwitcher from "../CUInfoSwitcher";

export interface ISoftWaterNotDetectedProps {
  controlUnit: IControlUnit;
}

const SoftWaterNotDetected: React.FC<ISoftWaterNotDetectedProps> = ({ controlUnit }) => {
  const { managerAppControlUnit: content, managerAppCommon } = useContent();
  const { id } = controlUnit;
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleClear = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({
        button: ECleaningUnitButtons.CLEAR_SOFT,
        controlUnitId: controlUnit.id
      })
    );
  }, [controlUnit.id, dispatch]);

  return (
    <CUInfoSwitcher
      outerChildren={
        <>
          <Icon name="Warn" size={48} className="text-warning-warning100 fill-current" />
          <Description className="text-center">
            {content.manager_app_cu_step_soft_water_not_detected_desc}
          </Description>
        </>
      }
      innerChildren={
        <div className="flex flex-col gap-md items-center h-full">
          <div className="flex flex-1 flex-col gap-md items-center justify-between">
            <Description>{content.manager_app_cu_step_soft_water_not_detected_info}</Description>
          </div>
          <div className="flex flex-row">
            <label className="cursor-pointer" htmlFor={id}>
              <SecondaryButton className="pointer-events-none">
                {managerAppCommon.common_cancel}
              </SecondaryButton>
            </label>
            <PrimaryButton disabled={loadingButtonPress} onClick={handleClear}>
              {managerAppCommon.common_clear}
            </PrimaryButton>
          </div>
        </div>
      }
      label={managerAppCommon.know_more}
      controlUnitId={id}
      labelAtBottom
    />
  );
};

export default SoftWaterNotDetected;
