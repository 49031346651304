import React, { useCallback } from "react";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";

export interface IInsightLeakLineProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightLeakLine: React.FC<IInsightLeakLineProps> = ({ insight, itemType }) => {
  const { managerAppInsights: content } = useContent();

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={content.manager_app_insights_leak_line_title}
          description={content.manager_app_insights_leak_line_desc}
          icon="Installation"
          insight={insight}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={content.manager_app_insights_leak_line_title}
          icon="Installation"
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={content.manager_app_insights_leak_line_title}
          description={content.manager_app_insights_leak_line_desc}
          icon="Installation"
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightLeakLine;
