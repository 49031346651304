import React, { useContext, useState } from "react";
import { Flipped } from "react-flip-toolkit";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { NotificationContext } from "@ddm-design-system/notification";
import { PasswordInput } from "@ddm-design-system/textinput";
import { Body, BodyHighlight, PageTitle } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";

interface IProps {
  token: string;
  setComplete: () => void;
  resetApp: string;
}

export const ResetPassword: React.FC<IProps> = ({ token, setComplete, resetApp }) => {
  const [password, setPassword] = useState("");
  const [rpassword, setRPassword] = useState("");
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const {
    managerAppWelcome: content,
    managerAppLogin: loginContent,
    managerAppCommon: commonContent
  } = useContent();
  const auth = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);

  const resetPasswordAux = async () => {
    try {
      await auth.resetPassword(token, password, resetApp);
      notificationContext.addNotification({
        iconProps: { name: "Ok" },
        text:
          content.manager_app_reset_password_success +
          (resetApp === "control_tower" ? " Redirecting..." : "")
      });
      setFinished(true);
      if (resetApp === "manager_app") {
        setComplete();
      } else if (resetApp === "control_tower") {
        setTimeout(() => {
          setComplete();
        }, 3000);
      }
    } catch (e) {
      setError(content.manager_app_reset_password_error);
    }
  };

  const handleConfirm = () => {
    if (password.length < 6) {
      setError(content.manager_app_reset_password_length);
    } else if (
      password.toLocaleUpperCase() === password ||
      password.toLocaleLowerCase() === password
    ) {
      setError(content.manager_app_reset_password_uppercase);
    } else if (password !== rpassword) {
      setError2(content.manager_app_welcome_pass_not_match);
    } else {
      resetPasswordAux();
    }
  };

  return (
    <>
      <Flipped flipId="title">
        <BodyHighlight className="title-dark">
          {loginContent[`${resetApp}_login_title`] || loginContent.manager_app_login_title}
        </BodyHighlight>
      </Flipped>
      <PageTitle className="page-title fade-in">
        {finished
          ? loginContent.manager_app_finished_reset
          : loginContent.manager_app_reset_password_title}
      </PageTitle>
      {!finished && (
        <Body className="fade-in text-left mt-md">
          {loginContent.manager_app_reset_password_description}
        </Body>
      )}
      {!finished && (
        <form className="login-form-container mt-lg" onSubmit={e => e.preventDefault()}>
          <PasswordInput
            autoFocus
            error={error !== "" ? error : undefined}
            label={content.manager_app_reset_password_placeholder}
            value={password}
            data-test-id="new-password"
            onChange={e => {
              setError("");
              setError2("");
              setPassword(e.target.value);
            }}
          />
          <PasswordInput
            className="reset-password-confirm-input"
            error={error2 !== "" ? error2 : undefined}
            label={content.manager_app_repeat_reset_password_placeholder}
            value={rpassword}
            data-test-id="new-c-password"
            onChange={e => {
              setError("");
              setError2("");
              setRPassword(e.target.value);
            }}
          />
          <div className="login-row button-container">
            <SecondaryButton onClick={setComplete}>{commonContent.common_cancel}</SecondaryButton>
            <Flipped flipId="confirm-button">
              <PrimaryButton
                disabled={password === "" || rpassword === ""}
                type="submit"
                onClick={handleConfirm}
              >
                {commonContent.common_confirm}
              </PrimaryButton>
            </Flipped>
          </div>
        </form>
      )}
    </>
  );
};

export default ResetPassword;
