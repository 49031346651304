import { createSelector } from "reselect";
import { IAppState } from "../index";
import { IGrouping, ILine, ILocationDetails } from "./types";
import { getBeverages } from "../beers/selectors";
import { BeverageTranslation, IBeverageTranslation } from "../beers/types";
import { IPressureChamber, ISteelKegUnit } from "../installation/types";
import { getChosenOutlet } from "../filter/selectors";
import { getOutletsFromGroup } from "../../helpers";
import { IOutletPickerGroupItem } from "../../components/common/outlet_picker/OutletPicker";

export const getAllOutlets = (state: IAppState) => state.outlet.outlets;

export const getLoadingOutlets = (state: IAppState) => state.outlet.loadingOutlets;

export const getLoadingOutletsStatus = (state: IAppState) => state.outlet.loadingOutletsStatus;

export const getLoadingOutletsDetails = (state: IAppState) => state.outlet.loadingOutletDetails;

export const getOutletDetailsError = (state: IAppState) => state.outlet.outletDetailsError;

export const getHasOutlets = (state: IAppState) => getAllOutlets(state).length > 0;

export const getOutletsStatus = (state: IAppState) => {
  const group = getChosenOutlet(state);
  let outletsT: string[] = [];
  if (group?.type) {
    outletsT = getOutletsFromGroup(group as IOutletPickerGroupItem).map(o => o.id);
  }
  return state.outlet.outletsStatus
    .filter(outlet => !!outlet.name && (outletsT.length > 0 ? outletsT.includes(outlet.id) : true))
    .sort((outletA, outletB) =>
      outletA.name.toLocaleLowerCase() < outletB.name.toLocaleLowerCase() ? -1 : 1
    );
};

export const getOutletDetails = (state: IAppState) => {
  return state.outlet.outletDetails;
};

export const getOutletDetailsWithTranslations = createSelector(
  getOutletDetails,
  getBeverages,
  (details, beverages) => {
    const lines: ILine[] = [];

    if (
      details &&
      (details.groupings.length !== 0 ||
        details.pressureChambers.length !== 0 ||
        details.controlUnits.length !== 0 ||
        details.coolingUnits.length !== 0 ||
        details.beerDrives.length !== 0 ||
        details.steelKegUnits.length !== 0 ||
        details.compressors.length !== 0)
    ) {
      const beverageMap: { [key: string]: BeverageTranslation } = {};
      const emptyBeverage = beverages.find(b => b.brand === "empty");

      const { beerDrives, pressureChambers, steelKegUnits } = [
        ...details.beerDrives,
        ...details.pressureChambers,
        ...details.steelKegUnits
      ].reduce(
        (
          acc: {
            beerDrives: IPressureChamber[];
            pressureChambers: IPressureChamber[];
            steelKegUnits: ISteelKegUnit[];
          },
          pressureChamber: any
        ) => {
          let outletBeverage;
          if (!pressureChamber.beverageId) {
            outletBeverage = null;
          } else if (!beverageMap[pressureChamber.beverageId]) {
            outletBeverage = beverages.find(
              (beverage: IBeverageTranslation) => beverage.id === pressureChamber.beverageId
            );
            if (outletBeverage) {
              beverageMap[pressureChamber.beverageId] = outletBeverage;
            }
          } else {
            outletBeverage = beverageMap[pressureChamber.beverageId];
          }

          return {
            ...acc,
            ...(pressureChamber.isBeerDrive
              ? {
                  beerDrives: [
                    ...acc.beerDrives,
                    {
                      ...pressureChamber,
                      beverage: outletBeverage || pressureChamber.beverage
                    }
                  ]
                }
              : pressureChamber.steelKeg
              ? {
                  steelKegUnits: [
                    ...acc.steelKegUnits,
                    {
                      ...pressureChamber,
                      beverage: outletBeverage || pressureChamber.beverage
                    }
                  ]
                }
              : {
                  pressureChambers: [
                    ...acc.pressureChambers,
                    {
                      ...pressureChamber,
                      beverage: outletBeverage || pressureChamber.beverage,
                      noKeg: outletBeverage?.id === emptyBeverage?.id
                    }
                  ]
                })
          };
        },
        { beerDrives: [], pressureChambers: [], steelKegUnits: [] }
      );
      const hasCompressorLine = details.groupings.some(g => g.type === "COMPRESSOR");
      const coolingLines = details.groupings.filter(g => g.unitId && g.type === "COOLING");

      if (details.groupings.length === 0 || !hasCompressorLine) {
        lines.push({
          pressureChambers,
          beerDrives,
          steelKegUnits,
          controlUnits: details?.controlUnits,
          coolingUnits: details?.coolingUnits,
          compressors: details?.compressors,
          errors: details?.componentsWithoutCompressorGroupingsErrors,
          index: 1
        });
      } else {
        let index = 0;
        details.groupings.forEach(g => {
          if (g.type === "COMPRESSOR") {
            let coolingLine: IGrouping | undefined;
            // get coolingLine with the same pcIds than compressor
            if (coolingLines) {
              coolingLine = coolingLines.find(l =>
                g.pressureChamberIds.some(pc => l.pressureChamberIds.includes(pc))
              );
            }
            lines.push({
              pressureChambers: pressureChambers.filter(pc => g.pressureChamberIds.includes(pc.id)),
              controlUnits: details.controlUnits[index] ? [details.controlUnits[index]] : [],
              coolingUnits: coolingLine
                ? details.coolingUnits.filter(cu => cu.id === coolingLine?.unitId)
                : [],
              compressors: g.unitId
                ? details?.compressors.filter(c => c.id === g.unitId)
                : details?.compressors,
              errors: g.errors,
              index: ++index
            });
          }
        });
        if (details.componentsWithoutCompressorGroupings.length > 0) {
          lines.push({
            pressureChambers: pressureChambers.filter(pc =>
              details.componentsWithoutCompressorGroupings.includes(pc.id)
            ),
            controlUnits: details?.controlUnits.filter(cu =>
              details.componentsWithoutCompressorGroupings.includes(cu.id)
            ),
            coolingUnits: details?.coolingUnits.filter(cu =>
              details.componentsWithoutCompressorGroupings.includes(cu.id)
            ),
            beerDrives,
            steelKegUnits,
            compressors: details?.compressors,
            errors: details.componentsWithoutCompressorGroupingsErrors,
            index: 0
          });
        } else if (details.beerDrives.length > 0) {
          lines.push({
            beerDrives,
            errors: details.componentsWithoutCompressorGroupingsErrors,
            index: 0
          });
        } else if (details.steelKegUnits.length > 0) {
          lines.push({
            steelKegUnits,
            errors: details.componentsWithoutCompressorGroupingsErrors,
            index: 0
          });
        }
      }
      lines.forEach(line => {
        if (line.controlUnits && line.controlUnits?.length > 0) {
          if (!line.controlUnits[0].alive && line.errors) {
            line.errors.offline = line.errors?.offline ? line.errors.offline++ : 1;
          }
        }
        if (line.coolingUnits && line.coolingUnits.length > 0) {
          if (!line.coolingUnits[0].alive && line.errors) {
            line.errors.offline = line.errors?.offline ? line.errors.offline++ : 1;
          }
        }
      });
    }
    return {
      ...details,
      lines
    } as ILocationDetails;
  }
);

export const getOutletById = (outletId: string) =>
  createSelector(getAllOutlets, outlets => {
    return outlets.find(outlet => outlet.id === outletId);
  });

export const getOutletLocationDataById = (state: IAppState, outletId: string) =>
  state.outlet.outletsLocationData[outletId];

export const getCurrentOutletPickerTab = (state: IAppState) => state.outlet.currentOutletPickerTab;

export const getOutletCountryInfoById = (outletId: string) =>
  createSelector(getAllOutlets, outlets => {
    const outletInfo = outlets.find(outlet => outlet.id === outletId);

    return outletInfo ? outletInfo.country : null;
  });
