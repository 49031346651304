export const REQUEST_PRODUCTS = "product/REQUEST_PRODUCTS";
export const REQUEST_PRODUCTS_SUCCESS = "product/REQUEST_PRODUCTS_SUCCESS";
export const REQUEST_PRODUCTS_ERROR = "product/REQUEST_PRODUCTS_ERROR";
export const SET_STOCK = "product/SET_STOCK";
export const SET_STOCK_SUCCESS = "product/SET_STOCK_SUCCESS";
export const SET_STOCK_ERROR = "product/SET_STOCK_ERROR";
export const REQUEST_INSIGHTS = "product/REQUEST_INSIGHTS";
export const REQUEST_INSIGHTS_SUCCESS = "product/REQUEST_INSIGHTS_SUCCESS";
export const REQUEST_INSIGHTS_ERROR = "product/REQUEST_INSIGHTS_ERROR";

export enum EPerformanceLevel {
  overperforming = "overperforming",
  good = "good",
  underperforming = "underperforming"
}

export interface IProductInfo {
  avgKegsPerWeek: number;
  kegsInStock: number | null;
  kegsPerWeek: number[];
  lastUpdatedStock: number;
  performance?: keyof typeof EPerformanceLevel;
  tapPositions: number[];
  validVolumePercentage: number;
  volume: number;
  volumePerTap: number;
}

export interface IProduct {
  [beverageId: string]: IProductInfo;
}

export interface IProducts {
  [locationId: string]: IProduct;
}

export interface IApiProduct {
  [beverageId: string]: {
    kegsInStock: number;
    lastUpdatedStock: number;
    kegsPerWeek: number[];
    tapPositions: number[];
    validVolumePercentage: number;
    volume: number;
  };
}

export interface IApiProducts {
  [locationId: string]: IApiProduct;
}

export interface IApiInsights {
  [locationId: string]: {
    goodLowestPerformingTap: boolean;
    underPerformingBeverageIds: string[];
    goodPerformingBeverageIds: string[];
  };
}

export interface IProductState {
  error: boolean;
  insights: IApiInsights;
  insightsError: boolean;
  insightsLoading: boolean;
  loading: boolean;
  products: IProducts;
}

export interface IRequestProducts {
  type: typeof REQUEST_PRODUCTS;
}

export interface IRequestProductsSuccess {
  type: typeof REQUEST_PRODUCTS_SUCCESS;
  payload: IApiProducts;
}

export interface IRequestProductsError {
  type: typeof REQUEST_PRODUCTS_ERROR;
}

export interface ISetStock {
  type: typeof SET_STOCK;
  payload: {
    locationId: string;
    stock: { beverageId: string; numberKegs: number }[];
    updatedToday: boolean;
  };
}

export interface ISetStockSuccess {
  type: typeof SET_STOCK_SUCCESS;
  payload: {
    locationId: string;
    stock: { beverageId: string; numberKegs: number }[];
  };
}

export interface ISetStockError {
  type: typeof SET_STOCK_ERROR;
}

export interface IRequestInsights {
  type: typeof REQUEST_INSIGHTS;
}

export interface IRequestInsightsSuccess {
  type: typeof REQUEST_INSIGHTS_SUCCESS;
  payload: IApiInsights;
}

export interface IRequestInsightsError {
  type: typeof REQUEST_INSIGHTS_ERROR;
}

export type ProductActionTypes =
  | IRequestProducts
  | IRequestProductsSuccess
  | IRequestProductsError
  | ISetStock
  | ISetStockSuccess
  | ISetStockError
  | IRequestInsights
  | IRequestInsightsError
  | IRequestInsightsSuccess;
