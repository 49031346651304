import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import * as d3 from "d3";
import { Subtitle } from "@ddm-design-system/typography";
import { useIsMobile, useIsTablet } from "@ddm-design-system/hooks";
import styles from "./sales.module.scss";
import SalesFilter from "./SalesFilter";
import TimeChartCard from "./cards/TimeChartCard";
import BeerChartCard from "./cards/BeerChartCard";
import { GroupTimeCardHour, GroupTimeCardWeek } from "./cards/GroupTimeCard";
import { day8 } from "../../lib/Time/TimeFunctions";
import { getFilter } from "../../store/filter/selectors";
import useContent from "../../hooks/useContent";
import SalesHeader from "./SalesHeader";
import SpecialityChartCard from "./cards/SpecialityChartCard";
import { FaultySensorBanner } from "./FaultySensorBanner";
import { ETimeFrame } from "../../lib/Time/types";

export const Sales: React.FC = () => {
  const filter = useSelector(getFilter);
  const timeFrame = useMemo(() => filter.timeFrame, [filter]);
  const { managerAppSales: content } = useContent();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const momentFrom = useMemo(
    () =>
      timeFrame.from
        ? isMobile
          ? d3.timeFormat("%a, %d %B %Y")(timeFrame.from.toJSDate())
          : d3.timeFormat("%a, %d %b %Y")(timeFrame.from.toJSDate())
        : "",
    [timeFrame, isMobile]
  );
  const momentTo = useMemo(
    () =>
      timeFrame.to
        ? isMobile
          ? d3.timeFormat("%a, %d %B %Y")(day8(timeFrame.to).toJSDate())
          : d3.timeFormat("%a, %d %b %Y")(day8(timeFrame.to).toJSDate())
        : "",
    [timeFrame, isMobile]
  );
  return (
    content && (
      <div className={styles.salesPage}>
        <SalesFilter />
        {!isMobile && !isTablet && (
          <div className={styles.dateTitleWrapper}>
            {!isMobile && (
              <div className={styles.dateTitle}>
                {timeFrame && (
                  <Subtitle>
                    {timeFrame.type === ETimeFrame.DAY || timeFrame.type === ETimeFrame.YESTERDAY
                      ? `${momentFrom}`
                      : `${momentFrom} - ${momentTo}`}
                  </Subtitle>
                )}
              </div>
            )}
            <FaultySensorBanner />
          </div>
        )}
        <div className={styles.salesPageContainer}>
          <SalesHeader id="sales_header" />
          <div className={styles.chartUpperContainer}>
            <TimeChartCard id="time_consumption" />
          </div>
          <div
            className={classnames(
              styles.chartUpperContainer,
              styles.beerSpeciality,
              (isTablet || isMobile) && styles.mobile
            )}
          >
            <div className={styles.beerSpecialityChart}>
              <SpecialityChartCard id="speciality_mix" />
            </div>
            <BeerChartCard id="beer_consumption" className={styles.beerChart} />
          </div>
          <div className={styles.timeChartsContainer}>
            <GroupTimeCardWeek id="average_per_week_day" className={styles.timeChart} />
            <GroupTimeCardHour id="average_per_day_hour" className={styles.timeChart} />
          </div>
        </div>
      </div>
    )
  );
};
export default Sales;
