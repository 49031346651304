import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Description } from "@ddm-design-system/typography";
import { useDispatch } from "react-redux";
import {
  ECleaningUnitButtons,
  IControlUnit,
  IPressureChamber
} from "../../../store/installation/types";
import { BeverageTranslation } from "../../../store/beers/types";
import { requestButtonPress } from "../../../store/installation/actions";
import useContent from "../../../hooks/useContent";

export interface ICleaningStartModeProps {
  controlUnit: IControlUnit;
  affectedPressureChambers: IPressureChamber[];
}

const MAX_LOGOS_LENGTH = 7;

const CleaningStartMode: React.FC<ICleaningStartModeProps> = ({
  affectedPressureChambers,
  controlUnit
}) => {
  const { managerAppControlUnit: content, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);
  const brands = affectedPressureChambers.flatMap(pc => pc.beverage);

  const showEllipsis = brands.length > MAX_LOGOS_LENGTH;

  const reverseBrands = useMemo(
    () => (showEllipsis ? brands.slice(0, MAX_LOGOS_LENGTH).reverse() : brands.reverse()),
    [brands, showEllipsis]
  );

  const handleImgError = (error: SyntheticEvent, brand: BeverageTranslation) => {
    error.currentTarget.setAttribute(
      "style",
      `background-color: ${brand.mainColor ?? "var(--color-grey-grey50)"}`
    );
  };

  const handleCancel = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({ button: ECleaningUnitButtons.BEER, controlUnitId: controlUnit.id })
    );
  }, [controlUnit.id, dispatch]);

  const handleStart = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({ button: ECleaningUnitButtons.CLEAN, controlUnitId: controlUnit.id })
    );
  }, [controlUnit.id, dispatch]);

  return (
    <div className="flex flex-col gap-md items-center h-full">
      <div className="flex flex-1 flex-col gap-lg items-center justify-center">
        {affectedPressureChambers.length > 0 && (
          <>
            <Description>
              {content.manager_app_cu_step_cleaning_start_mode_affected_taps}
            </Description>
            <div className="flex flex-row-reverse justify-end">
              {reverseBrands.map((brand, i) =>
                brand?.logoUrl ? (
                  <img
                    className="h-lg w-lg rounded-[50%] box-border border border-white flex-shrink-0 shadow-md mr-[-10px]"
                    key={brand.id}
                    src={brand.logoUrl}
                    alt=""
                    title={brand.name}
                    onError={error => handleImgError(error, brand)}
                  />
                ) : (
                  <div
                    className="h-lg w-lg rounded-[50%] box-border border border-white flex-shrink-0 shadow-md mr-[-10px] bg-grey-grey50"
                    key={brand?.id || i}
                    style={{ backgroundColor: brand?.mainColor }}
                    title={brand?.name}
                  />
                )
              )}
            </div>
          </>
        )}
        {affectedPressureChambers.length === 0 && (
          <Description>{content.manager_app_cu_step_cleaning_start_mode_ready}</Description>
        )}
      </div>
      <div className="flex">
        <SecondaryButton disabled={loadingButtonPress} onClick={handleCancel}>
          {common.common_cancel}
        </SecondaryButton>
        <PrimaryButton disabled={loadingButtonPress} onClick={handleStart}>
          {common.manager_app_start}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default CleaningStartMode;
