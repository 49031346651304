import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Flipped, Flipper } from "react-flip-toolkit";
import { useSelector } from "react-redux";
import { useIsMobile } from "@ddm-design-system/hooks";
import { Redirect, RouteComponentProps } from "react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import ReactMarkdown from "react-markdown";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LinkButton, PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { ErrorTextInput, PasswordInput } from "@ddm-design-system/textinput";
import { Chip } from "@ddm-design-system/chip";
import { Checkbox } from "@ddm-design-system/checkbox";
import { Icon } from "@ddm-design-system/icon";
import { Body, DescriptionMedium, PageTitle, SectionTitle } from "@ddm-design-system/typography";
import { getContentfulError } from "../../store/content/selectors";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";
import { AnalyticsContext } from "../../services/analytics";
import Routes from "../../routes";
import RecoverPassword from "./RecoverPassword";
import ResetPassword from "./ResetPassword";
import LanguageAndPrivacy from "./LanguageAndPrivacy";
import { ENVIRONMENT, ENVIRONMENT_TYPE, REGISTER_URL } from "../../constants";
import useParamTokens from "../../hooks/useParamTokens";
import useLoginPictures from "../../hooks/useLoginPictures";
import useAzureAuth from "../../hooks/useAzureAuth";
import "./login.scss";
import VerifyAccount from "./VerifyAccount";

const AZURE_REDIRECT_TIMEOUT_MS = 3000;

export const Login: React.FC<RouteComponentProps> = ({ location, history }) => {
  const analytics = useContext(AnalyticsContext);
  const auth = useContext(AuthContext);
  const { executeRecaptcha = null } = useGoogleReCaptcha();
  const { managerAppLogin: content, managerAppCommon: common } = useContent();
  const isMobile = useIsMobile();
  const contentfulError = useSelector(getContentfulError);
  const { recoveryToken, verifyToken, azureToken, resetApp } = useParamTokens();
  const renderedLoginPictures = useLoginPictures();
  useAzureAuth(azureToken);
  const [forgotPassword, setForgotPassword] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(false);
  const [showPasswordStep, setShowPasswordStep] = useState(false);
  const [isAzureAccount, setIsAzureAccount] = useState(false);
  const [isUsernameRegistered, setIsUsernameRegistered] = useState(true);

  // @ts-ignore
  const { from } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const from = location.state || { from: { pathname: "/" } };
    return from === Routes.login ? Routes.home : from;
  }, [location.state]);

  // check if account is azure or not and redirect accordingly
  const getIsAzureAccount = useCallback(async () => {
    const { isAzure, isRegistered } = await auth.isAzureAccount(username, analytics);
    setIsAzureAccount(isAzure);
    setIsUsernameRegistered(isRegistered);

    if (!isAzure && isRegistered) {
      setShowPasswordStep(true);
    } else if (isAzure) {
      setTimeout(() => {
        auth.adal?.login();
      }, AZURE_REDIRECT_TIMEOUT_MS);
    }
  }, [analytics, auth, username]);

  // login and redirect in case of route on URL
  const login = useCallback(
    async (user = "", pass = "") => {
      setError(false);

      try {
        const recaptchaToken = await executeRecaptcha?.();
        const userId = await auth.login(
          user || username,
          pass || password,
          recaptchaToken || "",
          rememberMe
        );

        analytics.logEvent("LOGIN", username);
        analytics.setAuthenticatedUserContext(userId);
        const { search } = location;
        const params = new URLSearchParams(search);
        const route = params.get("route");

        if (route) {
          setTimeout(() => {
            history.push(route);
          }, 1000);
        }
      } catch (e) {
        analytics.logEvent("LOGIN_ERROR", username, JSON.stringify(e));
        setError(true);
      }
    },
    [executeRecaptcha, auth, username, password, rememberMe, analytics, location, history]
  );

  // automatic login with username and password on URL params
  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);

    const user = params.get("username");
    const pass = params.get("password");
    if (user) {
      setUsername(user);
    }
    if (pass) {
      setPassword(pass);
    }
    if (user && pass && user.length > 0 && pass.length > 0 && executeRecaptcha) {
      login(user, pass);
      setShowPasswordStep(true);
    }
  }, [location, executeRecaptcha]);

  const renderContentfulError = () => (
    <div className="flex flex-col items-center m-auto">
      <SectionTitle className="contentful-error">
        There was an error loading content... <br />
        Please try again later.
      </SectionTitle>
      <div className="mt-md">
        <PrimaryButton onClick={() => window.location.reload()}>Refresh</PrimaryButton>
      </div>
    </div>
  );
  const renderLogo = () => (
    <div className="logo-container">
      <Icon
        name="DmFullLogo"
        height={32}
        fill={isMobile ? "var(--color-black)" : "var(--color-white)"}
      />
    </div>
  );

  const renderEmailStep = () => {
    return (
      <form
        className="login-form-container mt-lg"
        onSubmit={e => {
          e.preventDefault();

          if (username !== "") {
            getIsAzureAccount();
          }
        }}
      >
        <Flipped flipId="email-field">
          <ErrorTextInput
            autoComplete="username"
            error={isUsernameRegistered ? undefined : content.manager_app_wrong_email}
            label={content.manager_app_email}
            name="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Flipped>
        <div className="button-container">
          <Flipped flipId="confirm-button">
            <PrimaryButton type="submit" disabled={username === ""}>
              {common.common_continue}
            </PrimaryButton>
          </Flipped>
        </div>
      </form>
    );
  };

  const renderPasswordStep = () => {
    return (
      <form
        className="login-form-container mt-lg"
        onSubmit={e => {
          e.preventDefault();
          if (!auth.isAuthenticating) {
            login();
          }
        }}
      >
        <Chip className="username-badge">{username}</Chip>
        <Flipped flipId="email-field">
          <PasswordInput
            autoComplete="current-password"
            className="fade-in mt-lg"
            error={error ? content.manager_app_wrong_password : undefined}
            label={content.manager_app_password}
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Flipped>
        <div className="flex justify-between items-center mt-sm fade-in">
          <div className="flex whitespace-nowrap">
            <Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)}>
              {content.manager_app_remember_me}
            </Checkbox>
          </div>
          <div className="flex">
            <div onClick={() => setForgotPassword(true)} className="cursor-pointer">
              <DescriptionMedium>{content.manager_app_forgot_password}</DescriptionMedium>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Flipped flipId="back-button">
            <SecondaryButton
              type="button"
              className="back-button"
              onClick={() => {
                setShowPasswordStep(false);
              }}
            >
              {common.common_back}
            </SecondaryButton>
          </Flipped>
          <Flipped flipId="confirm-button">
            <PrimaryButton type="submit" disabled={password === "" || auth.isAuthenticating}>
              {content.manager_app_login_button}
            </PrimaryButton>
          </Flipped>
        </div>
      </form>
    );
  };

  const renderRedirectStep = () => {
    return (
      <Body className="redirect-text">
        <ReactMarkdown>{content.manager_app_redirect}</ReactMarkdown>
      </Body>
    );
  };

  const middle = () => {
    if (recoveryToken) {
      return (
        <ResetPassword
          resetApp={resetApp}
          token={recoveryToken}
          setComplete={() => {
            if (resetApp === "manager_app") {
              history.push(Routes.login);
            } else if (resetApp === "control_tower") {
              if (ENVIRONMENT === ENVIRONMENT_TYPE.DEV) {
                window.location.href = "https://controltower-dev.dmbuddy.io/login";
              } else if (ENVIRONMENT === ENVIRONMENT_TYPE.STAGING) {
                window.location.href = "https://controltower-staging.dmbuddy.io/login";
              } else {
                window.location.href = "https://controltower.dmbuddy.io/login";
              }
            }
          }}
        />
      );
    }
    if (verifyToken) {
      return (
        <VerifyAccount
          token={verifyToken}
          resetApp={resetApp}
          setComplete={() => {
            if (resetApp === "manager_app") {
              history.push(Routes.login);
            } else if (resetApp === "control_tower") {
              if (ENVIRONMENT === ENVIRONMENT_TYPE.DEV) {
                window.location.href = "https://controltower-dev.dmbuddy.io/login";
              } else if (ENVIRONMENT === ENVIRONMENT_TYPE.STAGING) {
                window.location.href = "https://controltower-staging.dmbuddy.io/login";
              } else {
                window.location.href = "https://controltower.dmbuddy.io/login";
              }
            }
          }}
        />
      );
    }
    if (forgotPassword) {
      return (
        <RecoverPassword
          username={username}
          setForgotPassword={setForgotPassword}
          setUsername={setUsername}
        />
      );
    }
    if (azureToken) {
      return (
        <>
          <Flipped flipId="title">
            <PageTitle>{content.manager_app_login_title}</PageTitle>
          </Flipped>
          <LinkButton className="login-bottom-button mt-xxl">
            <Body
              style={{ cursor: "pointer" }}
              onClick={() => {
                auth.adal?.login();
              }}
            >
              <ReactMarkdown>{content.manager_app_carlsberg_account}</ReactMarkdown>
            </Body>
          </LinkButton>
        </>
      );
    }

    return (
      <>
        <Flipped flipId="title">
          <PageTitle>{content.manager_app_login_title}</PageTitle>
        </Flipped>
        {!isAzureAccount && (
          <Body className="fade-in mt-md">{content.manager_app_login_subtitle}</Body>
        )}
        <SwitchTransition>
          <CSSTransition
            key={isAzureAccount ? "redirect" : showPasswordStep ? "password" : "email"}
            classNames={showPasswordStep ? "background-inverse" : "background"}
            timeout={400}
          >
            {isAzureAccount
              ? renderRedirectStep()
              : showPasswordStep
              ? renderPasswordStep()
              : renderEmailStep()}
          </CSSTransition>
        </SwitchTransition>
      </>
    );
  };

  const renderLogin = () => (
    <div className="flex flex-1 h-full bg-white overflow-hidden">
      {renderedLoginPictures}
      <Flipper className="login-form" flipKey={forgotPassword || recoveryToken || verifyToken}>
        {contentfulError ? (
          renderContentfulError()
        ) : (
          <>
            <div className="login-lower">
              {isMobile && renderLogo()}
              {!isMobile && <LanguageAndPrivacy />}
              <div className="login-form-middle">{middle()}</div>

              <div className="login-bottom fade-and-slide">
                {!azureToken &&
                  !isAzureAccount &&
                  !showPasswordStep &&
                  !recoveryToken &&
                  !verifyToken &&
                  !forgotPassword && (
                    <>
                      <LinkButton className="login-bottom-button">
                        <Body
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            auth.adal?.login();
                          }}
                        >
                          <ReactMarkdown>{content.manager_app_carlsberg_account}</ReactMarkdown>
                        </Body>
                      </LinkButton>
                      <a href={REGISTER_URL} target="_blank" rel="noopener noreferrer">
                        <LinkButton className="cursor-pointer login-bottom-button">
                          <Body>
                            <ReactMarkdown>{content.manager_app_carlsberg_register}</ReactMarkdown>
                          </Body>
                        </LinkButton>
                      </a>
                    </>
                  )}
                {isMobile && <LanguageAndPrivacy />}
              </div>
            </div>
            <span
              className="fade-and-slide
              absolute w-full text-center px-lg bottom-xxs left-0
              text-[10px] leading-[10px] box-border"
              style={{
                animationDelay: "2s",
                animationDuration: "1s"
              }}
            >
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy"> Privacy Policy </a> and
              <a href="https://policies.google.com/terms"> Terms of Service </a> apply.
            </span>
          </>
        )}
      </Flipper>
    </div>
  );

  return auth.isAuthenticated ? (
    <Redirect to={from} />
  ) : (
    <div className="login-page flex h-screen overflow-x-hidden xs:flex-col">{renderLogin()}</div>
  );
};

export default Login;
