import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { BodyHighlight, Subtitle, Body, DescriptionMedium } from "@ddm-design-system/typography";
import { useIsMobile, useIsTablet } from "@ddm-design-system/hooks";
import { Divider } from "@ddm-design-system/divider";
import { formatNumber } from "../../../helpers";
import useContent from "../../../hooks/useContent";
import { PERFORMANCE_TYPE } from "../../../store/filter/types";
import { getChosenOutlet, getFilter, isOutletChosen } from "../../../store/filter/selectors";
import Donut from "../../common/donut/Donut";
import styles from "./speciality-chart.module.scss";

interface ISpecialityMix {
  value: number;
  valueCompare: number;
}

interface IProps {
  data?: ISpecialityMix;
  loading?: boolean;
}

const SpecialityChart: React.FC<IProps> = ({ data, loading = false }) => {
  const {
    managerAppSales: content,
    managerAppOverview: contentOverview,
    managerAppCommon: common
  } = useContent();
  const chosenOutlet = useSelector(getChosenOutlet);
  const isOutletSet = useSelector(isOutletChosen) && !chosenOutlet?.type;
  const { performanceType, outletCompare } = useSelector(getFilter);
  const comparison = data && data?.value - data?.valueCompare;
  const tablet = useIsTablet();
  const mobile = useIsMobile();
  const donutSize = mobile && !tablet ? window.innerWidth - 48 : 300;

  return (
    <div
      className={classnames(styles.container, loading && styles.loading, tablet && styles.tablet)}
    >
      <div className={styles.chart}>
        <Donut
          className={styles.donut}
          value={Math.max(data?.value ?? 1, 1)}
          target={Math.max(data?.valueCompare ?? 1, 1)}
          backgroundColor={{
            border: "var(--color-grey-grey50)",
            fill: loading ? "var(--color-grey-grey50)" : "transparent"
          }}
          foregroundColor={{ fill: "var(--color-background-black" }}
          targetColor={{ fill: "var(--color-warning-warning100)" }}
          targetWidth={mobile && !tablet ? 1.77 : 1.14}
          thickness={20}
          size={donutSize}
        >
          <div className={classnames(styles.comparisonText, loading && styles.loading)}>
            {comparison !== undefined ? (
              <>
                <Subtitle
                  className={
                    comparison > 0 ? styles.positive : comparison < 0 ? styles.negative : ""
                  }
                >
                  {formatNumber(comparison, "%", comparison !== 0)}
                </Subtitle>
                <BodyHighlight>
                  {
                    contentOverview[
                      `manager_app_recent_sales_${PERFORMANCE_TYPE[
                        performanceType
                      ].toLowerCase()}_title`
                    ]
                  }
                </BodyHighlight>
              </>
            ) : (
              loading && (
                <>
                  <Subtitle className={styles.loadingValue}> </Subtitle>
                  <BodyHighlight className={styles.loadingText}> </BodyHighlight>
                </>
              )
            )}
          </div>
        </Donut>
      </div>
      <div className={classnames(styles.details, loading && styles.loading)}>
        <div className={styles.detailsItem}>
          <div className={styles.detailsHeader}>
            <div className={styles.yourOutlet} />
            <Body className="truncate">
              {isOutletSet ? common.your_outlet : common.all_outlets}
            </Body>
          </div>
          <Subtitle className={styles.detailsValue}>
            {formatNumber(data?.value ?? 0, "%", false)}
          </Subtitle>
          <DescriptionMedium>{content.manager_app_speciality_mix}</DescriptionMedium>
        </div>
        <Divider orientation="vertical" className={styles.divider} />
        <div className={styles.detailsItem}>
          <div className={styles.detailsHeader}>
            <div className={styles.compareOutlet} />
            <Body className="truncate">
              {performanceType === PERFORMANCE_TYPE.OUTLET
                ? outletCompare?.name
                : contentOverview[
                    `manager_app_recent_sales_${PERFORMANCE_TYPE[
                      performanceType
                    ].toLowerCase()}_title`
                  ]}
            </Body>
          </div>
          <Subtitle className={styles.detailsValue}>
            {formatNumber(data?.valueCompare ?? 0, "%", false)}
          </Subtitle>
          <DescriptionMedium>{content.manager_app_speciality_mix}</DescriptionMedium>
        </div>
      </div>
    </div>
  );
};

export default SpecialityChart;
