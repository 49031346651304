import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";
import { getPositionsFromOutletByThingIds } from "../../../../store/installation/selectors";

export interface IInsightFaultySensorProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightFaultySensor: React.FC<IInsightFaultySensorProps> = ({ insight, itemType }) => {
  const {
    managerAppInsights: content
  } = useContent();
  const { thing_ids }: any = insight.customFields;

  const positions = useSelector(getPositionsFromOutletByThingIds(insight.locationId,thing_ids));

  const description = thing_ids.length > 1 ? 
  content.manager_app_insights_faulty_sensor_desc : 
  content.manager_app_insights_faulty_sensor_unit_desc;

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={content.manager_app_insights_faulty_sensor_title}
          description={description.replace("%POS%", positions?.join(", ") || "")}
          icon="FaultySensor"
          iconOptions={{ name: "FaultySensor", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={content.manager_app_insights_faulty_sensor_title}
          icon="FaultySensor"
          iconOptions={{ name: "FaultySensor", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={content.manager_app_insights_faulty_sensor_title}
          description={description.replace("%POS%", positions?.join(", ") || "")}
          icon="FaultySensor"
          iconOptions={{ name: "FaultySensor", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightFaultySensor;
