import React from "react";
import { Dropdown } from "@ddm-design-system/dropdown";
import styled from "styled-components";
import { hoursToMinutes, minutesToHours } from "../../lib/Time/TimeFunctions";

const FIRST_HOUR = 0;
const hours = Array.from(Array(48).keys());
const hourItems = hours.map(_key => {
  const key = (_key + FIRST_HOUR * 2) % hours.length; // sets the first hour to appear on the dropdown
  const hour = Math.floor(key / 2);
  const minutes = key % 2 ? 30 : 0;
  const id = hoursToMinutes(hour, minutes);
  return {
    id: id.toString(),
    text: minutesToHours(id)
  };
});

interface IProps {
  className?: string;
  selectedHour: number;
  disabled?: boolean;
  onHourSelected: (hour: number) => void;
}

const StyledDropdown = styled(Dropdown)`
  min-width: unset;
`;

const HourDropdown: React.FC<IProps> = ({ disabled, selectedHour, onHourSelected, ...props }) => (
  <StyledDropdown
    selectedItem={
      selectedHour !== undefined
        ? hourItems.find(i => i.id === selectedHour.toString()) ?? {
            id: selectedHour.toString(),
            text: minutesToHours(selectedHour)
          }
        : undefined
    }
    onItemSelected={item => onHourSelected(parseInt(item.id))}
    items={hourItems}
    disabled={disabled}
    style={{ width: 100 }}
    className="teste"
    {...props}
  />
);

export default HourDropdown;
