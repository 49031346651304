import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReactMarkdown from "react-markdown";
import { DangerButton, PrimaryButton } from "@ddm-design-system/button";
import { ErrorTextInput, PasswordInput } from "@ddm-design-system/textinput";
import { Checkbox } from "@ddm-design-system/checkbox";
import { Divider } from "@ddm-design-system/divider";
import { Body, Description, SectionTitle } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import LanguagePicker from "../common/language_picker/LanguagePicker";
import AuthContext from "../auth/AuthContext";
import {
  deleteProfile,
  setEmail as setEmailAction,
  setName as setNameAction,
  setPassword as setPasswordAction,
  updateEmailFrequency
} from "../../store/profile/actions";
import { getProfile } from "../../store/profile/reducer";
import { digestEmailsFrequency } from "../../constants";
import { validateEmail } from "../../helpers";
import { SettingsBackButton } from "../settings/Settings";
import { ERole } from "../../store/users/types";

export const SettingsProfile: React.FC = () => {
  const auth = useContext(AuthContext);
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const { managerAppCommon: content, managerAppSettings: contentSettings } = useContent();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newRPassword, setNewRPassword] = useState("");
  const [loggingOut, setLoggingOut] = useState(false);

  const {
    user: me,
    deletingProfile,
    deleteProfileError,
    emailChangingSuccess
  } = useSelector(getProfile);

  const loginRoute = useMemo(() => `${Routes.logout}?username=${email}`, [email]);

  const logout = () => {
    setTimeout(() => {
      auth.logout();
      history.push(loginRoute);
    }, 4000);
  };

  function setNameAndEmail() {
    if (name !== me?.name) {
      dispatch(setNameAction(name));
    }
    if (email !== me?.username) {
      dispatch(setEmailAction(email));
    }
  }

  useEffect(() => {
    if (emailChangingSuccess) {
      setLoggingOut(true);
      logout();
    }
  }, [emailChangingSuccess]);

  function setPasswordAux() {
    if (newPassword !== "" && currentPassword !== "") {
      dispatch(setPasswordAction(currentPassword, newPassword));
      setCurrentPassword("");
      setNewPassword("");
      setNewRPassword("");
    }
  }

  const isValidEmail = useMemo(() => email && validateEmail(email), [email]);

  useEffect(() => {
    if (me) {
      setName(me?.name ?? "");
      setEmail(me?.username);
    }
  }, [me]);

  const deleteAccount = () => {
    dispatch(deleteProfile(confirmPassword));
    setConfirmPassword("");
  };

  const updateEmailFrequencyAux = (frequency: string) => () => {
    const freq = me?.emailDigestFrequencies.find(
      (f: string) => f.toUpperCase() === frequency.toUpperCase()
    );
    let frequencies = me?.emailDigestFrequencies || [];
    if (freq) {
      frequencies = frequencies.filter((f: string) => f.toUpperCase() !== frequency.toUpperCase());
    } else {
      frequencies.push(frequency.toUpperCase());
    }
    dispatch(updateEmailFrequency(frequencies));
  };

  return (
    me &&
    contentSettings && (
      <div className="mb-[96px]">
        {isMobile && (
          <div className="text-left mb-lg">
            <SettingsBackButton className="p-0 mb-xxl" />
            <SectionTitle>{contentSettings.manager_app_settings_account_tab}</SectionTitle>
          </div>
        )}
        {/*
         * This is only in case the user.azure comes undefined from the backend call
         * In that case it shows the password settings
         */}
        {!(typeof me?.azure === "boolean" && me?.azure) && (
          <>
            <div className="flex flex-col justify-start mt-xl">
              <SectionTitle className="text-left">
                {contentSettings.manager_app_settings_profile}
              </SectionTitle>

              <div className="mt-lg rounded-10 flex flex-col justify-start items-start">
                <div className="flex flex-row items-start w-full md:flex-col md:m-0">
                  {!loggingOut ? (
                    <>
                      <ErrorTextInput
                        className="md:mb-[10px] mr-xxl md:mr-0 md:w-full"
                        error={name !== null && name.length === 0}
                        label={content.common_form_name_placeholder}
                        value={name || undefined}
                        onChange={(e: any) => {
                          setName(e.target.value);
                        }}
                        data-test-id="name-input"
                      />
                      <ErrorTextInput
                        className="md:mb-[10px] mr-xxl md:mr-0 md:w-full"
                        error={
                          !isValidEmail
                            ? `${contentSettings.manager_app_settings_error_invalid_email}`
                            : undefined
                        }
                        label={content.common_form_email_placeholder}
                        value={email || undefined}
                        onChange={(e: any) => setEmail(e.target.value)}
                        data-test-id="email-input"
                      />
                      <PrimaryButton
                        className="settings-container-button"
                        disabled={!isValidEmail || (name !== null && name.length === 0)}
                        onClick={setNameAndEmail}
                        data-test-id="change-name-button"
                      >
                        {content.manager_app_save}
                      </PrimaryButton>
                    </>
                  ) : (
                    <ReactMarkdown className="logout-text">
                      {(content.manager_app_edit_profile_logout || "")
                        .replace("%EMAIL%", email)
                        .replace("%URL%", loginRoute)}
                    </ReactMarkdown>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-start mt-xl">
              <SectionTitle className="text-left">
                {contentSettings.manager_app_settings_password}
              </SectionTitle>
              <div className="mt-lg rounded-10 flex flex-col justify-start items-start">
                <div className="flex flex-row items-start w-full md:flex-col md:m-0">
                  <input
                    readOnly
                    autoComplete="username"
                    name="username"
                    style={{ display: "none" }}
                    value={me?.username}
                  />
                  <PasswordInput
                    autoComplete="old-password"
                    className="md:mb-[10px] mr-xxl md:mr-0 md:w-full"
                    error={
                      currentPassword.length === 0 &&
                      (newPassword.length !== 0 || newRPassword.length !== 0)
                    }
                    label={content.manager_app_current_password}
                    value={currentPassword}
                    onChange={(e: any) => setCurrentPassword(e.target.value)}
                    data-test-id="password-input"
                  />
                  <PasswordInput
                    autoComplete="new-password"
                    className="md:mb-[10px] mr-xxl md:mr-0 md:w-full"
                    label={content.manager_app_new_password}
                    value={newPassword}
                    onChange={(e: any) => setNewPassword(e.target.value)}
                    data-test-id="n-password-input"
                  />
                  <PasswordInput
                    autoComplete="new-password"
                    className="md:mb-[10px] mr-xxl md:mr-0 md:w-full"
                    error={newPassword.length !== 0 && newPassword !== newRPassword}
                    label={content.manager_app_repeat_new_password}
                    value={newRPassword}
                    onChange={(e: any) => setNewRPassword(e.target.value)}
                    data-test-id="c-password-input"
                  />
                  <PrimaryButton
                    className="settings-container-button"
                    disabled={
                      currentPassword.length === 0 ||
                      newPassword.length === 0 ||
                      newRPassword.length === 0 ||
                      newPassword !== newRPassword
                    }
                    onClick={setPasswordAux}
                    data-test-id="change-password-button"
                  >
                    {content.manager_app_save}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col justify-start mt-xl mb-xxl">
          <SectionTitle className="text-left">
            {contentSettings.manager_app_settings_language}
          </SectionTitle>
          <div className="mt-lg rounded-10 flex flex-col justify-start items-start">
            <LanguagePicker extended direction="right" />
          </div>
        </div>

        {[
          ERole.ROLE_SUB_SUPERVISOR,
          ERole.ROLE_OWNER,
          ERole.ROLE_STAFF,
          ERole.ROLE_READER,
          ERole.ROLE_SALES_REP
        ].includes(me?.role) && (
          <div className="flex flex-col justify-start mt-xl mb-xxl">
            <SectionTitle className="text-left">
              {contentSettings.manager_app_settings_statistic_emails}
            </SectionTitle>

            <div className="mt-lg rounded-10 flex flex-col justify-start items-start">
              <Body className="text-start text-grey-grey100">
                {contentSettings.manager_app_settings_statistic_emails_description}
              </Body>
              <div className="flex flex-wrap pt-xs">
                {digestEmailsFrequency.map(frequency => (
                  <Checkbox
                    checked={me?.emailDigestFrequencies
                      .map((d: string) => d.toUpperCase())
                      .includes(frequency)}
                    className="mr-lg"
                    key={frequency}
                    onChange={updateEmailFrequencyAux(frequency)}
                  >
                    {content[`manager_app_settings_${frequency.toLowerCase()}`]}
                  </Checkbox>
                ))}
              </div>
            </div>
          </div>
        )}

        {/*
         * This is only in case the user.azure comes undefined from the backend call
         * In that case it shows the password settings
         */}
        {!(typeof me?.azure === "boolean" && me?.azure) && (
          <>
            <Divider className="!bg-alert-alert25" />
            <div className="flex flex-col justify-start">
              <SectionTitle className="text-left text-alert-alert100 mt-lg">
                {contentSettings.manager_app_settings_delete_account}
              </SectionTitle>
              <div className="mt-lg rounded-10 flex flex-col justify-start items-start">
                <Body className="mb-lg text-start">
                  {contentSettings.manager_app_settings_delete_account_description}
                </Body>
                <div className="flex flex-row items-start w-full md:flex-col md:m-0 ">
                  <PasswordInput
                    className="md:mb-[10px] mr-xxl md:mr-0 md:w-full"
                    label={content.manager_app_current_password}
                    value={confirmPassword || undefined}
                    onChange={(e: any) => setConfirmPassword(e.target.value)}
                    data-test-id="delete-input"
                  />
                  <DangerButton
                    className="md:self-end"
                    disabled={confirmPassword.length === 0}
                    onClick={deleteAccount}
                    data-test-id="delete-button"
                  >
                    {deletingProfile
                      ? contentSettings.manager_app_settings_delete_loading
                      : contentSettings.manager_app_settings_delete_button}
                  </DangerButton>
                </div>
                {deleteProfileError != null && (
                  <Description className="text-left p-xs text-alert-alert100">
                    {contentSettings.manager_app_settings_delete_error}
                  </Description>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    )
  );
};
