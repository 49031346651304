import React, { useCallback, useContext, useMemo, useState } from "react";
import classnames from "classnames";
import { Body, BodyHighlight, SectionTitle } from "@ddm-design-system/typography";
import { LinkButton } from "@ddm-design-system/button";
import { Divider } from "@ddm-design-system/divider";
import { Icon } from "@ddm-design-system/icon";
import { getBeverageLogoOptions } from "../../../helpers";
import { BeverageTranslation } from "../../../store/beers/types";
import useContent from "../../../hooks/useContent";
import { translatedBeerType } from "../../../constants";
import { BeerType } from "../../../services/aggregator";
import ProductBeverageDataItem from "../ProductBeverageDataItem";
import { AnalyticsContext } from "../../../services/analytics";

export interface ISimilarBrandItemProps {
  beverage: BeverageTranslation;
  outletCountryId: string;
}

const SimilarBrandItem: React.FC<ISimilarBrandItemProps> = ({ beverage, outletCountryId }) => {
  const analytics = useContext(AnalyticsContext);
  const { managerAppCommon: common, managerAppProduct: content } = useContent();
  const [showMore, setShowMore] = useState(false);
  const avatarOptions = useMemo(() => getBeverageLogoOptions(beverage), [beverage]);

  const handleShowMore = useCallback(() => {
    analytics.logEvent("BRAND_RECOMENDATION_SHOW_MORE");
    setShowMore(!showMore);
  }, [analytics, showMore]);

  return (
    <div className="flex flex-col border border-grey-grey25 rounded-10 w-[360px] h-[max-content]">
      <div className="p-lg border-b border-grey-grey25">
        <div className="grid grid-cols-[62px,1fr] grid-rows-[31px,31px] items-center text-left">
          {avatarOptions.img?.src ? (
            <img
              src={avatarOptions.img?.src}
              className="rounded-[50%] flex-shrink-0 shadow-8 w-[62px] h-[62px] row-start-1 row-end-3"
              alt={beverage?.name}
            />
          ) : (
            <div
              className="flex items-center justify-center rounded-[50%] flex-shrink-0 shadow-8 w-[62px] h-[62px] row-start-1 row-end-3"
              style={{ backgroundColor: avatarOptions.backgroundColor }}
            >
              <SectionTitle>{avatarOptions.fallbackText}</SectionTitle>
            </div>
          )}
          <BodyHighlight className="ml-md col-[2] row-[1]">{beverage.name}</BodyHighlight>
          <Body className="ml-md col-[2] row-[2] text-grey-grey100">
            {
              common[
                `common_beer_type_${
                  translatedBeerType[
                    beverage.countryInformation?.[outletCountryId]?.craftAndSpeciality as BeerType
                  ]
                }`
              ]
            }
          </Body>
        </div>
        <div
          className={classnames(
            "max-h-0 opacity-0 transition-all duration-500 delay-[0s]",
            showMore && "max-h-[600px] opacity-100 mt-lg"
          )}
        >
          <div className="border border-grey-greenish rounded-10 py-md px-lg">
            <ProductBeverageDataItem label={content.manager_app_product_beer_type} isVertical>
              {beverage.type}
            </ProductBeverageDataItem>

            <Divider className="my-md" />

            <ProductBeverageDataItem label={content.manager_app_product_country} isVertical>
              {beverage.countryOfOrigin ?? "--"}
            </ProductBeverageDataItem>

            <Divider className="my-md" />

            <ProductBeverageDataItem label={content.manager_app_product_abv} isVertical>
              {(beverage.countryInformation?.[outletCountryId]?.abv || beverage.abv) ?? "--"}
            </ProductBeverageDataItem>
          </div>
          <div className="flex mt-lg">
            <Icon name="Warning" size={16} className="mt-xxs mr-xs text-black fill-current" />
            <Body className="flex-1 text-left">
              {content.manager_app_product_brand_recomendation_sales_rep}
            </Body>
          </div>
        </div>
      </div>
      <LinkButton
        className="flex items-center justify-center !px-0 w-full z-[1]"
        icon={showMore ? "ChevronUp" : "ChevronDown"}
        onClick={handleShowMore}
      >
        {showMore ? "Hide" : "Know more"}
      </LinkButton>
    </div>
  );
};

export default SimilarBrandItem;
