import React, { useCallback, useState } from "react";
import { Description } from "@ddm-design-system/typography";
import { PrimaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import SVGAirFlush from "./assets/air_flush";
import { ECleaningUnitButtons, IControlUnit } from "../../../store/installation/types";
import { requestButtonPress } from "../../../store/installation/actions";
import useContent from "../../../hooks/useContent";

export interface IAirFlushProps {
  controlUnit: IControlUnit;
}

const AirFlushMode: React.FC<IAirFlushProps> = ({ controlUnit }) => {
  const { managerAppControlUnit: content } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleContinue = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({ button: ECleaningUnitButtons.AIR_FLUSH, controlUnitId: controlUnit.id })
    );
  }, [controlUnit.id, dispatch]);

  return (
    <div className="flex flex-col gap-md items-center">
      <SVGAirFlush />
      <div className="flex flex-col gap-xxl items-center h-full">
        <Description>{content.manager_app_cu_step_airflush_mode_desc}</Description>
        <PrimaryButton disabled={loadingButtonPress} onClick={handleContinue}>
          {content.manager_app_cu_stop_airflush}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default AirFlushMode;
