import { translate, translateString } from "./translate";
import { IEntries } from "./index";

export interface IContentfulJSONContent {
  fields: {
    key: { [key: string]: string };
    content: { [key: string]: JSON };
  };
}

export default function mapJSONContent(entries: IEntries) {
  const parsedUnits: any = {};
  entries.jsonContent.ordered.forEach((entry: IContentfulJSONContent) => {
    const key = translateString(entry.fields.key);
    const content = translate(entry.fields.content);
    parsedUnits[key] = content;
  });
  return {
    jsonContent: parsedUnits
  };
}
