import { EBreakpoints } from "@ddm-design-system/tokens";
import { createGlobalStyle, css } from "styled-components";

// It seems global styles cannot be extended, so adding an extra, custom
// global styles for this carousel is necessary
export const CustomGlobalCarouselStyles = createGlobalStyle<{
  isLeftArrowVisible: boolean;
  isRightArrowVisible: boolean;
}>`
${({ isLeftArrowVisible, isRightArrowVisible, theme }) => css`
    .insights-carousel-container {
      .react-multi-carousel-track {
        gap: ${theme.spaceUnit.lg};
      }

      ::before,
      ::after {
        content: "";
        height: 100%;
        opacity: 0;
        position: absolute;
        transition: opacity 0.2s ease-in-out;
        width: 64px;
      }

      ${isLeftArrowVisible && css`
        ::before {
          opacity: 1;
          background: linear-gradient(270deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.9) 100%);
          z-index: 1;
          pointer-events: none;
          width: 100px;
          @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
            width: 40px;
          }
          left: 0;
        }
      `}

      ${isRightArrowVisible && css`
        ::after {
          opacity: 1;
          background: linear-gradient(90deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.9) 100%);
          pointer-events: none;
          width: 100px;
          @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
            width: 40px;
          }
          right: 0;
        }
      `}

      .react-multiple-carousel__arrow {
        background: ${theme.colors.grey.darkest};
        box-sizing: border-box;
        fill: ${theme.colors.white};
        height: 32px;
        padding: ${theme.spaceUnit.xxs};
        width: 32px;
        z-index: 1;
      }

      .react-multiple-carousel__arrow--left {
        left: ${theme.spaceUnit.lg};
      }

      .react-multiple-carousel__arrow--right {
        right: ${theme.spaceUnit.lg};
      }

      .react-multiple-carousel__arrow:hover {
        background: ${theme.colors.grey.darkest};
      }

      .react-multiple-carousel__arrow:disabled,
      .react-multiple-carousel__arrow[disabled] {
        opacity: 0;
        pointer-events: none;
      }

      .react-multi-carousel-item {
        justify-content: space-between;
        width: auto !important;
      }
    }
  `}
`;
