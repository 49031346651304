import React, { useCallback, useState } from "react";
import { Description } from "@ddm-design-system/typography";
import { PrimaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import { StyledProgressBar } from "./common.styles";
import SVGClock from "./assets/clock";
import { ECleaningUnitButtons, IControlUnit } from "../../../store/installation/types";
import { requestButtonPress } from "../../../store/installation/actions";
import useContent from "../../../hooks/useContent";

export interface ISoakModeProps {
  controlUnit: IControlUnit;
}

const SoakMode: React.FC<ISoakModeProps> = ({ controlUnit }) => {
  const { managerAppControlUnit: content, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleContinue = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({ button: ECleaningUnitButtons.CLEAN, controlUnitId: controlUnit.id })
    );
  }, [controlUnit.id, dispatch]);

  return (
    <div className="flex flex-col gap-md items-center h-full">
      <SVGClock />
      <Description className="text-center">
        {content.manager_app_cu_step_soak_mode_wait}
        <br /> ({Math.ceil((controlUnit.delayRemain || 0) / 60)} min)
      </Description>
      <StyledProgressBar value={controlUnit.progress} />
      <PrimaryButton
        disabled={controlUnit.progress < 100 || loadingButtonPress}
        onClick={handleContinue}
      >
        {common.common_continue}
      </PrimaryButton>
    </div>
  );
};

export default SoakMode;
