import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { ESidebarDirection, ESidebarModes, Sidebar } from "@ddm-design-system/sidebar";
import {
  BodyHighlight,
  Description,
  DescriptionMedium,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { Divider } from "@ddm-design-system/divider";
import ReactMarkdown from "react-markdown";
import { LinkButton } from "@ddm-design-system/button";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { useDispatch } from "react-redux";
import { showFaultySensorModal as showFaultySensorModalAction } from "../../store/modals/actions";
import { Keg } from "../common/keg/keg";
import { AnalyticsContext } from "../../services/analytics";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";

export interface IProps {
  pressureChamber: IPressureChamber | undefined;
  outletId?: string;
  isVisible: boolean;
  handleClose: () => void;
  onPressureChamberEdit: (outletId: string | undefined, pressureChamber: IPressureChamber) => void;
}

export const StyledSideBar = styled(Sidebar)(
  ({ theme }) => css`
    background-color: ${theme.colors.white};

    @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
      &.over.right {
        transform: translateY(100%);
      }

      &.over.open {
        transform: translateY(0);
      }
    }
  `
);

const PressureChamberDetailsSidebar: React.FC<IProps> = ({
  pressureChamber,
  outletId,
  onPressureChamberEdit,
  isVisible,
  handleClose
}) => {
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();
  const { managerAppInstallation: content, managerAppCommon: common } = useContent();
  const [fixedBeverageInfo, setFixedBeverageInfo] = useState(false);
  const {
    position,
    tapPosition,
    beverage: { name },
    tags,
    existsLeak,
    isOpen
  } = pressureChamber || { beverage: {} };

  const sidebarRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (sidebarRef?.current) {
      const sidebarElement = sidebarRef.current;
      const onScroll = () => {
        const shouldShowFixedBeverageInfo = sidebarElement.scrollTop > 130;
        if (shouldShowFixedBeverageInfo !== fixedBeverageInfo) {
          setFixedBeverageInfo(shouldShowFixedBeverageInfo);
        }
      };
      sidebarElement.addEventListener("scroll", onScroll);
      return () => sidebarElement.removeEventListener("scroll", onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarRef.current, fixedBeverageInfo]);

  const showFaultySensorModal = useCallback(() => {
    analytics.logEvent("FAULTY_SENSOR", "Installation");
    if (position) {
      dispatch(showFaultySensorModalAction([position]));
    }
  }, [dispatch, position, analytics]);

  const errorToShow = useMemo(
    () => (existsLeak && { existsLeak }) || tags?.find(t => t.level === "danger"),
    [existsLeak, tags]
  );

  return (
    <StyledSideBar
      ref={sidebarRef}
      className="!fixed overflow-auto max-h-screen !w-[432px] xs:!w-full xs:mt-xxxl xs:rounded-t-10 xs:!h-[calc(100%-64px)]"
      open={isVisible}
      onClose={handleClose}
      mode={ESidebarModes.OVER}
      direction={ESidebarDirection.RIGHT}
    >
      {isVisible && pressureChamber ? (
        <>
          <div
            className="flex flex-col items-center text-center pb-xxl relative"
            data-test-id="pressureChamber-sidebar"
          >
            <div className="flex items-center justify-between sticky box-border bg-white p-lg top-0 w-full border-b border-grey-grey50 z-[1] ">
              <Subtitle>
                {pressureChamber.isBeerDrive ? common.common_tank : common.common_keg}{" "}
                {position || "--"}
              </Subtitle>
              <Icon name="Close" className="cursor-pointer" size={16} onClick={handleClose} />
            </div>
            {isOpen ? (
              <SidebarErrorBanner errorToShow={{ isOpen }} />
            ) : (
              errorToShow && <SidebarErrorBanner errorToShow={errorToShow} />
            )}
            <div className="flex flex-col items-center text-center box-border w-full px-lg">
              <div
                className={`my-xxl transform-13 ${
                  pressureChamber.isBeerDrive && "w-[260px] blank-beer-drive"
                }`}
              >
                <Keg pressureChamber={pressureChamber} />
              </div>
              <SectionTitle>{name}</SectionTitle>
              <DescriptionMedium
                className="mt-xs text-grey-grey100 cursor-pointer"
                onClick={() => onPressureChamberEdit?.(outletId, pressureChamber)}
              >
                {content.manager_app_change_brand} &gt;
              </DescriptionMedium>
              <div className="flex mt-lg gap-xs">
                {tapPosition?.map(t => (
                  <DescriptionMedium
                    key={t}
                    className="rounded-[40px] px-lg py-xxs bg-grey-greenish"
                  >
                    {common.common_tap} {t}
                  </DescriptionMedium>
                ))}
              </div>
            </div>
            <Divider className="my-xl !bg-grey-grey50" />
            <div className="flex flex-col box-border items-center text-center w-full px-lg">
              <SectionTitle>{content.manager_app_general_status}</SectionTitle>
              {tags
                ?.filter(t => t.type !== "exists_leak")
                .map(tag => {
                  const level =
                    pressureChamber.offline &&
                    tag.type !== "connection" &&
                    !(tag.type === "sensor" && tag.level === "danger")
                      ? "disabled"
                      : tag.level;

                  return (
                    <div
                      key={tag.type}
                      className={`border border-grey-greenish rounded-10 w-[calc(100%-48px)] m-lg mb-0 p-lg
                        ${pressureChamber?.offline ? "opacity-50" : "opacity-100"}
                        ${
                          !pressureChamber?.offline &&
                          level === "danger" &&
                          "border-alert-alert50 text-alert-alert100"
                        }
                        ${
                          !pressureChamber?.offline &&
                          level === "warning" &&
                          "border-warning-warning50"
                        }
                      `}
                    >
                      <span
                        className={`flex items-center justify-center mb-sm ${
                          !pressureChamber?.offline &&
                          level === "warning" &&
                          "text-warning-warning100"
                        }`}
                      >
                        <Icon
                          className={`border border-transparent rounded-[50%] mr-xs
                            ${
                              level === "success"
                                ? tag.type === "connection"
                                  ? "text-white fill-current border-success-success100 bg-success-success100"
                                  : "text-success-success100 fill-current"
                                : ""
                            }
                            ${
                              level === "warning"
                                ? tag.type === "connection"
                                  ? "text-white fill-current border-warning-warning100 bg-warning-warning100"
                                  : "text-warning-warning100 fill-current"
                                : ""
                            }
                            ${
                              level === "danger"
                                ? tag.type === "connection"
                                  ? "text-white fill-current border-alert-alert100 bg-alert-alert100"
                                  : "text-alert-alert100 fill-current"
                                : ""
                            }
                            ${
                              level === "disabled"
                                ? tag.type === "connection"
                                  ? "text-white fill-current border-grey-grey50 bg-grey-grey50"
                                  : "text-grey-grey50 fill-current"
                                : ""
                            }
                            ${
                              level === "normal"
                                ? tag.type === "connection"
                                  ? "text-white fill-current border-black bg-black"
                                  : "text-black fill-current"
                                : ""
                            }
                          `}
                          name={tag.icon}
                          size={16}
                        />
                        <DescriptionMedium>
                          {content[`manager_app_${tag.type}_title`]}
                        </DescriptionMedium>
                      </span>
                      <Description>
                        {pressureChamber?.offline ? (
                          common.common_offline
                        ) : (
                          <ReactMarkdown>
                            {(content[`main_status_details_${tag.desc}`] || "")
                              .replace("%days%", tag.replaceValue)
                              .replace("%days2%", tag.replaceValue2)}
                          </ReactMarkdown>
                        )}
                      </Description>
                      {tag.type === "sensor" && pressureChamber.isFaulty && (
                        <div className="items-center">
                          <Divider className="mt-sm mb-xs" />
                          <div className="flex justify-center">
                            <LinkButton onClick={showFaultySensorModal}>
                              {common.know_more}
                            </LinkButton>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </StyledSideBar>
  );
};

export default PressureChamberDetailsSidebar;

export const SidebarErrorBanner: React.FC<{ errorToShow: any }> = ({ errorToShow }) => {
  const { managerAppInstallation: content } = useContent();

  return (
    <div
      data-test-id="error-banner"
      className={`flex flex-col items-center box-border w-full p-lg  ${
        errorToShow.isOpen ? "bg-grey-greenish text-black" : "bg-alert-alert100 text-white"
      }`}
    >
      <div className="flex items-center justify-center">
        <Icon
          className={`mr-xs ${errorToShow.isOpen ? "text-black" : "text-white"} fill-current`}
          name="Warning"
          size={24}
        />
        <BodyHighlight>
          {errorToShow.existsLeak
            ? content.manager_app_exists_leak_title
            : errorToShow.isOpen
            ? content.manager_app_is_open_title
            : content[`manager_app_${errorToShow.type}_title`]}
        </BodyHighlight>
      </div>
      <div className="mt-lg">
        <Description>
          {errorToShow.existsLeak ? (
            <span>{content.manager_app_exists_leak_description}</span>
          ) : errorToShow.isOpen ? (
            <span>{content.manager_app_is_open_description}</span>
          ) : (
            <ReactMarkdown>
              {(content[`main_status_details_${errorToShow.desc}`] || "")
                .replace("%days%", errorToShow.replaceValue)
                .replace("%days2%", errorToShow.replaceValue2)}
            </ReactMarkdown>
          )}
        </Description>
      </div>
    </div>
  );
};
