import React from "react";

const SVGTapPressure = () => (
  <svg width="43" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21.062" cy="27.843" r="20.562" stroke="#E5EBE8" />
    <mask id="a" maskUnits="userSpaceOnUse" x="8" y="0" width="26" height="58">
      <path
        d="M16.726 44.625h8.672m-8.672 0H12.39a2.89 2.89 0 0 0-2.891 2.89v9.646h23.126v-9.645a2.89 2.89 0 0 0-2.89-2.891h-4.337m-8.672 0V15.98m8.672 28.645V15.98m0 0h1.183a2.628 2.628 0 0 0 0-5.256h-2.237m1.054 5.256h-1.084m-7.588 0h-1.183a2.628 2.628 0 1 1 0-5.256H17.779m-1.053 5.256h1.084m2.2-6.7a4.207 4.207 0 0 0 0 8.144m0-8.145V1.263c0-.145.118-.263.263-.263h1.577c.145 0 .263.118.263.263v8.016m-2.102 0v4.73h2.102v-4.73m0 0a4.207 4.207 0 0 1 0 8.145m-2.102 0a4.215 4.215 0 0 0 2.102 0m-2.102 0v2.235c0 .29.235.525.525.525h1.051c.29 0 .526-.235.526-.525v-2.235"
        stroke="#000"
      />
    </mask>
    <g mask="url(#a)">
      <path
        d="M42.123 27.843c0 11.632-9.43 21.062-21.061 21.062C9.43 48.905 0 39.475 0 27.843 0 16.211 9.017-.652 20.649-.652c11.632 0 21.474 16.863 21.474 28.495z"
        fill="#070707"
      />
    </g>
    <path
      d="M20.029 20.879c0-.145.117-.263.263-.263h1.539c.145 0 .263.118.263.263v23.22a.263.263 0 0 1-.263.263h-1.54a.263.263 0 0 1-.262-.263V20.88z"
      fill="#00A4EF"
    />
    <path
      d="M20.029 20.879c0-.145.117-.263.263-.263h1.539c.145 0 .263.118.263.263v23.22a.263.263 0 0 1-.263.263h-1.54a.263.263 0 0 1-.262-.263V20.88z"
      fill="#80D1F7"
    />
    <path
      d="M22.852 28.148A.5.5 0 1 1 22 28.5V21m-2.852 7.898a.5.5 0 1 0 .852.352V21m2.067 9.183a.626.626 0 1 1-1.067.442V21"
      stroke="#00A4EF"
    />
    <path
      d="M22.852 28.148A.5.5 0 1 1 22 28.5V21m-2.852 7.898a.5.5 0 1 0 .852.352V21m2.067 9.183a.626.626 0 1 1-1.067.442V21"
      stroke="#80D1F7"
    />
  </svg>
);

export default SVGTapPressure;
