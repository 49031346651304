import React, { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Description, Subtitle } from "@ddm-design-system/typography";
import { LinkButton } from "@ddm-design-system/button";
import classNames from "classnames";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import Routes from "../../../../routes";
import { setFilterOutlets } from "../../../../store/filter/actions";
import {
  getIsLoadingComponents,
  getOutletWithTranslations
} from "../../../../store/installation/selectors";
import { getOutletById } from "../../../../store/outlet/selectors";
import { BeverageTranslation } from "../../../../store/beers/types";
import { IPressureChamber } from "../../../../store/installation/types";
import { setInsightsNotificationsClose, setInsightsStoriesClose } from "../../../../store/insights/actions";
import { AnalyticsContext } from "../../../../services/analytics";
import InsightStory from "../InsightStory";

interface IProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
  onPressureChamberEdit?: (outletId: string, pressureChamber: IPressureChamber) => void;
}

const InsightBrandConfirmation: React.FC<IProps> = ({ insight, itemType, onPressureChamberEdit }) => {
  const { managerAppInsights: content, managerAppInstallation: contentInstallation } = useContent();
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const outlet = useSelector(getOutletById(insight.locationId));
  const installation = useSelector(getOutletWithTranslations(insight.locationId));
  const loadingComponents: boolean = useSelector(getIsLoadingComponents);
  const beveragePerTap = useMemo(() => {
    const obj: {
      tapPosition: number;
      pressureChambers: IPressureChamber[];
      beverage: BeverageTranslation;
    }[] = [];
    if (installation?.pressureChambers?.length) {
      installation?.pressureChambers.forEach(p => {
        p.tapPosition.forEach(tp => {
          const existing = obj.find(o => o.tapPosition === tp);
          if (existing) {
            existing.pressureChambers.push(p);
          } else {
            obj.push({
              tapPosition: tp,
              pressureChambers: [p],
              beverage: p.beverage
            });
          }
        });
      });
    }
    return obj.sort((a, b) => a.tapPosition - b.tapPosition);
  }, [installation]);

  const handleLogoClick = useCallback(
    (pcs: IPressureChamber[]) => {
      if (pcs?.[0]) {
        onPressureChamberEdit?.(insight.locationId, pcs[0]);
      }
    },
    [insight, onPressureChamberEdit]
  );

  const callToAction = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS_WITH_CALL_TO_ACTION", insight.type);
    if (outlet) {
      dispatch(setFilterOutlets([outlet]));
    }
    dispatch(setInsightsNotificationsClose(insight.locationId));
    dispatch(setInsightsStoriesClose());
    history.push(Routes.installations);
  }, [dispatch, analytics, history, insight.locationId, outlet, insight.type]);

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={content.manager_app_insights_brand_confirmation_title}
          description={content.manager_app_insights_brand_confirmation_desc}
          icon="Beverage"
          insight={insight}
          footer={
            <div className="flex items-center justify-end">
              <LinkButton onClick={callToAction}>
                {content.manager_app_insights_brand_confirmation_action}
              </LinkButton>
            </div>
      }
        >
          <div
            className={classNames(
          "flex flex-wrap ml-xxxl pt-md pl-md rounded-10 bg-black",
          loadingComponents &&
            "h-xxxl p-0 overflow-hidden after:h-full after:w-0 after:bg-transparent after:relative after:shadow-26 after:animate-loadinggpc"
        )}
          >
            {!loadingComponents &&
          beveragePerTap.map(p => (
            <div
              key={p.beverage.id}
              className="flex items-center justify-center relative bg-grey-greenish cursor-pointer w-xxl h-xxl box-border bg-cover rounded-[50%] mr-lg mb-md"
              title={p.beverage.name}
              style={{ backgroundImage: `url(${p.beverage.logoUrl})` }}
              onClick={() => handleLogoClick(p.pressureChambers)}
            >
              <Subtitle className="flex items-center justify-center text-white absolute w-full h-full bg-background-overlay hover:bg-[rgba(7,7,7,0.2)]">
                {p.tapPosition}
              </Subtitle>
            </div>
          ))}
            {!loadingComponents && !beveragePerTap?.length && (
            <Description className="mb-md">
              {contentInstallation.manager_app_installation_empty}
            </Description>
        )}
          </div>
        </InsightItemContent>
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={content.manager_app_insights_brand_confirmation_title}
          icon="Beverage"
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={content.manager_app_insights_brand_confirmation_title}
          description={content.manager_app_insights_brand_confirmation_desc}
          icon="Beverage"
          insight={insight}
          callToAction={callToAction}
          actionTitle={content.manager_app_insights_brand_confirmation_action}
        />
      );
    default:
      return <></>;
  }
  }, [[insight, itemType]]);

  return renderInsight(itemType);
};

export default InsightBrandConfirmation;
