import { useIsMobile } from "@ddm-design-system/hooks";
import { Icon, IconNames } from "@ddm-design-system/icon";
import { MessageTooltip } from "@ddm-design-system/tooltip";
import { BodyHighlight } from "@ddm-design-system/typography";
import React, { useCallback } from "react";
import useContent from "../../hooks/useContent";
import { EOutletErrors } from "../../store/installation/types";
import { IGroupErrors } from "../../store/outlet/types";

interface ITagProps {
  name: IconNames;
  count?: number;
  iconSize?: number;
  description: string;
  customColor?: string;
}

const TagWrapper: React.FC<ITagProps> = ({ name, count, iconSize, description, customColor }) => {
  const renderTooltipHeader = () => {
    return (
      <span className="flex items-center">
        <Icon
          fill={!customColor ? "var(--color-alert-alert100)" : customColor}
          className="mr-xs"
          name={name}
          size={iconSize}
        />
        <BodyHighlight>{count}</BodyHighlight>
      </span>
    );
  };
  return (
    <MessageTooltip renderHeader={renderTooltipHeader}>
      <span>{description}</span>
    </MessageTooltip>
  );
};

interface IProps {
  errors?: IGroupErrors;
}

const ErrorList: React.FC<IProps> = ({ errors }) => {
  const isMobile = useIsMobile();
  const { managerAppInstallation: content } = useContent();

  const renderOutletTags = useCallback(() => {
    const iconSize = isMobile ? 24 : 20;
    const Tags: any[] = [];

    if (!errors) return;

    Object.entries(errors)
      .reverse()
      .forEach(([error, count]) => {
        if (count !== 0) {
          switch (error) {
            case EOutletErrors.cleaningError:
              Tags.push(
                <TagWrapper
                  key={error}
                  name="CleaningFill"
                  count={count}
                  iconSize={iconSize}
                  description={content.manager_app_cleaning_error}
                />
              );
              break;
            case EOutletErrors.sensorError:
              Tags.push(
                <TagWrapper
                  key={error}
                  name="SensorFill"
                  count={count}
                  iconSize={iconSize}
                  description={content.manager_app_sensor_error}
                />
              );
              break;
            case EOutletErrors.temperatureError:
              Tags.push(
                <TagWrapper
                  key={error}
                  name="TemperatureFill"
                  count={count}
                  iconSize={iconSize}
                  description={content.manager_app_temperature_error}
                />
              );
              break;
            case EOutletErrors.open:
              Tags.push(
                <TagWrapper
                  key={error}
                  name="OpenedFill"
                  count={count}
                  iconSize={iconSize}
                  description={content.manager_app_chamber_opened_warn}
                  customColor="var(--color-warning-warning100)"
                />
              );
              break;
            case EOutletErrors.offline:
              Tags.push(
                <TagWrapper
                  key={error}
                  name="Offline"
                  count={count}
                  iconSize={iconSize}
                  description={content.manager_app_unit_offline}
                  customColor="var(--color-grey-grey50)"
                />
              );
              break;
            case EOutletErrors.existsLeak:
              Tags.push(
                <TagWrapper
                  key={error}
                  name="LeakFill"
                  count={count}
                  iconSize={iconSize}
                  description={content.manager_app_leak_error}
                />
              );
              break;
            case EOutletErrors.timeOnKeg:
              Tags.push(
                <TagWrapper
                  key={error}
                  name="SleepFill"
                  count={count}
                  iconSize={iconSize}
                  description={content.manager_app_keg_expiration_error}
                />
              );
              break;
            default:
              break;
          }
        }
      });
    return Tags;
  }, [isMobile, errors]);
  return <div className="flex flex-row gap-x-xl xs:pt-xl xs:gap-x-md">{renderOutletTags()}</div>;
};
export default ErrorList;
