import React from "react";

interface IProps {
  fill: string;
}

const SVGCoolingUnit: React.FC<IProps>  = ({fill}) => (
  <svg width="59" height="112" viewBox="0 0 59 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_29678_421531)">
      <path d="M55.8049 7.59766H3.93745C2.4791 7.59766 1.29688 8.77988 1.29688 10.2382V109.029C1.29688 110.487 2.4791 111.669 3.93745 111.669H55.8049C57.2633 111.669 58.4455 110.487 58.4455 109.029V10.2382C58.4455 8.77988 57.2633 7.59766 55.8049 7.59766Z" fill="white" stroke={fill} strokeWidth="1.08197" strokeMiterlimit="10" />
      <path d="M17.0546 0.330078H41.5922C41.9389 0.330078 42.2823 0.398379 42.6027 0.53108C42.9231 0.663781 43.2141 0.858285 43.4593 1.10348C43.7045 1.34868 43.8991 1.63978 44.0318 1.96015C44.1645 2.28052 44.2328 2.62389 44.2328 2.97065V7.59166H14.4141V2.97065C14.4141 2.27033 14.6923 1.59869 15.1875 1.10348C15.6827 0.608281 16.3543 0.330078 17.0546 0.330078Z" fill="white" stroke={fill} strokeWidth="1.08197" strokeMiterlimit="10" />
      <path d="M47.131 79.4414H28.9374C27.4791 79.4414 26.2969 80.6236 26.2969 82.082V100.276C26.2969 101.734 27.4791 102.916 28.9374 102.916H47.131C48.5894 102.916 49.7716 101.734 49.7716 100.276V82.082C49.7716 80.6236 48.5894 79.4414 47.131 79.4414Z" fill="white" stroke={fill} strokeWidth="1.08197" strokeMiterlimit="10" />
      <path d="M12.3086 87.0982V74.6211" stroke={fill} strokeWidth="1.08197" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M12.3083 96.4369C13.5379 96.4369 14.5346 95.4402 14.5346 94.2107C14.5346 92.9811 13.5379 91.9844 12.3083 91.9844C11.0788 91.9844 10.082 92.9811 10.082 94.2107C10.082 95.4402 11.0788 96.4369 12.3083 96.4369Z" fill="white" stroke={fill} strokeWidth="1.08197" strokeMiterlimit="10" />
      <path d="M29.6899 43.5764C34.4806 43.5764 38.3642 41.4543 38.3642 38.8365C38.3642 36.2188 34.4806 34.0967 29.6899 34.0967C24.8992 34.0967 21.0156 36.2188 21.0156 38.8365C21.0156 41.4543 24.8992 43.5764 29.6899 43.5764Z" fill="white" stroke={fill} strokeWidth="1.08197" strokeMiterlimit="10" />
      <path d="M21.0156 38.8369H38.3642" stroke={fill} strokeWidth="1.08197" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_29678_421531">
        <rect width="57.8088" height="112" fill="white" transform="translate(0.96875)" />
      </clipPath>
    </defs>
  </svg>
);

export default SVGCoolingUnit;
