import React from "react";
import { CardBody } from "@ddm-design-system/card";
import { SectionTitle } from "@ddm-design-system/typography";
import { withAggRequest, IWithAggRequestProps } from "../../common/hoc/withAggRequest";
import useContent from "../../../hooks/useContent";
import SpecialityChart from "../charts/SpecialityChart";
import { SpecialityMixInfo } from "../SalesInfo";
import "./CardChart.scss";

const SpecialityChartCard: React.FC<IWithAggRequestProps> = ({ data, loading }) => {
  const { managerAppSales: content } = useContent();
  return (
    <div className="speciality-chart">
      <div className="speciality-chart-header">
        <SectionTitle>{content.manager_app_speciality_mix}</SectionTitle>
        <SpecialityMixInfo />
      </div>
      <CardBody className="speciality-chart-body">
        <SpecialityChart data={data?.specialityMix} loading={loading} />
      </CardBody>
    </div>
  );
};

export default withAggRequest(SpecialityChartCard);
