import { DataTable } from "@ddm-design-system/data-table";
import { Icon } from "@ddm-design-system/icon";
import { Subtitle } from "@ddm-design-system/typography";
import * as d3 from "d3";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useContent from "../../hooks/useContent";
import Time from "../../lib/Time/Time";
import Routes from "../../routes";
import { getChosenOutlet, getChosenOutletId } from "../../store/filter/selectors";
import { getStatusHistory } from "../../store/installation/actions";
import {
  getIsLoadingComponents,
  getOutletWithTranslations
} from "../../store/installation/selectors";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

export interface IProps {
  outletId: string;
  controlUnitId: string;
}

const successTypes = ["CLEANING_STARTED", "CLEANING_ENDED"];

const CUStatusHistory: React.FC<IProps> = ({ outletId, controlUnitId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { managerAppControlUnit: content, managerAppCommon: common } = useContent();
  const selectedOutletId: string = useSelector(getChosenOutletId);
  const selectedOutlet = useSelector(getChosenOutlet);
  const componentsLoading = useSelector(getIsLoadingComponents);
  const installation = useSelector(getOutletWithTranslations(outletId));

  useEffect(() => {
    if (installation?.controlUnits?.[0]?.id === controlUnitId) {
      dispatch(getStatusHistory(outletId, controlUnitId));
    }
  }, [installation?.controlUnits?.[0]?.id, outletId, controlUnitId, dispatch]);

  useEffect(() => {
    if (selectedOutletId && !selectedOutlet?.type && selectedOutletId !== outletId) {
      history.push(`/installation/${selectedOutletId}`);
    }
  }, [selectedOutletId, selectedOutlet, history]);

  const renderLoadingCards = useCallback(() => {
    const cards = [];
    for (let i = 0; i < 6; i++) {
      cards.push(
        <div
          key={i}
          className="rounded-[4px] box-border justify-between w-full relative mb-md
          cursor-pointer !bg-grey-grey25 opacity-0 min-h-[48px] xs:w-full xs:max-w-none animate-pulse-fade-in"
        />
      );
    }
    return cards;
  }, []);

  const formatType = useCallback(({ type }) => {
    return (
      <div className="flex flex-row items-center">
        <Icon name={successTypes.includes(type) ? "Ok" : "Error"} className="mr-md" />
        {content[`manager_app_cu_state_${type.toLowerCase()}`] || type}
      </div>
    );
  }, []);

  const dataTableColumns = [
    {
      format: formatType,
      name: common.common_status,
      selector: (row: any) => row.type,
      sortable: false,
      selectorId: "type"
    },
    {
      name: common.common_date,
      selector: (row: any) => d3.timeFormat("%c")(new Time(row.date).toJSDate()),
      sortable: true,
      selectorId: "date"
    }
  ];

  const breadcrumbItems = useMemo(() => {
    const list = [
      { link: `/installation/${installation?.outletId}`, name: installation?.outletName }
    ];
    if (!selectedOutletId || selectedOutlet?.type) {
      list.unshift({
        link: Routes.installations,
        name: !selectedOutletId ? common.common_all : selectedOutlet?.name
      });
    }
    return list;
  }, [installation, selectedOutletId, selectedOutlet]);

  return (
    <>
      <div data-test-id="status-history-breadcrumbs">
        <Breadcrumbs current={content.manager_app_cu_status_history} items={breadcrumbItems} />
        <Subtitle className="pt-xl text-left">{content.manager_app_cu_status_history}</Subtitle>
      </div>
      <div className="flex flex-col items-center text-center pb-xxl relative">
        <div className="pt-xxl w-full">
          {!componentsLoading ? (
            <DataTable
              columns={dataTableColumns}
              data={installation?.controlUnits[0]?.statusHistory || []}
            />
          ) : (
            renderLoadingCards()
          )}
        </div>
      </div>
    </>
  );
};

export default CUStatusHistory;
