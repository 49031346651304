import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Body } from "@ddm-design-system/typography";
import { ErrorTextInput } from "@ddm-design-system/textinput";
import { PrimaryButton } from "@ddm-design-system/button";
import { useDispatch, useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import { IOutlet } from "../../store/outlet/types";
import { validateEmail } from "../../helpers";
import { EUserRankNames, ISetPermissionsParams } from "../../store/users/types";
import { setPermissions as setPermissionsAction } from "../../store/users/actions";
import { getUser } from "../../store/profile/reducer";

interface IProps {
  outlet: IOutlet;
}

const OutletOwnerCard: React.FC<IProps> = ({ outlet }) => {
  const dispatch = useDispatch();
  const { managerAppSettings: content, managerAppCommon: contentCommon } = useContent();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const user = useSelector(getUser);

  const isSalesRep = useMemo(() => {
    return user?.role === "ROLE_SALES_REP";
  }, [user]);

  useEffect(() => {
    if (outlet.owners) {
      setName(outlet.owners?.[0]?.name);
      setEmail(outlet.owners?.[0]?.username);
    }
  }, [outlet.owners]);

  const save = useCallback(() => {
    setLoading(true);
    const userToAdd: ISetPermissionsParams = {
      outletId: outlet.id,
      username: email,
      name,
      rank: EUserRankNames.Owner,
      add: true
    };

    try {
      dispatch(setPermissionsAction(userToAdd));
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (e) {
      setLoading(false);
    }
  }, [outlet, email, name]);

  return isSalesRep ? (
    <div
      data-test-id="outlet-owner-card"
      className="flex flex-col gap-lg mb-lg py-lg px-xxl xs:pt-lg xs:pb-md xs:px-lg rounded-[6px] border-[1px] border-solid border-grey-grey50 xs:mt-xxl"
    >
      <Body>{content.manager_app_outlet_owner_info}</Body>
      <div className="flex gap-xxl xs:flex-col xs:gap-xs">
        <ErrorTextInput
          disabled={!!outlet.owners?.[0]}
          label={content.manager_app_outlet_owner_name}
          value={name}
          onChange={e => setName(e.target.value)}
          data-test-id="outlet-name-input"
        />
        <ErrorTextInput
          disabled={!!outlet.owners?.[0]}
          label={content.manager_app_outlet_owner_email}
          value={email}
          onChange={e => setEmail(e.target.value)}
          data-test-id="outlet-email-input"
        />
        {!outlet.owners?.[0] && (
          <div>
            <PrimaryButton
              disabled={loading || !validateEmail(email) || !name}
              onClick={save}
              data-test-id="outlet-owner-submit"
            >
              {contentCommon.manager_app_save}
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OutletOwnerCard;
