import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "@ddm-design-system/modal";
import { Body } from "@ddm-design-system/typography";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Checkbox } from "@ddm-design-system/checkbox";
import { useIsMobile } from "@ddm-design-system/hooks";
import StockListItem from "./StockListItem";
import { setStock } from "../../store/product/actions";
import { IAppState } from "../../store";
import { getProductsInfo } from "../../store/product/selectors";
import useContent from "../../hooks/useContent";
import { getOutletById } from "../../store/outlet/selectors";

interface IProps {
  locationId: string;
  isOpen: boolean;
  onClose: () => void;
  showOutletName?: boolean;
}

interface IStock {
  [beverageId: string]: {
    kegsInStock: number | null;
    lastUpdatedStock: number;
  };
}

const StockManagementModal: React.FC<IProps> = ({
  locationId,
  isOpen,
  onClose,
  showOutletName
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { managerAppProduct: content, managerAppCommon: contentCommon } = useContent();
  const [initStock, setInitStock] = useState<any>();
  const [currentLocationId, setCurrentLocationId] = useState<any>();
  const [beverageStock, setBeverageStock] = useState<any>({});
  const [updatedToday, setUpdatedToday] = useState(false);

  const outletInfo = useSelector(getOutletById(locationId));
  const products = useSelector((state: IAppState) => getProductsInfo(state, locationId)) || [];

  useEffect(() => {
    if ((!initStock && products.length) || currentLocationId !== locationId) {
      const stock: IStock = {};
      products.forEach(({ kegsInStock, lastUpdatedStock, beverage }) => {
        stock[beverage.id] = {
          kegsInStock,
          lastUpdatedStock
        };
      });
      setBeverageStock(stock);
      setInitStock(stock);
      setCurrentLocationId(locationId);
    }
  }, [initStock, products, currentLocationId, locationId]);

  const handleEdit = useCallback(() => {
    const newStock: any = [];
    Object.keys(beverageStock).forEach((b: string) => {
      if (initStock[b]?.kegsInStock !== beverageStock[b].kegsInStock) {
        newStock.push({
          beverageId: b,
          numberKegs: beverageStock[b].kegsInStock
        });
      }
    });
    dispatch(setStock(locationId, newStock, updatedToday));
    onClose();
  }, [dispatch, locationId, updatedToday, initStock, beverageStock, onClose]);

  const renderCheckbox = () => (
    <div className="flex flex-col mt-lg xs:mt-0 xs:mb-xxl">
      <Checkbox checked={updatedToday} onChange={e => setUpdatedToday(e.target.checked)}>
        <Body className="whitespace-normal">
          {content.manager_app_product_purchasing_order_today}
        </Body>
      </Checkbox>
      <Body className="mt-xs text-grey-grey100">
        {content.manager_app_product_purchasing_order_today_desc}
      </Body>
    </div>
  );

  return (
    <Modal
      className="max-w-[500px] max-h-[90vh] xs:max-w-full xs:max-h-0"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <ModalHeader
        headerTitle={content.manager_app_product_manage_stock}
        description={showOutletName ? outletInfo?.name : ""}
      />
      <ModalBody className="xs:max-h-[60vh] xs:overflow-y-auto scrollbar-hide">
        {isMobile && renderCheckbox()}
        <div className="max-h-[40vh] overflow-y-auto xs:max-h-[unset] xs:overflow-y-[unset] scrollbar-hide" data-test-id="manage-stock-modal-body">
          {locationId === currentLocationId &&
            products.map(({ beverage, lastUpdatedStock }) => {
              const { kegsInStock } = beverageStock?.[beverage?.id];

              return (
                <StockListItem
                  key={beverage.id}
                  beverage={beverage}
                  stock={kegsInStock || 0}
                  lastUpdatedStock={lastUpdatedStock}
                  onChange={value =>
                    setBeverageStock({
                      ...beverageStock,
                      [beverage.id]: {
                        ...beverageStock[beverage.id],
                        kegsInStock: value
                      }
                    })
                  }
                />
              );
            })}
        </div>
        {!isMobile && renderCheckbox()}
      </ModalBody>
      <ModalFooter>
        <div className="flex" data-test-id="manage-stock-modal-footer">
          <SecondaryButton onClick={onClose}>{contentCommon.common_cancel}</SecondaryButton>
          <PrimaryButton onClick={handleEdit}>{contentCommon.manager_app_save}</PrimaryButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default StockManagementModal;
