import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { SectionTitle } from "@ddm-design-system/typography";
import { withAggRequest, IWithAggRequestProps } from "../../common/hoc/withAggRequest";
import { IBeverageTranslation } from "../../../store/beers/types";
import BeerChart from "../charts/BeerChart";
import useContent from "../../../hooks/useContent";
import { getBeverages } from "../../../store/beers/selectors";
import "./CardChart.scss";
import { VolumePerBeerInfo } from "../SalesInfo";

interface IItem {
  id: IBeverageTranslation["id"];
  value: number;
}

const BeerChartCard: React.FC<IWithAggRequestProps> = ({ data, loading }) => {
  const { managerAppSales: content } = useContent();
  const beers = useSelector(getBeverages);
  const beersData = useMemo(
    () =>
      data?.beverages
        .map((item: IItem) => ({
          beer: beers.find(b => b.id === item.id),
          ...item
        }))
        .filter((item: any) => !!item.beer),
    [data, beers]
  );

  return (
    <div className="beer-chart">
      <div className="beer-chart-title">
        <SectionTitle>{content.manager_app_volume_per_beer}</SectionTitle>
        <VolumePerBeerInfo />
      </div>
      <BeerChart data={beersData} loading={loading} />
    </div>
  );
};

export default withAggRequest(BeerChartCard);
