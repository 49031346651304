import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { Body, Description } from "@ddm-design-system/typography";

import { validateEmail } from "../../helpers";
import useContent from "../../hooks/useContent";
import { setPermissions as setPermissionsAction } from "../../store/users/actions";
import { getUsers } from "../../store/users/selectors";
import {
  EUserRankNames,
  IOutletUsers,
  ISetPermissionsParams,
  IUser
} from "../../store/users/types";
import { StyledSelect } from "../settings-outlets/PlacesInput";
import "./settings-user.scss";

interface IProps {
  visible: boolean;
  outlet?: IOutletUsers;
  onClose: () => void;
}

interface IOption extends IUser {
  id: string;
  text: string;
  newValue?: boolean;
}

function isEmail(value: string) {
  return value && validateEmail(value);
}

const AddUserModal: React.FC<IProps> = ({ visible, outlet, onClose }) => {
  const { managerAppSettings: contentSettings, managerAppCommon: contentCommon } = useContent();
  const dispatch = useDispatch();

  const users = useSelector(getUsers);
  const [user, setUser] = useState<IOption | string>();

  const options = useMemo(
    () =>
      users.map(u => ({
        ...u,
        id: u.username,
        text: u.username
      })),
    [users]
  );
  useEffect(() => {
    if (user) {
      setUser(typeof user === "string" ? user : options.find(o => o.id === user.id));
    }
  }, [options, user]);

  const error = useMemo(
    () =>
      user && typeof user === "string" && !isEmail(user)
        ? contentSettings.manager_app_settings_valid_email
        : undefined,
    [user, contentSettings]
  );

  const setUserPermissions = useCallback(() => {
    if (!user || error || !outlet) {
      return;
    }

    const userToAdd: ISetPermissionsParams = {
      outletId: outlet.outletId,
      username: typeof user === "string" ? user : user.username,
      rank: EUserRankNames.Staff,
      add: true
    };
    try {
      dispatch(setPermissionsAction(userToAdd));
      onClose();
      setUser("");
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [user, error, outlet, dispatch, onClose]);

  const item = useMemo(() => (typeof user === "string" ? { id: user, text: user } : user), [user]);

  const renderItem = (username: string, name?: string) => (
    <div className="flex flex-col">
      {name && <Body className="overflow-ellipsis whitespace-nowrap overflow-hidden">{name}</Body>}
      <Description className="text-grey-grey100 overflow-ellipsis whitespace-nowrap overflow-hidden">
        {username}
      </Description>
    </div>
  );

  const onFilter = (value: any, text: string) => {
    const option = value as IOption;
    return (
      (option.username && option.username.toLocaleLowerCase().includes(text.toLocaleLowerCase())) ||
      (!!option.name && option.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()))
    );
  };

  return outlet ? (
    <Modal isOpen={visible} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <ModalHeader headerTitle={contentSettings.manager_app_settings_add_user} />
      <ModalBody>
        <ReactMarkdown>
          {(contentSettings.manager_app_settings_add_user_to || "").replace(
            "%OUTLET%",
            outlet?.outletName || ""
          )}
        </ReactMarkdown>
        <div className="my-md mx-0 w-[300px]" data-test-id="add-user-modal">
          <StyledSelect
            inputProps={{
              error,
              label: contentSettings.manager_app_settings_email
            }}
            items={options}
            onItemSelected={(o: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              setUser(o.newValue ? o.text : (o as any));
            }}
            selectedItem={item}
            onFilter={onFilter}
            renderItem={(i: unknown) => {
              const itemUser = i as IUser;
              return renderItem(itemUser.username, itemUser.name);
            }}
            onNewValue={(value: React.SetStateAction<string | IOption | undefined>) => {
              setUser(value);
            }}
            renderNewValueItem={(newValue: string | undefined) =>
              renderItem(contentSettings.manager_app_settings_email_new, newValue)
            }
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onClose}>{contentCommon.common_cancel}</SecondaryButton>
        <PrimaryButton disabled={!user || error !== undefined} onClick={setUserPermissions}>
          {contentCommon.manager_app_add}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  ) : (
    <span />
  );
};

export default AddUserModal;
