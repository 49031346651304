import React from "react";
import { withAggRequest, IWithAggRequestProps } from "../common/hoc/withAggRequest";
import PerformanceCard from "../common/performance/PerformanceCard";
import { PERFORMANCE_TYPE } from "../../store/filter/types";

const RecentSalesCard: React.FC<IWithAggRequestProps & { dark?: boolean }> = ({
  data = {},
  loading = false,
  dark = false,
  error
}) => {
  const { current, similar, target, similarOutlets, nearbyOutlets } = data;

  return (
    <div
      className="flex justify-between items-stretch pt-lg xs:flex-col xs:pt-0"
    >
      <div
        className="flex flex-1 justify-between items-stretch xs:flex-col lg:flex-wrap lg:min-w-full"
      >
        <PerformanceCard
          data={current}
          type={PERFORMANCE_TYPE.CURRENT}
          loading={loading}
          active
          dark={dark}
          error={error}
        />
        <PerformanceCard
          data={similar}
          type={PERFORMANCE_TYPE.AVG}
          loading={loading}
          dark={dark}
          error={error}
        />
        <PerformanceCard
          data={target}
          type={PERFORMANCE_TYPE.TARGET}
          loading={loading}
          dark={dark}
          error={error}
          detailedError={false}
        />
        <PerformanceCard
          data={similarOutlets}
          type={PERFORMANCE_TYPE.SIMILAR}
          loading={loading}
          dark={dark}
          error={error}
          detailedError={false}
        />
        <PerformanceCard
          data={nearbyOutlets}
          type={PERFORMANCE_TYPE.NEAR}
          loading={loading}
          dark={dark}
          error={error}
          detailedError={false}
        />
      </div>
    </div>
  );
};

export default withAggRequest(RecentSalesCard);
