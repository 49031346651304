import { ITimeRange, ITimeFrame, ETimeFrame } from "./types";
import Time from "./Time";
import { CUSTOM_DAY_TRESHOLD, CUSTOM_MONTH_TRESHOLD } from "../../constants";

export function day8(date: any) {
  const newDate = new Time(date);
  if (newDate.getHour() >= 8) {
    newDate.set({
      hour: 8,
      minute: 0,
      second: 0,
      millisecond: 0
    });
  } else {
    newDate.sub({ day: 1 }).set({
      hour: 8,
      minute: 0,
      second: 0,
      millisecond: 0
    });
  }
  return newDate;
}

// returns time for a desired timespan if needed approximation (in milliseconds)
export function getTimeOfTimeRange(timeRange: ITimeRange, start = 0, end = 0): number {
  let time = 0;
  switch (timeRange) {
    case ETimeFrame.MINUTE: {
      time = 1000 * 60;
      break;
    }
    case ETimeFrame.HOUR: {
      time = 1000 * 60 * 60;
      break;
    }
    case ETimeFrame.YESTERDAY: {
      time = 1000 * 60 * 60 * 24;
      break;
    }
    case ETimeFrame.DAY: {
      time = 1000 * 60 * 60 * 24;
      break;
    }
    case ETimeFrame.WEEK: {
      time = 1000 * 60 * 60 * 24 * 7;
      break;
    }
    case ETimeFrame.LAST_4WEEKS: {
      time = 1000 * 60 * 60 * 24 * 7 * 4;
      break;
    }
    case ETimeFrame.MONTH: {
      time = 1000 * 60 * 60 * 24 * 30;
      break;
    }
    case ETimeFrame.YEAR: {
      time = 1000 * 60 * 60 * 24 * 365;
      break;
    }
    case ETimeFrame.CUSTOM: {
      if (end && start) {
        time = end - start;
      }
      break;
    }
    default: {
      time = 1000 * 60 * 60 * 24;
      break;
    }
  }
  return time;
}

export function getFreqOfTimeRange(
  timerange: ITimeRange,
  start = new Time(),
  end = new Time()
): number {
  let freq;
  switch (timerange) {
    case ETimeFrame.MINUTE: {
      freq = 1000 * 1; // 1 second
      break;
    }
    case ETimeFrame.HOUR: {
      freq = 1000 * 60 * 1; // 1 minute
      break;
    }
    case ETimeFrame.YESTERDAY: {
      freq = 1000 * 60 * 60 * 1; // 1 Hour
      break;
    }
    case ETimeFrame.DAY: {
      freq = 1000 * 60 * 60 * 1; // 1 Hour
      break;
    }
    case ETimeFrame.WEEK: {
      freq = 1000 * 60 * 60 * 24; // 1 Day
      break;
    }
    case ETimeFrame.LAST_4WEEKS: {
      freq = 1000 * 60 * 60 * 24; // 1 Day
      break;
    }
    case ETimeFrame.MONTH: {
      freq = 1000 * 60 * 60 * 24; // 1 Day
      break;
    }
    case ETimeFrame.YEAR: {
      freq = 1000 * 60 * 60 * 24 * 30; // 30 days
      break;
    }
    case ETimeFrame.CUSTOM: {
      const daysDiff = start.diff(end, "day");
      const CUSTOM_DAY_TRESH = CUSTOM_DAY_TRESHOLD;

      if (daysDiff <= CUSTOM_DAY_TRESH) {
        freq = 1000 * 60 * 60;
      } else if (daysDiff > CUSTOM_DAY_TRESH && daysDiff < CUSTOM_MONTH_TRESHOLD) {
        freq = 1000 * 60 * 60 * 24;
      } else {
        freq = 1000 * 60 * 60 * 24 * 30;
      }
      break;
    }
    default: {
      freq = 1000 * 60 * 60 * 24; // 1 Day
      break;
    }
  }
  return freq;
}

export function setStartEndTimeRange(
  tr?: ITimeRange,
  startDate = new Time(),
  endDate = new Time()
): { start: Time; end: Time } {
  let start = new Time();
  let end = new Time();
  switch (tr) {
    case ETimeFrame.DAY: {
      end = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 });

      start = day8(new Time()).set({
        hour: 7,
        minute: 59,
        second: 59,
        millisecond: 0
      });

      break;
    }
    case ETimeFrame.YESTERDAY: {
      end = day8(new Time()).set({
        hour: 7,
        minute: 59,
        second: 59,
        millisecond: 0
      });

      start = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .sub({ day: 1 });
      break;
    }
    case ETimeFrame.WEEK: {
      end = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 });
      start = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 })
        .sub({ day: 7 });
      break;
    }
    case ETimeFrame.LAST_4WEEKS: {
      end = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 });

      start = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 })
        .sub({ day: 28 });
      break;
    }
    case ETimeFrame.MONTH: {
      end = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 });

      start = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 })
        .sub({ month: 1 });
      break;
    }
    case ETimeFrame.YEAR: {
      end = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 });

      start = day8(new Time())
        .set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        })
        .add({ day: 1 })
        .sub({ year: 1 });
      break;
    }
    case ETimeFrame.CUSTOM: {
      if (endDate) {
        end = endDate;
      }
      if (startDate) {
        start = startDate.set({
          hour: 7,
          minute: 59,
          second: 59,
          millisecond: 0
        });
      }
      break;
    }
    default: {
      if (endDate !== null) {
        end = day8(new Time(endDate.getTime()))
          .set({
            hour: 7,
            minute: 59,
            second: 59,
            milliseconds: 0
          })
          .add({ day: 1 });
      }

      if (startDate !== null) {
        start = day8(new Time(startDate.getTime())).set({
          hour: 7,
          minute: 59,
          second: 59,
          milliseconds: 0
        });
      }
      break;
    }
  }

  return { start, end };
}

export function getTimeFrameDaysText(timeFrame?: ITimeFrame, cntnt: any = null) {
  const content = cntnt || { unit_last_x_days: "", unit_last_day: "" };

  if (!timeFrame || !timeFrame.from || !timeFrame.to || !timeFrame.to.diff) {
    return "";
  }

  const days = timeFrame.from.diff(timeFrame.to, "day");

  return days === 1
    ? content.unit_last_day
    : (content.unit_last_x_days || "").replace("%DAYS%", days.toString());
  // return `last ${days} ${days === 1 ? "day" : "days"}`;
}

export function minutesToHours(minutes: number, separator = ":") {
  const hours = Math.floor(minutes / 60);
  const remainder = minutes % 60;
  return `${hours.toString().padStart(2, "0")}${separator}${remainder.toString().padStart(2, "0")}`;
}

export function hoursToMinutes(hours: number, minutes = 0) {
  return hours * 60 + minutes;
}

export function prepareOpeningHours(
  openingHours: { weekday: number; startTime: number; endTime: number }[]
) {
  const result: { weekday: number; startTime: number; endTime: number }[] = [];
  openingHours.forEach(o => {
    if (o.endTime >= o.startTime) {
      result.push(o);
    } else {
      result.push({ weekday: o.weekday, startTime: o.startTime, endTime: 24 * 60 });
      result.push({
        weekday: (o.weekday + 1) % 8 === 0 ? 1 : (o.weekday + 1) % 8,
        startTime: 0,
        endTime: o.endTime
      });
    }
  });

  return result;
}

export function isInsideOpeningHours(
  timespan: number,
  openingHours: { weekday: number; startTime: number; endTime: number }[]
) {
  if (openingHours.length === 0) {
    return true;
  }

  const date = new Time(timespan);
  const weekday = date.getWeekDay();
  const minutes = date.getHour() * 60 + date.getMinute();

  return openingHours
    .filter(o => o.weekday % 7 === weekday)
    .some(d => minutes >= d.startTime && minutes < d.endTime);
}
