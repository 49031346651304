import React from "react";
import classnames from "classnames";
import { Card, CardBody } from "@ddm-design-system/card";
import { Divider } from "@ddm-design-system/divider";
import { BodyHighlight, DescriptionMedium } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import { useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import SVGControlUnit from "./assets/control-unit";
import {
  getGroupingByControlUnitId,
  getPressureChambersByIds
} from "../../../store/installation/selectors";
import useContent from "../../../hooks/useContent";
import { StyledStepper } from "./common.styles";
import { IControlUnit } from "../../../store/installation/types";
import { getValuesFromCUState } from "../../../helpers";

export interface ICleaningUnitFlowProps {
  controlUnit: IControlUnit;
  outletId: string;
  onClick?: () => void;
}

const CleaningUnitFlow: React.FC<ICleaningUnitFlowProps> = ({ controlUnit, outletId, onClick }) => {
  const { managerAppControlUnit: content } = useContent();
  const isMobile = useIsMobile();
  const groupings = useSelector(getGroupingByControlUnitId(outletId, controlUnit.id));
  const pressureChambers = useSelector(getPressureChambersByIds(groupings?.pressureChambers || []));

  const { Component, step, mode, showStatus, faultyClass } = getValuesFromCUState(
    content,
    controlUnit
  );

  const cleaningDue = Math.floor((controlUnit?.cleaningDue || 0) / (3600 * 24));

  return (
    <Card
      className={classnames(
        "min-h-[234px] max-h-[234px] min-w-[560px] w-full xs:min-w-[unset] xs:max-w-[unset] xs:max-h-[unset] xs:w-full",
        faultyClass ? "!border-black" : "!border-grey-greenish"
      )}
    >
      <CardBody className="flex gap-xl overflow-hidden xs:flex-col !pt-xl">
        <section
          onClick={onClick}
          className="flex flex-1 flex-col gap-md max-h-[180px] cursor-pointer"
        >
          <div className="flex flex-1 gap-lg items-between justify-center">
            <SVGControlUnit />
            <div className="flex flex-1 flex-col gap-md text-center">
              <BodyHighlight>
                {(content.manager_app_cu_control_unit_title || "").replace(
                  "%POS%",
                  `${controlUnit.position}`
                )}
              </BodyHighlight>
              <Divider />
              <div>
                <DescriptionMedium className="text-grey-grey100 mr-xxs">
                  {content.manager_app_cu_mode}
                </DescriptionMedium>
                <DescriptionMedium data-test-id="ncu-mode">{mode}</DescriptionMedium>
              </div>
            </div>
          </div>
          <div>
            {!showStatus && <StyledStepper steps={5} activeStep={step} />}
            {showStatus && (
              <div className="grid grid-cols-[24px,24px] gap-xl justify-center">
                <div className="flex flex-col gap-xxs items-center justify-center text-grey-grey100">
                  <Icon
                    name="Wifi"
                    size={24}
                    className={`${
                      controlUnit.alive ? "text-success-success100" : "text-alert-alert100"
                    } fill-current`}
                  />
                </div>
                <div className="flex flex-col gap-xxs items-center justify-center text-grey-grey100">
                  <Icon
                    name="Cleaning"
                    size={24}
                    className={`${
                      !controlUnit.alive
                        ? "text-black"
                        : cleaningDue > 3
                        ? "text-success-success100"
                        : cleaningDue > 0
                        ? "text-warning-warning100"
                        : "text-alert-alert100"
                    } fill-current`}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
        <Divider orientation={isMobile ? "horizontal" : "vertical"} />
        <section className="flex flex-1 items-center justify-center max-h-[180px]">
          <Component controlUnit={controlUnit} affectedPressureChambers={pressureChambers} />
        </section>
      </CardBody>
    </Card>
  );
};

export default CleaningUnitFlow;
