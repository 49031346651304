import { createSelector } from "reselect";
import { IAppState } from "../index";
import { IFaultySensor, IOutlet, IOutletTags, IPressureChamber, ISteelKegUnit } from "./types";
import { getChosenOutlet, getChosenOutletId, getFilterOutlets } from "../filter/selectors";
import { getBeverages } from "../beers/selectors";
import { BeverageTranslation, IBeverageTranslation } from "../beers/types";
import { IOutlet as IOutletType } from "../outlet/types";
import { getOutletsFromGroup } from "../../helpers";

export const getOutlets = (state: IAppState) => state.installation.outlets;
export const getIsLoadingComponents = (state: IAppState) => state.installation.loadingComponents;

export const getHasErrorComponents = (state: IAppState) => state.installation.error;

export const getPressureChamberFromOutletById = (outletId: string, pressureChamberId: string) =>
  createSelector(getOutlets, outlets => {
    const outlet = Object.keys(outlets).find(outletKey => outlets[outletKey].outletId === outletId);
    if (outlet) {
      return [
        ...outlets[outlet].beerDrives,
        ...outlets[outlet].pressureChambers,
        ...outlets[outlet].steelKegUnits
      ].find(pc => pc.id === pressureChamberId);
    }
    return null;
  });

export const getPositionsFromOutletByThingIds = (outletId: string, thingIds: string[]) =>
  createSelector(getOutlets, outlets => {
    const outlet = Object.keys(outlets).find(outletKey => outlets[outletKey].outletId === outletId);
    // consider adding beerDrives on the filter
    if (outlet) {
      return [...outlets[outlet].pressureChambers]
        .filter(pc => thingIds.includes(pc.thingId))
        .map(p => p.position)
        .sort();
    }
    return null;
  });

export const getAllPressureChambers = (state: IAppState) =>
  Object.values(state.installation.outlets).reduce(
    (acc: IPressureChamber[], outlet) => [...acc, ...outlet.pressureChambers],
    []
  );

export const getTags = (outlet: any) => {
  if (!outlet) {
    return {
      dangerTags: "--",
      offlineTags: "--",
      okTags: "--",
      warningTags: "--"
    };
  }

  const { beerDrives, pressureChambers, steelKegUnits } = outlet;

  return {
    dangerTags: [...beerDrives, ...pressureChambers, ...steelKegUnits].filter(
      (p: IPressureChamber) => !p.offline && p.dangerTags > 0
    ).length,
    offlineTags: [...beerDrives, ...pressureChambers, ...steelKegUnits].filter(
      (p: IPressureChamber) => p.offline
    ).length,
    okTags: [...beerDrives, ...pressureChambers, ...steelKegUnits].filter(
      (p: IPressureChamber) => !p.offline && p.dangerTags <= 0 && p.warningTags <= 0
    ).length,
    warningTags: [...beerDrives, ...pressureChambers, ...steelKegUnits].filter(
      (p: IPressureChamber) => !p.offline && p.dangerTags <= 0 && p.warningTags > 0
    ).length
  };
};

export const getAllPressureChambersTags = createSelector(getOutlets, outlets => {
  return Object.values(outlets).reduce(
    (acc: IOutletTags, outlet: any) => {
      const tags: any = getTags(outlet);

      return {
        dangerTags: acc.dangerTags + tags.dangerTags,
        offlineTags: acc.offlineTags + tags.offlineTags,
        okTags: acc.okTags + tags.okTags,
        warningTags: acc.warningTags + tags.warningTags
      };
    },
    { dangerTags: 0, offlineTags: 0, okTags: 0, warningTags: 0 }
  );
});

export const getOutletPressureChambersTags = createSelector(
  getOutlets,
  getChosenOutletId,
  getChosenOutlet,
  (outlets, selectedOutletId, group: any = undefined) => {
    // if distributor or pg, sum all the tags from the selected outlets
    if (group?.type) {
      const outletsT: IOutletType[] = getOutletsFromGroup(group);

      return outletsT.reduce(
        (acc: IOutletTags, outlet: any) => {
          const tags: any = getTags(outlets[outlet.id]);

          return {
            dangerTags: acc.dangerTags + tags.dangerTags,
            offlineTags: acc.offlineTags + tags.offlineTags,
            okTags: acc.okTags + tags.okTags,
            warningTags: acc.warningTags + tags.warningTags
          };
        },
        { dangerTags: 0, offlineTags: 0, okTags: 0, warningTags: 0 }
      );
    }

    const selectedOutlet = outlets?.[selectedOutletId];
    return getTags(selectedOutlet);
  }
);

export const getAllFaultyPressureChambers = createSelector(getOutlets, outlets =>
  Object.values(outlets).reduce(
    (acc: IPressureChamber[], outlet) => [
      ...acc,
      ...outlet.pressureChambers.filter(p => p.isFaulty)
    ],
    []
  )
);

export const getOutletFaultyPressureChambers = createSelector(
  getOutlets,
  getChosenOutletId,
  getChosenOutlet,
  (outlets, selectedOutletId, group: any = undefined) => {
    // if distributor or pg, sum all the faulty from the selected outlets
    if (group?.type) {
      const outletsT: IOutletType[] = getOutletsFromGroup(group);

      return outletsT.filter(Boolean).reduce((acc: IPressureChamber[], outlet1) => {
        const outlet = outlets[outlet1.id || ""];
        return [...acc, ...(outlet?.pressureChambers.filter(p => p.isFaulty) || [])];
      }, []);
    }

    const selectedOutlet = outlets?.[selectedOutletId];
    if (!selectedOutlet) {
      return [];
    }
    return selectedOutlet.pressureChambers.filter(p => p.isFaulty);
  }
);

export const getOutletsWithTranslations = createSelector(
  getOutlets,
  getBeverages,
  (outlets: { [id: string]: IOutlet }, beverages) => {
    const outletsWithTranslations: { [id: string]: IOutlet } = {};
    const beverageMap: { [key: string]: BeverageTranslation } = {};
    const emptyBeverage = beverages.find(b => b.brand === "empty");

    Object.entries(outlets).forEach(([outletId, installation]) => {
      const { beerDrives, pressureChambers, steelKegUnits } = [
        ...installation.beerDrives,
        ...installation.steelKegUnits,
        ...installation.pressureChambers
      ].reduce(
        (
          acc: {
            beerDrives: IPressureChamber[];
            pressureChambers: IPressureChamber[];
            steelKegUnits: ISteelKegUnit[];
          },
          pressureChamber: any
        ) => {
          let outletBeverage;
          if (!pressureChamber.beverageId) {
            outletBeverage = null;
          } else if (!beverageMap[pressureChamber.beverageId]) {
            outletBeverage = beverages.find(
              (beverage: IBeverageTranslation) => beverage.id === pressureChamber.beverageId
            );
            if (outletBeverage) {
              beverageMap[pressureChamber.beverageId] = outletBeverage;
            }
          } else {
            outletBeverage = beverageMap[pressureChamber.beverageId];
          }

          return {
            ...acc,
            ...(pressureChamber.isBeerDrive
              ? {
                  beerDrives: [
                    ...acc.beerDrives,
                    {
                      ...pressureChamber,
                      beverage: outletBeverage || pressureChamber.beverage
                    }
                  ]
                }
              : {
                  pressureChambers: [
                    ...acc.pressureChambers,
                    {
                      ...pressureChamber,
                      beverage: outletBeverage || pressureChamber.beverage,
                      noKeg: outletBeverage?.id === emptyBeverage?.id
                    }
                  ]
                }),
            ...{
              steelKegUnits: [
                ...acc.steelKegUnits,
                {
                  ...pressureChamber,
                  beverage: outletBeverage || pressureChamber.beverage
                }
              ]
            }
          };
        },
        { beerDrives: [], pressureChambers: [], steelKegUnits: [] }
      );

      outletsWithTranslations[outletId] = {
        ...installation,
        beerDrives,
        pressureChambers,
        steelKegUnits
      };
    });

    return outletsWithTranslations;
  }
);

export const getSortedOutletsWithTranslations = createSelector(
  getOutletsWithTranslations,
  (outlets: { [id: string]: IOutlet }) => {
    return Object.values(outlets)
      .filter(outlet => !!outlet.outletName)
      .sort((outletA, outletB) =>
        outletA.outletName.toLocaleLowerCase() < outletB.outletName.toLocaleLowerCase() ? -1 : 1
      )
      .map(outlet => outlet.outletId);
  }
);

export const getOutletWithTranslations = (outletId: string) => {
  return createSelector(
    getOutletsWithTranslations,
    (outlets: { [id: string]: IOutlet }) => outlets[outletId]
  );
};

export const getFaultySensors = (state: IAppState) => state.installation.faultySensors;

export const getFaultySensorsByBeverage = (state: IAppState) => {
  const beverages: {
    [key: string]: IFaultySensor[];
  } = {};

  Object.values(state.installation.faultySensors).forEach(sensors =>
    sensors.forEach(sensor => {
      beverages[sensor.beverageId] = [...(beverages[sensor.beverageId] ?? []), sensor];
    })
  );

  return beverages;
};

export const getPressureChambersWithFaultySensor = createSelector(
  getOutlets,
  outlets => (locationId: string, sensor: IFaultySensor) => {
    return outlets[locationId].pressureChambers.find(p => p.id === sensor.pressureChamberId);
  }
);

export const makeGetFaultySensorsPosition = () =>
  createSelector(
    getAllPressureChambers,
    (_: any, sensors: IFaultySensor[]) => sensors,
    (pressureChambers, sensors) =>
      sensors
        .map(sensor => pressureChambers.find(p => p.id === sensor.pressureChamberId)?.position)
        .filter(p => p !== undefined) as number[]
  );

export const getPressureChamberBeverageName = (thingId: string) =>
  createSelector(getOutletsWithTranslations, outlets => {
    const pressureChambers = Object.values(outlets).reduce(
      (acc: any[], outlet) => [
        ...acc,
        ...outlet.beerDrives,
        ...outlet.pressureChambers,
        ...outlet.steelKegUnits
      ],
      []
    );
    return pressureChambers.find(p => p.thingId === thingId)?.beverage?.name;
  });

export const getFaultySensorPosition = () =>
  createSelector(
    (state: IAppState) => state.installation.outlets,
    (_: any, locationId: string) => locationId,
    (_: any, sensor: IFaultySensor) => sensor,
    (outlets, locationId, sensor) =>
      outlets[locationId]?.pressureChambers.find(p => p.id === sensor.pressureChamberId)
  );

export const getOutletTypeById = (outletId: string) =>
  createSelector(getOutlets, outlets => outlets[outletId].carlsbergCategory);

export const getAllPressureChambersByOutletId = (outletId: string) =>
  createSelector(getOutlets, outlets => [
    ...(outlets?.[outletId]?.beerDrives || []),
    ...(outlets?.[outletId]?.pressureChambers || []),
    ...(outlets?.[outletId]?.steelKegUnits || [])
  ]);

export const getAllBeveragesIdsFromOutletById = (outletId: string) =>
  createSelector(getAllPressureChambersByOutletId(outletId), pcs => pcs?.map(pc => pc.beverageId));

export const getGroupingsByOutletId = (outletId: string) =>
  createSelector(getOutlets, outlets => outlets?.[outletId]?.groupings);

export const getGroupingByControlUnitId = (outletId: string, controlUnitId: string) =>
  createSelector(getGroupingsByOutletId(outletId), groupings =>
    (groupings || []).find(grouping => grouping.controlUnits.includes(controlUnitId))
  );

export const getPressureChambersByIds = (pressureChamberIds: string[]) =>
  createSelector(getAllPressureChambers, getBeverages, (pressureChambers, beverages) =>
    pressureChambers
      .filter(pc => pressureChamberIds.includes(pc.id))
      .map(p => {
        const beverage = beverages.find(b => b.id === p.beverageId) || new BeverageTranslation();
        return { ...p, beverage };
      })
  );

export const isNCUOutlet = createSelector(
  getOutlets,
  getFilterOutlets,
  (outlets, outletsFiltered) => {
    const outletId = outletsFiltered?.[0]?.id;

    if (!outletId) {
      return false;
    }

    return (
      outlets?.[outletId]?.controlUnits?.length >= 1 &&
      !outlets?.[outletId]?.pressureChambers?.length &&
      !outlets?.[outletId]?.beerDrives?.length &&
      !outlets?.[outletId]?.steelKegUnits?.length &&
      !outlets?.[outletId]?.coolingUnits?.length &&
      !outlets?.[outletId]?.compressors?.length
    );
  }
);
