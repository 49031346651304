import React, { useContext, useEffect } from "react";
import "./mailunsubscribe.scss";
import { RouteComponentProps } from "react-router";
import { PrimaryButton } from "@ddm-design-system/button";
import { ReactComponent as Keg } from "../../assets/unsubscribe-keg.svg";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";

export const MailUnsubscribe: React.FC<RouteComponentProps> = ({ location }) => {
  const { managerAppWelcome: content } = useContent();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const token = params.get("unsubscribetoken");

    if (!token) {
      window.location.href = "https://manager.dmbuddy.io";
    }

    auth.unsubscribe(token || "");
  }, [auth, location, location.search]);

  return (
    <div className="mail-unsubscribe">
      <Keg />
      <span>{content.manager_app_unsubscribe_emails}</span>
      <PrimaryButton
        className="mt-xl"
        onClick={() => {
          window.location.href = "https://manager.dmbuddy.io";
        }}
      >
        {content.manager_app_open_manager_app}
      </PrimaryButton>
    </div>
  );
};
export default MailUnsubscribe;
