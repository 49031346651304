import React from "react";
import { Description } from "@ddm-design-system/typography";
import { SecondaryButton } from "@ddm-design-system/button";
import SVGButtonPress from "./assets/button-press";
import useContent from "../../../hooks/useContent";
import { IControlUnit } from "../../../store/installation/types";

interface ICleanInfoProps {
  controlUnit: IControlUnit;
}

const CleanInfo: React.FC<ICleanInfoProps> = ({ controlUnit }) => {
  const { managerAppCommon: common, managerAppControlUnit: content } = useContent();
  const { id } = controlUnit;

  return (
    <div className="flex flex-col gap-md items-center h-full">
      <div className="flex flex-1 flex-col gap-md items-center justify-between text-center">
        <Description>{content.manager_app_cu_clean_info_how_to}</Description>
        <SVGButtonPress />
      </div>
      <label className="cursor-pointer" htmlFor={id}>
        <SecondaryButton className="pointer-events-none">{common.common_cancel}</SecondaryButton>
      </label>
    </div>
  );
};

export default CleanInfo;
