import React, { useMemo } from "react";
import { Keg as DDMKeg, KegStyles } from "@ddm-design-system/keg";
import styled, { css } from "styled-components";
import { IPressureChamber, ISteelKegUnit } from "../../../store/installation/types";
import { generateInitials } from "../../../helpers";

export interface IProps {
  pressureChamber?: IPressureChamber;
  steelKegUnit?: ISteelKegUnit;
}

const StyledKeg = styled(DDMKeg)<{ offline: boolean; open: boolean; isSteelKeg: boolean }>(
  ({ theme, offline, open, isSteelKeg }) => css`
    background-color: ${theme.colors.white};

    ${(offline || open) &&
    css`
      opacity: 0.5;
      border-color: ${theme.colors.grey.grey50} !important;
    `}

    ${open &&
    css`
      border-color: ${theme.colors.grey.grey50} !important;

      ${KegStyles.StyledKegLabel} {
        opacity: 0;
      }

      ${KegStyles.StyledBeverage} {
        opacity: 0;
      }
    `}

    ${isSteelKeg &&
    css`
      border-radius: 20px;
      ${KegStyles.StyledBeverageWrapper} {
        border-radius: 18px;
      }
    `}
  `
);

export const Keg: React.FC<IProps> = ({ pressureChamber, steelKegUnit }) => {
  const noVolume =
    !!steelKegUnit || (!pressureChamber?.isBeerDrive && !pressureChamber?.hasPressureSwitch);
  const hasError =
    pressureChamber?.isBeerDrive || !!steelKegUnit
      ? pressureChamber?.offline || steelKegUnit?.offline
      : pressureChamber?.offline ||
        pressureChamber?.noKeg ||
        !pressureChamber?.sensorConnected ||
        pressureChamber?.isFaulty;

  const hasWarning = pressureChamber?.isOpen;

  const logoFallback = useMemo(
    () => ({
      color: (pressureChamber?.beverage.mainColor || steelKegUnit?.beverage.mainColor) ?? "",
      initials: generateInitials(
        pressureChamber?.beverage.brand || steelKegUnit?.beverage.brand,
        pressureChamber?.beverage.subbrand || pressureChamber?.beverage.subbrand
      )
    }),
    [pressureChamber]
  );

  return (
    <StyledKeg
      emptyLabel="0L"
      hasWarning={hasWarning}
      hasError={!(noVolume && (pressureChamber?.offline || steelKegUnit?.offline)) && hasError}
      logoFallback={logoFallback}
      logoUrl={(pressureChamber?.beverage.logoUrl || steelKegUnit?.beverage.logoUrl) ?? undefined}
      maxVolume={pressureChamber?.volume}
      volumeLeft={pressureChamber?.volumeLeft || null}
      offline={(pressureChamber?.offline || steelKegUnit?.offline) as boolean}
      open={!!pressureChamber?.isOpen}
      noVolume={noVolume}
      isSteelKeg={!!steelKegUnit}
    />
  );
};
