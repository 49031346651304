import React from "react";
import classnames from "classnames";
import { Body, BodyHighlight } from "@ddm-design-system/typography";

interface IProductBeverageDataItem {
  isVertical?: boolean;
  withBorder?: boolean;
  label: string;
}

const ProductBeverageDataItem: React.FC<IProductBeverageDataItem> = ({
  children,
  isVertical = false,
  withBorder = false,
  label
}) => (
  <div
    className={classnames({
      "mb-md": !isVertical,
      "flex flex-col !mb-0": isVertical,
      "border border-solid border-grey-greenish rounded-10 p-md": withBorder
    })}
  >
    <Body
      className={classnames("text-grey-grey100", { "mb-xs": isVertical, "mr-xxs": !isVertical })}
    >
      {label}
    </Body>
    <BodyHighlight>{children}</BodyHighlight>
  </div>
);

export default ProductBeverageDataItem;
