import { translate, translateString } from "./translate";
import { IEntries } from "./index";

export default function mapUnits(entries: IEntries, lang: any) {
  const parsedUnits = entries.units.ordered[0];
  // translate page strings
  const timeAgoStrings: any[] = translate(parsedUnits.fields.timeAgoStrings) || [];
  const parsedTimeAgoStrings: { [key: string]: string } = {};
  timeAgoStrings.forEach(s => {
    const key = translateString(s.fields.stringKey);
    parsedTimeAgoStrings[key] = translateString(s.fields.content, lang);
  });

  const timeStrings: any[] = translate(parsedUnits.fields.timeStrings) || [];
  const parsedTimeStrings: { [key: string]: string } = {};
  timeStrings.forEach(s => {
    const key = translateString(s.fields.stringKey);
    parsedTimeStrings[key] = translateString(s.fields.content, lang);
  });
  return {
    units: {
      timeAgoStrings: parsedTimeAgoStrings,
      timeStrings: parsedTimeStrings
    }
  };
}
