export const adalConfig: any = {
  tenant: "common",
  clientId: "1d44441f-418c-4741-ab8f-c6434496dcc0",
  endpoints: {
    api: "00000002-0000-0000-c000-000000000000"
  },
  cacheLocation: "localStorage",
  callback: (/* errorDesc: string | null, token: string | null, error: any */) => {
    // console.log(errorDesc, token, error);
  }
};
