import React, { useCallback, useState } from "react";
import { Description } from "@ddm-design-system/typography";
import { PrimaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import SVGWaterFlush from "./assets/water_flush";
import { ECleaningUnitButtons, IControlUnit } from "../../../store/installation/types";
import { requestButtonPress } from "../../../store/installation/actions";
import useContent from "../../../hooks/useContent";

export interface IWaterFlushProps {
  controlUnit: IControlUnit;
}

const WaterFlushMode: React.FC<IWaterFlushProps> = ({ controlUnit }) => {
  const { managerAppControlUnit: content } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleContinue = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({
        button: ECleaningUnitButtons.WATER_FLUSH,
        controlUnitId: controlUnit.id
      })
    );
  }, [controlUnit.id, dispatch]);

  return (
    <div className="flex flex-col gap-md items-center">
      <SVGWaterFlush />
      <div className="flex flex-col gap-xxl items-center h-full">
        <Description>{content.manager_app_cu_step_waterflush_mode_desc}</Description>
        <PrimaryButton disabled={loadingButtonPress} onClick={handleContinue}>
          {content.manager_app_cu_stop_waterflush}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default WaterFlushMode;
