import { createSelector } from "reselect";
import { IAppState } from "../index";
import { BeerType } from "../../services/aggregator";
import { IProduct, IProductInfo, IProducts } from "./types";
import { getBeverage, getBeverages } from "../beers/selectors";
import { getAllOutlets, getOutletById } from "../outlet/selectors";
import { BeverageTranslation } from "../beers/types";
import { getChosenOutlet } from "../filter/selectors";
import { getOutletsFromGroup } from "../../helpers";
import { IOutlet } from "../outlet/types";
import { IOutletPickerGroupItem } from "../../components/common/outlet_picker/OutletPicker";

export const getProduct = (state: IAppState, outletId: string) => {
  return state.product.products[outletId] ?? {};
};

export const getProducts = (state: IAppState) => {
  const outlets: any = {};
  state.outlet.outlets.forEach(o => {
    outlets[o.id] = {};
  });
  return {...outlets, ...state.product.products};
};
export const getIsLoading = (state: IAppState) => state.product.loading;
export const getHasError = (state: IAppState) => state.product.error;
export const getInsights = (state: IAppState) => state.product.insights;
export const getIsInsightsLoading = (state: IAppState) => state.product.insightsLoading;
export const getHasInsightsError = (state: IAppState) => state.product.insightsError;

export const getHasProducts = (state: IAppState) => Object.keys(getProducts(state)).length > 0;

export const getAllProducts = (state: IAppState) =>
  Object.values(state.product.products).reduce((acc: IProduct[], product) => [...acc, product], []);

export const getAllMainstreamBrands = createSelector(
  getChosenOutlet,
  getProducts,
  getBeverages,
  (outlet, products, beers) => (selectedOutlet?: IOutlet) =>
    Object.keys(products).length > 0
      ? beers.filter(beer => {
        if (outlet || selectedOutlet) {
          let outletTemp = outlet as IOutlet;
          if (selectedOutlet) {
            outletTemp = selectedOutlet;
          }
          if (outlet && outlet.type) {
            outletTemp = getOutletsFromGroup(outlet as IOutletPickerGroupItem)[0];
          }

          return (
            beer.countryInformation?.[outletTemp.country?.id || ""]?.craftAndSpeciality ===
            BeerType.CORE_BEER
          );
        }
        if (!beer.countryInformation?.[0]?.craftAndSpeciality) {
          return true;
        }

        return Object.keys(beer.countryInformation).some(
          k => beer.countryInformation?.[k].craftAndSpeciality === BeerType.CORE_BEER
        );
      })
      : []
);

export const getAllSpecialityBrands = createSelector(
  getChosenOutlet,
  getProducts,

  getBeverages,
  (outlet, products, beers) => (selectedOutlet?: IOutlet) =>
    Object.keys(products).length > 0
      ? beers.filter(beer => {
        if (outlet || selectedOutlet) {
          let outletTemp = outlet as IOutlet;
          if (selectedOutlet) {
            outletTemp = selectedOutlet;
          }
          if (outlet && outlet.type) {
            outletTemp = getOutletsFromGroup(outlet as IOutletPickerGroupItem)[0];
          }
          return (
            beer.countryInformation?.[outletTemp.country.id || ""]?.craftAndSpeciality !==
            BeerType.CORE_BEER
          );
        }
        return Object.keys(beer.countryInformation || {}).some(
          k => beer.countryInformation?.[k].craftAndSpeciality !== BeerType.CORE_BEER
        );
      })
      : []
);

export const getMainstreamBrands = (state: IAppState, outletId: string) => {
  const group: any = getChosenOutlet(state);
  let beverageIds: string[] = [];
  if (group?.id === outletId && group?.type) {
    const bevMap = new Set<string>();
    const outlets = getOutletsFromGroup(group);
    outlets.forEach((o: IOutlet) => {
      const product = getProduct(state, o?.id);
      Object.keys(product).forEach(k => {
        bevMap.add(k);
      });
    });

    beverageIds = [...bevMap];
  } else {
    const product = getProduct(state, outletId);
    beverageIds = Object.keys(product);
  }

  const outletInfo = getOutletById(outletId)(state);

  const allMainstreamBrands = getAllMainstreamBrands(state)(outletInfo);

  return allMainstreamBrands.filter(beer => beverageIds.includes(beer.id));
};

export const getSpecialityBrands = (state: IAppState, outletId: string) => {
  const group: any = getChosenOutlet(state);
  let beverageIds: string[] = [];
  if (group?.id === outletId && group?.type) {
    const bevMap = new Set<string>();
    const outlets = getOutletsFromGroup(group);
    outlets.forEach((o: IOutlet) => {
      const product = getProduct(state, o?.id);
      Object.keys(product).forEach(k => {
        bevMap.add(k);
      });
    });

    beverageIds = [...bevMap];
  } else {
    const product = getProduct(state, outletId);
    beverageIds = Object.keys(product);
  }


  const outletInfo = getOutletById(outletId)(state);

  const allSpecialityBrands = getAllSpecialityBrands(state)(outletInfo);

  return allSpecialityBrands.filter(beer => beverageIds.includes(beer.id));
};

export const getAllTapsCount = createSelector(getProducts, products =>
  Object.values(products).reduce(
    (acc: any, product: any) =>
      acc +
      Object.values(product).reduce(
        (productAcc, productData: any) => productAcc + productData.tapPositions.length,
        0
      ),
    0
  )
);

export const getTapCount = (state: IAppState, outletId: string) => {
  const group: any = getChosenOutlet(state);
  // get all taps for the outlets on the group
  if (group?.id === outletId && group?.type) {
    const outlets = getOutletsFromGroup(group);

    return Object.values(outlets).reduce((acc: any, outlet: any) => {
      const product = getProduct(state, outlet?.id);
      return (
        acc +
        Object.values(product).reduce(
          (productAcc, productData: any) => productAcc + productData.tapPositions.length,
          0
        )
      );
    }, 0);
  }

  const product = getProduct(state, outletId);
  const uniqueArray = [...new Set(Object.values(product).flatMap(b => b?.tapPositions || []))];
  return uniqueArray.length || 0;
};

export const getAvgKegsPerWeek = (state: IAppState, outletId: string) => {
  const group: any = getChosenOutlet(state);
  // get avg for the outlets on the group
  if (group?.id === outletId && group?.type) {
    const outlets = getOutletsFromGroup(group);

    return Object.values(outlets).reduce((acc: any, outlet: any) => {
      const product = getProduct(state, outlet?.id);
      return acc + Object.values(product).reduce((acc, beer) => acc + beer.avgKegsPerWeek, 0);
    }, 0);
  }

  const product = getProduct(state, outletId);
  return Object.values(product).reduce((acc, beer) => acc + beer.avgKegsPerWeek, 0);
};

export const getAllAvgKegsPerWeek = (state: IAppState) => {
  const products = getProducts(state);

  return Object.keys(products).reduce((acc, outletId) => {
    const avgPerWeek = getAvgKegsPerWeek(state, outletId) as number;

    return avgPerWeek ? acc + avgPerWeek : acc;
  }, 0);
};

export const getProductsInfo = (
  state: IAppState,
  selectedOutletId: string
): (IProductInfo & { beverage: BeverageTranslation })[] | undefined => {
  const product = getProduct(state, selectedOutletId);

  if (!product) {
    return;
  }

  return Object.keys(product)
    .map(beverageId => {
      if (!beverageId) {
        return null;
      }
      const beverageInfo = getBeverage(state, beverageId);
      const productInfo = product[beverageId];

      return (
        beverageInfo && {
          ...productInfo,
          tapPositions: productInfo.tapPositions
            .sort((a, b) => a - b)
            .filter((tap, tapIndex, self) => self.indexOf(tap) === tapIndex),
          beverage: beverageInfo
        }
      );
    })
    .sort((a: any, b: any) =>
      a.beverage?.name?.toLocaleLowerCase() < b.beverage?.name?.toLocaleLowerCase() ? -1 : 1
    )
    .filter(Boolean) as (IProductInfo & { beverage: BeverageTranslation })[];
};

export const getSortedProducts = createSelector(
  getProducts,
  getAllOutlets,
  getChosenOutlet,
  (products, outlets, group) => {
    const sortedProducts: IProducts = {};
    let outletsT: string[] = [];
    if (group?.type) {
      outletsT = getOutletsFromGroup(group as IOutletPickerGroupItem).map((o: IOutlet) => o.id);
    }

    Object.keys(products)
      .filter(id => (outletsT.length > 0 ? outletsT.includes(id) : true))
      .sort((outletIdA, outletIdB) => {
        const outletA = outlets.find(outlet => outlet.id === outletIdA);
        const outletB = outlets.find(outlet => outlet.id === outletIdB);

        return (outletA?.name.toLocaleLowerCase() || "") < (outletB?.name.toLocaleLowerCase() || "")
          ? -1
          : 1;
      })
      .forEach(outletId => {
        sortedProducts[outletId] = products[outletId];
      });

    return sortedProducts;
  }
);

export const hasInsightsData = (state: IAppState) =>
  !state.product.insightsLoading &&
  state.product.insights &&
  Object.keys(state.product.insights).length > 0;

export const getOutletInsights = (state: IAppState, outletId: string) =>
  getInsights(state)[outletId] ?? {
    goodLowestPerformingTap: false,
    goodPerformingBeverageIds: [],
    underPerformingBeverageIds: []
  };
