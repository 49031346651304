import { call, put, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { GET_USERS, ISetPermissions, SET_PERMISSIONS } from "../store/users/types";
import {
  getUsers as getUsersAction,
  getUsersError,
  getUsersSuccess,
  setPermissionsError,
  setPermissionsSuccess
} from "../store/users/actions";
import { requestOutlets as getOutletsAction } from "../store/outlet/actions";

export function* getUsers(jCoreService: IJCoreService) {
  try {
    const res = yield call([jCoreService, jCoreService.getUsers]);
    yield put(getUsersSuccess(res.data));
  } catch (e) {
    yield put(getUsersError());
  }
}

export function* setPermissions(context: IAppContext, action: ISetPermissions) {
  const { jCoreService, notificationService } = context;
  try {
    yield call([jCoreService, jCoreService.setUserPermissions], action.payload);
    yield put(setPermissionsSuccess());
    yield put(getUsersAction());
    yield put(getOutletsAction());
    notificationService.addNotification({
      iconProps: { name: "Ok", fill: "var(--color-success-success100)" },
      text: action.payload.add ? "User added with success" : "User removed with success"
    });
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      text: action.payload.add ? "Error adding new user" : "Error removing user"
    });
    yield put(setPermissionsError());
  }
}

export function* usersWatcher(context: IAppContext) {
  yield takeLatest(GET_USERS, getUsers, context.jCoreService);
  yield takeLatest(SET_PERMISSIONS, setPermissions, context);
}

export default usersWatcher;
