import { Reducer } from "redux";
import produce from "immer";
import { IAppState } from "../index";
import {
  IRequestAggregatorState,
  REQUEST_AGGREGATOR,
  REQUEST_AGGREGATOR_ERROR,
  REQUEST_AGGREGATOR_SUCCESS,
  SET_OFF_HOUR_HIGHLIGHTING,
  RequestAggregatorActionTypes
} from "./types";

export const initialState: IRequestAggregatorState = {
  aggregators: {},
  aggregatorsPending: {},
  aggregatorsError: {},
  offHourHighlighting: false
};

const reducer: Reducer<IRequestAggregatorState, RequestAggregatorActionTypes> = (
  state = initialState,
  action: RequestAggregatorActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_AGGREGATOR:
        draft.aggregatorsPending = {
          ...draft.aggregatorsPending,
          [action.payload.id]: true
        };
        draft.aggregatorsError = {
          ...draft.aggregatorsError,
          [action.payload.id]: false
        };
        break;
      case REQUEST_AGGREGATOR_SUCCESS:
        draft.aggregators = { ...draft.aggregators, [action.id]: action.data };
        draft.aggregatorsPending = {
          ...draft.aggregatorsPending,
          [action.id]: false
        };
        draft.aggregatorsError = {
          ...draft.aggregatorsError,
          [action.id]: false
        };
        break;
      case REQUEST_AGGREGATOR_ERROR:
        draft.aggregatorsPending = {
          ...draft.aggregatorsPending,
          [action.id]: false
        };
        draft.aggregatorsError = {
          ...draft.aggregatorsError,
          [action.id]: true
        };
        break;
      case SET_OFF_HOUR_HIGHLIGHTING:
        draft.offHourHighlighting = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;

export const getAggregator = (state: IAppState) => state.aggregator;
