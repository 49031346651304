import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Description, SectionTitle } from "@ddm-design-system/typography";
import Header from "../header/header";
import "./baselayout.scss";
import useContent from "../../../hooks/useContent";
import Routes from "../../../routes";
import { isNCUOutlet } from "../../../store/installation/selectors";
import { ReactComponent as IconPerformance } from "../../../assets/Performance.svg";
import { ReactComponent as IconOverview } from "../../../assets/Insights.svg";
import { ReactComponent as IconProduct } from "../../../assets/Product.svg";

interface IProps extends RouteComponentProps {}

const REG_EX_ROUTES = new RegExp(/^(\/[a-zA-Z]+)/);

const BaseLayout: React.FC<IProps> = ({ history, children, location: { pathname } }) => {
  const {
    managerAppSettings: settingsContent,
    managerAppSidebar: contentSidebar,
    managerAppCommon: content
  } = useContent();
  const routesForDDM = [
    Routes.overview,
    Routes.sales,
    Routes.products,
    Routes.product,
    Routes.overviewAll
  ];
  const isNCUOnly =
    useSelector(isNCUOutlet) && routesForDDM.includes(REG_EX_ROUTES.exec(pathname)?.[0] || "");

  let showOutletPicker = true;
  let title = "";
  let NCUOnlyTitle = "";
  let NCUOnlyIcon;
  let customClassName = "";
  switch (REG_EX_ROUTES.exec(pathname)?.[0] || "") {
    case Routes.settings:
      showOutletPicker = false;
      title = settingsContent.manager_app_settings_title;
      customClassName = "base-layout-mobile-settings";
      break;
    case Routes.product:
    case Routes.products:
      NCUOnlyTitle = contentSidebar.manager_app_product;
      NCUOnlyIcon = IconProduct;
      customClassName = "base-layout-mobile-product";
      break;
    case Routes.installations:
      customClassName = "base-layout-mobile-installation";
      break;
    case Routes.sales:
      NCUOnlyTitle = contentSidebar.manager_app_sales;
      NCUOnlyIcon = IconPerformance;
      customClassName = "base-layout-mobile-sales";
      break;
    case Routes.overviewAll:
    case Routes.overview:
      NCUOnlyTitle = contentSidebar.manager_app_overview;
      NCUOnlyIcon = IconOverview;
      customClassName = "base-layout-mobile-overview";
      break;
    default:
  }

  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.overflow = isNCUOnly ? "hidden" : "auto";
      root.scrollTop = 0;
    }
  }, [isNCUOnly]);

  return (
    <div
      className={classnames("base-layout-container", customClassName)}
      style={isNCUOnly ? { overflow: "initial" } : {}}
    >
      <Header showOutletPicker={showOutletPicker} title={title} />
      <div className={isNCUOnly ? "relative" : ""}>
        <div style={isNCUOnly ? { filter: "blur(3px)" } : {}}>{children}</div>
        {isNCUOnly && (
          <div className="ncu-only absolute top-0 left-o w-full h-full bg-[rgba(255,255,255,0.4)] px-lg box-border">
            <div
              className="ncu-only-content sticky top-xxl max-w-[460px] m-auto p-md rounded-10 bg-white flex flex-col items-center justify-center"
              style={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)" }}
            >
              <SectionTitle>{NCUOnlyTitle}</SectionTitle>
              <Description className="text-grey-grey100 mt-md max-w-[300px]">
                {content.common_learn_more_description}
              </Description>
              <NCUOnlyIcon className="my-lg xs:h-[100px]" />
              <div className="flex justify-end items-center w-full gap-xs">
                <SecondaryButton
                  className="!px-xs"
                  onClick={() => {
                    history.push(Routes.installations);
                  }}
                >
                  {content.common_check_installation}
                </SecondaryButton>
                <a
                  href="https://www.draughtmaster.com/en/digital/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PrimaryButton className="!px-md">{content.common_learn_more}</PrimaryButton>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(BaseLayout);
