import React from "react";

const SVGClock = () => (
  <svg width="44" height="44" viewBox="0 0 108 123" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="53.7841" cy="68.9765" r="52.578" stroke="#E5EBE8" strokeWidth="2" />
    <path
      d="M49.0659 22.9108L49.0659 12.4559C49.0659 12.0066 49.2508 11.5757 49.5799 11.2579C49.909 10.9402 50.3554 10.7617 50.8208 10.7617L57.8403 10.7617C58.3057 10.7617 58.7521 10.9402 59.0812 11.2579C59.4103 11.5757 59.5951 12.0066 59.5951 12.4559L59.5951 22.9108"
      fill="white"
    />
    <path
      d="M49.0659 22.9108L49.0659 12.4559C49.0659 12.0066 49.2508 11.5757 49.5799 11.2579C49.909 10.9402 50.3554 10.7617 50.8208 10.7617L57.8403 10.7617C58.3057 10.7617 58.7521 10.9402 59.0812 11.2579C59.4103 11.5757 59.5951 12.0066 59.5951 12.4559L59.5951 22.9108"
      stroke="#202020"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.315 26.7397L94.998 16.4228C94.3039 15.7287 93.1785 15.7287 92.4844 16.4228L88.5834 20.3238C87.8893 21.0179 87.8893 22.1433 88.5834 22.8374L98.9003 33.1542C99.5944 33.8483 100.72 33.8483 101.414 33.1542L105.315 29.2532C106.009 28.5591 106.009 27.4338 105.315 26.7397Z"
      stroke="#202020"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.3232 35.8709L93.6127 27.7715"
      stroke="#202020"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.3304 115.243C80.0512 115.243 100.902 94.5739 100.902 69.0768C100.902 43.5797 80.0512 22.9102 54.3304 22.9102C28.6096 22.9102 7.75879 43.5797 7.75879 69.0768C7.75879 94.5739 28.6096 115.243 54.3304 115.243Z"
      stroke="#202020"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.9253 33.4395L53.9253 64.2172"
      stroke="#202020"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.3305 74.748C57.2381 74.748 59.5951 72.3909 59.5951 69.4834C59.5951 66.5758 57.2381 64.2188 54.3305 64.2188C51.423 64.2188 49.0659 66.5758 49.0659 69.4834C49.0659 72.3909 51.423 74.748 54.3305 74.748Z"
      stroke="#202020"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.2119 69.6992L88.0277 82.0618"
      stroke="#202020"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGClock;
