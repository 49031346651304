import React from "react";

const SVGControlUnit = () => (
  <svg width="62" height="104" viewBox="0 0 62 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 0C25 0 5.5 0 0.5 5V14V100.5C0.5 101.605 1.39543 102.5 2.5 102.5H59.5C60.6046 102.5 61.5 101.605 61.5 100.5V14V5C56.5 0 37 0 37 0H31H25Z"
      fill="white"
    />
    <path d="M61.5 14.5H0.5" stroke="#CCCCCC" />
    <path d="M0.5 6.5C5.5 11.5 25 11.5 25 11.5H31H37C37 11.5 56.5 11.5 61.5 6.5" stroke="#CCCCCC" />
    <circle cx="26" cy="6" r="2.5" stroke="#CCCCCC" />
    <circle cx="36" cy="6" r="2.5" stroke="#CCCCCC" />
    <path
      d="M25 0.5C25 0.5 6.93501 0.5 1.12185 4.95835C0.700947 5.28116 0.5 5.79799 0.5 6.32843V14.5V101C0.5 102.105 1.39543 103 2.5 103H59.5C60.6046 103 61.5 102.105 61.5 101V14.5V6.32843C61.5 5.79799 61.2991 5.28116 60.8782 4.95835C55.065 0.5 37 0.5 37 0.5H31H25Z"
      stroke="#CCCCCC"
    />
  </svg>
);

export default SVGControlUnit;
