import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
  className?: string;
  children?: React.ReactNode;
}

interface IScrollPositions {
  [key: string]: number;
}

const ScrollToTop: React.FC<IProps> = ({ className, children, history }) => {
  const scrollingElement = document.getElementById("root");
  const [scrollPositions, setScrollPositions] = useState<IScrollPositions>({});
  const [lastPath, setLastPath] = useState<string>();
  useEffect(() => {
    if (scrollingElement) {
      const unlisten = history.listen((location: { pathname: any }) => {
        const positions = { ...scrollPositions };

        if (lastPath) {
          positions[lastPath] = scrollingElement.scrollTop;
        }
        const path = location.pathname;
        const scroll = positions[path] || 0;
        setScrollPositions(positions);
        setLastPath(path);
        setTimeout(() => {
          scrollingElement.scrollTo(0, scroll || 0);
        });
      });
      return () => {
        unlisten();
      };
    }

    return undefined;
  }, [history, scrollingElement, scrollPositions, lastPath]);

  return <div className={className}>{children}</div>;
};

export default withRouter(ScrollToTop);
