import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IAppState } from "../store";
import {
  getOutletLocationData as getOutletLocationDataAction,
  getOutletLocationDataSuccess,
  getOutletLocationDetailsError,
  getOutletLocationDetailsSuccess,
  getOutletLocationStatusError,
  getOutletLocationStatusSuccess,
  requestOutletsError,
  setOutletFavouriteError,
  setOutletFavouriteSuccess,
  setOutlets
} from "../store/outlet/actions";
import { getOutletLocationDataById } from "../store/outlet/selectors";
import {
  GET_OUTLET_LOCATION_DATA,
  GET_OUTLET_LOCATION_DETAILS,
  GET_OUTLET_LOCATION_STATUS,
  IGetOutletLocationData,
  IGetOutletLocationDetails,
  ISetOutletFavourite,
  ISetOutletGoogleLocationData,
  ISetOutletManualLocationData,
  REQUEST_OUTLETS,
  SET_OUTLET_FAVOURITE,
  SET_OUTLET_GOOGLE_LOCATION_DATA,
  SET_OUTLET_MANUAL_LOCATION_DATA
} from "../store/outlet/types";
import { IJCoreService } from "../services/jcore";
import history from "../history";
import Routes from "../routes";
import { getCurrentContent } from "../store/content/selectors";

export function* requestOutlets(jcoreService: IJCoreService) {
  let result = [];
  try {
    const response = yield call([jcoreService, jcoreService.getOutlets]);
    result = response.data;
    yield put(setOutlets(result));
  } catch {
    yield put(requestOutletsError());
  }
  if (result.length === 0) {
    history.replace(Routes.overviewAll);
  }
}

export function* getLocationData(jcoreService: IJCoreService, action: IGetOutletLocationData) {
  try {
    const outletLocationData = yield select((state: IAppState) =>
      getOutletLocationDataById(state, action.payload.outletId)
    );

    // only request location data if it isn't already in the store
    if (action.payload.force || !outletLocationData) {
      const result = yield call(
        [jcoreService, jcoreService.getLocationData],
        action.payload.outletId
      );
      yield put(getOutletLocationDataSuccess(action.payload.outletId, result.data));
    }
  } catch {
    // TODO handle error in set View
  }
}

export function* getLocationStatus(jcoreService: IJCoreService) {
  try {
    const result = yield call([jcoreService, jcoreService.getLocationStatus]);

    yield put(getOutletLocationStatusSuccess(result.data));
  } catch {
    yield put(getOutletLocationStatusError());
  }
}

export function* getLocationDetails(
  jcoreService: IJCoreService,
  action: IGetOutletLocationDetails
) {
  try {
    const result = yield call(
      [jcoreService, jcoreService.getLocationDetails],
      action.payload.outletId
    );

    yield put(getOutletLocationDetailsSuccess(result.data[0]));
  } catch (e) {
    yield put(getOutletLocationDetailsError());
  }
}

export function* setOutletGoogleLocationData(
  context: IAppContext,
  action: ISetOutletGoogleLocationData
) {
  const content = yield select(getCurrentContent);
  const contentSettings = content ? content.managerAppSettings : {};
  const { jCoreService, placesService, notificationService } = context;
  try {
    const details = yield call(
      [placesService, placesService.getPlaceDetails],
      action.payload.placeId
    );
    yield call([jCoreService, jCoreService.setLocationData], action.payload.outletId, details);
    yield put(getOutletLocationDataAction(action.payload.outletId, true));

    notificationService.addNotification({
      iconProps: { name: "Ok" },
      title: contentSettings.manager_app_settings_outlet_updated_title,
      text: (contentSettings.manager_app_settings_outlet_updated_description || "").replace(
        "%outlet%",
        action.payload.outletName || details.name || details.googleBusinessName
      )
    });
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      title: contentSettings.manager_app_settings_outlet_error_updated_title,
      text: (contentSettings.manager_app_settings_outlet_error_updated_description || "").replace(
        "%outlet%",
        action.payload.outletName
      )
    });
  }
}

export function* setOutletManualLocationData(
  context: IAppContext,
  action: ISetOutletManualLocationData
) {
  const content = yield select(getCurrentContent);
  const contentSettings = content ? content.managerAppSettings : {};
  const { jCoreService, notificationService } = context;
  try {
    yield call(
      [jCoreService, jCoreService.setLocationData],
      action.payload.outletId,
      action.payload.data
    );
    yield put(getOutletLocationDataAction(action.payload.outletId, true));

    notificationService.addNotification({
      iconProps: { name: "Ok" },
      title: contentSettings.manager_app_settings_outlet_updated_title,
      text: (contentSettings.manager_app_settings_outlet_updated_description || "").replace(
        "%outlet%",
        action.payload.outletName
      )
    });
  } catch {
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      title: contentSettings.manager_app_settings_outlet_error_updated_title,
      text: (contentSettings.manager_app_settings_outlet_error_updated_description || "").replace(
        "%outlet%",
        action.payload.outletName
      )
    });
  }

  return null;
}

export function* setFavourite(jcoreService: IJCoreService, action: ISetOutletFavourite) {
  try {
    yield call(
      [jcoreService, jcoreService.setOutletFavourite],
      action.payload.outletId,
      action.payload.favourite
    );
    yield put(setOutletFavouriteSuccess(action.payload));
  } catch {
    yield put(setOutletFavouriteError(action.payload));
  }

  return null;
}

export function* outletWatcher(context: IAppContext) {
  yield takeLatest(REQUEST_OUTLETS, requestOutlets, context.jCoreService);
  yield takeEvery(GET_OUTLET_LOCATION_DATA, getLocationData, context.jCoreService);
  yield takeEvery(GET_OUTLET_LOCATION_STATUS, getLocationStatus, context.jCoreService);
  yield takeEvery(GET_OUTLET_LOCATION_DETAILS, getLocationDetails, context.jCoreService);
  yield takeEvery(SET_OUTLET_GOOGLE_LOCATION_DATA, setOutletGoogleLocationData, context);
  yield takeEvery(SET_OUTLET_MANUAL_LOCATION_DATA, setOutletManualLocationData, context);
  yield takeLatest(SET_OUTLET_FAVOURITE, setFavourite, context.jCoreService);
}

export default outletWatcher;
