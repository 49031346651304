export const REQUEST_INSIGHTS_NOTIFICATIONS = "insights/REQUEST_INSIGHTS";
export const REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS =
  "insights/REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS";
export const REQUEST_INSIGHTS_NOTIFICATIONS_ERROR = "insights/REQUEST_INSIGHTS_NOTIFICATIONS_ERROR";
export const SET_INSIGHTS_NOTIFICATIONS_OPEN = "insights/SET_INSIGHTS_NOTIFICATIONS_OPEN";
export const SET_INSIGHTS_NOTIFICATIONS_CLOSE = "insights/SET_INSIGHTS_NOTIFICATIONS_CLOSE";
export const SET_INSIGHT_SEEN = "insights/SET_INSIGHT_SEEN";
export const SET_INSIGHTS_STORIES_OPEN = "insights/SET_INSIGHTS_STORIES_OPEN";
export const SET_INSIGHTS_STORIES_CLOSE = "insights/SET_INSIGHTS_STORIES_CLOSE";
export const SET_INSIGHT_FEEDBACK = "insights/SET_INSIGHT_FEEDBACK";
export const DELETE_STALE_KEG_NOTIFICATION = "insights/DELETE_STALE_KEG_NOTIFICATION";

export enum EInsightsNotificationType {
  BRAND_CONFIRMATION = "BRAND_CONFIRMATION",
  FAULTY_SENSOR = "FAULTY_SENSOR",
  LEAK_LINE = "LEAK_LINE",
  LEAK_CHAMBER = "LEAK_CHAMBER",
  MISSING_TARGET = "MISSING_TARGET",
  MISSING_OPENING_HOURS = "MISSING_OPENING_HOURS",
  STALE_KEG = "STALE_KEG",
  SPECIALITY_MIX_PERFORMANCE = "SPECIALITY_MIX_PERFORMANCE",
  BEST_PERFORMANCE_HOUR = "BEST_PERFORMANCE_HOUR",
  POUR_QUALITY_SCORE = "POUR_QUALITY_SCORE",
  WEEKLY_VOLUME = "WEEKLY_VOLUME",
  WEEKLY_MIX_CS_VOLUME = "WEEKLY_MIX_CS_VOLUME",
  WEEKLY_TARGET = "WEEKLY_TARGET",
  WEEKLY_TARGET_MIX_CS = "WEEKLY_TARGET_MIX_CS",
  PEER_COMPARISON_NEARBY = "PEER_COMPARISON_NEARBY",
  PEER_COMPARISON_SIMILAR = "PEER_COMPARISON_SIMILAR",
  PEER_COMPARISON_CHAIN = "PEER_COMPARISON_CHAIN",
  PRESSURIZING_TIME_DRIFT_INCREASE = "PRESSURIZING_TIME_DRIFT_INCREASE",
  TAPS_PERFORMANCE = "TAPS_PERFORMANCE",
  TEMPERATURE_ABOVE_LIMIT_WATER_TUBE = "TEMPERATURE_ABOVE_LIMIT_WATER_TUBE",
  TEMPERATURE_ABOVE_LIMIT_ROOM = "TEMPERATURE_ABOVE_LIMIT_ROOM",
  TEMPERATURE_BELOW_LIMIT_WATER_TUBE = "TEMPERATURE_BELOW_LIMIT_WATER_TUBE",
  TEMPERATURE_DRIFT_FAN_DECREASE = "TEMPERATURE_DRIFT_FAN_DECREASE",
  TEMPERATURE_DRIFT_FAN_INCREASE = "TEMPERATURE_DRIFT_FAN_INCREASE",
  TEMPERATURE_DRIFT_WATER_TUBE_DECREASE = "TEMPERATURE_DRIFT_WATER_TUBE_DECREASE",
  TEMPERATURE_DRIFT_WATER_TUBE_INCREASE = "TEMPERATURE_DRIFT_WATER_TUBE_INCREASE",
  TEMPERATURE_FAN_ABOVE_LIMIT = "TEMPERATURE_FAN_ABOVE_LIMIT",
  NO_HEARTBEATS = "NO_HEARTBEATS",
  DISCONNECTED_SENSOR = "DISCONNECTED_SENSOR",
  CLEANING_DUE_DATE_REACHED = "CLEANING_DUE_DATE_REACHED",
  NFC_TAG_UNAVAILABLE = "NFC_TAG_UNAVAILABLE",
  SYSTEM_AIR_LEAK = "SYSTEM_AIR_LEAK"
}

export enum EInsightsDetailType {
  BRAND_WITH_BIGGEST_CHANGE = "BRAND_WITH_BIGGEST_CHANGE",
  CS_MIX_BRAND_WITH_BIGGEST_CHANGE = "CS_MIX_BRAND_WITH_BIGGEST_CHANGE",
  DAY_WITH_BIGGEST_CHANGE = "DAY_WITH_BIGGEST_CHANGE",
  CS_MIX_DAY_WITH_BIGGEST_CHANGE = "CS_MIX_DAY_WITH_BIGGEST_CHANGE",
  PORTFOLIO_CHANGE = "PORTFOLIO_CHANGE",
  HOUR_WITH_HIGH_CONSUMPTION = "HOUR_WITH_HIGH_CONSUMPTION",
  DAYS_WITH_ZERO_CONSUMPTION = "DAYS_WITH_ZERO_CONSUMPTION",
  TIMEZONE_WITH_BIGGEST_CHANGE = "TIMEZONE_WITH_BIGGEST_CHANGE",
  CS_MIX_TIMEZONE_WITH_BIGGEST_CHANGE = "CS_MIX_TIMEZONE_WITH_BIGGEST_CHANGE"
}

export enum EInsightItemType {
  INSIGHT_ITEM = "INSIGHT_ITEM",
  INSIGHT_CARD = "INSIGHT_CARD",
  INSIGHT_STORY = "INSIGHT_STORY"
}

export interface IAPIInsightsNotifications {
  lastRequest: string;
  insights: {
    [key: string]: IInsightsNotification[];
  };
}

export interface IInsightsNotification {
  id: string;
  locationId: string;
  locationName?: string;
  type: EInsightsNotificationType;
  customFields?: { [key: string]: any };
  createDateTime: number;
  feedback?: boolean;
  details: IInsightsDetail[];
}

export interface IInsightsDetail {
  id: string;
  detailType: EInsightsDetailType;
  customFields?: { [key: string]: any };
  createDateTime: number;
}

export interface IInsightsNotifications {
  [locationId: string]: IInsightsNotification[];
}

export interface IInsightStoryData {
  id: string;
  locationId: string;
}
export interface IInsightsNotificationsState {
  insightsNotifications: IInsightsNotifications;
  insightStoryData: null | IInsightStoryData;
  insightsRead: { [locationId: string]: Pick<IInsightsNotification, "id" | "createDateTime">[] };
  insightsNotificationOpen: boolean;
  error: boolean;
  loading: boolean;
  selectedTab: "ALL" | "FAVOURITE";
}

export interface IRequestInsightsNotifications {
  type: typeof REQUEST_INSIGHTS_NOTIFICATIONS;
}
export interface IRequestInsightsNotificationsSuccess {
  type: typeof REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS;
  payload: { data: IAPIInsightsNotifications; userId?: string };
}

export interface IRequestInsightsNotificationsError {
  type: typeof REQUEST_INSIGHTS_NOTIFICATIONS_ERROR;
}

export interface ISetInsightsNotificationsOpen {
  type: typeof SET_INSIGHTS_NOTIFICATIONS_OPEN;
}

export interface ISetInsightsNotificationsClose {
  type: typeof SET_INSIGHTS_NOTIFICATIONS_CLOSE;
  payload: { outletId?: string; insights?: IInsightsNotifications };
}

export interface ISetInsightsStoriesOpen {
  type: typeof SET_INSIGHTS_STORIES_OPEN;
  payload: IInsightStoryData;
}

export interface ISetInsightsStoriesClose {
  type: typeof SET_INSIGHTS_STORIES_CLOSE;
}

export interface ISetInsightFeedback {
  type: typeof SET_INSIGHT_FEEDBACK;
  payload: { locationId: string; insightId: string; feedback: boolean };
}

export interface ISetInsightSeen {
  type: typeof SET_INSIGHT_SEEN;
  payload: { locationId: string; insightId: string };
}

export interface IDeleteStaleKegNotification {
  type: typeof DELETE_STALE_KEG_NOTIFICATION;
  payload: { locationId: string; pressureChamberId: string };
}

export type InsightsNotificationsActionTypes =
  | IRequestInsightsNotifications
  | IRequestInsightsNotificationsSuccess
  | IRequestInsightsNotificationsError
  | ISetInsightsNotificationsOpen
  | ISetInsightsNotificationsClose
  | ISetInsightsStoriesOpen
  | ISetInsightsStoriesClose
  | ISetInsightSeen
  | ISetInsightFeedback
  | IDeleteStaleKegNotification;
