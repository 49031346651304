import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getBeverageLogoOptions } from "../../../../helpers";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";
import { getOutletWithTranslations } from "../../../../store/installation/selectors";
import Time from "../../../../lib/Time/Time";

export interface IInsightStaleKegProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightStaleKeg: React.FC<IInsightStaleKegProps> = ({ insight, itemType }) => {
  const {
    managerAppInsights: content,
    units: { timeStrings: unit, timeAgoStrings: timeAgo }
  } = useContent();
  const {
    customFields: { pressureChambers }
  }: any = insight;

  const installation = useSelector(getOutletWithTranslations(insight.locationId));

  const pcInfos = useMemo(() => {
    if (installation && installation.pressureChambers) {
      return pressureChambers.map((p: any) => {
          const pc = installation.pressureChambers.find(pc => pc.id === p.id);
      
          return {
            id: pc?.id,
            tapPosition: pc?.tapPosition,
            position: pc?.position,
            beverage: pc?.beverage,
            daysLeft: new Time(p.limitDate).diff(new Time(Date.now())),
            expired: new Time(p.limitDate) < new Time(Date.now())
          };
      });
    }
    return [];
  }, [installation]);

  const firstPC = pcInfos[0];

  const description =  useMemo(() => (
    <div key={insight.id}>
      {pcInfos.map((pc: any) => {
        const descBase = pc.expired ? 
          content.manager_app_insights_stale_keg_expired_desc : 
          content.manager_app_insights_stale_keg_desc;
            return (
              <div className="pb-xs" key={pc.id}>
                {descBase
                  .replace("%BEER_NAME%", pc.beverage?.name || "")
                  .replace("%POSITION%", pc.position || "")
                  .replace("%TAP_NUMBER%", `${pc?.tapPosition}` || "")
                  .replace(
                  "%DAYS_LEFT%",
                  pc.daysLeft > 0 ?
                    pc.expired ? (timeAgo.daysAgo as any).replace("%days%", pc.daysLeft) :
                     (unit.unit_in_x_days as any).replace("%DAYS%", pc.daysLeft)
                    : pc.daysLeft === 1
                    ? unit.unit_in_one_day
                    : unit.unit_today
                )}
              </div>);
          })}
    </div>
  ), [pcInfos]);

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={content.manager_app_insights_stale_keg_title}
          description={description}
          insight={insight}
          avatarOptions={getBeverageLogoOptions(firstPC?.beverage)}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={content.manager_app_insights_stale_keg_title}
          avatarOptions={getBeverageLogoOptions(firstPC?.beverage)}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={content.manager_app_insights_stale_keg_title}
          description={description}
          avatarOptions={getBeverageLogoOptions(firstPC?.beverage)}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType, pcInfos]);

  return renderInsight(itemType);
};

export default InsightStaleKeg;
