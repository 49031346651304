import React, {forwardRef, useMemo, useRef} from "react";
import {useSelector} from "react-redux";

import {TertiaryButton} from "@ddm-design-system/button";
import {Card, CardBody} from "@ddm-design-system/card";
import {Divider} from "@ddm-design-system/divider";
import {ExpandablePanel} from "@ddm-design-system/expandable-panel";
import {useIsMobile} from "@ddm-design-system/hooks";

import {isInViewport} from "../../helpers";
import useContent from "../../hooks/useContent";
import {getUser} from "../../store/profile/reducer";
import {getIsRequestingUsers, getRequestingUsersError} from "../../store/users/selectors";
import {ERole, IOutletUsers, IUser} from "../../store/users/types";
import UserListItem from "./UserListItem";

import "./settings-user.scss";

interface IProps {
  outlet: IOutletUsers;
  filter?: string;
  startExpanded?: boolean;
  openModal: (val: boolean) => void;
  removeMe: () => void;
}

const OutletUsersCard = forwardRef<HTMLDivElement, IProps>(
  ({outlet, filter = "", startExpanded, openModal, removeMe}, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const {managerAppSettings: contentSettings} = useContent();
    const me = useSelector(getUser);
    const requestingUsersError = useSelector(getRequestingUsersError);

    const requestingUsers = useSelector(getIsRequestingUsers);

    const users = useMemo(
      () =>
        outlet.users.filter(
          (user: IUser) =>
            user.role === ERole.ROLE_OWNER ||
            user.role === ERole.ROLE_STAFF ||
            user.role === ERole.ROLE_READER
        ),
      [outlet.users]
    );

    const filteredUsers: IUser[] = useMemo(() => {
      return (
        users.filter(
          (u: IUser) =>
            (u.name && u.name.toLowerCase().includes(filter)) ||
            u.username.toLowerCase().includes(filter)
        ) || users
      );
    }, [users, filter]);

    const usersWithManagePermissions = useMemo(
      () => users.filter((user: IUser) => user.role === ERole.ROLE_OWNER).length,
      [users]
    );

    const title = outlet.outletName;
    const subtitle = requestingUsersError
      ? contentSettings.manager_app_settings_error_users
      : requestingUsers
        ? contentSettings.manager_app_settings_loading_users
        : (contentSettings.manager_app_settings_outlet_card_description || "")
          .replace("%USERS_NUMBER%", users.length.toString())
          .replace("%USERS_NUMBER2%", usersWithManagePermissions.toString());

    const handleExpand = (isExpanded: boolean) => {
      // expand animation needs to end in order for isInViewport calculation to be accurate
      setTimeout(() => {
        if (isExpanded && !isInViewport(containerRef?.current, isMobile)) {
          containerRef.current?.scrollIntoView({
            behavior: "smooth",
            block: isMobile ? "center" : "end"
          });
        }
      }, 500);
    };

    return me ? (
      <span ref={containerRef} data-test-id="outlet-users-card">
        {/* @ts-ignore */}
        <ExpandablePanel
          ref={ref}
          initialExpanded={startExpanded}
          title={title || ""}
          subtitle={subtitle}
          renderHeaderDetails={() =>
            (me?.role === ERole.ROLE_OWNER || me?.role === ERole.ROLE_ADMIN) && (
              <TertiaryButton
                className="flex-shrink-0 md:self-center md:mt-md !p-0"
                icon="More"
                onClick={(e: any) => {
                  e.stopPropagation();
                  openModal(true);
                }}
              >
                {contentSettings.manager_app_settings_add_user_to_outlet}
              </TertiaryButton>
            )
          }
          disabled={requestingUsersError || filteredUsers.length === 0}
          onClick={handleExpand}
        >
          <div className="w-full py-0 px-lg md:p-0 md:space-y-md">
            {requestingUsers || requestingUsersError ? (
              <div className="border-t border-solid border-grey-grey50">
                <div className="bg-loading rounded-md max-w-[40%] my-md mx-0">&nbsp;</div>
              </div>
            ) : (
              filteredUsers.map((user: IUser) =>
                isMobile ? (
                  <Card key={user.username}>
                    <CardBody>
                      <UserListItem outletId={outlet.outletId} user={user} removeMe={removeMe} />
                    </CardBody>
                  </Card>
                ) : (
                  <React.Fragment key={user.username}>
                    <Divider />
                    <UserListItem outletId={outlet.outletId} user={user} removeMe={removeMe} />
                  </React.Fragment>
                )
              )
            )}
          </div>
        </ExpandablePanel>
      </span>
    ) : (
      <span />
    );
  }
);

export default OutletUsersCard;
