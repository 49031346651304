import React, { forwardRef } from "react";
import classnames from "classnames";
import "./outlet-review.scss";
import {
  SectionTitle,
  BodyHighlight,
  DescriptionMedium,
  LabelMedium
} from "@ddm-design-system/typography";
import { useDispatch } from "react-redux";
import useContent from "../../hooks/useContent";
import { setFilterOutlets } from "../../store/filter/actions";
import { formatNumber } from "../../helpers";

interface IOutletReviewCardProps {
  outlet: any;
  style: any;
}
const OutletReviewCard = forwardRef<HTMLDivElement, IOutletReviewCardProps>(
  ({ outlet, style }, ref) => {
    const dispatch = useDispatch();
    function goToOutlet() {
      dispatch(setFilterOutlets([outlet]));
    }
    const { managerAppCommon: common } = useContent();

    const isPositiveVolume = outlet.volume.pct >= 0;
    const isPositiveSpecialityMix = outlet.speciality.pct >= 0;

    return (
      outlet && (
        <div className="outlet-review-card" style={style} onClick={goToOutlet} ref={ref}>
          <div className="outlet-review-card-header">
            <BodyHighlight className="outlet-review-name" title={outlet.name}>
              {outlet.name}
            </BodyHighlight>
          </div>
          <div className="outlet-review-card-content">
            <div className="outlet-review-card-content-item">
              <LabelMedium className="outlet-review-card-item-label">
                {common.common_volume}
              </LabelMedium>
              <SectionTitle
                className={classnames("outlet-review-card-item-pct", {
                  positive: isPositiveVolume,
                  negative: !isPositiveVolume
                })}
              >
                {formatNumber(outlet.volume.pct)}
              </SectionTitle>
              <DescriptionMedium className="outlet-review-card-item-value">
                {formatNumber(outlet.volume.value, "L", false)}
              </DescriptionMedium>
            </div>
            <div className="outlet-review-card-content-item">
              <LabelMedium className="outlet-review-card-item-label">
                {common.common_speciality_mix}
              </LabelMedium>
              <SectionTitle
                className={classnames("outlet-review-card-item-pct", {
                  positive: isPositiveSpecialityMix,
                  negative: !isPositiveSpecialityMix
                })}
              >
                {formatNumber(outlet.speciality.pct, "%")}
              </SectionTitle>
              <DescriptionMedium className="outlet-review-card-item-value">
                {formatNumber(outlet.speciality.value, "%", false)}
              </DescriptionMedium>
            </div>
          </div>
        </div>
      )
    );
  }
);

export default OutletReviewCard;
