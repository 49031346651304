import React, { useMemo } from "react";
import { Card, CardBody } from "@ddm-design-system/card";
import { BodyHighlight, Description, DescriptionMedium } from "@ddm-design-system/typography";
import { Divider } from "@ddm-design-system/divider";
import { Icon } from "@ddm-design-system/icon";
import classnames from "classnames";
import { useIsMobile } from "@ddm-design-system/hooks";
import useContent from "../../hooks/useContent";
import { ICompressor } from "../../store/installation/types";
import SVGCompressor from "./cleaning-unit-flow/assets/compressor";
import Time from "../../lib/Time/Time";
import { IWithAggRequestProps } from "../common/hoc/withAggRequest";
import { CLEANING_MAX_DAYS } from "../../constants";

interface IProps {
  compressor: ICompressor;
}

export const CompressorCard: React.FC<IWithAggRequestProps & IProps> = ({
  data = {},
  loading = false,
  error,
  compressor
}) => {
  const {
    managerAppCommon: contentCommon,
    managerAppInstallation: content,
    managerAppControlUnit: cuContent
  } = useContent();
  const isMobile = useIsMobile();

  const status = !compressor.alive || compressor.existsLeak ? "danger" : "ok";

  const daysToCleaning = useMemo(() => {
    return (
      compressor.lastCleaningStartTime &&
      CLEANING_MAX_DAYS - new Time(Date.now()).diff(new Time(compressor.lastCleaningStartTime))
    );
  }, [compressor.lastCleaningStartTime]);

  return (
    <Card
      className={`flex !flex-row gap-md p-md !overflow-hidden md:!flex-col xs:!flex-col w-full
      ${status === "danger" ? "!border-alert-alert100 " : "!border-grey-greenish"}
      `}
      key={compressor.id}
    >
      <Card
        className={classnames(
          "h-[200px] xs:h-auto w-full xs:min-w-[unset] xs:max-w-[unset] xs:max-h-[unset] xs:w-full !border-grey-greenish md:!max-w-[100%]",
          {
            "max-w-[360px]": !compressor.existsLeak,
            "min-w-[530px] w-full max-w-[560px]": compressor.existsLeak
          }
        )}
      >
        <CardBody className="flex gap-xl overflow-hidden xs:flex-col !pt-xl">
          <section className="flex flex-1 flex-col gap-md max-h-[180px] cursor-pointer">
            <div className="flex flex-1 gap-lg items-between justify-center">
              <SVGCompressor />
              <div className="flex flex-1 flex-col gap-md text-center">
                <BodyHighlight>
                  {contentCommon.common_ecolab} {compressor.position || "--"}
                </BodyHighlight>
                <Divider />
                {/* <div> */}
                {/*  <DescriptionMedium className="text-grey-grey100 mr-sm"> */}
                {/*    {content.manager_app_water_consumption} */}
                {/*  </DescriptionMedium> */}
                {/*  <DescriptionMedium data-test-id="compressor-water"> */}
                {/*    {data.waterVolume?.value?.toFixed(0) || "--"} L */}
                {/*  </DescriptionMedium> */}
                {/* </div> */}
                <div className="flex gap-md items-center justify-center text-grey-grey100">
                  <Icon
                    name="Wifi"
                    size={24}
                    className={`${
                      compressor.alive ? "text-success-success100" : "text-alert-alert100"
                    } fill-current`}
                  />
                  {compressor.existsLeak && (
                    <Icon name="Leak" size={24} className="text-alert-alert100 fill-current" />
                  )}
                </div>
              </div>
            </div>
          </section>
          {compressor.existsLeak && (
            <>
              <Divider orientation={isMobile ? "horizontal" : "vertical"} className="min-w-[1px]" />
              <section className="min-w-[200px] flex flex-col gap-xs text-center items-center justify-center xs:px-md">
                <BodyHighlight>{content.manager_app_compressor_leak}</BodyHighlight>
                <Description>{content.manager_app_compressor_leak_desc}</Description>
              </section>
            </>
          )}
        </CardBody>
      </Card>
      <Card className="box-border flex items-center justify-center p-xl !border-grey-greenish xs:w-auto w-full">
        <div className="flex flex-col gap-y-md items-center">
          <div className="flex flex-row gap-x-xs items-center">
            <Icon name="Cleaning" />
            <DescriptionMedium>{cuContent.manager_app_cu_cleaning}</DescriptionMedium>
          </div>
          {compressor.lastCleaningStartTime ? (
            <Description className="text-center">
              {daysToCleaning > 0
                ? cuContent.manager_app_cu_recommended_cleaning_limit.replace(
                    "%DAYS%",
                    daysToCleaning.toString()
                  )
                : cuContent.manager_app_cu_reached_recommended_cleaning_limit}
            </Description>
          ) : (
            <Description className="text-center">{contentCommon.common_ok}</Description>
          )}
        </div>
      </Card>
    </Card>
  );
};

// export default withAggRequest(CompressorCard);
export default CompressorCard;
