import React, { useContext, useState } from "react";
import { Flipped } from "react-flip-toolkit";
import { PrimaryButton } from "@ddm-design-system/button";
import { NotificationContext } from "@ddm-design-system/notification";
import { Body, BodyHighlight, PageTitle } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import AuthContext from "./AuthContext";

interface IProps {
  token: string;
  setComplete: () => void;
  resetApp?: string;
}

export const VerifyAccount: React.FC<IProps> = ({ token, resetApp, setComplete }) => {
  const [finished, setFinished] = useState(false);
  const {
    managerAppWelcome: content,
    managerAppLogin: loginContent,
    managerAppCommon: commonContent
  } = useContent();
  const auth = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);

  const verifyAccountAux = async () => {
    try {
      await auth.verifyAccount(token);
      notificationContext.addNotification({
        iconProps: { name: "Ok" },
        text:
          content.manager_app_verify_account_success +
          (resetApp === "control_tower" ? " Redirecting..." : "")
      });
      setFinished(true);
      if (resetApp === "manager_app") {
        setComplete();
      } else if (resetApp === "control_tower") {
        setTimeout(() => {
          setComplete();
        }, 3000);
      }
    } catch (e) {
      notificationContext.addNotification({
        iconProps: { name: "Error" },
        text: content.manager_app_verify_account_error
      });
    }
  };

  const handleConfirm = () => {
    if (token) {
      verifyAccountAux();
    }
  };

  return (
    <>
      <Flipped flipId="title">
        <BodyHighlight className="title-dark">
          {loginContent[`${resetApp}_login_title`] || loginContent.manager_app_login_title}
        </BodyHighlight>
      </Flipped>
      <PageTitle className="page-title fade-in">
        {finished
          ? loginContent.manager_app_finished_verify
          : loginContent.manager_app_verify_account_title}
      </PageTitle>
      {!finished && (
        <Body className="fade-in text-left mt-md">
          {loginContent.manager_app_verify_account_description}
        </Body>
      )}
      {!finished && (
        <div className="login-row button-container">
          <Flipped flipId="confirm-button">
            <PrimaryButton type="submit" onClick={handleConfirm}>
              {loginContent.manager_app_verify_account_button}
            </PrimaryButton>
          </Flipped>
        </div>
      )}
    </>
  );
};

export default VerifyAccount;
