import React, { useCallback, useContext, useMemo } from "react";
import { Icon } from "@ddm-design-system/icon";
import { useDispatch } from "react-redux";
import { Description, DescriptionMedium } from "@ddm-design-system/typography";
import { InspectorTooltip } from "@ddm-design-system/tooltip";
import { Divider } from "@ddm-design-system/divider";
import { LinkButton } from "@ddm-design-system/button";
import ReactMarkdown from "react-markdown";
import { AnalyticsContext } from "../../services/analytics";
import { showFaultySensorModal as showFaultySensorModalAction } from "../../store/modals/actions";
import { IPressureChamber } from "../../store/installation/types";
import useContent from "../../hooks/useContent";

interface IProps {
  pressureChamber: IPressureChamber;
}

export const PressureChamberBadges: React.FC<IProps> = ({ pressureChamber }) => {
  const { managerAppInstallation: content, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);

  const showFaultySensorModal = useCallback(() => {
    analytics.logEvent("FAULTY_SENSOR", "Installation");
    dispatch(showFaultySensorModalAction([pressureChamber.position]));
  }, [dispatch, pressureChamber, analytics]);

  const allGood = useMemo(
    () =>
      !pressureChamber.offline &&
      !pressureChamber.isOpen &&
      pressureChamber.dangerTags === 0 &&
      pressureChamber.warningTags === 0,
    [pressureChamber]
  );
  return (
    <div className="flex justify-around mt-md w-full h-lg items-center">
      {allGood ? (
        <DescriptionMedium className="text-success-success100">
          {common.common_ok}
        </DescriptionMedium>
      ) : pressureChamber.offline ? (
        <DescriptionMedium className="text-grey-grey50">{common.common_offline}</DescriptionMedium>
      ) : pressureChamber.isOpen ? (
        <DescriptionMedium className="text-black">
          {content.manager_app_champer_open}
        </DescriptionMedium>
      ) : (
        pressureChamber.tags
          ?.filter(t => t.type !== "connection" && (t.level === "danger" || t.level === "warning"))
          .map(tag => {
            const iconColor =
              tag.level === "warning"
                ? "text-warning-warning100"
                : tag.level === "danger"
                ? "text-alert-alert100"
                : "";
            return (
              <InspectorTooltip
                key={tag.type}
                title={
                  <span className="flex items-center flex-start">
                    <Icon
                      className={`${iconColor} border border-transparent rounded-[50%] mr-xs fill-current`}
                      name={tag.icon}
                      size={16}
                    />
                    <DescriptionMedium>
                      {content[`manager_app_${tag.type}_title`]}
                    </DescriptionMedium>
                  </span>
                }
                renderHeader={() => (
                  <Icon
                    className={`${iconColor} border border-transparent rounded-[50%] fill-current`}
                    name={tag.icon}
                    size={24}
                  />
                )}
                zIndex={90}
              >
                <>
                  <Divider className="mt-sm mb-md" />
                  <Description>
                    <ReactMarkdown className="leading-p">
                      {(content[`main_status_details_${tag.desc}`] || "")
                        .replace("%days%", tag.replaceValue)
                        .replace("%days2%", tag.replaceValue2)}
                    </ReactMarkdown>
                  </Description>
                  {tag.type === "sensor" && pressureChamber.isFaulty && (
                    <div className="items-center">
                      <Divider className="mb-xs" />
                      <div className="flex justify-center">
                        <LinkButton onClick={showFaultySensorModal}>{common.know_more}</LinkButton>
                      </div>
                    </div>
                  )}
                </>
              </InspectorTooltip>
            );
          })
      )}
    </div>
  );
};

export default PressureChamberBadges;
