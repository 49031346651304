import styled, { css } from "styled-components";
import { Stepper, StepperStyles } from "@ddm-design-system/stepper";
import { ProgressBar, ProgressBarStyles } from "@ddm-design-system/progress-bar";

export const StyledStepper = styled(Stepper)`
  width: 100%;

  ${StepperStyles.Line} {
    z-index: unset;
  }
`;

export const StyledProgressBar = styled(ProgressBar)<{ colorType?: "water" | "detergent" }>(
  ({ colorType }) => css`
    ${ProgressBarStyles.OuterBar} {
      width: 214px !important;
    }
    ${colorType &&
    css`
      ${ProgressBarStyles.InnerBar} {
        background-color: ${colorType === "detergent" ? "#CA83F3" : "#80d1f7"};
      }
    `}
  `
);
