import { Reducer } from "redux";
import produce from "immer";
import {
  HIDE_ADD_TARGET_MODAL,
  HIDE_FAULTY_SENSOR_MODAL,
  IHomescreenModalState,
  modalsActionTypes,
  SHOW_ADD_TARGET_MODAL,
  SHOW_FAULTY_SENSOR_MODAL
} from "./types";
import { IAppState } from "..";

export const initialState: IHomescreenModalState = {
  faultySensorModal: { isVisible: false, pressureChambers: undefined },
  addTargetModal: { isVisible: false }
};

const reducer: Reducer<IHomescreenModalState, modalsActionTypes> = (
  state = initialState,
  action: modalsActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case SHOW_FAULTY_SENSOR_MODAL:
        const sortedPressureChambers = [...new Set(action.payload)].sort();

        draft.faultySensorModal.isVisible = true;
        draft.faultySensorModal.pressureChambers = sortedPressureChambers;
        break;
      case HIDE_FAULTY_SENSOR_MODAL:
        draft.faultySensorModal.isVisible = false;
        draft.faultySensorModal.pressureChambers = undefined;
        break;
      case SHOW_ADD_TARGET_MODAL:
        draft.addTargetModal.isVisible = true;
        break;
      case HIDE_ADD_TARGET_MODAL:
        draft.addTargetModal.isVisible = false;
        break;
      default:
        break;
    }
  });

export default reducer;

export const getFaultySensorModalData = (state: IAppState) => state.modals.faultySensorModal;
