import React, { useContext } from "react";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import AuthContext from "./AuthContext";
import Routes from "../../routes";
import { AnalyticsContext } from "../../services/analytics";
import { logout } from "../../store/auth/actions";

const Logout: React.FC = () => {
  const auth = useContext(AuthContext);
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();
  analytics.logEvent("LOGOUT");
  analytics.clearAuthenticatedUserContext();
  dispatch(logout());
  auth.logout();
  return <Redirect to={Routes.login} />;
};

export default Logout;
