import { call, put, select, takeEvery } from "redux-saga/effects";
import * as d3 from "d3";
import { IAppContext } from "../services";
import {
  ADD_TARGET,
  DELETE_TARGET,
  IAddTarget,
  IDeleteTarget,
  ITargetParams
} from "../store/targets/types";
import { getCurrentContent } from "../store/content/selectors";
import { requestAggregator } from "../store/aggregator/actions";
import Time from "../lib/Time/Time";
import { setCurrentTarget } from "../store/targets/actions";
import { ISetFilterOutlets, SET_FILTER_OUTLETS } from "../store/filter/types";
import { setFilterOutletsSuccess } from "../store/filter/actions";
import { getChosenOutletId, getFilter } from "../store/filter/selectors";
import { getAllOutlets } from "../store/outlet/selectors";

function* showErrorNotification(notificationService: any, options: { isDelete: boolean }) {
  const content = yield select(getCurrentContent);
  const contentOverview = content ? content.managerAppOverview : null;
  const notificationErrorTextTokens = (
    options.isDelete
      ? contentOverview.target_notification_error_delete
      : contentOverview.target_notification_error_edit
  ).split("%SPLIT%");

  notificationService.addNotification({
    iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
    text: notificationErrorTextTokens[1],
    title: notificationErrorTextTokens[0]
  });
}

export function* addTarget(context: IAppContext, action: IAddTarget) {
  const { jCoreService, notificationService } = context;
  const { payload: target } = action;

  try {
    const selectedOutletId = yield select(getChosenOutletId);
    const filter = yield select(getFilter);

    yield call([jCoreService, jCoreService.addTarget], selectedOutletId, target);

    yield put(setCurrentTarget(target));
    // force data reload
    yield put(requestAggregator("monthly_target", filter));
  } catch {
    yield call(showErrorNotification, notificationService, { isDelete: false });
  }
}

export function* deleteTarget(context: IAppContext, action: IDeleteTarget) {
  const { jCoreService, notificationService } = context;
  const { payload: month } = action;

  try {
    const selectedOutletId = yield select(getChosenOutletId);
    const filter = yield select(getFilter);

    yield call([jCoreService, jCoreService.deleteTarget], selectedOutletId, month);
    const currentMonth = d3.timeFormat("%Y%m")(new Time().toJSDate());
    if (month === currentMonth) {
      yield put(setCurrentTarget());
    }
    // force data reload
    yield put(requestAggregator("monthly_target", filter));
  } catch {
    yield call(showErrorNotification, notificationService, { isDelete: true });
  }
}

export function* getTargets(context: IAppContext, action: ISetFilterOutlets) {
  const { jCoreService } = context;

  const selectedOutletId =
    action.payload.length === 1 && !action.payload[0].type ? action.payload[0].id : null;
  const currentMonth = d3.timeFormat("%Y%m")(new Time().toJSDate());

  if (selectedOutletId) {
    if ((selectedOutletId as any).type) {
      yield put(setFilterOutletsSuccess(action.payload));
    } else {
      const targets = yield call(
        [jCoreService, jCoreService.getAllMonthlyTargets],
        selectedOutletId
      );
      const currentTarget = targets?.data.filter(
        (t: ITargetParams) => t.month === currentMonth
      )?.[0];
      yield put(
        setFilterOutletsSuccess(
          action.payload,
          currentTarget ? { ...currentTarget, allActiveTargets: true } : null
        )
      );
    }
  } else {
    const targets = yield call([jCoreService, jCoreService.getAllMonthlyTargets]);
    const currentTargets = (targets?.data || []).filter(
      (t: ITargetParams) => t.month === currentMonth
    );
    const specialityArray = currentTargets.map(
      (t: ITargetParams) => t.craftAndSpecialityPercentage
    );

    const allOutlets = yield select(getAllOutlets);
    yield put(
      setFilterOutletsSuccess(action.payload, {
        month: currentMonth,
        totalVolume: 0,
        craftAndSpecialityPercentage: d3.mean(specialityArray) || 0,
        allActiveTargets: allOutlets.length === currentTargets.length,
        outletsWithTargetsCount: currentTargets.length
      })
    );
  }
}

export function* targetsWatcher(context: IAppContext) {
  yield takeEvery(ADD_TARGET, addTarget, context);
  yield takeEvery(DELETE_TARGET, deleteTarget, context);
  yield takeEvery(SET_FILTER_OUTLETS, getTargets, context);
}

export default targetsWatcher;
