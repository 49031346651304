import React, { useEffect, useRef } from "react";
import { Authentication } from "adal-ts";
import PullToRefresh from "pulltorefreshjs";
import ReactDOMServer from "react-dom/server";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { SplashLoading } from "@ddm-design-system/icon";
import {
  INotificationStackRef,
  NotificationProvider,
  NotificationStack,
  useNotificationContext
} from "@ddm-design-system/notification";
import { ddmTheme } from "@ddm-design-system/tokens";
import { AuthProvider, useAuthContext } from "./components/auth/AuthContext";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import MailUnsubscribe from "./components/auth/MailUnsubscribe";
import PrivateRoute from "./components/auth/PrivateRoute";
import CookiesForm from "./components/common/cookies_form/CookiesForm";
import AnimatedDDM from "./components/common/dm_icon/DMIcon";
import Footer from "./components/common/footer/footer";
import FaultySensorModal from "./components/common/modals/faulty_sensor_modal/FaultySensorModal";
import HomeContainer from "./components/home/HomeContainer";
import { RECAPTCHA_KEY } from "./constants";
import history from "./history";
import Routes from "./routes";
import { AnalyticsProvider } from "./services/analytics";
import { PlacesProvider } from "./services/places";
import { context } from "./services";
import { persistor, store } from "./store/setup";
import "./sagas/setup";
import { getCurrentLanguage, getIsLoadingContentful } from "./store/content/selectors";
import "./styles/index.scss";
import "./App.css";

history.listen((listener: { pathname: any; search: any }) => {
  context.analyticsService.logPageView(listener.pathname + listener.search);
});

const App: React.FC = () => {
  Authentication.getAadRedirectProcessor().process();
  const authValue = useAuthContext(context.jCoreService, () => {
    persistor.persist();
  });
  const notificationStackRef = useRef<INotificationStackRef>(null);
  const notificationContext = useNotificationContext(notificationStackRef);

  context.setNotificationService(notificationContext);

  useEffect(() => {
    PullToRefresh.init({
      mainElement: "body",
      shouldPullToRefresh: () =>
        document.querySelector("#root")?.scrollTop === 0 &&
        !document.querySelector(".diana-portal") &&
        !document.querySelector(".outlet-picker-popover") &&
        !document.querySelector(".insight-stories") &&
        !document.querySelector(".ReactModal__Overlay"),
      onRefresh() {
        window.location.reload();
      },
      instructionsReleaseToRefresh: " ",
      instructionsPullToRefresh: " ",
      instructionsRefreshing: " ",
      iconRefreshing: ReactDOMServer.renderToString(
        <div className="flex justify-center w-full pt-xxl">
          {/* @ts-ignore */}
          <AnimatedDDM />
        </div>
      )
    });
    return () => {
      PullToRefresh.destroyAll();
    };
  }, []);

  return (
    <div className="root-container">
      <AuthProvider value={authValue}>
        <AnalyticsProvider value={context.analyticsService}>
          <PlacesProvider value={context.placesService}>
            <NotificationProvider value={notificationContext}>
              <ThemeProvider theme={ddmTheme}>
                <Provider store={store}>
                  <Router history={history}>
                    <PersistGate loading={null} persistor={persistor}>
                      <InnerApp />
                    </PersistGate>
                  </Router>
                  {/* @ts-ignore */}
                  <NotificationStack ref={notificationStackRef} />
                  <CookiesForm />
                </Provider>
              </ThemeProvider>
            </NotificationProvider>
          </PlacesProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </div>
  );
};

export const InnerAppComponent: React.FC = () => {
  const language = useSelector(getCurrentLanguage);
  const loadingContentful = useSelector(getIsLoadingContentful);

  return !loadingContentful ? (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language={language}>
      <div className="App">
        <Switch>
          <Route path={Routes.mailUnsubscribe} component={MailUnsubscribe} />
          <Route path={Routes.login} component={Login} />
          <Route path={Routes.logout} component={Logout} />
          {/* @ts-ignore */}
          <PrivateRoute path={Routes.home} component={HomeContainer} />
        </Switch>
        <Footer />
        <FaultySensorModal />
      </div>
    </GoogleReCaptchaProvider>
  ) : (
    <div className="flex justify-center h-full w-full p-lg box-border">
      <SplashLoading className="splash-loading" />
    </div>
  );
};
export const InnerApp = InnerAppComponent;
export default App;
