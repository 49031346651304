import { Entry, Sys } from "contentful";
import { IContentfulContent } from "./types";
import { translate } from "./translate";
import mapLanguageInfo from "./mapLanguageInfo";
import mapPage from "./mapPage";
import mapUnits from "./mapUnits";
import { DEFAULT_LANG } from "../../constants";
import { mapMediaContainer } from "./mapMediaContainer";
import { mapAvailableEmailDomains } from "./mapEmailDomains";
import mapJSONContent from "./mapJSONContent";

export interface ILanguageContent {
  [key: string]: {};
}
export interface IEntries {
  [key: string]: {
    ordered: Array<IJCoreEntry<any>>;
    keyed: {
      [key: string]: IJCoreEntry<any>;
    };
  };
}

// @ts-ignore
export interface IJCoreSys extends Sys {
  contentType: string;
}

// @ts-ignore
export interface IJCoreEntry<T> extends Entry<T> {
  sys: IJCoreSys;
}

export interface ILanguageAvailable {
  [key: string]: ILanguageContent;
}

function replaceTypeLink(
  entry: IJCoreEntry<any>,
  entries: IJCoreEntry<any>[],
  assets: IJCoreEntry<any>[]
) {
  let newEntry = { ...entry };
  Object.keys(entry.fields).forEach(f => {
    let fieldEntries = newEntry.fields[f][DEFAULT_LANG];
    if (fieldEntries.sys && fieldEntries.sys.type === "Link") {
      const entryFound =
        fieldEntries.sys.linkType === "Entry"
          ? entries.find((e: any) => e.sys.id === fieldEntries.sys.id)
          : assets.find((e: any) => e.sys.id === fieldEntries.sys.id);

      if (entryFound) {
        fieldEntries = entryFound;
      }
    } else if (Array.isArray(fieldEntries)) {
      fieldEntries = fieldEntries.map((fe: any) => {
        let entryFound;
        if (fe.sys && fe.sys.type === "Link") {
          entryFound =
            fe.sys.linkType === "Entry"
              ? entries.find((e: any) => e.sys.id === fe.sys.id)
              : assets.find((e: any) => e.sys.id === fe.sys.id);

          if (entryFound) {
            entryFound = replaceTypeLink(entryFound, entries, assets);
          }
        }

        return entryFound || fe;
      });
    }
    newEntry = {
      ...newEntry,
      fields: { ...newEntry.fields, [f]: { ...newEntry.fields[f], [DEFAULT_LANG]: fieldEntries } }
    };
  });
  return newEntry;
}

function mapLanguage(entries: IEntries, lang: string): ILanguageContent {
  const c = {
    ...mapLanguageInfo(entries, lang),
    ...mapPage(entries, lang),
    ...mapUnits(entries, lang),
    ...mapJSONContent(entries),
    ...mapMediaContainer(entries),
    ...mapAvailableEmailDomains(entries)
  };
  return c;
}

export function mapContentfulEntries(
  contentfulEntries: IJCoreEntry<any>[],
  contentfulAssets: IJCoreEntry<any>[]
): IContentfulContent {
  const entries: IEntries = {};

  if (contentfulEntries) {
    contentfulEntries.forEach((entry: IJCoreEntry<any>) => {
      const key = entry.sys?.contentType;
      if (!key) return;

      const newEntry = replaceTypeLink(entry, contentfulEntries, contentfulAssets);

      if (!entries[key]) {
        entries[key] = {
          ordered: [],
          keyed: {}
        };
      }
      const target = entries[newEntry.sys.contentType];

      target.ordered.push(newEntry);
      target.keyed[entry.sys.id] = newEntry;
    });
  }

  const languagesAvailable: ILanguageAvailable = {};
  entries.languageInfo.ordered.forEach(entry => {
    const code = translate(entry.fields.languageCode);
    if (code) {
      languagesAvailable[code] = mapLanguage(entries, code);
    }
  });

  return {
    ...languagesAvailable
  };
}
