import React, { useCallback } from "react";
import useContent from "../../../../hooks/useContent";
import { EInsightsNotificationType, EInsightItemType, IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";

export interface IInsightWeeklyTargetProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightWeeklyTarget: React.FC<IInsightWeeklyTargetProps> = ({ insight, itemType }) => {
  const {
    managerAppInsights: content
  } = useContent();
  const {
    customFields: { isOnTarget }
  }: any = insight;

  const title = insight.type === EInsightsNotificationType.WEEKLY_TARGET ? 
  isOnTarget ? content.manager_app_insights_weekly_target_title_on : content.manager_app_insights_weekly_target_title_off :
  isOnTarget ? content.manager_app_insights_weekly_target_mix_title_on : content.manager_app_insights_weekly_target_mix_title_off;

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={title}
          icon="Target"
          iconOptions={{ name: "Target", className: "text-white fill-current" }}
          insight={insight}
          avatarOptions={{
            backgroundColor: isOnTarget
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={title}
          icon="Target"
          iconOptions={{ name: "Target", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: isOnTarget
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={title}
          icon="Target"
          iconOptions={{ name: "Target", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: isOnTarget
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightWeeklyTarget;
