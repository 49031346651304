import React from "react";
import { DescriptionMedium, Description } from "@ddm-design-system/typography";
import { Icon, IconNames } from "@ddm-design-system/icon";
import "./infomodalitem.scss";

export interface IInfoModalItemProps {
  iconName: IconNames;
  title: string;
  children: any;
  fill?: string;
}

const InfoModalItem: React.FC<IInfoModalItemProps> = ({ iconName, title, children, fill }) => (
  <div className="info-modal-item-wrapper">
    <DescriptionMedium className="title">
      <Icon className="icon" name={iconName} fill={fill} />
      {title}
    </DescriptionMedium>
    {typeof children === "string" ? <Description>{children}</Description> : children}
  </div>
);

export default InfoModalItem;
