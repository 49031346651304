import { IOutlet } from "../outlet/types";
import {
  ISetBeerIds,
  ISetCompareOutlet,
  ISetFilterOutlets,
  ISetFilterOutletsSuccess,
  ISetPerformanceType,
  ISetTimeFrame,
  PERFORMANCE_TYPE,
  SET_BEER_IDS,
  SET_COMPARE_OUTLET,
  SET_FILTER_OUTLETS,
  SET_FILTER_OUTLETS_SUCCESS,
  SET_PERFORMANCE_TYPE,
  SET_TIME_FRAME
} from "./types";
import { ITimeFrame } from "../../lib/Time/types";
import { ITargetParams } from "../targets/types";
import { IOutletPickerItem } from "../../components/common/outlet_picker/OutletPicker";

export const setFilterOutlets = (outlets: IOutletPickerItem[]): ISetFilterOutlets => ({
  type: SET_FILTER_OUTLETS,
  payload: outlets
});

export const setFilterOutletsSuccess = (
  outlets: IOutletPickerItem[],
  currentTarget?: ITargetParams
): ISetFilterOutletsSuccess => ({
  type: SET_FILTER_OUTLETS_SUCCESS,
  payload: {
    outlets,
    currentTarget
  }
});

export const setTimeFrame = (timeFrame: ITimeFrame): ISetTimeFrame => ({
  type: SET_TIME_FRAME,
  payload: timeFrame
});

export const setBeerIds = (beerIds: string[], beers: any[]): ISetBeerIds => ({
  type: SET_BEER_IDS,
  payload: {
    beerIds,
    beers
  }
});

export const setCompareOutlet = (outlet: IOutlet | null): ISetCompareOutlet => ({
  type: SET_COMPARE_OUTLET,
  payload: outlet
});

export const setPerformanceType = (performanceType: PERFORMANCE_TYPE): ISetPerformanceType => ({
  type: SET_PERFORMANCE_TYPE,
  payload: performanceType
});
