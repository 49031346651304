import produce from "immer";
import { Reducer } from "redux";
import isEqual from "lodash/isEqual";
import differenceWith from "lodash/differenceWith";
import {
  GET_FAULTY_SENSORS_ERROR,
  GET_FAULTY_SENSORS_SUCCESS,
  GET_STATUS_HISTORY,
  GET_STATUS_HISTORY_SUCCESS,
  IInstallationState,
  InstallationActionTypes,
  IOutlet,
  REQUEST_COMPONENTS,
  REQUEST_COMPONENTS_ERROR,
  REQUEST_COMPONENTS_SUCCESS,
  REQUEST_SINGLE_COMPONENTS_SUCCESS,
  SET_PRESSURE_CHAMBER_BEVERAGE
} from "./types";
import { mapBeerDrives, mapPressureChambers, mapSteelKegUnits } from "./utils";

export const initialState: IInstallationState = {
  outlets: {},
  error: false,
  loadingComponents: false,
  faultySensors: {}
};

const reducer: Reducer<IInstallationState, InstallationActionTypes> = (
  state = initialState,
  action: InstallationActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_COMPONENTS:
        draft.error = false;
        draft.loadingComponents = true;

        break;
      case REQUEST_COMPONENTS_SUCCESS:
        const mapOutlets: { [id: string]: IOutlet } = {};
        Object.entries(action.payload).forEach(([outletId, installation]) => {
          const pressureChambers = mapPressureChambers(installation);
          const beerDrives = mapBeerDrives(installation);
          const steelKegUnits = mapSteelKegUnits(installation);

          mapOutlets[outletId] = {
            name: installation?.name || installation.outletName,
            outletId: installation.outletId,
            outletName: installation.outletName,
            offlinePressureChambers: 0,
            lastTimestamp: null,
            carlsbergCategory: installation?.carlsbergCategory,
            pressureChambers,
            controlUnits: installation?.controlUnits,
            coolingUnits: installation?.coolingUnits,
            compressors: installation?.compressors,
            groupings: installation?.groupings,
            beerDrives,
            steelKegUnits
          };
        });

        draft.outlets = mapOutlets;

        draft.loadingComponents = false;
        draft.error = false;
        break;
      case REQUEST_COMPONENTS_ERROR:
        draft.outlets = {};
        draft.loadingComponents = false;
        draft.error = true;
        break;
      case REQUEST_SINGLE_COMPONENTS_SUCCESS:
        const pressureChambers = mapPressureChambers(action.payload.outletInfo);
        const beerDrives = mapBeerDrives(action.payload.outletInfo);
        const steelKegUnits = mapSteelKegUnits(action.payload.outletInfo);
        const { controlUnits, groupings } = action.payload.outletInfo;
        const orderedControlUnits = (controlUnits || []).sort((a, b) =>
          a.position > b.position ? 1 : -1
        );
        const currentInfo = draft.outlets[action.payload.outletId];

        const differencePressureChambers = differenceWith(
          currentInfo.pressureChambers,
          pressureChambers,
          isEqual
        );

        if (differencePressureChambers.length > 0 || !currentInfo.pressureChambers) {
          draft.outlets[action.payload.outletId].pressureChambers = pressureChambers;
        }

        const differenceBeerDrives = differenceWith(currentInfo.beerDrives, beerDrives, isEqual);

        if (differenceBeerDrives.length > 0 || (!currentInfo.beerDrives && beerDrives.length > 0)) {
          draft.outlets[action.payload.outletId].beerDrives = beerDrives;
        }

        const differenceSteelKegs = differenceWith(
          currentInfo.steelKegUnits,
          steelKegUnits,
          isEqual
        );

        if (
          differenceSteelKegs.length > 0 ||
          (!currentInfo.steelKegUnits && steelKegUnits.length > 0)
        ) {
          draft.outlets[action.payload.outletId].steelKegUnits = steelKegUnits;
        }

        const differenceControlUnits = differenceWith(
          currentInfo.controlUnits,
          orderedControlUnits,
          isEqual
        );

        if (
          differenceControlUnits.length > 0 ||
          (!currentInfo.controlUnits && orderedControlUnits.length > 0)
        ) {
          draft.outlets[action.payload.outletId].controlUnits = orderedControlUnits.map(l => ({
            ...currentInfo.controlUnits?.find(c => c.id === l.id),
            ...l
            // state: EControlUnitState.WATER_FLUSH
          }));
        }

        const differenceGroupings = differenceWith(currentInfo.groupings, groupings, isEqual);

        if (differenceGroupings.length > 0 || (!currentInfo.groupings && groupings.length > 0)) {
          draft.outlets[action.payload.outletId].groupings = groupings;
        }

        break;
      case SET_PRESSURE_CHAMBER_BEVERAGE: {
        const updatedOutlet = draft.outlets[action.payload.outletId];
        const { isSteelKeg, isBeerDrive } = action.payload;
        console.log("IS STEEL KEG", isSteelKeg, isBeerDrive);
        if (isSteelKeg) {
          const pressureChamberIndex = updatedOutlet?.steelKegUnits.findIndex(
            pressureChamber => pressureChamber.thingId === action.payload.thingId
          );
          console.log(pressureChamberIndex, action.payload);

          const updatedPressureChamber: any = {
            ...updatedOutlet?.steelKegUnits[pressureChamberIndex],
            beverageId: action.payload.beverage.id,
            beverage: action.payload.beverage
          };
          updatedOutlet?.steelKegUnits.splice(pressureChamberIndex, 1, updatedPressureChamber);
        } else if (isBeerDrive) {
          const pressureChamberIndex = updatedOutlet?.beerDrives.findIndex(
            pressureChamber => pressureChamber.thingId === action.payload.thingId
          );

          const updatedPressureChamber: any = {
            ...updatedOutlet?.beerDrives[pressureChamberIndex],
            beverageId: action.payload.beverage.id,
            beverage: action.payload.beverage
          };
          updatedOutlet?.beerDrives.splice(pressureChamberIndex, 1, updatedPressureChamber);
        } else {
          const pressureChamberIndex = updatedOutlet?.pressureChambers.findIndex(
            pressureChamber => pressureChamber.thingId === action.payload.thingId
          );

          const updatedPressureChamber: any = {
            ...updatedOutlet?.pressureChambers[pressureChamberIndex],
            beverageId: action.payload.beverage.id,
            beverage: action.payload.beverage
          };
          updatedOutlet?.pressureChambers.splice(pressureChamberIndex, 1, updatedPressureChamber);
        }

        break;
      }
      case GET_FAULTY_SENSORS_SUCCESS:
        draft.faultySensors = action.payload;
        break;
      case GET_FAULTY_SENSORS_ERROR:
        draft.faultySensors = initialState.faultySensors;
        break;
      case GET_STATUS_HISTORY:
        draft.loadingComponents = true;
        break;
      case GET_STATUS_HISTORY_SUCCESS: {
        const updatedOutlet = state.outlets[action.payload.outletId];
        const cuIndex = updatedOutlet?.controlUnits.findIndex(
          cu => cu.id === action.payload.controlUnitId
        );
        if (cuIndex) {
          return;
        }
        draft.outlets[action.payload.outletId].controlUnits[cuIndex] = {
          ...updatedOutlet?.controlUnits[cuIndex],
          statusHistory: action.payload.statusHistory
        };
        draft.loadingComponents = false;
        break;
      }
      default:
        break;
    }
  });

export default reducer;
