import { TertiaryButton } from "@ddm-design-system/button";
import { Icon } from "@ddm-design-system/icon";
import { SectionTitle } from "@ddm-design-system/typography";
import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { getOutletById } from "../../store/outlet/selectors";
import { IAppState } from "../../store";
import { getAvgKegsPerWeek, getTapCount } from "../../store/product/selectors";

interface IProps {
  outletId: string;
}

export const ProductCard: React.FC<IProps> = ({ outletId }) => {
  const history = useHistory();
  const outletInfo = useSelector(getOutletById(outletId));
  const tapsCount = useSelector((state: IAppState) => getTapCount(state, outletId));
  const kegsPerWeekCount = useSelector((state: IAppState) => getAvgKegsPerWeek(state, outletId));

  return (
    <>
      <div className="flex flex-row items-center mt-lg w-full border-[1px] cursor-pointer rounded-10 border-grey-grey50 md:items-start">
        <div
          className="flex flex-row justify-between overflow-hidden w-full p-lg md:flex-col md:text-left "
          onClick={() => history.push(`/product/${outletId}`)}
        >
          <div className="flex items-center overflow-hidden mr-xs ">
            <SectionTitle className="overflow-hidden whitespace-nowrap overflow-ellipsis">
              {outletInfo?.name}
            </SectionTitle>
          </div>
          <div className="flex xs:-ml-xs xs:mt-xs">
            <TertiaryButton
              icon="TapDouble"
              iconColor="var(--color-grey-grey100)"
              className="direct-span:w-[3ch] direct-span:text-left"
            >
              {(tapsCount as number).toString()}
            </TertiaryButton>
            <TertiaryButton
              icon="Keg"
              iconColor="var(--color-grey-grey100)"
              className="direct-span:w-[3ch] direct-span:text-left"
            >
              {numeral(kegsPerWeekCount).format("0.[00]")}
            </TertiaryButton>
          </div>
        </div>
        <div className="md:mt-lg xs:mt-[30px]">
          <Icon name="ChevronRight" className="pr-lg" />
        </div>
      </div>
    </>
  );
};

export default ProductCard;
