import React from "react";
import { Icon, IconNames, IIconProps } from "@ddm-design-system/icon";
import { Infoicon } from "@ddm-design-system/infoicon";
import { useSelector } from "react-redux";
import { IInsightsNotification } from "../../../store/insights/types";
import { getOutletById } from "../../../store/outlet/selectors";
import { getInsightsUnreadLengthByOutlet, getIsInsightSeen } from "../../../store/insights/selectors";
import { getChosenOutletId } from "../../../store/filter/selectors";

interface IProps {
  insight: IInsightsNotification;
  title: string;
  icon?: IconNames;
  iconOptions?: IIconProps;
  avatarOptions?: any;
}

interface ISkeletonProps {
  loading?: boolean;
}

export const InsightCard: React.FC<IProps> = ({
  title,
  icon,
  insight,
  iconOptions,
  avatarOptions = { backgroundColor: "var(--color-background-white)" },
  ...props
}) => {
    const outlet = useSelector(getOutletById(insight.locationId));
    const insightSeen = useSelector(getIsInsightSeen(insight.locationId, insight.id));
    const chosenOutletId = useSelector(getChosenOutletId);
    const insightsUnread = useSelector(getInsightsUnreadLengthByOutlet);
    let outletWithInsightUnread = false;

    if (!chosenOutletId) {
      const outletInsightsUnread = insightsUnread[insight.locationId];
      outletWithInsightUnread = outletInsightsUnread > 0;
    }
  return (
    <div
      data-test-id="insight-card"
      className={`bg-primary w-[160px] h-[268px] p-[10px] rounded-10 cursor-pointer ${insightSeen && !outletWithInsightUnread ? "" : "border-2 border-success-success100"}`}
      {...props}
    >
      <div className="flex flex-col h-full ">
        <Infoicon
          className="text-white"
          avatarOptions={{
            children: icon && <Icon name={icon} className="text-background-black fill-current" {...iconOptions} />,
            ...avatarOptions
          }}
        />
        <span className="text-background-white text-left mt-lg">{title}</span>
        {!chosenOutletId && (<span className="text-background-white text-left mt-auto">{outlet?.name}</span>)}
      </div>
    </div>
  );
};

export const InsightCardSkeleton: React.FC<ISkeletonProps> = ({loading = false}) => {
  const loadingClass = loading ? "bg-dark-loading" : "opacity-25";
    
  return (
    <div
      data-test-id="insight-card-skeleton"
      className={`bg-primary w-[160px] h-[268px] p-[10px] rounded-10 ${!loading && "opacity-25"}`}
    >
      <div className="flex flex-col h-full ">
        <div className={`bg-grey-darkest rounded-10 w-xxl h-xxl ${loadingClass}`} />
        {loading && (
        <>
          <div className="flex flex-col gap-1 mt-lg">
            <div className={`bg-grey-darkest rounded-10 h-[13px] w-4/5 ${loadingClass}`} />
            <div className={`bg-grey-darkest rounded-10 h-[13px] w-5/5 ${loadingClass}`} />
            <div className={`bg-grey-darkest rounded-10 h-[13px] w-2/5 ${loadingClass}`} />
          </div>
          <div className={`bg-grey-darkest rounded-10 h-[13px] w-2/5 mt-auto ${loadingClass}`} />
        </>
        )}
      </div>
        
    </div>
  );
};

export default InsightCard;
