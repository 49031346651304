import React from "react";
import { Body } from "@ddm-design-system/typography";
import * as d3 from "d3";
import { SimpleInputSpinner } from "@ddm-design-system/input-spinner";
import { BaseInfoiconStyles, Infoicon } from "@ddm-design-system/infoicon";
import { Divider } from "@ddm-design-system/divider";
import { useIsMobile } from "@ddm-design-system/hooks";
import styled, { css } from "styled-components";
import { getBeverageLogoOptions } from "../../helpers";
import { BeverageTranslation } from "../../store/beers/types";
import Time from "../../lib/Time/Time";
import useContent from "../../hooks/useContent";

const StyledInfoIcon = styled(Infoicon)(
  () => css`
    ${BaseInfoiconStyles.StyledAvatar} {
      border-width: 0;
    }
  `
);

interface IProps {
  beverage: BeverageTranslation;
  lastUpdatedStock: number;
  stock: number;
  onChange: (value: number) => void;
}

const StockListItem: React.FC<IProps> = ({ beverage, stock, lastUpdatedStock, onChange }) => {
  const { managerAppProduct: content } = useContent();
  const isMobile = useIsMobile();
  const formattedDate = d3.timeFormat("%x")(new Time(lastUpdatedStock).toJSDate());

  return (
    <>
      <div className="flex items-center justify-between my-lg mx-0 first:mt-0" data-test-id={`${beverage.id}-stock`}>
        <StyledInfoIcon
          className="stock-list-item-img"
          avatarOptions={getBeverageLogoOptions(beverage)}
        />
        <div className="flex flex-col mr-xxl flex-[1] overflow-hidden xs:mr-xs">
          <Body className="text-black mb-xxs whitespace-nowrap overflow-ellipsis overflow-hidden">
            {beverage.name}
          </Body>
          <Body className="text-grey-grey100">
            {isMobile
              ? formattedDate
              : (content.manager_app_product_updated_at || "").replace("%DATE%", formattedDate)}
          </Body>
        </div>
        <SimpleInputSpinner min={-99} max={99} value={stock} onChange={onChange} />
      </div>
      <Divider />
    </>
  );
};

export default StockListItem;
