import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import numeral from "numeral";
import classnames from "classnames";
import { PrimaryButton } from "@ddm-design-system/button";
import { Card, CardBody } from "@ddm-design-system/card";
import { StatusChip } from "@ddm-design-system/chip";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { EPerformanceLevel, IProductInfo } from "../../store/product/types";
import { BeverageTranslation } from "../../store/beers/types";
import useContent from "../../hooks/useContent";
import { BeerType } from "../../services/aggregator";
import { getBeverageLogoOptions, getPerformanceBadgeIcon } from "../../helpers";
import { translatedBeerType } from "../../constants";
import { getIsLoading } from "../../store/product/selectors";
import ProductBeverageDataItem from "./ProductBeverageDataItem";
import { AnalyticsContext } from "../../services/analytics";

interface IProductBeverageCard {
  beverage: IProductInfo & { beverage: BeverageTranslation; outletCountryId: string };
  onClick: (beverageInfo: {
    beverage: IProductInfo & { beverage: BeverageTranslation };
    avatarOptions: any;
  }) => void;
  stockAccess?: boolean;
}

const ProductBeverageCard: React.FC<IProductBeverageCard> = ({
  beverage,
  onClick,
  stockAccess
}) => {
  const analytics = useContext(AnalyticsContext);
  const loading = useSelector(getIsLoading);
  const { managerAppProduct: content, managerAppCommon: common } = useContent();
  const [hasError, setHasError] = useState(false);

  const {
    tapPositions,
    avgKegsPerWeek,
    kegsInStock,
    performance,
    validVolumePercentage,
    beverage: { name, countryInformation },
    outletCountryId
  } = beverage;

  const avatarOptions = useMemo(() => getBeverageLogoOptions(beverage?.beverage), [beverage]);

  const onError = useCallback(() => setHasError(true), []);
  const handleClick = useCallback(
    () => onClick({ beverage, avatarOptions }),
    [avatarOptions, beverage, onClick]
  );

  const handleCarlshopClick = useCallback(() => {
    analytics.logEvent("CLICK_ORDER_CARLSHOP", name);
  }, [analytics, name, countryInformation, outletCountryId]);

  return (
    <div onClick={handleClick}>
      <Card
        className={classnames("!flex !flex-col cursor-pointer", {
          loading,
          "!text-transparent animate-pulse-fade-in": loading
        })}
      >
        <CardBody className="flex items-center flex-col">
          <div
            className={classnames("flex flex-col items-center h-[184px] mb-md", {
              "w-full": loading
            })}
          >
            {!loading && avatarOptions.img?.src && !hasError ? (
              <img
                src={avatarOptions.img?.src}
                className="w-[92px] h-[92px] mb-xs rounded-[50%] flex-shrink-0"
                alt={name}
                onError={onError}
                style={{ boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 8px" }}
              />
            ) : (
              <div
                className={classnames(
                  "w-[92px] h-[92px] mb-xs rounded-[50%] flex-shrink-0 flex items-center justify-center",
                  { "bg-grey-grey25": loading }
                )}
                style={{
                  backgroundColor: avatarOptions.backgroundColor,
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 8px"
                }}
              >
                <SectionTitle>{avatarOptions.fallbackText}</SectionTitle>
              </div>
            )}
            <SectionTitle
              className={classnames({ "bg-grey-grey25 min-w-full min-h-[16px]": loading })}
            >
              {name}
            </SectionTitle>
            <Body
              className={classnames("text-grey-grey100 mt-xxs mb-lg", {
                "min-w-full min-h-[16px]": loading
              })}
            >
              {
                common[
                  `common_beer_type_${
                    translatedBeerType[
                      (countryInformation?.[outletCountryId]?.craftAndSpeciality as BeerType) ||
                        BeerType.CORE_BEER
                    ]
                  }`
                ]
              }
            </Body>
          </div>

          <div className="flex flex-col justify-between min-h-[fit-content]">
            <ProductBeverageDataItem label={common.common_tap}>
              {tapPositions.length > 0 ? tapPositions.join(", ") : "--"}
            </ProductBeverageDataItem>

            <ProductBeverageDataItem label={content.manager_app_product_kegs_per_week}>
              {numeral(avgKegsPerWeek).format("0.[00]")}
            </ProductBeverageDataItem>

            {stockAccess && (
              <ProductBeverageDataItem label={content.manager_app_product_kegs_in_stock}>
                {kegsInStock !== null ? kegsInStock : "--"}
              </ProductBeverageDataItem>
            )}

            {performance && (
              <StatusChip
                type={getPerformanceBadgeIcon(performance, validVolumePercentage)}
                selected
              >
                {!validVolumePercentage
                  ? content.manager_app_insights_not_enough_data
                  : performance === EPerformanceLevel.overperforming
                  ? content.manager_app_insights_outstanding_performance
                  : performance === EPerformanceLevel.good
                  ? content.manager_app_insights_good_performance
                  : content.manager_app_insights_under_performance}
              </StatusChip>
            )}

            {countryInformation?.[outletCountryId]?.carlsShopUrl && (
              <div className="flex justify-center mt-md">
                <a
                  href={countryInformation?.[outletCountryId]?.carlsShopUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PrimaryButton
                    className="py-xs px-xl"
                    onClick={() => {
                      handleCarlshopClick();
                    }}
                  >
                    {content.manager_app_product_order_at_carl_shop}
                  </PrimaryButton>
                </a>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductBeverageCard;
