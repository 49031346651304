import {
  IRequestBeers,
  IRequestBeersSuccess,
  REQUEST_BEERS,
  REQUEST_BEERS_SUCCESS,
  IRequestBeersError,
  REQUEST_BEERS_ERROR,
  IRequestBeerSuccessResponseItem,
  IEditBeerPayload,
  EDIT_BEER
} from "./types";

export const requestBeers = (locationIds?: string[]): IRequestBeers => ({
  type: REQUEST_BEERS,
  payload: {
    locationIds
  }
});

export const requestBeersSuccess = (
  payload: IRequestBeerSuccessResponseItem
): IRequestBeersSuccess => ({
  type: REQUEST_BEERS_SUCCESS,
  payload
});

export const requestBeersError = (error: Error): IRequestBeersError => ({
  type: REQUEST_BEERS_ERROR,
  payload: error
});

export const editBeer = (payload: IEditBeerPayload) => ({
  type: EDIT_BEER,
  payload
});
