import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { useHistory, useLocation } from "react-router";
import classnames from "classnames";
import { createGlobalStyle, css } from "styled-components";
import NukaCarousel from "nuka-carousel";
import { useIsMobile, useIsTablet } from "@ddm-design-system/hooks";
import { Icon } from "@ddm-design-system/icon";
import { EBreakpoints } from "@ddm-design-system/tokens";
import { getInsights, getOutletIdsSortedByName } from "../../../store/insights/selectors";
import InsightItemStory from "./InsightItemStory";
import { AnalyticsContext } from "../../../services/analytics";

const NUKA_CAROUSEL_MOBILE_SCALE_PCT = 0.85;
const CAROUSEL_ARROW_WIDTH_PX = 32;

interface IProps {
  selectedInsightData: { id: string; locationId: string };
  onClose: () => void;
}

const CarouselGlobalStyle = createGlobalStyle`
${({ theme }) => css`
  .insight-stories {
    .slider-slide {
      cursor: default;
      @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
        width: 100% !important;
        height: 100% !important;
      }

      &:focus-visible {
        outline: none;
      }
    }
    .slider-list {
      @media (max-width: ${theme.breakpoints[EBreakpoints.MOBILE1]}px) {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
`}
`;

export const InsightStoryList: React.FC<IProps> = ({ selectedInsightData, onClose }) => {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const insights = useSelector(getInsights);
  const orderedOutletIds = useSelector(getOutletIdsSortedByName);
  const [currentOutletId, setCurrentOutletId] = useState(selectedInsightData.locationId);
  const [currentInsightIds, setCurrentInsightIds] = useState<{
    [key: string]: { id: string; position: number };
  }>({});
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(false);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(false);
  const insightStoryRef = useRef<HTMLDivElement>(null);
  const analytics = useContext(AnalyticsContext);

  /**
   * If "animation" prop is passed to NukaCarousel, slides are scaled to 0.85 of its original size.
   * To make slides properly fit the whole screen in mobile, it is necessary to translate them horizontally by
   * the remaining pct / 2.
   */
  const mobileTranslateX = isMobile ? ((1 - NUKA_CAROUSEL_MOBILE_SCALE_PCT) / 2) * 100 : null;

  const target = useMemo(() => {
    const el = document.createElement("div");
    el.className = "";

    return el;
  }, []);

  // Add route for insight stories (if necessary) to allow native back button to work on
  // mobile devices
  // Commented because its crashing the application when refreshing the page with story list open.
  // useEffect(() => {
  //   // @ts-ignore
  //   if (!location.state?.insightId) {
  //     history.push(
  //       location.pathname,
  //       {
  //         ...location.state,
  //         insightId: selectedInsightData.id,
  //         insightOutletId: selectedInsightData.locationId
  //       }
  //     );
  //   }
  // }, []);

  useEffect(() => {
    document.body.appendChild(target);

    return () => {
      target.remove();
    };
  }, [target]);

  // Init currentInsightIds and currentSlideIndex - can't do it as default values of useState
  // bc insights might not be loaded yet (if app is refreshed while on stories)
  useEffect(() => {
    if (!currentInsightIds[currentOutletId] && !!insights[currentOutletId]) {
      setCurrentInsightIds(
        Object.entries(insights).reduce(
          (acc, [outletId, insightList]) => ({
            ...acc,
            [outletId]: {
              id:
                outletId === selectedInsightData.locationId
                  ? selectedInsightData.id
                  : insightList[0],
              position:
                outletId === selectedInsightData.locationId
                  ? insightList.findIndex(insight => insight.id === selectedInsightData.id)
                  : 0
            }
          }),
          {}
        )
      );
      setCurrentSlideIndex(orderedOutletIds.indexOf(currentOutletId));
    }
  }, [currentInsightIds, insights]);

  useEffect(() => {
    const handleEsc = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  // Handle enabling/disabling of carousel arrows
  useEffect(() => {
    if (currentInsightIds[currentOutletId]) {
      const insightPosition = currentInsightIds[currentOutletId].position;

      if (orderedOutletIds.indexOf(currentOutletId) === 0 && insightPosition === 0) {
        setIsLeftArrowDisabled(true);
      } else if (isLeftArrowDisabled) {
        setIsLeftArrowDisabled(false);
      }

      if (
        orderedOutletIds.indexOf(currentOutletId) === orderedOutletIds.length - 1 &&
        insightPosition === insights[currentOutletId].length - 1
      ) {
        setIsRightArrowDisabled(true);
      } else if (isRightArrowDisabled) {
        setIsRightArrowDisabled(false);
      }
    }
  }, [currentInsightIds, currentOutletId]);

  const handleNext = (direction: "left" | "right") => {
    const insightPosition = currentInsightIds[currentOutletId].position;

    if (
      (direction === "left" && isLeftArrowDisabled) ||
      (direction === "right" && isRightArrowDisabled)
    ) {
      return;
    }

    if (direction === "left") {
      if (insightPosition > 0) {
        setCurrentInsightIds(prevIds => ({
          ...prevIds,
          [currentOutletId]: {
            id: insights[currentOutletId][insightPosition - 1].id,
            position: insightPosition - 1
          }
        }));
      } else {
        setCurrentSlideIndex(prevIndex => prevIndex - 1);
        setCurrentOutletId(prevId => {
          return orderedOutletIds[orderedOutletIds.indexOf(prevId) - 1];
        });
      }
    }

    if (direction === "right") {
      if (insightPosition < insights[currentOutletId].length - 1) {
        setCurrentInsightIds(prevIds => ({
          ...prevIds,
          [currentOutletId]: {
            id: insights[currentOutletId][insightPosition + 1].id,
            position: insightPosition + 1
          }
        }));
      } else {
        setCurrentSlideIndex(prevIndex => prevIndex + 1);
        setCurrentOutletId(prevId => {
          return orderedOutletIds[orderedOutletIds.indexOf(prevId) + 1];
        });
      }
    }
  };

  const handleClose = () => {
    history.push(
      location.pathname,
      // @ts-ignore
      location.state?.outletId ? { outletId: location.state?.outletId } : null
    );
    onClose();
  };

  const handleOutletClick = (outletId: string) => {
    if (outletId !== currentOutletId) {
      setCurrentSlideIndex(orderedOutletIds.indexOf(outletId));
      setCurrentOutletId(outletId);
      analytics.logEvent("SWITCH_OUTLET_INSIGHT_STORIES", outletId);
    }
  };

  const handleSlideChange = (newSlideIndex: number) => {
    setCurrentSlideIndex(newSlideIndex);
    setCurrentOutletId(orderedOutletIds[newSlideIndex]);
    analytics.logEvent("CHANGE_CURRENT_INSIGHT_STORY", orderedOutletIds[newSlideIndex]);
  };

  const customLeftArrow = () => (
    <Icon
      className={classnames(
        "flex-shrink-0 cursor-pointer box-border rounded-full bg-grey-darkest p-xs",
        { "opacity-0 pointer-events-none": isLeftArrowDisabled }
      )}
      fill="#fff"
      name="ChevronLeft"
      size={CAROUSEL_ARROW_WIDTH_PX}
      onClick={() => handleNext("left")}
    />
  );

  const customRightArrow = () => (
    <Icon
      className={classnames(
        "flex-shrink-0 cursor-pointer box-border rounded-full bg-grey-darkest p-xs",
        { "opacity-0 pointer-events-none": isRightArrowDisabled }
      )}
      fill="#fff"
      name="ChevronRight"
      size={CAROUSEL_ARROW_WIDTH_PX}
      onClick={() => handleNext("right")}
    />
  );

  const mobileHandlers = useSwipeable({
    onSwipedDown: handleClose,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    trackTouch: true
  });

  const body = (
    <div className="flex flex-col absolute bg-background-black inset-0 z-[2000]">
      <div className={classnames("insight-stories h-full overflow-hidden")}>
        {!isMobile && (
          <div className="flex pr-xxl py-xxl w-full justify-between fixed">
            <Icon name="DmFullLogo" height={32} fill="var(--color-white)" />
            <div className="pr-xxl">
              <Icon
                className="cursor-pointer text-white fill-current"
                name="Close"
                size={16}
                onClick={handleClose}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col justify-center h-full w-full">
          <CarouselGlobalStyle />
          {currentInsightIds[currentOutletId] && (
            <NukaCarousel
              animation={isMobile ? "zoom" : undefined}
              cellAlign="center"
              disableEdgeSwiping
              dragging={false}
              getControlsContainerStyles={key => {
                // desktop - 5 slides, 20% width each, arrows need to be offset 2 slides
                // - ${CAROUSEL_ARROW_WIDTH_PX} - distance between arrow and slide (16px)
                // tablet - 3 slides, 33% width each, arrows need to be offset by 1 slide
                // - ${CAROUSEL_ARROW_WIDTH_PX} - distance between arrow and slide (16px)
                const offset = isTablet
                  ? `calc(33% - ${CAROUSEL_ARROW_WIDTH_PX}px - 16px)`
                  : `calc(39% - ${CAROUSEL_ARROW_WIDTH_PX}px - 16px)`;

                if (key === "CenterLeft") {
                  return isMobile
                    ? {
                        opacity: 0,
                        pointerEvents: "none"
                      }
                    : {
                        left: offset,
                        top: "50%"
                      };
                }

                if (key === "CenterRight") {
                  return isMobile
                    ? {
                        opacity: 0,
                        pointerEvents: "none"
                      }
                    : {
                        right: offset,
                        top: "50%"
                      };
                }

                return {};
              }}
              renderBottomCenterControls={null}
              renderCenterLeftControls={customLeftArrow}
              renderCenterRightControls={customRightArrow}
              scrollMode="page"
              slideIndex={currentSlideIndex}
              slidesToScroll={1}
              slidesToShow={isMobile ? 1 : isTablet ? 3 : 5}
              zoomScale={0.4}
              afterSlide={handleSlideChange}
              className="!h-auto xs:!h-full xs:!w-full"
            >
              {orderedOutletIds.map(outletId => {
                const card = (
                  <div
                    key={outletId}
                    className="w-full xs:h-full"
                    onClick={() => (!isMobile ? handleOutletClick(outletId) : {})}
                    {...(isMobile && outletId === currentOutletId ? { ...mobileHandlers } : {})}
                  >
                    {outletId !== currentOutletId && (
                      <div className="absolute w-full h-full cursor-pointer z-10" />
                    )}
                    <InsightItemStory
                      insights={insights[outletId]}
                      insight={insights[outletId][currentInsightIds[outletId].position]}
                      handleNext={handleNext}
                      outletFocused={outletId === currentOutletId}
                      className={classnames({
                        "scale-[0.4]": !isMobile && outletId !== currentOutletId
                      })}
                      // passing style as current version of tailwind has an issue with negative values
                      style={
                        isMobile
                          ? {
                              transform: `translateX(-${
                                orderedOutletIds.length > 1
                                  ? mobileTranslateX
                                  : (mobileTranslateX as number) * 2
                              }%)`
                            }
                          : null
                      }
                      ref={insightStoryRef}
                    />
                  </div>
                );

                return outletId === currentOutletId && !isMobile ? (
                  <div key={`${currentInsightIds[outletId]}-story}`} className="flex items-center">
                    {card}
                  </div>
                ) : (
                  card
                );
              })}
            </NukaCarousel>
          )}
        </div>
      </div>
    </div>
  );

  return createPortal(body, target);
};

export default InsightStoryList;
