/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "@ddm-design-system/icon";
import { Description } from "@ddm-design-system/typography";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import { ECleaningUnitButtons, IControlUnit } from "../../../../store/installation/types";
import { requestButtonPress } from "../../../../store/installation/actions";
import useContent from "../../../../hooks/useContent";
import CUInfoSwitcher from "../CUInfoSwitcher";

export interface ISoftDetergentNotDetectedProps {
  controlUnit: IControlUnit;
}

const SoftDetergentNotDetected: React.FC<ISoftDetergentNotDetectedProps> = ({ controlUnit }) => {
  const { managerAppCommon, managerAppControlUnit: content } = useContent();
  const { id } = controlUnit;
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);

  const handleRetry = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({
        button: ECleaningUnitButtons.CLEAN,
        controlUnitId: controlUnit.id
      })
    );
  }, [controlUnit.id, dispatch]);

  const handleCancel = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(
      requestButtonPress({
        button: ECleaningUnitButtons.CLEAR_SOFT,
        controlUnitId: controlUnit.id
      })
    );
  }, [controlUnit.id, dispatch]);

  useEffect(() => {
    if (loadingButtonPress) {
      const timeoutButtonPress = setTimeout(() => setLoadingButtonPress(false), 4000);
      return () => clearTimeout(timeoutButtonPress);
    }
  }, [loadingButtonPress]);

  return (
    <CUInfoSwitcher
      outerChildren={
        <>
          <Icon name="Warn" size={48} className="text-warning-warning100 fill-current" />
          <Description className="text-center">
            {content.manager_app_cu_step_soft_detergent_not_detected_desc}
          </Description>
        </>
      }
      innerChildren={
        <div className="flex flex-col gap-md items-center h-full">
          <div className="flex flex-1 flex-col gap-md items-center justify-between">
            <Description>
              {content.manager_app_cu_step_soft_detergent_not_detected_info}
            </Description>
          </div>
          <div className="flex flex-row">
            <SecondaryButton disabled={loadingButtonPress} onClick={handleCancel}>
              {managerAppCommon.common_cancel}
            </SecondaryButton>
            <PrimaryButton disabled={loadingButtonPress} onClick={handleRetry}>
              {managerAppCommon.common_retry}
            </PrimaryButton>
          </div>
        </div>
      }
      label={managerAppCommon.know_more}
      controlUnitId={id}
      labelAtBottom
    />
  );
};

export default SoftDetergentNotDetected;
