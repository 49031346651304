import produce from "immer";
import { Reducer } from "redux";
import * as d3 from "d3";
import numeral from "numeral";
import {
  IProductState,
  ProductActionTypes,
  REQUEST_INSIGHTS,
  REQUEST_INSIGHTS_ERROR,
  REQUEST_INSIGHTS_SUCCESS,
  REQUEST_PRODUCTS,
  REQUEST_PRODUCTS_ERROR,
  REQUEST_PRODUCTS_SUCCESS,
  SET_STOCK_SUCCESS
} from "./types";

export const initialState: IProductState = {
  error: false,
  insights: {},
  insightsError: false,
  insightsLoading: false,
  loading: false,
  products: {}
};

const reducer: Reducer<IProductState, ProductActionTypes> = (
  state = initialState,
  action: ProductActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_PRODUCTS:
        draft.error = false;
        draft.loading = true;

        break;

      case REQUEST_PRODUCTS_SUCCESS:
        Object.keys(action.payload).forEach(locationId => {
          if (!draft.products[locationId]) {
            draft.products[locationId] = {};
          }

          Object.keys(action.payload[locationId])
            // remove empty (No Beverage)
            .filter(
              beverageId =>
                beverageId !== "null" && beverageId !== "81daa384-d017-49e5-883c-0aa613d26d04"
            )
            .forEach(beverageId => {
              const { tapPositions, validVolumePercentage, volume } =
                action.payload[locationId][beverageId];

              const volumePerTap = !(
                volume !== null &&
                validVolumePercentage !== null &&
                validVolumePercentage !== 0
              )
                ? 0
                : tapPositions.length
                ? (volume * 100.0) / validVolumePercentage / tapPositions.length
                : (volume * 100.0) / validVolumePercentage;

              // hiding for now
              const performance = undefined; /* validVolumePercentage
                ? volumePerTap > UPPER_PERFORMANCE_THRESHOLD * (validVolumePercentage / 100)
                  ? EPerformanceLevel.overperforming
                  : volumePerTap < LOWER_PERFORMANCE_THRESHOLD * (validVolumePercentage / 100)
                  ? EPerformanceLevel.underperforming
                  : EPerformanceLevel.good
                : EPerformanceLevel.underperforming; */

              const kegsPerWeek = action.payload?.[locationId]?.[beverageId]?.kegsPerWeek || [];
              const kegsPerWeekSliced = kegsPerWeek.slice(0, 4);
              draft.products[locationId][beverageId] = {
                ...action.payload[locationId][beverageId],
                kegsPerWeek: kegsPerWeekSliced,
                avgKegsPerWeek: Number.parseFloat(
                  numeral(d3.mean(kegsPerWeekSliced) ?? 0).format("0.00")
                ),
                performance,
                volumePerTap
              };
            });
        });

        draft.loading = false;
        draft.error = false;
        break;

      case REQUEST_PRODUCTS_ERROR:
        draft.products = {};
        draft.error = true;
        draft.loading = false;
        break;

      case SET_STOCK_SUCCESS:
        const products = state.products[action.payload.locationId];
        action.payload.stock.forEach(s => {
          products[s.beverageId].kegsInStock = s.numberKegs;
        });

        draft.products = {
          ...state.products,
          [action.payload.locationId]: products
        };
        break;

      case REQUEST_INSIGHTS:
        draft.insightsLoading = true;
        break;

      case REQUEST_INSIGHTS_ERROR:
        draft.insights = {};
        draft.insightsError = true;
        draft.insightsLoading = false;
        break;

      case REQUEST_INSIGHTS_SUCCESS:
        draft.insights = action.payload;
        draft.insightsLoading = false;
        break;

      default:
        break;
    }
  });

export default reducer;
