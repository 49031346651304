import React, { useCallback, useMemo, useState } from "react";
import * as d3 from "d3";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardFooter } from "@ddm-design-system/card";
import { Tab, TabGroup } from "@ddm-design-system/tab";
import { Label, LabelMedium, SectionTitle } from "@ddm-design-system/typography";
import { LinkButton } from "@ddm-design-system/button";
import useContent from "../../hooks/useContent";
import Time from "../../lib/Time/Time";
import { ETimeFrame, ITimeRange } from "../../lib/Time/types";
import { setStartEndTimeRange } from "../../lib/Time/TimeFunctions";
import { setTimeFrame as setTimeFrameAction } from "../../store/filter/actions";
import Routes from "../../routes";
import RecentSalesCard from "./RecentSalesCard";
import RecentSalesInfo from "./RecentSalesInfo";
import { getTimeFrame } from "../../store/filter/selectors";
import { isNCUOutlet } from "../../store/installation/selectors";

const tabs: ITimeRange[] = [
  ETimeFrame.DAY,
  ETimeFrame.YESTERDAY,
  ETimeFrame.WEEK,
  ETimeFrame.LAST_4WEEKS
];

const RecentSales: React.FC = () => {
  const storeTimeFrame = useSelector(getTimeFrame);
  const [timeFrame, setTimeFrame] = useState(storeTimeFrame);
  const timeRange = useMemo(() => timeFrame.type, [timeFrame]);
  const tabIndex = useMemo(
    () =>
      Math.max(
        tabs.findIndex(tab => tab === timeRange),
        0
      ),
    [timeRange]
  );
  const selectedTab = useMemo(() => tabs[tabIndex], [tabIndex]);
  const {managerAppOverview: content, units} = useContent();
  const dispatch = useDispatch();
  const dates = useMemo(() => setStartEndTimeRange(selectedTab), [selectedTab]);
  const startMoment = dates.start.toJSDate();
  const history = useHistory();
  const isNCUOnly = useSelector(isNCUOutlet);

  const dateString = (
    <LabelMedium>
      {timeFrame.type === ETimeFrame.YESTERDAY || timeFrame.type === ETimeFrame.DAY
        ? d3.timeFormat("%d %b")(startMoment)
        : `${d3.timeFormat("%d %b")(dates.start.toJSDate())} - ${d3.timeFormat("%d %b")(
          new Time().toJSDate()
        )}`}
    </LabelMedium>
  );

  const goToSales = useMemo(
    () => () => {
      dispatch(
        setTimeFrameAction({
          from: setStartEndTimeRange(selectedTab).start,
          to: setStartEndTimeRange(selectedTab).end,
          type: selectedTab
        })
      );
      history.push(Routes.sales);
    },
    [dispatch, history, selectedTab]
  );

  const handleTabClick = useCallback((index: React.Key) => {
    const newTab = tabs[index as number];
    setTimeFrame({
      from: setStartEndTimeRange(newTab).start,
      to: setStartEndTimeRange(newTab).end,
      type: newTab
    });
  }, []);

  const {timeStrings = {}} = units;
  const getTabTitle = useCallback(
    (timeframe: ITimeRange) =>
      timeframe === ETimeFrame.YESTERDAY
        ? timeStrings.unit_yesterday
        : timeframe === ETimeFrame.DAY
          ? timeStrings.unit_today
          : timeframe === ETimeFrame.WEEK
            ? timeStrings.unit_last_7_days
            : timeStrings.unit_last_4_weeks,
    [timeStrings]
  );

  return (
    <div
      className="flex flex-1 flex-col items-stretch mr-xxl lg:mb-xxl lg:mr-0 w-full"
      data-test-id="recent-sales-container"
    >
      <div className="flex justify-start items-center mb-lg" data-test-id="recent-sales-header">
        <SectionTitle className="text-left xs:ml-lg">
          {content.manager_app_recent_performance}
        </SectionTitle>
        <RecentSalesInfo />
      </div>
      <Card className="xs:p-0 xs:mx-lg xs:my-0">
        <CardBody>
          <div className="flex justify-between xs:!w-full xs:overflow-y-auto scrollbar-hide">
            {!isNCUOnly &&
              <TabGroup
                className="xs:justify-between"
                selectedTab={tabIndex}
                onTabClick={handleTabClick}
              >
                {tabs.map(tab => (
                  <Tab
                    key={tab}
                    label={getTabTitle(tab)}
                    className="xs:!mr-xxs xs:!ml-0"
                    data-test-id="recent-sales-tab"
                  />
                ))}
              </TabGroup>
            }
            <Label className="flex items-center xs:!hidden">{dateString}</Label>
          </div>
          <div>
            <RecentSalesCard id={`overview_${selectedTab.toLowerCase()}`} />
          </div>
        </CardBody>
        <CardFooter className="!p-md">
          <LinkButton icon="ChevronRight" onClick={goToSales}>
            {content[`manager_app_redirect_${selectedTab.toLowerCase()}_sales`]}
          </LinkButton>
        </CardFooter>
      </Card>
    </div>
  );
};

export default RecentSales;
