import React from "react";
import classnames from "classnames";
import { BodyHighlight, Description } from "@ddm-design-system/typography";
import useContent from "../../../hooks/useContent";
import { ITargetData } from "../../../store/targets/types";
import Donut from "../../common/donut/Donut";

export interface IProps {
  isCurrentMonth: boolean;
  isSpeciality?: boolean;
  targetData: ITargetData;
}

const TargetItem: React.FC<IProps> = ({ isCurrentMonth, isSpeciality = false, targetData }) => {
  const { managerAppOverview: content } = useContent();
  const { current, expected, goal } = targetData;
  const roundedCurrent = Math.round(current);
  const roundedExpected = Math.round(expected ?? 0);
  const isBelowExpected = expected && expected < 0;

  const expectedText = isCurrentMonth
    ? isBelowExpected
      ? (content.target_expected_below_target || "").replace(
          "%value%",
          `${Math.abs(roundedExpected ?? 0)}${isSpeciality ? "%" : "L"}`
        )
      : (content.target_expected_above_target || "").replace(
          "%value%",
          `${roundedExpected}${isSpeciality ? "%" : "L"}`
        )
    : isBelowExpected
    ? (content.target_below_target || "").replace(
        "%value%",
        `${Math.abs(roundedExpected)}${isSpeciality ? "%" : "L"}`
      )
    : (content.target_above_target || "").replace(
        "%value%",
        `${roundedExpected}${isSpeciality ? "%" : "L"}`
      );

  return (
    <div className="target-item">
      <Donut
        value={(current / goal) * 100}
        foregroundColor={{
          fill: isBelowExpected ? "var(--color-alert-alert100)" : "var(--color-success-success100)"
        }}
      >
        {`${roundedCurrent}${isSpeciality ? "%" : "L"}`}
      </Donut>
      <div className="target-item-info">
        <BodyHighlight>
          {isSpeciality
            ? (content.target_percent_in_speciality || "").replace("%percent%", `${goal}`)
            : (content.target_liters_in_volume || "").replace("%liters%", `${goal}`)}
        </BodyHighlight>
        <Description
          className={classnames(
            "target-item-info-expected",
            isBelowExpected
              ? "target-item-info-expected--below"
              : "target-item-info-expected--above"
          )}
        >
          {expectedText}
        </Description>
      </div>
    </div>
  );
};

export default TargetItem;
