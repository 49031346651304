import {
  GET_OUTLET_LOCATION_DATA,
  GET_OUTLET_LOCATION_DATA_SUCCESS,
  GET_OUTLET_LOCATION_DETAILS,
  GET_OUTLET_LOCATION_DETAILS_ERROR,
  GET_OUTLET_LOCATION_DETAILS_SUCCESS,
  GET_OUTLET_LOCATION_STATUS,
  GET_OUTLET_LOCATION_STATUS_ERROR,
  GET_OUTLET_LOCATION_STATUS_SUCCESS,
  IGetOutletLocationData,
  IGetOutletLocationDataSuccess,
  IGetOutletLocationDetails,
  IGetOutletLocationDetailsError,
  IGetOutletLocationDetailsSuccess,
  IGetOutletLocationStatus,
  IGetOutletLocationStatusError,
  IGetOutletLocationStatusSuccess,
  ILocationData,
  ILocationDetails,
  IManualLocationDataInput,
  IOutlet,
  IRequestOutlets,
  IRequestOutletsError,
  ISetOutletFavourite,
  ISetOutletFavouriteError,
  ISetOutletFavouriteSuccess,
  ISetOutletGoogleLocationData,
  ISetOutletManualLocationData,
  ISetOutletPickerTab,
  ISetOutlets,
  REQUEST_OUTLETS,
  REQUEST_OUTLETS_ERROR,
  SET_OUTLET_FAVOURITE,
  SET_OUTLET_FAVOURITE_ERROR,
  SET_OUTLET_FAVOURITE_SUCCESS,
  SET_OUTLET_GOOGLE_LOCATION_DATA,
  SET_OUTLET_MANUAL_LOCATION_DATA,
  SET_OUTLET_PICKER_TAB,
  SET_OUTLETS
} from "./types";

export const setOutletPickerTab = (outletPickerTab: number): ISetOutletPickerTab => ({
  type: SET_OUTLET_PICKER_TAB,
  payload: outletPickerTab
});
export const setOutlets = (outlets: IOutlet[]): ISetOutlets => ({
  type: SET_OUTLETS,
  payload: outlets
});
export const requestOutlets = (): IRequestOutlets => ({
  type: REQUEST_OUTLETS
});
export const requestOutletsError = (): IRequestOutletsError => ({
  type: REQUEST_OUTLETS_ERROR
});

export const getOutletLocationStatus = (): IGetOutletLocationStatus => ({
  type: GET_OUTLET_LOCATION_STATUS
});

export const getOutletLocationStatusSuccess = (data: []): IGetOutletLocationStatusSuccess => ({
  type: GET_OUTLET_LOCATION_STATUS_SUCCESS,
  payload: {
    data
  }
});

export const getOutletLocationStatusError = (): IGetOutletLocationStatusError => ({
  type: GET_OUTLET_LOCATION_STATUS_ERROR
});

export const getOutletLocationDetails = (outletId: string): IGetOutletLocationDetails => ({
  type: GET_OUTLET_LOCATION_DETAILS,
  payload: {
    outletId
  }
});

export const getOutletLocationDetailsSuccess = (
  data: ILocationDetails
): IGetOutletLocationDetailsSuccess => ({
  type: GET_OUTLET_LOCATION_DETAILS_SUCCESS,
  payload: {
    data
  }
});

export const getOutletLocationDetailsError = (): IGetOutletLocationDetailsError => ({
  type: GET_OUTLET_LOCATION_DETAILS_ERROR
});

export const getOutletLocationData = (outletId: string, force = false): IGetOutletLocationData => ({
  type: GET_OUTLET_LOCATION_DATA,
  payload: {
    outletId,
    force
  }
});
export const getOutletLocationDataSuccess = (
  outletId: string,
  data: ILocationData
): IGetOutletLocationDataSuccess => ({
  type: GET_OUTLET_LOCATION_DATA_SUCCESS,
  payload: {
    outletId,
    data
  }
});
export const setOutletGoogleLocationData = (
  outletId: string,
  outletName: string,
  placeId: string
): ISetOutletGoogleLocationData => ({
  type: SET_OUTLET_GOOGLE_LOCATION_DATA,
  payload: { outletId, outletName, placeId }
});

export const setOutletManualLocationData = (
  outletId: string,
  outletName: string,
  data: IManualLocationDataInput
): ISetOutletManualLocationData => ({
  type: SET_OUTLET_MANUAL_LOCATION_DATA,
  payload: { outletId, outletName, data }
});

export const setOutletFavourite = (outletId: string, favourite: boolean): ISetOutletFavourite => ({
  type: SET_OUTLET_FAVOURITE,
  payload: { outletId, favourite }
});

export const setOutletFavouriteSuccess = (data: {
  outletId: string;
  favourite: boolean;
}): ISetOutletFavouriteSuccess => ({
  type: SET_OUTLET_FAVOURITE_SUCCESS,
  payload: data
});

export const setOutletFavouriteError = (data: {
  outletId: string;
  favourite: boolean;
}): ISetOutletFavouriteError => ({
  type: SET_OUTLET_FAVOURITE_ERROR,
  payload: data
});
