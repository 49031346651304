import React from "react";
import "./header.scss";
import { PageTitle, Subtitle } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import OutletPicker from "../outlet_picker/OutletPicker";
import InsightsButton from "../insights/InsightsButton";

interface IProps {
  showOutletPicker?: boolean;
  title?: string;
}

const Header: React.FC<IProps> = ({ title, showOutletPicker }) => {
  const isMobile = useIsMobile();
  const Title = isMobile ? Subtitle : PageTitle;
  return (
    <div className="header">
      {(title && <Title>{title}</Title>) || (!showOutletPicker && <div />)}
      {showOutletPicker && <OutletPicker />}
      <InsightsButton />
    </div>
  );
};

export default Header;
