import React, { useCallback } from "react";
import { SectionTitle } from "@ddm-design-system/typography";
import classnames from "classnames";
import { BarChart } from "@ddm-design-system/bar-chart";
import { useSelector } from "react-redux";
import { useIsMobile } from "@ddm-design-system/hooks";
import { IWithAggRequestProps, withAggRequest } from "../../common/hoc/withAggRequest";
import { SalesLegend } from "../../common/legend/Legend";
import useContent from "../../../hooks/useContent";
import { ChartTooltip } from "../charts/ChartTooltip";
import { AveragePerformancePerHourInfo, AveragePerformancePerWeekdayInfo } from "../SalesInfo";
import { getFilter } from "../../../store/filter/selectors";
import { PERFORMANCE_TYPE } from "../../../store/filter/types";
import { formatReadableNumbersToLiters } from "../../../helpers";

const GWeek: React.FC<IWithAggRequestProps> = ({ data, loading, error }) => {
  const { managerAppSales: content } = useContent();

  const noLineData = data?.line?.[0] === undefined;

  return content && data ? (
    <div className="card-chart">
      <div className="card-header">
        <div className="card-title">
          <SectionTitle>{content.manager_app_average_per_week_day}</SectionTitle>
          <AveragePerformancePerWeekdayInfo />
        </div>
        <SalesLegend />
      </div>
      <div className={classnames("card-content no-card", { loading, error })}>
        {/* {loading && <Body className="loading-text">{loading && "Loading..."}</Body>} */}
        <BarChart
          domain={data?.bars?.map((b: any) => b.label) || []}
          data={data?.bars || []}
          lineData={noLineData ? [] : data?.line || []}
          tickYFormat={dataLabel => formatReadableNumbersToLiters(dataLabel)}
          height={300}
          tooltipElement={ChartTooltip}
          margin={{ top: 10, left: 50, right: 0, bottom: 30 }}
          tooltipYOffset={-100}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

const GHour: React.FC<IWithAggRequestProps> = ({ data, loading, error }) => {
  const { managerAppSales: content } = useContent();
  const { performanceType } = useSelector(getFilter);
  const isMobile = useIsMobile();

  const getTickFormat = useCallback(
    (dataFormat: string) =>
      isMobile ? (parseInt(dataFormat) % 2 === 0 ? dataFormat : "") : dataFormat,
    [isMobile]
  );

  return content && data ? (
    <div className="card-chart">
      <div className="card-header">
        <div className="card-title">
          <SectionTitle>{content.manager_app_average_per_hour_day}</SectionTitle>
          <AveragePerformancePerHourInfo />
        </div>
        <SalesLegend hideCompare />
      </div>
      <div className={classnames("card-content no-card", { loading, error })}>
        {/* {loading && <Body className="loading-text">{loading && "Loading..."}</Body>} */}
        <BarChart
          domain={data?.bars?.map((b: any) => b.label) || []}
          data={data?.bars || []}
          lineData={performanceType === PERFORMANCE_TYPE.TARGET ? [] : data?.line || []}
          tickYFormat={dataLabel => formatReadableNumbersToLiters(dataLabel)}
          tickFormat={getTickFormat}
          height={300}
          tooltipElement={ChartTooltip}
          margin={{ top: 10, left: 50, right: 0, bottom: 30 }}
          tooltipYOffset={-100}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
export const GroupTimeCardHour = withAggRequest(GHour);
export const GroupTimeCardWeek = withAggRequest(GWeek);
