export const Routes = {
  home: "/",
  mailUnsubscribe: "/unsubscribedigestemail",
  firstLogin: "/firstlogin",
  overview: "/overview/:id?",
  overviewAll: "/overview",
  sales: "/performance",
  products: "/product",
  product: "/product/:id",
  installations: "/installation",
  installation: "/installation/:id",
  CUStatusHistory: "/installation/:id/:controlUnitId/statusHistory",
  settings: "/settings",
  profile: "/settings/profile",
  users: "/settings/users",
  outlets: "/settings/outlets",
  login: "/login",
  logout: "/logout"
};
export default Routes;
