import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { Icon, IconNames, IIconProps } from "@ddm-design-system/icon";
import { Menu, MenuItem } from "@ddm-design-system/menu";
import classnames from "classnames";
import { Infoicon } from "@ddm-design-system/infoicon";
import { useIsMobile } from "@ddm-design-system/hooks";
import { Divider } from "@ddm-design-system/divider";
import { BodyHighlight, Body } from "@ddm-design-system/typography";
import { IInsightsNotification } from "../../../store/insights/types";
import { setInsightFeedback } from "../../../store/insights/actions";
import InsightDetails from "./InsightDetails";
import { AnalyticsContext } from "../../../services/analytics";

interface IProps {
  title: string;
  description?: JSX.Element | string;
  icon?: IconNames;
  iconOptions?: IIconProps;
  avatarOptions?: any;
  insight: IInsightsNotification;
  footer?: JSX.Element;
  className?: string;
}
const InsightItemContent: React.FC<IProps> = ({
  className,
  title,
  description,
  icon,
  insight,
  footer,
  children,
  iconOptions,
  avatarOptions = {}
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);

  const setFeedback = useCallback(
    (f: boolean) => {
      dispatch(setInsightFeedback(insight.locationId, insight.id, f));
      analytics.logEvent("SET_INSIGHT_FEEDBACK", insight.id);
    },
    [insight, dispatch]
  );

  const feedBackIconClassnames =
    "!cursor-pointer border border-black rounded-[50%] bg-black text-white fill-current mx-md min-w-[24px] min-h-[24px]";

  return (
    <div className={className}>
      <div className="flex">
        <Infoicon
          title={<BodyHighlight className="text-white mb-xs">{title}</BodyHighlight>}
          className="flex-1"
          avatarOptions={{
            children: icon && <Icon name={icon} {...iconOptions} />,
            ...avatarOptions
          }}
        >
          <Body className="!text-grey-grey50">
            <div className={`${insight.details.length > 0 && "mb-xs"}`}>{description}</div>
            {insight.details.length > 0 && <InsightDetails details={insight.details} />}
          </Body>
        </Infoicon>
        <Menu
          renderHeader={() => (
            <Icon
              name="Ellipsis"
              size={16}
              className="text-grey-grey100 fill-current min-w-[16px] hover:text-grey-grey50"
            />
          )}
          direction="bottom-right"
          className="h-lg mt-[2px]"
        >
          <MenuItem
            key="Feedback"
            className="!bg-background-black !border-background-black !cursor-default"
          >
            <div className="flex flex-row justify-around items-center">
              <Icon
                className={classnames(feedBackIconClassnames, "hover:border-success-success100", {
                  "!border-transparent !text-success-success100": insight.feedback === true
                })}
                name="ThumbsUp"
                size={16}
                onClick={() => setFeedback(true)}
              />
              <Icon
                className={classnames(feedBackIconClassnames, "hover:border-alert-alert100", {
                  "!border-transparent !text-alert-alert100": insight.feedback === false
                })}
                name="ThumbsDown"
                size={16}
                onClick={() => setFeedback(false)}
              />
            </div>
          </MenuItem>
        </Menu>
      </div>

      <div data-test-id="insight-item-content" className="my-md">
        {children}
      </div>
      {footer && <div>{footer}</div>}
      {isMobile && <Divider className="my-lg float-right !w-[calc(100%-64px)]" />}
    </div>
  );
};

export default InsightItemContent;
