import { call, put, select, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import { ISetStock, REQUEST_PRODUCTS, SET_STOCK, REQUEST_INSIGHTS } from "../store/product/types";
import {
  requestProductsError,
  requestProductsSuccess,
  setStockError,
  setStockSuccess,
  requestInsightsSuccess,
  requestInsightsError
} from "../store/product/actions";
import { getCurrentContent } from "../store/content/selectors";

export function* requestProductsSaga(jCoreService: IJCoreService) {
  try {
    const result = yield call([jCoreService, jCoreService.requestProducts]);

    yield put(requestProductsSuccess(result.data));
  } catch {
    yield put(requestProductsError());
  }
}

export function* setStockSaga(context: IAppContext, action: ISetStock) {
  const { jCoreService, notificationService } = context;
  const content = yield select(getCurrentContent);
  const contentProduct = content ? content.managerAppProduct : null;

  try {
    yield call(
      // @ts-ignore
      [jCoreService, jCoreService.setStock],
      action.payload.locationId,
      action.payload.stock,
      action.payload.updatedToday
    );

    yield put(setStockSuccess(action.payload));

    notificationService.addNotification({
      iconProps: { name: "Ok" },
      title: contentProduct.manager_app_product_stock_updated,
      text: contentProduct.manager_app_product_stock_updated_desc
    });
  } catch {
    yield put(setStockError());

    notificationService.addNotification({
      iconProps: { name: "error" },
      title: contentProduct.manager_app_product_stock_updated_error
    });
  }
}

export function* requestInsightsSaga(jCoreService: IJCoreService) {
  try {
    const result = yield call([jCoreService, jCoreService.requestInsights]);

    yield put(requestInsightsSuccess(result.data));
  } catch {
    yield put(requestInsightsError());
  }
}

export function* productWatcher(context: IAppContext) {
  yield takeLatest(REQUEST_PRODUCTS, requestProductsSaga, context.jCoreService);
  yield takeLatest(SET_STOCK, setStockSaga, context);
  yield takeLatest(REQUEST_INSIGHTS, requestInsightsSaga, context.jCoreService);
}

export default productWatcher;
