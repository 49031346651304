import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { SectionTitle } from "@ddm-design-system/typography";
import { IconNames } from "@ddm-design-system/icon";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";
import { getBeverage } from "../../../../store/beers/selectors";
import { IAppState } from "../../../../store";

interface IProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightTapsPerformance: React.FC<IProps> = ({ insight, itemType }) => {
  const { managerAppInsights: content } = useContent();
  const { isFastSelling, taps }: any = insight.customFields;
  const state = useSelector((s: IAppState) => s);
  let title: string;
  let description: string;
  let iconBg: string;
  let icon: IconNames;

  const tapsToShow = useMemo(() => {
    if (taps.length > 0) {
      return taps.map((t: { beverage_id: string; position: number }) => {
        const beverage = getBeverage(state, t.beverage_id || "");
        return (
          <div
            key={`${beverage?.id}-${t.position}`}
            className="flex items-center justify-center relative bg-grey-greenish w-[36px] h-[36px] box-border bg-cover rounded-[50%] mb-md mr-md"
            title={beverage?.name}
            style={{ backgroundImage: `url(${beverage?.logoUrl})` }}
          >
            <SectionTitle className="flex items-center justify-center text-white absolute w-full h-full rounded-[50%] bg-background-overlay hover:bg-[rgba(7,7,7,0.2)]">
              {t.position}
            </SectionTitle>
          </div>
        );
      });
    }
    return <SectionTitle>--</SectionTitle>;
  }, [insight, taps]);

  if (isFastSelling) {
    title = content.manager_app_insights_taps_performance_over_title;
    description = content.manager_app_insights_taps_performance_over_desc;
    iconBg = "var(--color-success-success100)";
    icon = "TapOverSelling";
  } else {
    title = content.manager_app_insights_taps_performance_under_title;
    description = content.manager_app_insights_taps_performance_under_desc;
    iconBg = "var(--color-alert-alert100)";
    icon = "TapUnderSelling";
  }

  const renderInsight = useCallback(
    itemType => {
      switch (itemType) {
        case EInsightItemType.INSIGHT_ITEM:
          return (
            <InsightItemContent
              className="w-full"
              title={title}
              description={description}
              icon={icon}
              iconOptions={{ name: icon, className: "text-white fill-current" }}
              avatarOptions={{
                backgroundColor: iconBg
              }}
              insight={insight}
            >
              <div className="flex flex-wrap ml-xxxl pt-md pl-md rounded-10 bg-black">
                {tapsToShow}
              </div>
            </InsightItemContent>
          );
        case EInsightItemType.INSIGHT_CARD:
          return (
            <InsightCard
              title={title}
              icon={icon}
              iconOptions={{ name: icon, className: "text-white fill-current" }}
              avatarOptions={{
                backgroundColor: iconBg
              }}
              insight={insight}
            />
          );
        case EInsightItemType.INSIGHT_STORY:
          return (
            <InsightStory
              title={title}
              description={description}
              icon={icon}
              iconOptions={{ name: icon, className: "text-white fill-current" }}
              avatarOptions={{
                backgroundColor: iconBg
              }}
              insight={insight}
            >
              <div className="flex flex-wrap max-h-[15vh] overflow-y-auto mt-md pt-md pl-md rounded-10 bg-background-black">
                {tapsToShow}
              </div>
            </InsightStory>
          );
        default:
          return <></>;
      }
    },
    [[insight, itemType]]
  );

  return renderInsight(itemType);
};

export default InsightTapsPerformance;
