import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Description, SectionTitle, Subtitle } from "@ddm-design-system/typography";
import { ExpandablePanels } from "@ddm-design-system/expandable-panel";
import { useHistory } from "react-router";
import { PrimaryButton } from "@ddm-design-system/button";
import Routes from "../../routes";
import {
  getLoadingOutletsDetails,
  getOutletById,
  getOutletDetailsError,
  getOutletDetailsWithTranslations
} from "../../store/outlet/selectors";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import { getChosenOutlet, getChosenOutletId } from "../../store/filter/selectors";
import { InstallationLine } from "./InstallationLine";
import { getOutletLocationDetails } from "../../store/outlet/actions";
import { getBeersLoading } from "../../store/beers/selectors";
import useContent from "../../hooks/useContent";
import PressureChamberDetailsSidebar from "./PressureChamberDetailsSidebar";
import { IControlUnit, IPressureChamber, ISteelKegUnit } from "../../store/installation/types";
import { AnalyticsContext } from "../../services/analytics";
import EditBeverageModal from "./EditBeverageModal";
import ControlUnitDetailsSidebar from "./ControlUnitDetailsSidebar";
import { getOutletsFromGroup } from "../../helpers";
import { IOutletPickerGroupItem } from "../common/outlet_picker/OutletPicker";
import { isNCUOutlet } from "../../store/installation/selectors";
import SteelKegUnitDetailsSidebar from "./SteelKegUnitDetailsSidebar";

interface IProps {
  outletId: string;
}

export const InstallationDetail: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const { managerAppCommon: content } = useContent();
  const outlet = useSelector(getOutletById(outletId));
  const selectedOutletId: string = useSelector(getChosenOutletId);
  const selectedOutlet = useSelector(getChosenOutlet);
  const outletDetailsHasError: boolean = useSelector(getOutletDetailsError);
  const loadingOutletDetails: boolean = useSelector(getLoadingOutletsDetails);
  const loadingBeverages: boolean = useSelector(getBeersLoading);
  const isNCUOnly = useSelector(isNCUOutlet);

  const outletDetails = useSelector(getOutletDetailsWithTranslations);

  const [editedPressureChamber, setEditedPressureChamber] = useState<{
    pressureChamber: IPressureChamber;
    outletId: string;
  }>();
  const [editedSteelKegUnit, setEditedSteelKegUnit] = useState<{
    steelKegUnit: ISteelKegUnit;
    outletId: string;
  }>();
  const [selectedPC, setSelectedPC] = useState<
    | {
        pressureChamber: IPressureChamber;
      }
    | undefined
  >();
  const [selectedCU, setSelectedCU] = useState<
    | {
        controlUnit: IControlUnit;
      }
    | undefined
  >();
  const [selectedSK, setSelectedSK] = useState<
    | {
        steelKegUnit: ISteelKegUnit;
      }
    | undefined
  >();

  useEffect(() => {
    if (selectedOutletId && !selectedOutlet?.type && selectedOutletId !== outletId) {
      history.push(`/installation/${selectedOutletId}`);
    } else if (selectedOutletId && selectedOutlet?.type) {
      const outletsIds = getOutletsFromGroup(selectedOutlet as IOutletPickerGroupItem).map(
        o => o.id
      );
      if (!outletsIds.includes(outletId)) {
        history.push("/installation");
      }
    } else {
      dispatch(getOutletLocationDetails(outletId));
    }
  }, [outletId, selectedOutlet, selectedOutletId, dispatch]);

  useEffect(() => {
    const intervalFunc = setInterval(() => {
      if (outletId) {
        dispatch(getOutletLocationDetails(outletId));
      }
    }, 2000);

    return () => {
      clearInterval(intervalFunc);
    };
  }, [dispatch, outletId]);

  const renderLines = useMemo(() => {
    return outletDetails.lines.map((line, i) => {
      return (
        <InstallationLine
          key={line.index}
          outletId={outletId}
          line={line}
          cleaningDueDate={outletDetails.cleaningDueDate}
          setSelectedPC={setSelectedPC}
          setSelectedCU={setSelectedCU}
          setSelectedSK={setSelectedSK}
          initialExpanded={i === 0}
        />
      );
    });
  }, [outletDetails, selectedOutletId]);

  const renderLoadingCards = useCallback(() => {
    const cards = [];
    for (let i = 0; i < 6; i++) {
      cards.push(
        <div
          key={i}
          className="rounded-[4px] box-border justify-between w-full relative mb-md
          cursor-pointer !bg-grey-grey25 opacity-0 min-h-[76px] xs:w-full xs:max-w-none animate-pulse-fade-in"
        />
      );
    }
    return cards;
  }, []);

  const handlePressureChamberEdit = useCallback((outletId, pressureChamber) => {
    setEditedPressureChamber({ outletId, pressureChamber });
  }, []);

  const handleSteelKegUnitEdit = useCallback((outletId, steelKegUnit) => {
    setEditedSteelKegUnit({ outletId, steelKegUnit });
  }, []);

  const handleDetailsModalClose = useCallback(() => {
    analytics.logEvent(
      selectedCU ? "CONTROL_UNIT_DETAILS_CLOSE" : "PRESSURE_CHAMBER_DETAILS_CLOSE"
    );
    setSelectedPC(undefined);
    setSelectedCU(undefined);
    setSelectedSK(undefined);
  }, [analytics]);

  const handleEditModalClose = useCallback(() => {
    setEditedPressureChamber(undefined);
    setEditedSteelKegUnit(undefined);
  }, []);

  return (
    <div className="flex flex-col w-full">
      {(!selectedOutletId || selectedOutlet?.type) && (
        <>
          <Breadcrumbs
            data-test-id="installation-breadcrumbs"
            current={outlet?.name}
            items={
              !selectedOutletId
                ? [{ link: Routes.installations, name: content.common_all }]
                : [{ link: Routes.installations, name: selectedOutlet?.name }]
            }
          />
          <Subtitle className="pt-xl text-left">{outlet?.name}</Subtitle>
        </>
      )}
      <div className="pt-md" data-test-id="installation-lines">
        {loadingOutletDetails || loadingBeverages ? (
          renderLoadingCards()
        ) : outletDetailsHasError ? (
          <div className="w-full">{content.common_error_during_request}</div>
        ) : outletDetails.lines.length > 0 ? (
          <>
            <ExpandablePanels className="w-full" initialExpandedPanelIndex={1}>
              {renderLines}
            </ExpandablePanels>
            {isNCUOnly && (
              <div className="flex flex-col w-[300px] justify-center m-auto mb-xxl">
                <SectionTitle className="text-grey-darkest">
                  {content.common_no_other_units}
                </SectionTitle>
                <Description className="my-md text-grey-grey100">
                  {content.common_learn_more_description}
                </Description>
                <div className="flex justify-center">
                  <a
                    href="https://www.draughtmaster.com/en/digital/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <PrimaryButton>{content.common_learn_more}</PrimaryButton>
                  </a>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>{content.no_components_found}</div>
        )}
      </div>
      <PressureChamberDetailsSidebar
        outletId={outletId}
        pressureChamber={selectedPC?.pressureChamber}
        onPressureChamberEdit={handlePressureChamberEdit}
        isVisible={!!selectedPC}
        handleClose={handleDetailsModalClose}
      />
      <SteelKegUnitDetailsSidebar
        outletId={outletId}
        onSteelKegUnitEdit={handleSteelKegUnitEdit}
        steelKegUnit={selectedSK?.steelKegUnit}
        isVisible={!!selectedSK}
        handleClose={handleDetailsModalClose}
      />
      <ControlUnitDetailsSidebar
        controlUnit={selectedCU?.controlUnit}
        isVisible={!!selectedCU}
        handleClose={handleDetailsModalClose}
      />
      {editedPressureChamber && selectedPC && (
        <EditBeverageModal
          outletId={outletId}
          pressureChamber={selectedPC.pressureChamber}
          onClose={handleEditModalClose}
        />
      )}
      {editedSteelKegUnit && selectedSK && (
        <EditBeverageModal
          outletId={outletId}
          pressureChamber={selectedSK.steelKegUnit as IPressureChamber}
          onClose={handleEditModalClose}
          isSteelKeg
        />
      )}
    </div>
  );
};
