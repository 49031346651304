import React, { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";

import { DangerButton, SecondaryButton } from "@ddm-design-system/button";
import { ExpandablePanels } from "@ddm-design-system/expandable-panel";
import { useInfiniteScrollList, useIsMobile } from "@ddm-design-system/hooks";
import { Icon } from "@ddm-design-system/icon";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { SectionTitle } from "@ddm-design-system/typography";

import { MAX_OUTLETS_IN_DESCRIPTION } from "../../constants";
import useContent from "../../hooks/useContent";
import { getAllOutlets } from "../../store/outlet/selectors";
import { getUser } from "../../store/profile/reducer";
import { getUsers, setPermissions } from "../../store/users/actions";
import { getUsers as getAllUsers } from "../../store/users/selectors";
import { ERole, EUserRankNames, IOutletUsers, IUser } from "../../store/users/types";
import Filter from "../common/filter/Filter";
import { SettingsBackButton } from "../settings/Settings";
import AddUserModal from "./AddUserModal";
import OutletUsersCard from "./OutletUsersCard";

const intersectionOptions = {
  root: document.querySelector("#root"),
  rootMargin: "0px 0px 100px 0px"
};

export const SettingsUser: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { managerAppCommon: content, managerAppSettings: settingsContent } = useContent();
  const [removeOutletModal, setRemoveOutletModal] = useState(false);
  const users = useSelector(getAllUsers);
  const me = useSelector(getUser);
  const outlets = useSelector(getAllOutlets);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const outletsMap: IOutletUsers[] = useMemo(
    () =>
      outlets.map(o => {
        const usersFlat: any = [];
        users.forEach((user: IUser) => {
          const includesLocation = user.locationIds?.includes(o.id);
          if (includesLocation) {
            // if (usersMap[user.id] != null) {
            //   usersMap[user.id].actions.concat(p);
            // } else {
            usersFlat.push({
              id: user.id,
              name: user.name,
              // permissions: p,
              rank: user.rank,
              role: user.role,
              username: user.username
            });
            // }
          }
        });
        return {
          outletId: o.id,
          outletName: o.name,
          users: usersFlat
        };
      }),
    [users, outlets]
  );

  const [addUserVisible, setAddUserVisible] = useState(false);
  const [pickedOutlet, setPickedOutlet] = useState<IOutletUsers>();
  const [filter, setFilter] = useState("");
  let filteredOutlets: IOutletUsers[] = [];
  filteredOutlets = useMemo(
    () =>
      filter.length === 0
        ? outletsMap
        : outletsMap.filter((o: IOutletUsers) => {
            const searchTerm = filter.toLocaleLowerCase();
            return (
              (o.outletName && o.outletName.toLowerCase().includes(searchTerm)) ||
              o.users.some(
                (u: IUser) =>
                  (u.name && u.name.toLowerCase().includes(searchTerm)) ||
                  u.username.toLowerCase().includes(searchTerm)
              )
            );
          }),
    [filter, outletsMap]
  );

  const joinedBars = useMemo(() => {
    const barsArray = outlets?.map(o => o.name);
    if (!barsArray) {
      return "";
    }
    let str = "";
    let breakSignal = false;
    for (let i = 0; i < barsArray.length; i++) {
      if (i > 0 && i !== barsArray.length - 1 && i < MAX_OUTLETS_IN_DESCRIPTION) {
        str += ", ";
      } else if (i > 0) {
        if (i < MAX_OUTLETS_IN_DESCRIPTION) {
          str += `__ ${content.manager_app_common_and} __`;
        } else {
          str += `__ ${content.manager_app_common_and} __`;
          breakSignal = true;
        }
      }
      if (breakSignal) {
        str += `${barsArray.length - MAX_OUTLETS_IN_DESCRIPTION} ${
          content.manager_app_common_others
        }`;
        break;
      } else {
        str += barsArray[i];
      }
    }
    return str;
  }, [outlets]);

  const handleRemoveOutletCancel = () => {
    setRemoveOutletModal(false);
  };

  const handleRemoveOutletConfirm = () => {
    if (me) {
      dispatch(
        setPermissions({
          outletId: pickedOutlet?.outletId ?? "",
          rank: EUserRankNames.Staff,
          username: me.username,
          add: false
        })
      );

      setRemoveOutletModal(false);
    }
  };

  const outletCards = useMemo(
    () =>
      filteredOutlets.map((outlet: IOutletUsers) => (
        <OutletUsersCard
          startExpanded={filteredOutlets.length === 1}
          key={outlet.outletId}
          outlet={outlet}
          openModal={e => {
            setPickedOutlet(outlet);
            setAddUserVisible(e);
          }}
          removeMe={() => {
            setPickedOutlet(outlet);
            setRemoveOutletModal(true);
          }}
        />
      )),
    [filter, filteredOutlets]
  );
  const outletsToShow = useInfiniteScrollList(outletCards, {
    intersectionOptions
  });

  return (
    settingsContent &&
    content &&
    me && (
      <div className="pt-xl md:pb-xl md:flex-col md:py-xxs xs:pt-0">
        {isMobile && (
          <div className="text-left mb-lg">
            <SettingsBackButton className="p-0 mb-xxl" />
            <SectionTitle>{settingsContent.manager_app_settings_user_management_tab}</SectionTitle>
          </div>
        )}
        {me?.role === ERole.ROLE_OWNER && (
          <div className="text-left my-lg leading-6">
            <ReactMarkdown>
              {(settingsContent.manager_app_settings_role_description_owner || "").replace(
                "%STRING%",
                joinedBars
              )}
            </ReactMarkdown>
          </div>
        )}
        {me?.role === ERole.ROLE_STAFF && (
          <div className="text-left my-lg leading-6">
            <ReactMarkdown>
              {(settingsContent.manager_app_settings_role_description_staff || "").replace(
                "%STRING%",
                joinedBars
              )}
            </ReactMarkdown>
          </div>
        )}
        {me?.role === ERole.ROLE_READER && (
          <div className="text-left my-lg leading-6">
            <ReactMarkdown>
              {(settingsContent.manager_app_settings_role_description_reader || "").replace(
                "%STRING%",
                joinedBars
              )}
            </ReactMarkdown>
          </div>
        )}
        <Filter
          data-test-id="settings-user-filter"
          className="mb-lg md:w-auto"
          onFilterChange={e => setFilter(e)}
          label={settingsContent.manager_app_settings_user_filter_description}
        />
        <ExpandablePanels>{outletsToShow}</ExpandablePanels>
        {outlets.length > 0 && (
          <AddUserModal
            visible={addUserVisible}
            outlet={pickedOutlet}
            onClose={() => setAddUserVisible(false)}
          />
        )}
        {outlets.length > 0 && filteredOutlets.length < 1 && (
          <div className="w-full text-left text-grey-grey100">
            <ReactMarkdown>
              {(settingsContent.manager_app_settings_no_outlets || "").replace("%STRING%", filter)}
            </ReactMarkdown>
          </div>
        )}

        <Modal
          isOpen={removeOutletModal}
          shouldCloseOnOverlayClick
          onRequestClose={handleRemoveOutletCancel}
        >
          <ModalHeader
            className="text-alert-alert100"
            icon={<Icon className="fill-current" name="Exit" />}
            headerTitle={settingsContent.manager_app_settings_leave_outlet_title}
            showCloseButton={false}
          />
          <ModalBody>
            <ReactMarkdown>
              {settingsContent.manager_app_settings_leave_outlet_message}
            </ReactMarkdown>
          </ModalBody>
          <ModalFooter>
            <SecondaryButton onClick={handleRemoveOutletCancel}>
              {content.common_cancel}
            </SecondaryButton>
            <DangerButton onClick={handleRemoveOutletConfirm}>
              {settingsContent.manager_app_settings_leave_outlet_confirm}
            </DangerButton>
          </ModalFooter>
        </Modal>
      </div>
    )
  );
};
