import React, {useContext, useEffect} from "react";
import {NotificationContext} from "@ddm-design-system/notification";
import useContent from "./useContent";
import AuthContext from "../components/auth/AuthContext";

// rotate between login page background pictures
export function useAzureAuth(azureToken: string | null) {
  const auth = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const { managerAppLogin: content, emailDomains } = useContent();

  useEffect(() => {
    if (azureToken) {
      const userAdal = auth.adal?.getUser()?.unique_name;
      if (!userAdal) {
        return;
      }
      const tokens = userAdal ? userAdal.split("@") : null;
      if (tokens) {
        if (tokens[1] && emailDomains.split("\n").includes(tokens[1])) {
          auth.validateAzureInvitationToken(azureToken);
        } else {
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
            displayDuration: 6000,
            text: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  {(content.manager_app_email_domain_error || "").replace("%EMAIL%", tokens[1])}
                </span>
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (auth.adal) {
                      localStorage.removeItem("ddm-azureinvitationtoken");
                      auth.adal.logout();
                    }
                  }}
                >
                  {content.manager_app_adal_logout}
                </span>
              </div>
            )
          });
        }
      }
    }
  }, [azureToken]);

  // handle auth azure errors
  useEffect(() => {
    if (auth.error && auth.error.body) {
      switch (auth.error.type) {
        case "NO_ACCESS_AZURE":
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
            displayDuration: 10000,
            text: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  {(content.manager_app_adal_error || "").replace("%ACCOUNT%", auth.error.body)}
                </span>
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    if (auth.adal) {
                      localStorage.removeItem("ddm-azureinvitationtoken");
                      auth.adal?.logout();
                    }
                  }}
                >
                  {content.manager_app_adal_logout}
                </span>
              </div>
            )
          });
          break;
        case "ERROR_LINKING_ACCOUNT":
          if (auth.error.body === "registered") {
            notificationContext.addNotification({
              iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
              displayDuration: 6000,
              text: "Error linking this azure account. Error: Account already exists on the platform"
            });
          } else {
            notificationContext.addNotification({
              iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
              displayDuration: 6000,
              text: `${content.manager_app_error_linking}. Error: Invalid Token`
            });
          }
          break;
        default:
          notificationContext.addNotification({
            iconProps: { name: "Warning", color: "var(--color-alert-alert100)" },
            displayDuration: 6000,
            text: "Unknown Error"
          });
          break;
      }
      localStorage.removeItem("ddm-azureinvitationtoken");
    }
  }, [auth.error]);
}

export default useAzureAuth;
