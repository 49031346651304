import React, { HTMLAttributes, useCallback, useRef, useState } from "react";
import { debounce } from "lodash";
import { SearchInput } from "@ddm-design-system/textinput";
import classnames from "classnames";
import "./filter.scss";

interface IProps extends HTMLAttributes<HTMLElement> {
  onFilterChange?: (text: string) => void;
  label?: string;
}

export const Filter: React.FC<IProps> = ({ onFilterChange, className, label, ...props }) => {
  const [filterText, setFilterText] = useState("");

  const debouncedOnFilterChange = useRef(
    debounce((text: string) => {
      onFilterChange?.(text);
    }, 250)
  ).current;

  const setFilter = useCallback(
    (text: string) => {
      setFilterText(text);
      debouncedOnFilterChange(text);
    },
    [debouncedOnFilterChange]
  );

  return (
    <SearchInput
      className={classnames("filter", className)}
      value={filterText}
      label={label}
      onChange={e => {
        setFilter(e.target.value);
      }}
      {...props}
    />
  );
};
export default Filter;
