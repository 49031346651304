import React from "react";
import ReactMarkdown from "react-markdown";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { Body } from "@ddm-design-system/typography";
import { IJCoreService } from "../services/jcore";
import { IAppContext } from "../services";
import { EDIT_BEER, IEditBeer, IRequestBeers, REQUEST_BEERS } from "../store/beers/types";
import { getCurrentContent } from "../store/content/selectors";
import { getBeverage } from "../store/beers/selectors";
import { requestBeersError, requestBeersSuccess } from "../store/beers/actions";
import {
  setPressureChamberBeverage,
  setPressureChamberBeverageError
} from "../store/installation/actions";
import { getPressureChamberBeverageName } from "../store/installation/selectors";
import { deleteStaleKegNotification } from "../store/insights/actions";

export function* requestBeers(jCoreService: IJCoreService, action: IRequestBeers) {
  try {
    const result: any = yield call(
      [jCoreService, jCoreService.getBeers],
      action.payload.locationIds
    );

    yield put(requestBeersSuccess(result.data));
  } catch (error) {
    yield put(requestBeersError(error));
  }
}

export function* editBeer(context: IAppContext, action: IEditBeer) {
  const { jCoreService, notificationService } = context;
  const { beverageId, outletId, thingId, pressureChamberId, isSteelKeg, isBeerDrive } =
    action.payload;
  const oldBeverageName = yield select(getPressureChamberBeverageName(thingId));
  const newBeverage = yield select(getBeverage, beverageId);
  const content = yield select(getCurrentContent);
  const contentCommon = content ? content.managerAppCommon : null;

  try {
    yield call([jCoreService, jCoreService.editBeer], thingId, beverageId, isBeerDrive, isSteelKeg);
    yield put(
      setPressureChamberBeverage(
        outletId,
        thingId,
        newBeverage,
        oldBeverageName,
        isBeerDrive,
        isSteelKeg
      )
    );
    yield put(deleteStaleKegNotification(outletId, pressureChamberId));

    const notificationText = (contentCommon.manager_app_notification_set_beverage_success || "")
      .replace("%OLDBEVERAGE%", oldBeverageName)
      .replace("%NEWBEVERAGE%", newBeverage.name);

    notificationService.addNotification({
      iconProps: { name: "Ok" },
      text: (
        <Body>
          <ReactMarkdown>{notificationText}</ReactMarkdown>
        </Body>
      )
    });
  } catch (error) {
    yield put(setPressureChamberBeverageError());

    const notificationText = contentCommon.manager_app_notification_set_beverage_error;
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      text: (
        <Body>
          <ReactMarkdown>{notificationText}</ReactMarkdown>
        </Body>
      )
    });
  }
}

export function* beersWatcher(context: IAppContext) {
  yield takeEvery(REQUEST_BEERS, requestBeers, context.jCoreService);
  yield takeEvery(EDIT_BEER, editBeer, context);
}

export default beersWatcher;
