import React, { forwardRef, HTMLAttributes, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useIsMobile } from "@ddm-design-system/hooks";
import { getIsInsightSeen } from "../../../store/insights/selectors";
import InsightComponentMap, { InsightComponentTestMap } from "./InsightItemMap";
import { EInsightItemType, IInsightsNotification } from "../../../store/insights/types";
import { setInsightSeen } from "../../../store/insights/actions";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  insights: IInsightsNotification[];
  insight: IInsightsNotification;
  handleNext: (direction: string) => void;
  outletFocused?: boolean;
}

const InsightItemStory = forwardRef<HTMLDivElement, IProps & { [key: string]: unknown }>(
  ({ insights, insight, handleNext, outletFocused, className, style, ...props }, ref) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const InsightComponent =
      (InsightComponentMap as any)[insight.type] || (InsightComponentTestMap as any)[insight.type];

    const insightSeen = useSelector(getIsInsightSeen(insight.locationId, insight.id));

    useEffect(() => {
      if (outletFocused && !insightSeen) {
        dispatch(setInsightSeen(insight.locationId, insight.id));
      }
    }, [insight]);

    return InsightComponentMap && InsightComponentTestMap && InsightComponent ? (
      <div
        data-test-id="insight-item-story"
        className={classNames("cursor-pointer xs:h-full", { "flex justify-center": !isMobile })}
        ref={ref}
      >
        <div
          data-test-id="insight-story"
          className={classNames(
            className,
            "bg-primary h-[72vh] w-[calc(72vh*0.46)] lg:h-[50vh] p-[6%] rounded-10 box-border",
            !insightSeen && "border-2 border-success-success100",
            isMobile && "!w-full !h-full"
          )}
          style={style}
        >
          <div className="flex flex-col h-full">
            <div className="flex flex-row justify-items-center gap-1 w-full mt-xs">
              {insights.length > 1 &&
                insights.map(i => (
                  <div
                    key={i.id}
                    className={`h-0.5 w-full rounded-10 ${
                      i.id === insight.id ? "bg-gray-200" : "bg-gray-600"
                    }`}
                  />
                ))}
            </div>
            <div
              className="absolute inset-y-0 right-2/3 left-0"
              onClick={() => {
                handleNext("left");
              }}
            />
            <div
              className="absolute inset-y-0 left-2/3 right-0"
              onClick={() => {
                handleNext("right");
              }}
            />
            <InsightComponent
              insight={insight}
              itemType={EInsightItemType.INSIGHT_STORY}
              {...props}
            />
          </div>
        </div>
      </div>
    ) : (
      <>{insight.type}</>
    );
  }
);

export default InsightItemStory;
