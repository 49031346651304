import React from "react";

const SVGAirFlush = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="23.5" stroke="#E5EBE8" />
    <path
      d="M13.5841 24.8081H26.8318C28.0029 24.8081 29.1261 25.2734 29.9543 26.1015C30.7824 26.9296 31.2477 28.0528 31.2477 29.224C31.2477 30.3952 30.7824 31.5184 29.9543 32.3465C29.1261 33.1746 28.0029 33.6399 26.8318 33.6399"
      stroke="#070707"
      strokeWidth="2.21"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.4638 15.2402C27.0494 15.2402 27.611 15.4729 28.025 15.8869C28.4391 16.301 28.6717 16.8626 28.6717 17.4482C28.6717 18.0338 28.4391 18.5954 28.025 19.0094C27.611 19.4235 27.0494 19.6561 26.4638 19.6561H16.896"
      stroke="#070707"
      strokeWidth="2.21"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVGAirFlush;
