import React, { useMemo, SyntheticEvent } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import { DescriptionMedium } from "@ddm-design-system/typography";
import { BeverageTranslation } from "../../store/beers/types";
import { getIsLoading } from "../../store/product/selectors";

const MAX_LOGOS_LENGTH = 7;

interface IProps {
  title: string;
  brands: InstanceType<typeof BeverageTranslation>[];
  isProductInfo?: boolean;
  dataTestId?: string;
}

const ProductSummaryGroup: React.FC<IProps> = ({ title, brands, isProductInfo, dataTestId }) => {
  const showEllipsis = brands.length > MAX_LOGOS_LENGTH;

  // for UI stacking order purposes, so that visually brands on the left stack over ones on the right
  const reverseBrands = useMemo(
    () => (showEllipsis ? brands.slice(0, MAX_LOGOS_LENGTH).reverse() : brands.reverse()),
    [brands, showEllipsis]
  );

  // dynamically set background color for broken logo imgs
  const handleImgError = (error: SyntheticEvent, brand: BeverageTranslation) => {
    error.currentTarget.setAttribute(
      "style",
      `background-color: ${brand.mainColor ?? "var(--color-grey-grey50)"}`
    );
  };
  const loading = useSelector(getIsLoading);

  return (
    <div
      className={classnames(
        "border border-solid border-grey-greenish rounded-10 box-border py-md px-lg w-[calc(25%-(3*var(--space-unit-xxl)/4))] lg:w-[calc(50%-(var(--space-unit-lg)/2))] xs:w-full ",
        {
          "text-transparent": loading,
          "border-none !mb-0 !w-auto lg:!w-auto": isProductInfo
        }
      )}
      data-test-id={dataTestId}
    >
      <div className="flex justify-between mb-xs">
        <DescriptionMedium className={classnames("text-grey-grey100", { "mr-lg": isProductInfo })}>
          {title}
        </DescriptionMedium>
        <DescriptionMedium>{brands.length}</DescriptionMedium>
      </div>
      <div className="flex flex-row-reverse justify-end">
        {showEllipsis && (
          <Icon
            className={classnames(
              "shadow-8 border border-solid border-white rounded-[50%] box-border flex-shrink-0 h-[24px] w-[24px] p-xxs",
              {
                "bg-grey-grey25": loading,
                "bg-grey-grey50": !loading
              }
            )}
            name="Ellipsis"
          />
        )}
        {(loading ? Array(4).fill("") : reverseBrands).map((brand, i) =>
          brand?.logoUrl ? (
            <img
              className="border border-solid border-white rounded-[50%] box-border flex-shrink-0 h-[24px] w-[24px] mr-[-6px]"
              key={brand.id}
              style={{ boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 8px" }}
              src={loading ? "" : brand.logoUrl}
              alt=""
              title={brand.name}
              onError={error => handleImgError(error, brand)}
            />
          ) : (
            <div
              className={classnames(
                "shadow-8 border border-solid border-white rounded-[50%] box-border flex-shrink-0 h-[24px] w-[24px] mr-[-6px]",
                {
                  "bg-grey-grey25": loading,
                  "bg-grey-grey50": !loading
                }
              )}
              key={brand?.id || i}
              style={{
                backgroundColor: brand?.mainColor
              }}
              title={brand?.name}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ProductSummaryGroup;
