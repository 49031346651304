import { useIsMobile } from "@ddm-design-system/hooks";
import { PrimaryButton } from "@ddm-design-system/button";
import { Icon } from "@ddm-design-system/icon";
import { DescriptionMedium, SectionTitle } from "@ddm-design-system/typography";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { ILocationStatus } from "../../store/outlet/types";
import { requestButtonPress } from "../../store/installation/actions";
import { ECleaningUnitButtons } from "../../store/installation/types";
import useContent from "../../hooks/useContent";
import Time from "../../lib/Time/Time";
import ErrorList from "./ErrorList";

interface IProps {
  outletStatus: ILocationStatus;
}

export const InstallationCard: React.FC<IProps> = ({ outletStatus }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { managerAppControlUnit: cuContent } = useContent();
  const history = useHistory();
  const hasNCU = !!outletStatus?.cleaningStatus;

  const handleStart = useCallback(() => {
    if (!outletStatus?.controlUnitId) return;
    dispatch(
      requestButtonPress({
        button: ECleaningUnitButtons.CLEAN,
        controlUnitId: outletStatus?.controlUnitId
      })
    );
    history.push(`/installation/${outletStatus?.id}`);
  }, [outletStatus, dispatch, history]);

  return (
    <>
      <div
        className={`flex flex-row items-center mt-lg w-full border-[1px] cursor-pointer ${
          hasNCU ? "rounded-t-10" : "rounded-10"
        } border-grey-grey50 md:items-start`}
      >
        <div
          className="flex flex-row justify-between overflow-hidden w-full p-lg md:flex-col md:text-left xs:max-w-[70%]"
          onClick={() => history.push(`/installation/${outletStatus?.id}`)}
        >
          <div className="flex items-center overflow-hidden mr-xs ">
            <SectionTitle className="overflow-hidden whitespace-nowrap overflow-ellipsis">
              {outletStatus?.name}
            </SectionTitle>
          </div>
          <ErrorList errors={outletStatus.errors} />
        </div>
        <div className="md:mt-lg">
          <Icon name="ChevronRight" className="pr-lg" />
        </div>
      </div>
      {hasNCU && (
        <div
          className={`flex w-full border-[1px] rounded-b-10
                    ${
                      outletStatus.cleaningStatus === "ERROR"
                        ? "bg-alert-alert25 border-alert-alert25"
                        : outletStatus.cleaningStatus === "WARNING"
                        ? "bg-warning-warning25 border-warning-warning25"
                        : "bg-grey-grey50 border-grey-grey50"
                    }`}
        >
          <div className="flex flex-row items-center justify-between w-full p-md">
            <div className="flex flex-row gap-x-lg items-center">
              <div className="flex flex-row gap-x-xs items-center">
                <Icon name="Cleaning" />
                <DescriptionMedium>{cuContent.manager_app_cu_cleaning}</DescriptionMedium>
              </div>
              {!isMobile && (
                <DescriptionMedium>
                  {outletStatus.cleaningDueDate
                    ? (cuContent.manager_app_cu_recommended_cleaning_limit || "").replace(
                        "%DAYS%",
                        new Time(Date.now()).diff(new Time(outletStatus.cleaningDueDate)).toString()
                      )
                    : cuContent.manager_app_cu_reached_recommended_cleaning_limit}
                </DescriptionMedium>
              )}
            </div>
            <PrimaryButton
              type="submit"
              onClick={handleStart}
              className={`${
                outletStatus.cleaningStatus === "ERROR"
                  ? "hover:!bg-alert-alert50"
                  : outletStatus.cleaningStatus === "WARNING"
                  ? "hover:!bg-warning-warning50"
                  : "hover:!bg-grey-grey100"
              }`}
            >
              {!outletStatus.isCleaning
                ? cuContent.manager_app_cu_start_cleaning_cycle
                : cuContent.manager_app_cu_view_cleaning_cycle}
            </PrimaryButton>
          </div>
        </div>
      )}
    </>
  );
};

export default InstallationCard;
