import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Divider } from "@ddm-design-system/divider";
import { useInfiniteScrollList, useIsMobile } from "@ddm-design-system/hooks";
import { Icon } from "@ddm-design-system/icon";
import { IPopoverRef, Popover } from "@ddm-design-system/popover";
import { Tab, TabGroup } from "@ddm-design-system/tab";
import { SearchInput } from "@ddm-design-system/textinput";
import {
  Body,
  Description,
  PageTitle,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import { deburr } from "lodash";
import { Key } from "../../../constants";
import useContent from "../../../hooks/useContent";
import Routes from "../../../routes";
import { getCurrentLanguage } from "../../../store/content/selectors";
import { setFilterOutlets } from "../../../store/filter/actions";
import { getChosenOutlet } from "../../../store/filter/selectors";
import { getInsightsUnreadLengthByOutlet } from "../../../store/insights/selectors";
import { setOutletFavourite, setOutletPickerTab } from "../../../store/outlet/actions";
import { getAllOutlets, getCurrentOutletPickerTab } from "../../../store/outlet/selectors";
import { IOutlet } from "../../../store/outlet/types";
import { getUser } from "../../../store/profile/reducer";
import OutletPickerItem from "./OutletPickerItem";
import "./outlet-picker.scss";
import PurchasingGroupItem from "./PurchasingGroupItem";
import DistributorItem from "./DistributorItem";

export interface IOutletPickerDistributorItem {
  type: "DISTRIBUTOR";
  id: string;
  name: string;
  outlets: IOutlet[];
}

export interface IOutletPickerPGItem {
  type: "PG";
  id: string;
  name: string;
  distributors: IOutletPickerDistributorItem[];
}

export type IOutletPickerGroupItem = IOutletPickerDistributorItem | IOutletPickerPGItem;
export type IOutletPickerItem = IOutlet | IOutletPickerDistributorItem | IOutletPickerPGItem;

const handlePressUpArrow = () => {
  const activeEl = document.activeElement;
  const prevEl = document.activeElement?.previousElementSibling as HTMLDivElement;
  const outletElList = document.getElementsByClassName("outlet-item-container");

  if (activeEl?.className.includes("outlet-item-container")) {
    if (prevEl && !prevEl.className.includes("outlet-item-container")) {
      (prevEl.previousElementSibling as HTMLDivElement)?.focus();
      const prevSibling = prevEl.previousElementSibling as HTMLDivElement;
      const prevSibling2 = prevSibling?.previousElementSibling as HTMLDivElement;

      if (prevSibling && prevSibling.className.includes("outlet-item-container")) {
        prevSibling?.focus();
        return;
      }
      if (prevSibling2 && prevSibling2.className.includes("outlet-item-container")) {
        prevSibling2?.focus();
        return;
      }

      if (outletElList.length > 1) {
        (outletElList[0] as HTMLDivElement)?.focus();
      }
    } else if (prevEl) {
      prevEl.focus();
    } else {
      const firstOutletEl = outletElList.length && (outletElList[0] as HTMLDivElement);

      if (firstOutletEl && activeEl !== firstOutletEl) {
        firstOutletEl.focus();
      }
    }
  }
};

const handlePressDownArrow = () => {
  const activeEl = document.activeElement;
  const nextEl = document.activeElement?.nextElementSibling as HTMLDivElement;
  const outletElList = document.getElementsByClassName("outlet-item-container");

  if (!activeEl?.className.includes("outlet-item-container") && outletElList.length) {
    (outletElList[0] as HTMLDivElement).focus();
  } else if (nextEl && !nextEl.className.includes("outlet-item-container")) {
    const nextSibling = nextEl.nextElementSibling as HTMLDivElement;
    const nextSibling2 = nextSibling?.nextElementSibling as HTMLDivElement;

    if (nextSibling && nextSibling.className.includes("outlet-item-container")) {
      nextSibling?.focus();
      return;
    }
    if (nextSibling2 && nextSibling2.className.includes("outlet-item-container")) {
      nextSibling2?.focus();
      return;
    }

    if (outletElList.length > 1) {
      (outletElList[1] as HTMLDivElement)?.focus();
    }
  } else if (nextEl) {
    nextEl.focus();
  }
};

export const OutletPicker: React.FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const { managerAppCommon: content } = useContent();
  const isMobile = useIsMobile();
  const outlets = useSelector(getAllOutlets);
  const selectedOutlet = useSelector(getChosenOutlet);
  const currentOutletPickerTab = useSelector(getCurrentOutletPickerTab);
  const popoverRef = useRef<IPopoverRef>(null);
  const popoverBodyRef = useRef<HTMLDivElement>(null);
  const currentLanguage = useSelector(getCurrentLanguage);
  const insights = useSelector(getInsightsUnreadLengthByOutlet);
  const me = useSelector(getUser);
  const [searchText, setSearchText] = useState("");
  const [initialScrollLimit, setInitialScrollLimit] = useState(10);
  const [popoverOpening, setPopoverOpening] = useState(false);
  const [visible, setVisible] = useState(false);
  const [shouldScrollToOutlet, setShouldScrollToOutlet] = useState(false);
  const outletRef = useRef<HTMLDivElement>(null);

  const Title = isMobile ? Subtitle : PageTitle;

  const setOutletAux = useCallback(
    (outlet: IOutletPickerItem[]) => {
      // filters the outlet
      dispatch(setFilterOutlets(outlet));
    },
    [currentLanguage, dispatch, me, outlets.length]
  );

  const closePopover = useCallback(() => {
    popoverRef?.current?.hide();
  }, []);

  const handleChooseOutlet = useCallback(
    (outlet?: IOutletPickerItem) => {
      setOutletAux(!outlet ? [] : [outlet]);
      closePopover();

      history.push(location.pathname, { outletId: outlet ? outlet.id : "all" });
    },
    [closePopover, history, location.pathname, setOutletAux]
  );

  const handleToggleFavourite = useCallback(
    (outletId: string, favourite: boolean) => {
      const outletIndex = outlets.findIndex(outlet => outlet.id === outletId);

      // sets the initial scroll limit because toggling favourite will cause a refresh on the list
      setInitialScrollLimit(
        Math.max(initialScrollLimit, outletIndex === -1 || outletIndex < 10 ? 10 : outletIndex + 10)
      );

      dispatch(setOutletFavourite(outletId, favourite));
    },
    [outlets, dispatch, initialScrollLimit]
  );

  const handleSelectAllTab = useCallback(
    tabIndex => {
      dispatch(setOutletPickerTab(tabIndex));
      setShouldScrollToOutlet(true);
    },
    [dispatch]
  );

  const handleKeyDown = useCallback(e => {
    if (e.keyCode === Key.ARROW_UP) {
      e.stopPropagation();
      e.preventDefault();

      handlePressUpArrow();
    } else if (e.keyCode === Key.ARROW_DOWN) {
      e.stopPropagation();
      e.preventDefault();

      handlePressDownArrow();
    }
  }, []);

  const groupedOutlets = useMemo(() => {
    const result = outlets.reduce(
      (agg, o) => {
        o.distributors?.forEach(distributor => {
          const pg = distributor?.purchasingGroup;
          if (pg) {
            const group = agg[0].find(p => p.id === pg.id);
            if (group) {
              const groupDist = group.distributors.find(p => p.id === distributor.id);
              if (groupDist) {
                groupDist.outlets.push(o);
              } else {
                group.distributors.push({
                  type: "DISTRIBUTOR",
                  id: distributor.id,
                  name: distributor.name,
                  outlets: [o]
                });
              }
            } else {
              agg[0].push({
                type: "PG",
                id: pg.id,
                name: pg.name,
                distributors: [
                  {
                    type: "DISTRIBUTOR",
                    id: distributor.id,
                    name: distributor.name,
                    outlets: [o]
                  }
                ]
              });
            }
          } else if (distributor) {
            const groupDist = agg[1].find(p => p.id === distributor.id);
            if (groupDist) {
              groupDist.outlets.push(o);
            } else {
              agg[1].push({
                type: "DISTRIBUTOR",
                id: distributor.id,
                name: distributor.name,
                outlets: [o]
              });
            }
          }
        });
        if (!o.distributors?.length) {
          agg[2].push(o);
        }

        return agg;
      },
      [[], [], []] as [IOutletPickerPGItem[], IOutletPickerDistributorItem[], IOutlet[]]
    );

    return [
      ...(result[0]?.length ? ["PG", ...result[0]] : []),
      ...(result[1]?.length ? ["DISTRIBUTOR_WITHOUT", ...result[1]] : []),
      ...(result[2]?.length ? ["OUTLET_WITHOUT", ...result[2]] : [])
    ];
  }, [outlets]);

  // apply the search filter
  const filteredOutlets = useMemo(
    () =>
      outlets.filter((o: IOutlet) =>
        deburr(o.name).toLocaleLowerCase().includes(deburr(searchText).toLocaleLowerCase())
      ),
    [outlets, searchText]
  );

  const favouriteOutlets = useMemo(() => {
    return filteredOutlets.filter((o: IOutlet) => o.favourite);
  }, [filteredOutlets]);

  const showAllOutletsItem = useMemo(
    () =>
      outlets.length > 1 &&
      deburr(content.all_outlets || "")
        .toLocaleLowerCase()
        .includes(deburr(searchText).toLocaleLowerCase()),
    [content.all_outlets, outlets.length, searchText]
  );

  // focus on first outlet in list for keyboard controls purposes
  useEffect(() => {
    if (visible) {
      const outletElList = document.getElementsByClassName("outlet-item-container");

      if (outletElList.length) {
        setTimeout(() => {
          (outletElList[0] as HTMLDivElement).focus();
        });
      }
    }
  }, [visible]);

  // if there is a selected outlet, setup scrolling
  useEffect(() => {
    if (visible && selectedOutlet) {
      const outletIndex = outlets.findIndex(outlet => outlet.id === selectedOutlet.id);
      setInitialScrollLimit(outletIndex === -1 || outletIndex < 10 ? 10 : outletIndex + 10);
      setShouldScrollToOutlet(true);

      // also focus on the selected outlet, so the keyboard works from there
      setTimeout(() => {
        outletRef?.current?.focus();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOutlet, visible]);

  // handle scrolling to selected outlet
  useEffect(() => {
    if (visible) {
      if (outletRef.current) {
        setTimeout(() => {
          outletRef.current?.scrollIntoView({ behavior: "instant" as any, block: "start" });
          setShouldScrollToOutlet(false);
        });
      }
      setTimeout(() => {
        setPopoverOpening(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletRef.current, visible]);

  useEffect(() => {
    if (!outlets.length) return;

    // automatically chooses the only outlet available
    if (me && outlets.length === 1) {
      setOutletAux([outlets[0]]);
    }
  }, [me, outlets]); // eslint-disable-line react-hooks/exhaustive-deps

  const outletsToShow = useMemo(
    () =>
      (searchText !== "" ? filteredOutlets : groupedOutlets).map(
        (outlet: string | IOutletPickerItem, i) =>
          typeof outlet === "string" ? (
            <div className="text-grey-grey100 mb-xs" key={outlet}>
              <Description>
                {outlet === "PG"
                  ? content.common_purchasing_groups
                  : outlet === "DISTRIBUTOR_WITHOUT"
                  ? content.common_distributors_without
                  : outlet === "OUTLET_WITHOUT"
                  ? content.common_outlets_without
                  : ""}
              </Description>
            </div>
          ) : (outlet as IOutletPickerPGItem).type === "PG" ? (
            <PurchasingGroupItem
              ref={shouldScrollToOutlet && outlet.id === selectedOutlet?.id ? outletRef : null}
              key={outlet?.id + outlet?.name}
              purchasingGroup={outlet as IOutletPickerPGItem}
              selected={selectedOutlet?.id === outlet.id}
              onClick={() => handleChooseOutlet(outlet)}
              selectedOutletId={selectedOutlet?.id}
              renderChildItem={d => (
                <DistributorItem
                  ref={shouldScrollToOutlet && d.id === selectedOutlet?.id ? outletRef : null}
                  key={d.id}
                  distributor={d}
                  selected={selectedOutlet?.id === d.id}
                  onClick={() => handleChooseOutlet(d)}
                  inside={1}
                  selectedOutletId={selectedOutlet?.id}
                  renderChildItem={d2 => (
                    <OutletPickerItem
                      ref={shouldScrollToOutlet && d2.id === selectedOutlet?.id ? outletRef : null}
                      key={d2.id}
                      outlet={d2}
                      selected={selectedOutlet?.id === d2.id}
                      onClick={() => handleChooseOutlet(d2)}
                      onFavourite={handleToggleFavourite}
                      insightsLength={insights[d2.id]}
                      inside={2}
                    />
                  )}
                />
              )}
            />
          ) : (outlet as IOutletPickerDistributorItem).type === "DISTRIBUTOR" ? (
            <DistributorItem
              ref={shouldScrollToOutlet && outlet.id === selectedOutlet?.id ? outletRef : null}
              key={`${outlet.id + outlet?.name}-${i}`}
              distributor={outlet as IOutletPickerDistributorItem}
              selected={selectedOutlet?.id === outlet.id}
              onClick={() => handleChooseOutlet(outlet)}
              selectedOutletId={selectedOutlet?.id}
              renderChildItem={d2 => (
                <OutletPickerItem
                  ref={shouldScrollToOutlet && d2.id === selectedOutlet?.id ? outletRef : null}
                  key={d2.id}
                  outlet={d2}
                  selected={selectedOutlet?.id === d2.id}
                  onClick={() => handleChooseOutlet(d2)}
                  onFavourite={handleToggleFavourite}
                  insightsLength={insights[d2.id]}
                  inside={1}
                />
              )}
            />
          ) : (
            <OutletPickerItem
              ref={shouldScrollToOutlet && outlet.id === selectedOutlet?.id ? outletRef : null}
              key={outlet.id}
              outlet={outlet as IOutlet}
              selected={selectedOutlet?.id === outlet.id}
              onClick={() => handleChooseOutlet(outlet)}
              onFavourite={handleToggleFavourite}
              insightsLength={insights[outlet.id]}
            />
          )
      ),
    [
      insights,
      handleChooseOutlet,
      handleToggleFavourite,
      filteredOutlets,
      selectedOutlet,
      shouldScrollToOutlet
    ]
  );

  const listRef = useRef(null);
  const infiniteScrollOptions = useMemo(
    () => ({
      dependencies: [currentOutletPickerTab],
      intersectionOptions: {
        root: listRef.current,
        rootMargin: "0px 100px 0px 0px"
      },
      initialLimit: initialScrollLimit,
      limit: 50
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOutletPickerTab, visible, initialScrollLimit]
  );
  const infiniteOutlets = useInfiniteScrollList(outletsToShow, infiniteScrollOptions);

  // if there is no outlet id in the history's state, manually set it
  useEffect(() => {
    // @ts-ignore
    if (!location.state?.outletId && location.pathname !== Routes.home) {
      const outletId = selectedOutlet ? selectedOutlet.id : "all";
      history.replace(location.pathname, { outletId });
    }
  }, [history, location.pathname, location.state, selectedOutlet]);

  useEffect(() => {
    const unlisten = history.listen((newLocation, action) => {
      // listen to history navigation via the browser
      if (action === "POP") {
        // @ts-ignore
        const outletId = newLocation.state?.outletId;

        if (
          outletId &&
          outlets.length > 0 &&
          (selectedOutlet ? selectedOutlet.id !== outletId : outletId !== "all")
        ) {
          const outlet = outlets.find(o => o.id === outletId);
          setOutletAux(!outlet ? [] : [outlet]);
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [history, outlets, selectedOutlet, setOutletAux]);

  return (
    <div data-test-id="outlet-picker">
      <Popover
        ref={popoverRef}
        overlayParent
        disabled={outlets.length <= 1}
        showOverlay
        dismissOnClick
        forceDirection
        renderHeader={() => (
          <div className="outlet-picker" data-test-id="outlet-picker-header">
            <Title className="outlet-picker-name" data-hj-surpress>
              {selectedOutlet?.name || content.all_outlets}
            </Title>
            {outlets.length > 1 && (
              <Icon name="ChevronDown" style={{ minWidth: "var(--space-unit-md)" }} />
            )}
          </div>
        )}
        onHide={() => {
          setSearchText("");
          setVisible(false);
        }}
        onShow={() => {
          setPopoverOpening(true);
          setVisible(true);

          // focus on popover element to setup sequential keyboard navigation (w/ tab and arrow keys)
          // timeout necessary to guarantee popover is rendered before focusing
          setTimeout(() => {
            popoverBodyRef?.current?.focus();
          });
        }}
      >
        <div
          className="outlet-picker-popover"
          data-test-id="outlet-picker-popover"
          style={{ opacity: popoverOpening ? 0 : 1 }}
          ref={popoverBodyRef}
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          <div className="outlet-picker-popover-header">
            <SectionTitle>{content.common_choose_outlet}</SectionTitle>
            <Icon name="Close" className="close-icon" onClick={closePopover} />
          </div>
          <div className="outlet-picker-popover-search">
            <SearchInput
              placeholder={content.search_outlet_name}
              tabIndex={0}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              data-test-id="outlet-picker-search-input"
            />
          </div>

          <div className="outlet-picker-popover-tabs" ref={listRef}>
            <TabGroup
              className="outlet-picker-popover-tabs-group"
              initialTab={currentOutletPickerTab}
              onTabClick={handleSelectAllTab}
            >
              <Tab
                className="outlet-picker-tab-all"
                label={(content.common_all_outlets_number || "").replace(
                  "%number%",
                  `${filteredOutlets.length}`
                )}
              >
                {showAllOutletsItem && (
                  <div className="outlet-item-all-outlets">
                    <OutletPickerItem
                      selected={!selectedOutlet}
                      onClick={() => handleChooseOutlet()}
                    />
                    <Divider />
                  </div>
                )}
                <div
                  className="outlet-picker-scrollable-content"
                  data-test-id="outlet-picker-items"
                >
                  {filteredOutlets.length === 0 && (
                    <Body className="empty-outlet-list">{content.no_outlets_found}</Body>
                  )}
                  {infiniteOutlets}
                </div>
              </Tab>
              <Tab
                className="outlet-picker-tab-favourites"
                label={(content.common_favourite_outlets_number || "").replace(
                  "%number%",
                  `${favouriteOutlets.length}`
                )}
              >
                {favouriteOutlets.length === 0 && (
                  <Body className="empty-outlet-list">{content.no_favourites_found}</Body>
                )}
                {favouriteOutlets.map((outlet: IOutlet) => (
                  <OutletPickerItem
                    ref={
                      shouldScrollToOutlet && outlet.id === selectedOutlet?.id ? outletRef : null
                    }
                    key={outlet.id}
                    outlet={outlet}
                    selected={selectedOutlet?.id === outlet.id}
                    onClick={() => handleChooseOutlet(outlet)}
                    onFavourite={handleToggleFavourite}
                    insightsLength={insights[outlet.id]}
                  />
                ))}
              </Tab>
            </TabGroup>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default withRouter(OutletPicker);
