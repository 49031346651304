import { Reducer } from "redux";
import produce from "immer";
import {
  BeverageTranslation,
  IBeverage,
  IRequestBeersState,
  REQUEST_BEERS,
  REQUEST_BEERS_ERROR,
  REQUEST_BEERS_SUCCESS,
  RequestBeersActionTypes
} from "./types";

export const initialState: IRequestBeersState = {
  beers: [],
  loading: false
};

const reducer: Reducer<IRequestBeersState, RequestBeersActionTypes> = (
  state = initialState,
  action: RequestBeersActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case REQUEST_BEERS:
        draft.loading = true;
        break;
      case REQUEST_BEERS_SUCCESS:
        const { beverages, beveragesTranslations } = action.payload;
        const translatedBeverages: BeverageTranslation[] = [];
        try {
          beverages.forEach((beer: IBeverage) => {
            const translations: any = beveragesTranslations[beer.id]
              ? beveragesTranslations[beer.id]
              : [];
            const b = new BeverageTranslation(beer, translations);
            translatedBeverages.push(b);
          });
          draft.beers = translatedBeverages.filter(
            beer => beer.brand !== "NONE" && beer.brand !== "empty"
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log("error translating beverages", e);
        }
        draft.error = undefined;
        draft.loading = false;
        break;
      case REQUEST_BEERS_ERROR:
        draft.error = action.payload;
        draft.loading = false;
        break;
      default:
        break;
    }
  });

export default reducer;
