import React, { useCallback, useMemo } from "react";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";

export interface IInsightPouringQualityProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightPouringQuality: React.FC<IInsightPouringQualityProps> = ({ insight, itemType }) => {
  const { managerAppInsights: content } = useContent();
  const {
    customFields: { score }
  }: any = insight;

  const contentData = useMemo(() => {
    if (score > 90) {
      return {
        title: content.manager_app_insights_pouring_quality_title_1,
        desc: content.manager_app_insights_pouring_quality_desc_1,
        backgroundColor: "var(--color-success-success100)"
      };
    }
    if (score >= 80) {
      return {
        title: content.manager_app_insights_pouring_quality_title_2,
        desc: content.manager_app_insights_pouring_quality_desc_2,
        backgroundColor: "var(--color-warning-warning100)"
      };
    }
    return {
      title: content.manager_app_insights_pouring_quality_title_3,
      desc: content.manager_app_insights_pouring_quality_desc_3,
      backgroundColor: "var(--color-alert-alert100)"
    };
  }, [content, score]);

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={contentData.title}
          description={contentData.desc}
          icon="Beverage"
          iconOptions={{ name: "Beverage", className: "text-white fill-current" }}
          insight={insight}
          avatarOptions={{
            backgroundColor: contentData.backgroundColor
          }}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={contentData.title}
          icon="Beverage"
          iconOptions={{ name: "Beverage", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: contentData.backgroundColor
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={contentData.title}
          description={contentData.desc}
          icon="Beverage"
          iconOptions={{ name: "Beverage", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: contentData.backgroundColor
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightPouringQuality;
