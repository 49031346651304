import React from "react";
import { Icon } from "@ddm-design-system/icon";
import "./sidebar-header.scss";

interface ISidebarHeaderProps {
  isTablet?: boolean;
}

export const SidebarHeader: React.FC<ISidebarHeaderProps> = ({ isTablet = false }) => (
  <div className="sidebar-header">
    <Icon name={isTablet ? "DmLogo" : "DmFullLogo"} height={32} fill="var(--color-black)" />
  </div>
);

export default SidebarHeader;
