import { LinkButton } from "@ddm-design-system/button";
import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Routes from "../../../../routes";
import { AnalyticsContext } from "../../../../services/analytics";
import { setInsightsNotificationsClose, setInsightsStoriesClose } from "../../../../store/insights/actions";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";


export interface IInsightOpeningHoursProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightOpeningHours: React.FC<IInsightOpeningHoursProps> = ({ insight, itemType }) => {
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const { managerAppInsights: content } = useContent();

  const callToAction = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS_WITH_CALL_TO_ACTION", insight.type);
    dispatch(setInsightsNotificationsClose(insight.locationId));
    dispatch(setInsightsStoriesClose());
    history.push(Routes.outlets);
  }, [analytics, dispatch, history, insight.locationId, insight.type]);

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={content.manager_app_insights_opening_hours_title}
          description={content.manager_app_insights_opening_hours_desc}
          icon="Settings"
          insight={insight}
          footer={
            <div className="flex items-center justify-end">
              <LinkButton onClick={callToAction}>
                {content.manager_app_insights_opening_hours_cta}
              </LinkButton>
            </div>
      }
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={content.manager_app_insights_opening_hours_title}
          icon="Settings"
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={content.manager_app_insights_opening_hours_title}
          description={content.manager_app_insights_opening_hours_desc}
          icon="Settings"
          insight={insight}
          callToAction={callToAction}
          actionTitle={content.manager_app_insights_opening_hours_cta}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightOpeningHours;
