import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ExpandablePanels } from "@ddm-design-system/expandable-panel";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { useInfiniteScrollList, useIsMobile } from "@ddm-design-system/hooks";
import ReactMarkdown from "react-markdown";
import { getAllOutlets } from "../../store/outlet/selectors";
import useContent from "../../hooks/useContent";
import OutletInfoCard from "./OutletInfoCard";
import { SettingsBackButton } from "../settings/Settings";
import Filter from "../common/filter/Filter";
import { IOutlet } from "../../store/outlet/types";

const intersectionOptions = {
  root: document.querySelector("#root"),
  rootMargin: "0px 0px 100px 0px"
};

interface IProps {
  location: { state?: { scrollableOutletId: string } };
}

export const SettingsOutlets: React.FC<IProps> = ({ location }) => {
  const { managerAppSettings: content } = useContent();
  const isMobile = useIsMobile();
  const outlets = useSelector(getAllOutlets);
  const [initialLimit, setInitialLimit] = useState(10);
  const [shouldScrollToOutlet, setShouldScrollToOutlet] = useState(false);
  const outletRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState("");
  const { managerAppSettings: contentSettings } = useContent();

  // if there was a redirect that provided an outlet id, scroll to the outlet w/ that id and open its panel
  useEffect(() => {
    if (location.state?.scrollableOutletId) {
      const outletIndex = outlets.findIndex(
        outlet => outlet.id === location.state?.scrollableOutletId
      );
      setInitialLimit(outletIndex === -1 || outletIndex < 10 ? 10 : outletIndex + 10);
      setShouldScrollToOutlet(true);
    }
  }, [location.state, outlets]);

  // handle scrolling
  useEffect(() => {
    if (outletRef.current) {
      // even after checking if element exists, trying to immediately scroll or even w/ timeout 0 isn't sure to work
      setTimeout(() => {
        outletRef.current?.scrollIntoView();
        (outletRef.current?.firstChild as HTMLElement).click();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletRef.current]);

  let filteredOutlets: IOutlet[] = [];
  filteredOutlets = useMemo(
    () =>
      filter.length === 0
        ? outlets
        : outlets.filter((o: IOutlet) => {
            const searchTerm = filter.toLocaleLowerCase();
            return o.name && o.name.toLowerCase().includes(searchTerm);
          }),
    [filter, outlets]
  );

  const outletCards = useMemo(
    () =>
      filteredOutlets.map(outlet => (
        <OutletInfoCard
          ref={
            shouldScrollToOutlet && outlet.id === location.state?.scrollableOutletId
              ? outletRef
              : null
          }
          outlet={outlet}
          key={outlet.id}
        />
      )),
    [location.state, filteredOutlets, shouldScrollToOutlet]
  );

  const cards = useInfiniteScrollList(outletCards, {
    dependencies: [initialLimit],
    initialLimit,
    intersectionOptions
  });

  return (
    <div className="flex flex-col items-stretch text-left py-xl">
      {isMobile && (
        <div className="text-left mb-lg">
          <SettingsBackButton className="p-0 mb-xxl" />
          <SectionTitle>{content.manager_app_settings_outlet_info_tab}</SectionTitle>
        </div>
      )}
      <Body className="mb-md">{content.manager_app_settings_outlet_info_intro}</Body>
      <Body className="mb-md">{content.manager_app_settings_outlet_info_text}</Body>
      <Body className="mb-xxl">{content.manager_app_settings_outlet_info_text_2}</Body>
      <div>
        <Filter
          data-test-id="settings-outlet-filter"
          className="mb-lg md:w-auto"
          onFilterChange={e => setFilter(e)}
          label={contentSettings.manager_app_settings_outlet_filter_description}
        />
      </div>
      <ExpandablePanels>{cards}</ExpandablePanels>
      {outlets.length > 0 && cards.length < 1 && (
        <div className="w-full text-left text-grey-grey100">
          <ReactMarkdown>
            {(contentSettings.manager_app_settings_no_outlets || "").replace("%STRING%", filter)}
          </ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default SettingsOutlets;
