import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SectionTitle } from "@ddm-design-system/typography";
import { useInfiniteScrollList } from "@ddm-design-system/hooks";
import { useHistory } from "react-router";
import { getOutletLocationStatus } from "../../store/outlet/actions";
import useContent from "../../hooks/useContent";
import InstallationStatusTooltip from "./InstallationStatusTooltip";
import { getLoadingOutletsStatus, getOutletsStatus } from "../../store/outlet/selectors";
import { getChosenOutlet, getChosenOutletId } from "../../store/filter/selectors";
import InstallationCard from "./InstallationCard";
import "./installation.scss";

const intersectionOptions = {
  root: document.querySelector("#root"),
  rootMargin: "0px 0px 100px 0px"
};

const INITIAL_OUTLET_NR_LIMIT = 10;

export const Installation: React.FC = () => {
  const { managerAppCommon: content } = useContent();
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedOutletId: string = useSelector(getChosenOutletId);
  const selectedOutlet = useSelector(getChosenOutlet);
  const outletsStatus = useSelector(getOutletsStatus);
  const loadingOutletsStatus: boolean = useSelector(getLoadingOutletsStatus);

  useEffect(() => {
    if (selectedOutletId && !selectedOutlet?.type) {
      history.push(`/installation/${selectedOutletId}`);
    }
  }, [selectedOutletId, selectedOutlet, history]);

  useEffect(() => {
    if (!selectedOutletId || selectedOutlet?.type) {
      dispatch(getOutletLocationStatus());
    }
  }, [selectedOutletId, selectedOutlet, dispatch]);

  const outletsToShow = useMemo(() => {
    return outletsStatus.map(outlet => {
      return (
        <div key={outlet.id}>
          <InstallationCard outletStatus={outlet} />
        </div>
      );
    });
  }, [outletsStatus]);

  const infiniteOutlets = useInfiniteScrollList(outletsToShow, {
    initialLimit: INITIAL_OUTLET_NR_LIMIT,
    intersectionOptions
  });

  const renderLoadingCards = useCallback(() => {
    const cards = [];
    for (let i = 0; i < 6; i++) {
      cards.push(
        <div
          key={i}
          className="rounded-[4px] box-border justify-between w-full relative
          cursor-pointer !bg-grey-grey25 opacity-0 min-h-[76px] xs:w-full xs:max-w-none animate-pulse-fade-in"
        />
      );
    }
    return cards;
  }, []);

  return (
    <div className="flex flex-col" data-test-id="installations-list">
      <div className="flex items-center justify-start my-lg">
        <SectionTitle>{content.manager_app_installation_status}</SectionTitle>
        <div data-test-id="installation-status-tooltip">
          <InstallationStatusTooltip />
        </div>
      </div>
      <div
        className={
          loadingOutletsStatus && outletsStatus.length === 0 ? "flex flex-col gap-xxl mb-xxl" : ""
        }
      >
        {loadingOutletsStatus && outletsStatus.length === 0
          ? renderLoadingCards()
          : infiniteOutlets}
      </div>
    </div>
  );
};
