import React, { useCallback, useMemo } from "react";
import { IconNames } from "@ddm-design-system/icon";
import useContent from "../../../../hooks/useContent";
import { EInsightsNotificationType, EInsightItemType, IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";


export interface IInsightTemperatureProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

type IInsightValues = {
    icon: IconNames;
    title: string;
    description: string;
};

const InsightTemperature: React.FC<IInsightTemperatureProps> = ({ insight, itemType }) => {
  const {
    managerAppInsights: content
  } = useContent();
  

  const insightValues = useMemo<IInsightValues>(() => {
      const { currentWeekAvg, initialWeekAvg }: any = insight.customFields;
      switch (insight.type) {
        case EInsightsNotificationType.TEMPERATURE_ABOVE_LIMIT_WATER_TUBE:
            return {
                icon: "HighTempPython",
                title: content.manager_app_insights_temperature_above_limit_water_tube_title,
                description: content.manager_app_insights_temperature_above_limit_water_tube_desc
            };
        case EInsightsNotificationType.TEMPERATURE_BELOW_LIMIT_WATER_TUBE:
            return {
                icon: "LowTempPython",
                title: content.manager_app_insights_temperature_below_limit_water_tube_title,
                description: content.manager_app_insights_temperature_below_limit_water_tube_desc
            };
        case EInsightsNotificationType.TEMPERATURE_ABOVE_LIMIT_ROOM:
            return {
                icon: "HighTempAround",
                title: content.manager_app_insights_temperature_above_limit_room_title,
                description: content.manager_app_insights_temperature_above_limit_room_desc
            };
        case EInsightsNotificationType.TEMPERATURE_FAN_ABOVE_LIMIT:
            return {
                icon: "HighTempCooling",
                title: content.manager_app_insights_temperature_fan_above_limit_title,
                description: content.manager_app_insights_temperature_fan_above_limit_desc
            };
        case EInsightsNotificationType.TEMPERATURE_DRIFT_FAN_DECREASE:
            return {
                icon: "LowTempCooling",
                title: content.manager_app_insights_temperature_drift_fan_decrease_title.replace("%X%", `${Math.round(initialWeekAvg - currentWeekAvg)}`),
                description: content.manager_app_insights_call_technical_service_desc
            };
        case EInsightsNotificationType.TEMPERATURE_DRIFT_FAN_INCREASE:
            return {
                icon: "HighTempCooling",
                title: content.manager_app_insights_temperature_drift_fan_increase_title.replace("%X%", `${Math.round(currentWeekAvg - initialWeekAvg)}`),
                description: content.manager_app_insights_call_technical_service_desc
            };
        case EInsightsNotificationType.TEMPERATURE_DRIFT_WATER_TUBE_DECREASE:
            return {
                icon: "LowTempServing",
                title: content.manager_app_insights_temperature_drift_water_tube_decrease_title.replace("%X%", `${Math.round(initialWeekAvg - currentWeekAvg)}`),
                description: content.manager_app_insights_call_technical_service_desc
            };
        case EInsightsNotificationType.TEMPERATURE_DRIFT_WATER_TUBE_INCREASE:
            return {
                icon: "HighTempServing",
                title: content.manager_app_insights_temperature_drift_water_tube_increase_title.replace("%X%", `${Math.round(currentWeekAvg - initialWeekAvg)}`),
                description: content.manager_app_insights_call_technical_service_desc
            };
        case EInsightsNotificationType.PRESSURIZING_TIME_DRIFT_INCREASE:
            return {
                icon: "PressureIncrease",
                title: content.manager_app_insights_high_pressurizing_time_title,
                description: content.manager_app_insights_high_pressurizing_time_desc
            };
        
        default:
            return {
                icon: "Temperature",
                title: "",
                description: ""
            };
      }
  }, [insight, content]);

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={insightValues.title}
          description={insightValues.description}
          icon={insightValues.icon}
          iconOptions={{ name: insightValues.icon, className: "text-white fill-current" }}
          insight={insight}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={insightValues.title}
          icon={insightValues.icon}
          iconOptions={{ name: insightValues.icon, className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={insightValues.title}
          description={insightValues.description}
          icon={insightValues.icon}
          iconOptions={{ name: insightValues.icon, className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightTemperature;
