import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import { getPressureChamberFromOutletById } from "../../../../store/installation/selectors";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";

export interface IInsightLeakChamberProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightLeakChamber: React.FC<IInsightLeakChamberProps> = ({ insight, itemType }) => {
  const { managerAppInsights: content } = useContent();
  const pressureChamber = useSelector(
    getPressureChamberFromOutletById(insight.locationId, insight.customFields?.pressureChamberId)
  );

  const [pressureChamberPos, withBeer, ...rest] = content.manager_app_insights_leak_chamber_desc
    .replace("%POS%", `${pressureChamber?.position}`)
    .replace("%BEER_NAME%", `${pressureChamber?.beverage.name}`)
    .split("%SPLIT%");

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={content.manager_app_insights_leak_chamber_title.replace(
            "%POS%",
            `${pressureChamber?.position}`
          )}
          description={[pressureChamberPos, pressureChamber?.beverage?.name && withBeer, ...rest]
            .filter(Boolean)
            .join("")}
          icon="Installation"
          insight={insight}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={content.manager_app_insights_leak_chamber_title.replace(
            "%POS%",
            `${pressureChamber?.position}`
          )}
          icon="Installation"
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={content.manager_app_insights_leak_chamber_title.replace(
            "%POS%",
            `${pressureChamber?.position}`
          )}
          description={[pressureChamberPos, pressureChamber?.beverage?.name && withBeer, ...rest]
            .filter(Boolean)
            .join("")}
          icon="Installation"
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightLeakChamber;
