import produce from "immer";
import { Reducer } from "redux";
import { RehydrateAction } from "redux-persist";
import {
  FilterActionTypes,
  IFilterState,
  PERFORMANCE_TYPE,
  SET_BEER_IDS,
  SET_COMPARE_OUTLET,
  SET_FILTER_OUTLETS_SUCCESS,
  SET_PERFORMANCE_TYPE,
  SET_TIME_FRAME
} from "./types";
import { setStartEndTimeRange } from "../../lib/Time/TimeFunctions";
import Time, { defaultTimezone } from "../../lib/Time/Time";
import { SET_CURRENT_TARGET } from "../targets/types";
import { ETimeFrame } from "../../lib/Time/types";
import { getOutletsFromGroup } from "../../helpers";
import { IOutlet } from "../outlet/types";
import { IOutletPickerGroupItem } from "../../components/common/outlet_picker/OutletPicker";

export const initialState: IFilterState = {
  outlets: [],
  timeFrame: {
    from: setStartEndTimeRange(ETimeFrame.WEEK).start,
    to: setStartEndTimeRange(ETimeFrame.WEEK).end,
    type: ETimeFrame.WEEK
  },
  beerIds: [],
  outletCompare: undefined,
  performanceType: PERFORMANCE_TYPE.AVG,
  allBeerIdsSelected: true,
  currentTarget: undefined
};

const reducer: Reducer<IFilterState, FilterActionTypes | RehydrateAction> = (
  state = initialState,
  action: FilterActionTypes | RehydrateAction
) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_FILTER_OUTLETS_SUCCESS:
        // eslint-disable-next-line prefer-const
        let { outlets, currentTarget } = action.payload;
        const outletsTemp = outlets;
        draft.outlets = outletsTemp;

        // choose outlets in case of distributor or pg
        if (outlets[0]?.type) {
          outlets = getOutletsFromGroup(outlets[0] as IOutletPickerGroupItem);
        }

        Time.timezone = (outlets[0] as IOutlet)?.timezone || defaultTimezone;
        draft.currentTarget = currentTarget;
        if (outlets.length === 0) {
          draft.outletCompare = initialState.outletCompare;
          if (
            (state.performanceType !== PERFORMANCE_TYPE.AVG &&
              state.performanceType !== PERFORMANCE_TYPE.TARGET) ||
            (state.performanceType === PERFORMANCE_TYPE.TARGET && !currentTarget?.allActiveTargets)
          ) {
            draft.performanceType = PERFORMANCE_TYPE.AVG;
          }
        } else if (
          state.performanceType === PERFORMANCE_TYPE.OUTLET &&
          state.outletCompare?.id === outlets[0].id
        ) {
          draft.outletCompare = initialState.outletCompare;
          draft.performanceType = PERFORMANCE_TYPE.AVG;
        }
        draft.beerIds = [];
        break;
      case SET_BEER_IDS:
        draft.beerIds = action.payload.beerIds;
        if (
          action.payload.beerIds.length > 0 &&
          action.payload.beerIds.length < action.payload.beers.length
        ) {
          draft.performanceType = PERFORMANCE_TYPE.AVG;
          draft.allBeerIdsSelected = false;
        } else {
          draft.allBeerIdsSelected = true;
        }
        break;
      case SET_TIME_FRAME:
        draft.timeFrame = action.payload;
        break;
      case SET_COMPARE_OUTLET:
        draft.outletCompare = action.payload;
        break;
      case SET_PERFORMANCE_TYPE:
        draft.performanceType = action.payload;
        break;
      case SET_CURRENT_TARGET:
        draft.currentTarget = action.payload;
        break;
      case "persist/REHYDRATE":
        if (action.key === "filter" && action.payload) {
          const payload = action.payload as any;
          const { timeFrame } = payload;
          if (timeFrame) {
            if (timeFrame.type) {
              const { start, end } = setStartEndTimeRange(timeFrame.type);

              draft.timeFrame = {
                ...timeFrame,
                from: start,
                to: end
              };
              break;
            }
            draft.timeFrame = {
              ...timeFrame,
              from: new Time(timeFrame.from.spaceObject.epoch),
              to: timeFrame.to ? new Time(timeFrame.to.spaceObject.epoch) : undefined
            };
          }
        }
        break;
      default:
        break;
    }
  });

export default reducer;
