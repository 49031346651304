import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CustomCarousel, GlobalCarouselStyles } from "@ddm-design-system/carousel";
import { ModalTooltip } from "@ddm-design-system/modal";
import { SectionTitle } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import { IWithAggRequestProps, withAggRequest } from "../common/hoc/withAggRequest";
import { getAllOutlets } from "../../store/outlet/selectors";
import OutletReviewCard from "./OutletReviewCard";
import useContent from "../../hooks/useContent";
import OutletReviewCardLoading from "./OutletReviewCardLoading";
import OutletReviewSortingContainer from "./OutletReviewSortingContainer";
import { AnalyticsContext } from "../../services/analytics";
import "./outlet-review.scss";
import { getFilter } from "../../store/filter/selectors";
import { getOutletsFromGroup } from "../../helpers";
import { IOutletPickerGroupItem } from "../common/outlet_picker/OutletPicker";

export const OutletReview: React.FC<IWithAggRequestProps> = ({ data }) => {
  const outlets = useSelector(getAllOutlets);
  const filter = useSelector(getFilter);
  const [modOutlets, setModOutlets] = useState<any[]>();
  const {
    managerAppOverview: content,
    units: { timeStrings = {} }
  } = useContent();
  const isMobile = useIsMobile();
  const analytics = useContext(AnalyticsContext);

  const outletsFiltered = useMemo(() => {
    let outletsTemp = outlets;

    if (filter.outlets[0]?.type) {
      outletsTemp = getOutletsFromGroup(filter.outlets[0] as IOutletPickerGroupItem);
    }

    return outletsTemp;
  }, [filter, outlets]);

  const sortingOptions = useMemo(
    () => ({
      volumeGrowth: {
        id: "1",
        text: content.manager_app_volume_growth
      },
      volumeDecrease: {
        id: "2",
        text: content.manager_app_volume_decrease
      },
      mostVolume: {
        id: "3",
        text: content.manager_app_most_volume
      },
      leastVolume: {
        id: "4",
        text: content.manager_app_least_volume
      },
      specialityMixGrowth: {
        id: "5",
        text: content.manager_app_speciality_mix_growth
      },
      specialityMixDecrease: {
        id: "6",
        text: content.manager_app_speciality_mix_decrease
      },
      mostSpecialityMix: {
        id: "7",
        text: content.manager_app_most_speciality_mix
      },
      leastSpecialityMix: {
        id: "8",
        text: content.manager_app_least_speciality_mix
      }
    }),
    [content]
  );

  const refreshOutlets = useCallback(
    (sortingOption: any) => {
      const tempOutlets: any[] = outletsFiltered
        .map((outlet: any) => {
          const { volume = { pct: 0, value: 0 }, speciality = { pct: 0, value: 0 } } =
            data?.[outlet.id] || {};
          return {
            ...outlet,
            volume,
            speciality
          };
        })
        .sort((a, b) => {
          switch (sortingOption) {
            case sortingOptions.volumeGrowth:
              return +a.volume.pct > +b.volume.pct ? -1 : 1;
            case sortingOptions.volumeDecrease:
              return +a.volume.pct > +b.volume.pct ? 1 : -1;
            case sortingOptions.mostVolume:
              return +a.volume.value > +b.volume.value ? -1 : 1;
            case sortingOptions.leastVolume:
              return +a.volume.value > +b.volume.value ? 1 : -1;

            case sortingOptions.specialityMixGrowth:
              return +a.speciality.pct > +b.speciality.pct ? -1 : 1;
            case sortingOptions.specialityMixDecrease:
              return +a.speciality.pct > +b.speciality.pct ? 1 : -1;
            case sortingOptions.mostSpecialityMix:
              return +a.speciality.value > +b.speciality.value ? -1 : 1;
            case sortingOptions.leastSpecialityMix:
              return +a.speciality.value > +b.speciality.value ? 1 : -1;
            default:
              break;
          }

          return 1;
        });
      setModOutlets(tempOutlets);
    },
    [data, filter, outletsFiltered, sortingOptions]
  );

  useEffect(() => {
    if (outletsFiltered && data) {
      refreshOutlets(sortingOptions.mostVolume);
    }
  }, [outletsFiltered, data, refreshOutlets, sortingOptions]);

  const sortAction = (sort: any) => {
    refreshOutlets(sort);
  };

  const carouselItems = useMemo(
    () =>
      data && outletsFiltered && modOutlets && modOutlets.length > 0
        ? modOutlets.map((d: any, i: number) => (
          <OutletReviewCard
            key={d.id}
            outlet={d}
            style={{
                marginLeft: i === 0 && "0"
              }}
          />
          ))
        : Array(10)
            .fill(0)
            .map((d: any, i: number) => (
              <OutletReviewCardLoading
                style={{
                  marginLeft: i === 0 && "0"
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              />
            )),
    [data, modOutlets, outletsFiltered]
  );

  return (
    <div className="outlet-review">
      <div className="outlet-review-header">
        <div className="outlet-review-info">
          <SectionTitle style={{ textAlign: "left" }}>
            {content.manager_app_outlets_overview}
            {!isMobile && `, ${timeStrings.unit_last_7_days}`}
          </SectionTitle>
          <ModalTooltip
            title={content.manager_app_outlets_overview}
            onAfterOpen={() => analytics.logEvent("TOOLTIP", "Outlet Overview")}
          >
            {content.manager_app_outlets_overview_description_1}
            <br />
            <br />
            {content?.manager_app_outlets_overview_description_2}
          </ModalTooltip>
        </div>
        <OutletReviewSortingContainer
          sortingOptions={sortingOptions}
          sortingCallback={sortAction}
        />
      </div>
      <GlobalCarouselStyles />
      <CustomCarousel
        className="outlet-review-container"
        itemsLength={carouselItems.length}
        responsive={{
          desktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 4
          },
          tablet: { breakpoint: { max: 1140, min: 900 }, items: 3 },
          mobile2: { breakpoint: { max: 900, min: 700 }, items: 2 },
          mobile: { breakpoint: { max: 700, min: 0 }, items: 1 }
        }}
      >
        {carouselItems}
      </CustomCarousel>
    </div>
  );
};

export default withAggRequest(OutletReview);
