import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Divider } from "@ddm-design-system/divider";
import { ModalTooltip } from "@ddm-design-system/modal";
import useContent from "../../hooks/useContent";
import InfoModalItem from "../common/info_modal_item/InfoModalItem";
import { getChosenOutlet, isOutletChosen } from "../../store/filter/selectors";
import { AnalyticsContext } from "../../services/analytics";

const RecentSalesInfo: React.FC = () => {
  const { managerAppOverview: content, managerAppCommon: common } = useContent();
  const analytics = useContext(AnalyticsContext);
  const chosenOutlet = useSelector(getChosenOutlet);
  const isOutletSet = useSelector(isOutletChosen) && !chosenOutlet?.type;

  return (
    <ModalTooltip
      title={content.manager_app_live_performance}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Recent Performance")}
      data-test-id="recent-sales-info"
      iconClassName="!p-0 ml-xs"
    >
      {/**
       * Your Outlet
       */}
      <InfoModalItem iconName="Keg" title={isOutletSet ? common.your_outlet : common.all_outlets}>
        {content.recent_performance_this_outlet_description}
      </InfoModalItem>
      <Divider />

      {/**
       * vs Similar Time
       */}
      <InfoModalItem iconName="AveragePositive" title={common.vs_similar_time}>
        {content.recent_performance_vs_similar_time_description}
      </InfoModalItem>
      <Divider />

      {/**
       * vs Target
       */}
      <InfoModalItem iconName="Target" title={common.vs_target}>
        {content.recent_performance_vs_target_description}
      </InfoModalItem>
      <Divider />

      {/**
       * vs Similar Outlets
       */}
      {/* <InfoModalItem iconName="Store" title={common.vs_similar_outlets}> */}
      {/*  {content.recent_performance_vs_similar_outlets_description} */}
      {/* </InfoModalItem> */}
      {/* <Divider /> */}

      {/**
       * vs Near Outlets
       */}
      {/* <InfoModalItem iconName="Outlets" title={common.vs_near_outlets}> */}
      {/*  {content.recent_performance_vs_near_outlets_description} */}
      {/* </InfoModalItem> */}
    </ModalTooltip>
  );
};

export default RecentSalesInfo;
