import { createSelector } from "reselect";
import { get } from "lodash";
import { IAppState } from "../index";
import { initialState } from "./reducer";
import { IOutletPickerItem } from "../../components/common/outlet_picker/OutletPicker";

import { IFilterState, PERFORMANCE_TYPE } from "./types";
import { IOutlet } from "../outlet/types";
import { ITimeFrame } from "../../lib/Time/types";
import { ITargetParams } from "../targets/types";

export const getFilter = (state: IAppState): IFilterState => get(state, "filter", initialState);

export const getFilterOutlets = (state: IAppState): IOutlet[] =>
  get(state, "filter.outlets", initialState.outlets);

export const getTimeFrame = (state: IAppState): ITimeFrame =>
  get(state, "filter.timeFrame", initialState.timeFrame);

export const getFilterBeers = (state: IAppState): string[] =>
  get(state, "filter.beerIds", initialState.beerIds);

export const getPerformanceType = (state: IAppState): PERFORMANCE_TYPE =>
  get(state, "filter.performanceType", initialState.performanceType);

export const getOutletCompare = (state: IAppState): IOutlet | null =>
  get(state, "filter.outletCompare", initialState.outletCompare);

export const getCurrentTarget = (state: IAppState): ITargetParams | undefined =>
  get(state, "filter.currentTarget", initialState.currentTarget);

export const getOutletsWithTargetsCount = (state: IAppState): ITargetParams | undefined =>
  get(state, "filter.currentTarget.outletsWithTargetsCount", initialState.currentTarget);

export const allOutletsWithActiveTargets = createSelector<
  IAppState,
  ITargetParams | undefined,
  boolean
>(getCurrentTarget, currentTarget => !!currentTarget?.allActiveTargets);

export const isOutletChosen = createSelector<IAppState, IOutletPickerItem[], boolean>(
  getFilterOutlets,
  outlets => outlets.length === 1
);
export const getChosenOutlet = createSelector<IAppState, IOutletPickerItem[], IOutletPickerItem>(
  getFilterOutlets,
  outlets => outlets?.[0]
);

export const getChosenOutletId = createSelector<IAppState, IOutletPickerItem[], string>(
  getFilterOutlets,
  outlets => outlets?.[0]?.id || ""
);
