import React, { useContext } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { RouteChildrenProps, withRouter } from "react-router";
import AuthContext from "./AuthContext";
import Routes from "../../routes";

interface IProps extends RouteComponentProps {
  component?: React.ReactType;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

const PrivateRoute: React.FC<IProps> = ({ location, component: Component, render, ...rest }) => {
  const auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated ? (
          Component ? (
            <Component {...props} />
          ) : (
            render && render(props)
          )
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: location.pathname },
              search: location.search
            }}
          />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
