import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Body, SectionTitle, Subtitle } from "@ddm-design-system/typography";
import { useHistory } from "react-router";
import { TertiaryButton } from "@ddm-design-system/button";
import classnames from "classnames";
import { Infoicon } from "@ddm-design-system/infoicon";
import { Icon } from "@ddm-design-system/icon";
import numeral from "numeral";
import Routes from "../../routes";
import { getOutletById } from "../../store/outlet/selectors";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import { getChosenOutlet, getChosenOutletId } from "../../store/filter/selectors";
import { getBeersLoading } from "../../store/beers/selectors";
import useContent from "../../hooks/useContent";
import { AnalyticsContext } from "../../services/analytics";
import { getOutletsFromGroup } from "../../helpers";
import { IOutletPickerGroupItem } from "../common/outlet_picker/OutletPicker";
import StockManagementModal from "./StockManagementModal";
import { IAppState } from "../../store";
import {
  getAvgKegsPerWeek,
  getHasError,
  getIsLoading,
  getMainstreamBrands,
  getProductsInfo,
  getSpecialityBrands,
  getTapCount
} from "../../store/product/selectors";
import ProductBeverageDetailsSidebar from "./ProductBeverageDetailsSidebar";
import { IProductInfo } from "../../store/product/types";
import { BeverageTranslation } from "../../store/beers/types";
import ProductBeverageCard from "./ProductBeverageCard";
import ProductSummaryGroup from "./ProductSummaryGroup";

interface IProps {
  outletId: string;
}

export const ProductDetail: React.FC<IProps> = ({ outletId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const { managerAppCommon: contentCommon, managerAppProduct: content } = useContent();
  const outlet = useSelector(getOutletById(outletId));
  const selectedOutletId: string = useSelector(getChosenOutletId);
  const selectedOutlet = useSelector(getChosenOutlet);
  const outletInfo = useSelector(getOutletById(outletId));
  const beverageInfo = useSelector((state: IAppState) => getProductsInfo(state, outletId));

  const mainstreamBrands = useSelector((state: IAppState) => getMainstreamBrands(state, outletId));
  const specialityBrands = useSelector((state: IAppState) => getSpecialityBrands(state, outletId));
  const tapCount = useSelector((state: IAppState) => getTapCount(state, outletId));
  const kegsPerWeek = useSelector((state: IAppState) => getAvgKegsPerWeek(state, outletId));

  const loading = useSelector(getIsLoading);
  const hasError = useSelector(getHasError);
  const loadingBeverages: boolean = useSelector(getBeersLoading);
  const [selectedBeverageInfo, setSelectedBeverageInfo] = useState<
    | {
        beverage: IProductInfo & { beverage: BeverageTranslation };
        avatarOptions: { [key: string]: string };
      }
    | undefined
  >();
  const [stockModalLocationId, setStockModalLocationId] = useState<string | undefined>();

  useEffect(() => {
    if (selectedOutletId && !selectedOutlet?.type && selectedOutletId !== outletId) {
      history.push(`/product/${selectedOutletId}`);
    } else if (selectedOutletId && selectedOutlet?.type) {
      const outletsIds = getOutletsFromGroup(selectedOutlet as IOutletPickerGroupItem).map(
        o => o.id
      );
      if (!outletsIds.includes(outletId)) {
        history.push("/product");
      }
    }
  }, [outletId, selectedOutlet, selectedOutletId, dispatch]);

  const renderLoadingCards = useCallback(() => {
    const cards = [];
    for (let i = 0; i < 6; i++) {
      cards.push(
        <div
          key={i}
          className="rounded-[4px] box-border justify-between w-full relative mb-md
          cursor-pointer !bg-grey-grey25 opacity-0 min-h-[76px] xs:w-full xs:max-w-none animate-pulse-fade-in"
        />
      );
    }
    return cards;
  }, []);

  const outletBeveragesCards = useMemo(
    () => (
      <>
        <div
          className={classnames(
            "grid gap-xxl items-center justify-center overflow-hidden lg:gap-lg"
          )}
          style={{ gridTemplateColumns: "repeat(auto-fill,minmax(249px,1fr))" }}
        >
          {beverageInfo?.map(beverage => (
            <ProductBeverageCard
              key={`${outletId}-${beverage.beverage.id}`}
              beverage={{ ...beverage, outletCountryId: outletInfo?.country.id || "" }}
              onClick={setSelectedBeverageInfo}
              stockAccess={outletInfo?.stockAccess}
            />
          ))}
        </div>
      </>
    ),
    [content, outletInfo, beverageInfo, outletId]
  );

  const handleDetailsModalClose = useCallback(() => {
    analytics.logEvent("PRODUCT_BEVERAGE_DETAILS_CLOSE");
    setSelectedBeverageInfo(undefined);
  }, [analytics]);

  const handleStockModalOpen = useCallback(() => setStockModalLocationId(outletId), [outletId]);

  const handleStockModalClose = useCallback(() => setStockModalLocationId(undefined), []);

  return (
    <div className="flex flex-col w-full">
      {(!selectedOutletId || selectedOutlet?.type) && (
        <>
          <Breadcrumbs
            data-test-id="installation-breadcrumbs"
            current={outlet?.name}
            items={
              !selectedOutletId
                ? [{ link: Routes.products, name: contentCommon.common_all }]
                : [{ link: Routes.products, name: selectedOutlet?.name }]
            }
          />
          <Subtitle className="pt-xl text-left">{outlet?.name}</Subtitle>
        </>
      )}
      <div
        className={classnames(
          "pt-lg flex flex-1 justify-between lg:flex-wrap xs:mt-lg lg:gap-y-lg",
          {
            loading
          }
        )}
      >
        <ProductSummaryGroup
          title={content.manager_app_product_mainstream_brands}
          brands={mainstreamBrands}
          dataTestId="mainstream-brands"
        />
        <ProductSummaryGroup
          title={content.manager_app_product_speciality_brands}
          brands={specialityBrands}
          dataTestId="speciality-brands"
        />
        <Infoicon
          className={classnames(
            "border border-solid border-grey-greenish rounded-10 box-border py-md px-lg w-[calc(25%-(3*var(--space-unit-xxl)/4))] lg:w-[calc(50%-(var(--space-unit-lg)/2))] xs:w-full numberOfTaps",
            { loading }
          )}
          title={content.manager_app_product_number_of_taps}
          avatarOptions={{
            children: <Icon name="TapDouble" />
          }}
        >
          {loading ? "" : `${tapCount}`}
        </Infoicon>
        <Infoicon
          className={classnames(
            "border border-solid border-grey-greenish rounded-10 box-border py-md px-lg w-[calc(25%-(3*var(--space-unit-xxl)/4))] lg:w-[calc(50%-(var(--space-unit-lg)/2))] xs:w-full kegsPerWeek",
            { loading }
          )}
          title={content.manager_app_product_kegs_per_week}
          avatarOptions={{
            children: <Icon name="Keg" />
          }}
        >
          {loading ? "" : numeral(kegsPerWeek).format("0.[00]")}
        </Infoicon>
      </div>
      <div className="pt-md" data-test-id="installation-lines">
        {loading || loadingBeverages ? (
          renderLoadingCards()
        ) : hasError ? (
          <div className="w-full">{contentCommon.common_error_during_request}</div>
        ) : (beverageInfo?.length as number) > 0 ? (
          <>
            <div className="flex flex-col w-full" data-test-id="outlet-beverage-cards">
              <div
                className="flex justify-between items-center mb-xl"
                data-test-id="products-header"
              >
                <SectionTitle>{content.manager_app_product_brands_on_tap}</SectionTitle>
                {outletInfo?.stockAccess && (
                  <TertiaryButton icon="Box" onClick={handleStockModalOpen}>
                    {content.manager_app_product_manage_stock}
                  </TertiaryButton>
                )}
              </div>
              {outletBeveragesCards}
            </div>
          </>
        ) : (
          <div className="mt-lg">
            <Body>{content.manager_app_product_empty}</Body>
          </div>
        )}
      </div>
      <StockManagementModal
        locationId={stockModalLocationId || ""}
        isOpen={!!stockModalLocationId}
        onClose={handleStockModalClose}
        showOutletName={!selectedOutletId || !!selectedOutlet?.type}
      />
      <ProductBeverageDetailsSidebar
        avatarOptions={selectedBeverageInfo?.avatarOptions}
        beverage={selectedBeverageInfo?.beverage as any}
        isOpen={!!selectedBeverageInfo}
        handleClose={handleDetailsModalClose}
        outletId={outletId}
      />
    </div>
  );
};
