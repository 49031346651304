import React from "react";
import { Card } from "@ddm-design-system/card";
import { Description, DescriptionMedium } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { PrimaryButton } from "@ddm-design-system/button";
import { useIsMobile } from "@ddm-design-system/hooks";
import { useHistory } from "react-router";
import useContent from "../../hooks/useContent";
import { IControlUnit } from "../../store/installation/types";
import CleaningUnitFlow from "./cleaning-unit-flow/CleaningUnitFlow";
import Time from "../../lib/Time/Time";

interface IProps {
  controlUnit: IControlUnit;
  outletId: string;
  cleaningDueDate?: string;
  setSelectedCU: (controlUnit: { controlUnit: IControlUnit }) => void | undefined;
}

export const ControlUnitCard: React.FC<IProps> = ({
  controlUnit,
  outletId,
  cleaningDueDate,
  setSelectedCU
}) => {
  const { managerAppControlUnit: cuContent } = useContent();
  const isMobile = useIsMobile();
  const history = useHistory();

  return (
    <Card className="flex !flex-row gap-lg mb-xxl p-md !overflow-hidden md:!flex-col xs:!flex-col">
      <CleaningUnitFlow
        key={controlUnit.id}
        controlUnit={controlUnit}
        outletId={outletId}
        onClick={() => setSelectedCU({ controlUnit })}
      />
      <Card className="p-md h-[202px] !border-grey-greenish xs:h-auto">
        <div className="flex flex-col gap-y-md items-center">
          <div className="flex flex-row gap-x-xs items-center">
            <Icon name="Cleaning" />
            <DescriptionMedium>{cuContent.manager_app_cu_cleaning}</DescriptionMedium>
          </div>
          {!isMobile && (
            <Description className="text-center">
              {cleaningDueDate
                ? cuContent.manager_app_cu_recommended_cleaning_limit.replace(
                    "%DAYS%",
                    new Time(Date.now()).diff(new Time(cleaningDueDate)).toString()
                  )
                : cuContent.manager_app_cu_reached_recommended_cleaning_limit}
            </Description>
          )}
          <PrimaryButton
            type="submit"
            onClick={() =>
              history.push(`/installation/${outletId}/${controlUnit.id}/statusHistory`)
            }
            className="mt-xxl xs:mt-md whitespace-nowrap"
          >
            {cuContent.manager_app_cu_status_history}
          </PrimaryButton>
        </div>
      </Card>
    </Card>
  );
};

export default ControlUnitCard;
