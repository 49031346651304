import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel, GlobalCarouselStyles, ICarouselRef } from "@ddm-design-system/carousel";
import { SectionTitle } from "@ddm-design-system/typography";
import { useIsMobile, useIsTablet } from "@ddm-design-system/hooks";
import { CustomGlobalCarouselStyles } from "./InsightCardList.styles";
import { getChosenOutlet, getChosenOutletId } from "../../store/filter/selectors";
import { setInsightsStoriesClose, setInsightsStoriesOpen } from "../../store/insights/actions";
import {
  getInsights,
  getInsightsStoriesData,
  getInsightsUnreadLength,
  getOutletIdsSortedByName
} from "../../store/insights/selectors";
import InsightItemCard from "../common/insights/InsightItemCard";
import { InsightCardSkeleton } from "../common/insights/InsightCard";
import { IInsightsNotification } from "../../store/insights/types";
import useContent from "../../hooks/useContent";
import { getIsLoadingComponents } from "../../store/installation/selectors";
import { getBeersLoading } from "../../store/beers/selectors";
import { getIsInsightsLoading } from "../../store/product/selectors";
import InsightStoryList from "../common/insights/InsightStoryList";
import { AnalyticsContext } from "../../services/analytics";

// Temporary - may change once proper insight cards are used
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 700 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1.5,
    slidesToSlide: 0.75
  }
};

const InsightCardList: React.FC = () => {
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const chosenOutlet = useSelector(getChosenOutlet);
  const chosenOutletId = useSelector(getChosenOutletId);
  const insights = useSelector(getInsights);
  const orderedOutletIds = useSelector(getOutletIdsSortedByName);
  const insightsLoading = useSelector(getIsInsightsLoading);
  const beersLoading = useSelector(getBeersLoading);
  const componentsLoading = useSelector(getIsLoadingComponents);
  const insightStoryData = useSelector(getInsightsStoriesData);
  const insightRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<ICarouselRef>(null);
  const unreadInsightsLength = useSelector(getInsightsUnreadLength);
  const { managerAppInsights: content } = useContent();
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);
  const [isMoving, setIsMoving] = useState(false);
  const loading = componentsLoading || insightsLoading || beersLoading;
  const [lessThanDefault, setLessThanDefault] = useState(false);

  const minimumItemsDefault = useMemo(() => {
    return isMobile ? 2 : isTablet ? 4 : 6;
  }, [isMobile, isTablet]);

  const handleCardClick = useCallback(
    (insightData: { id: string; locationId: string }) => {
      if (!isMoving) {
        dispatch(setInsightsStoriesOpen(insightData));
        analytics.logEvent("OPEN_INSIGHT_STORIES");
      }
    },
    [isMoving]
  );

  const handleStoriesClose = () => {
    dispatch(setInsightsStoriesClose());
    analytics.logEvent("CLOSE_INSIGHT_STORIES");
  };

  const insightsToShow = useMemo(() => {
    const cards =
      chosenOutletId && !chosenOutlet?.type
        ? insights[chosenOutletId].map((insight: IInsightsNotification) => (
          <InsightItemCard
            key={`${insight.id}-${insight.type}`}
            insight={insight}
            chosenOutletId={chosenOutletId}
            ref={insightRef}
            onClick={handleCardClick}
          />
          ))
        : orderedOutletIds.map(outletId => {
            const insight = insights[outletId][0];

            return (
              <InsightItemCard
                key={`${insight.id}-${insight.type}`}
                insight={insight}
                chosenOutletId={chosenOutletId}
                ref={insightRef}
                onClick={handleCardClick}
              />
            );
          });
    setLessThanDefault(cards.length < minimumItemsDefault);

    if (lessThanDefault && cards.length > 0) {
      for (let i = cards.length; i < minimumItemsDefault; i++) {
        cards.push(<InsightCardSkeleton key={i} />);
      }
    }

    return cards;
  }, [insights, chosenOutletId, minimumItemsDefault, lessThanDefault, handleCardClick]);

  const renderLoadingCards = useCallback(() => {
    const cards = [];
    for (let i = 0; i < minimumItemsDefault; i++) {
      cards.push(<InsightCardSkeleton key={i} loading />);
    }
    return cards;
  }, []);

  useEffect(() => {
    carouselRef?.current?.goToSlide(0);
  }, [chosenOutletId]);

  const handleChange = (
    _: number,
    carouselState: { currentSlide: number; slidesToShow: number }
  ) => {
    if (carouselState.currentSlide === 0 || insightsToShow.length === 0) {
      setIsLeftArrowVisible(false);
    } else if (!isLeftArrowVisible) {
      setIsLeftArrowVisible(true);
    }

    if (
      isRightArrowVisible &&
      carouselState.currentSlide + carouselState.slidesToShow === insightsToShow.length
    ) {
      setIsRightArrowVisible(false);
    } else if (!isRightArrowVisible) {
      setIsRightArrowVisible(true);
    }
    setIsMoving(false);
  };

  return (
    <div data-test-id="weeks-insights-container">
      <div className="flex justify-start items-center mb-lg" data-test-id="weeks-insights-header">
        <SectionTitle className="text-left xs:ml-lg">
          {content.manager_app_insights_cards_title}
        </SectionTitle>
        {unreadInsightsLength > 0 && (
          <span
            data-test-id="insights-number"
            className="flex items-center justify-center p-xxs ml-xs rounded-10 bg-success-success100 text-white pt-[5px] text-[10px] leading-[10px]"
          >
            {unreadInsightsLength.toString().padStart(2, "0")}
          </span>
        )}
      </div>
      <GlobalCarouselStyles />
      <CustomGlobalCarouselStyles
        isLeftArrowVisible={isLeftArrowVisible}
        isRightArrowVisible={isRightArrowVisible}
      />
      <Carousel
        className="flex flex-row bg-grey-darkest rounded-10 py-md px-md mb-xxl h-auto self-center xl:mb-xxxl xs:mx-lg select-none"
        containerClass="insights-carousel-container"
        ref={carouselRef}
        partialVisible
        responsive={responsive}
        removeArrowOnDeviceType={["mobile", "tablet"]}
        afterChange={handleChange}
        beforeChange={() => setIsMoving(true)}
        arrows={!lessThanDefault}
        draggable={!lessThanDefault}
      >
        {loading ? (
          renderLoadingCards()
        ) : !insightsToShow?.length ? (
          <div className="flex flex-row items-center text-white p-xs">
            <span className="flex items-center justify-center p-[4px] mr-xs rounded-10 bg-gray-300 text-grey-darkest w-xs h-xs text-xs font-bold">
              i
            </span>
            <span>{content.manager_app_insights_cards_empty}</span>
          </div>
        ) : (
          insightsToShow.map(Insight => <div key={`${Insight.key}`}>{Insight}</div>)
        )}
      </Carousel>
      {!!insightStoryData && (
        <InsightStoryList selectedInsightData={insightStoryData} onClose={handleStoriesClose} />
      )}
    </div>
  );
};

export default InsightCardList;
