// @ts-nocheck
import { useSelector } from "react-redux";
import { getCurrentContent } from "../store/content/selectors";
import { IContent, ILanguageInfo } from "../store/content/types";
import { IAppState } from "../store";

interface IUnit {
  [key: string]: string;
}

interface IUnits {
  [key: string]: IUnit;
}

interface IJSONContent {
  [key: string]: any;
}

interface IContentfulMap {
  languageInfo: ILanguageInfo[];
  managerAppCommon: IContent;
  managerAppError: IContent;
  managerAppLogin: IContent;
  managerAppModals: IContent;
  managerAppOverview: IContent;
  managerAppInstallation: IContent;
  managerAppProduct: IContent;
  managerAppSales: IContent;
  managerAppSidebar: IContent;
  managerAppWelcome: IContent;
  managerAppSettings: IContent;
  managerAppInsights: IContent;
  managerAppControlUnit: IContent;
  mediaContainer: string[];
  emailDomains: string;
  units: IUnits;
  jsonContent: IJSONContent;
}

const initialContent: IContentfulMap = {
  languageInfo: [],
  managerAppCommon: {},
  managerAppError: {},
  managerAppLogin: {},
  managerAppModals: {},
  managerAppOverview: {},
  managerAppInstallation: {},
  managerAppProduct: {},
  managerAppSales: {},
  managerAppSidebar: {},
  managerAppWelcome: {},
  managerAppSettings: {},
  managerAppInsights: {},
  managerAppControlUnit: {},
  mediaContainer: [],
  units: {},
  emailDomains: ""
};

const useContent: () => IContentfulMap = () => {
  const content = useSelector((state: IAppState) => getCurrentContent(state));

  return {
    // @ts-ignore
    languageInfo: [],
    managerAppCommon: {},
    managerAppError: {},
    managerAppLogin: {},
    managerAppModals: {},
    managerAppOverview: {},
    managerAppInstallation: {},
    managerAppProduct: {},
    managerAppSales: {},
    managerAppSidebar: {},
    managerAppWelcome: {},
    managerAppSettings: {},
    managerAppInsights: {},
    managerAppControlUnit: {},
    mediaContainer: [],
    units: {},
    emailDomains: "",
    ...initialContent,
    ...((content as IContentfulMap) || {})
  };
};

export default useContent;
