import React from "react";
import ReactMarkdown from "react-markdown";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import {
  createProfileError,
  createProfileSuccess,
  deleteProfileError,
  deleteProfileSuccess,
  getProfileError,
  getProfileSuccess,
  setEmailError,
  setEmailProperty,
  setEmailSuccess,
  setNameError,
  setNameProperty,
  setNameSuccess,
  setPasswordError,
  setPasswordSuccess,
  updateEmailFrequencyError,
  updateEmailFrequencySuccess
} from "../store/profile/actions";
import { IJCoreService } from "../services/jcore";
import {
  CREATE_PROFILE,
  DELETE_PROFILE,
  GET_PROFILE,
  ICreateProfile,
  IDeleteProfile,
  ISetEmail,
  ISetName,
  ISetPassword,
  IUpdateEmailFrequency,
  SET_EMAIL,
  SET_NAME,
  SET_PASSWORD,
  UPDATE_EMAIL_FREQUENCY,
  UPDATE_EMAIL_FREQUENCY_ERROR
} from "../store/profile/types";
import { getCurrentContent } from "../store/content/selectors";
import Routes from "../routes";
import history from "../history";
import { IPlacesService } from "../services/places";

export function* createProfile(jcoreService: IJCoreService, action: ICreateProfile) {
  try {
    yield call([jcoreService, jcoreService.createProfile], action.payload);
    yield call(
      [jcoreService, jcoreService.updateEmailFrequency],
      action.payload.emailFrequencies || []
    );
    yield put(createProfileSuccess());
    localStorage.removeItem("jcoreFirstLogin");
  } catch {
    yield put(createProfileError());
    localStorage.removeItem("jcoreFirstLogin");
  }
}

export function* getProfile(jCoreService: IJCoreService, placesService: IPlacesService) {
  try {
    const response: any = yield call([jCoreService, jCoreService.getProfile]);
    yield put(getProfileSuccess(response.data));
    placesService.setCountry(response.data.country.code);
  } catch {
    yield put(getProfileError());
  }
}

export function* setName(context: IAppContext, action: ISetName) {
  const { jCoreService, notificationService } = context;
  const content = yield select(getCurrentContent);
  const contentCommon = content ? content.managerAppCommon : null;

  try {
    yield call([jCoreService, jCoreService.changeName], action.payload);
    yield put(setNameProperty(action.payload));
    yield put(setNameSuccess(action.payload));

    const notificationText = (contentCommon.manager_app_notification_name_changed || "").replace(
      "%NAME%",
      action.payload
    );
    notificationService.addNotification({
      iconProps: { name: "Ok" },
      text: <ReactMarkdown>{notificationText}</ReactMarkdown>
    });
  } catch {
    yield put(setNameError());

    const notificationText = contentCommon.manager_app_notification_name_changed_error;
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      text: <ReactMarkdown>{notificationText}</ReactMarkdown>
    });
  }
}

export function* setEmail(context: IAppContext, action: ISetEmail) {
  const { jCoreService, notificationService } = context;
  const content = yield select(getCurrentContent);
  const contentCommon = content ? content.managerAppCommon : null;

  try {
    yield call([jCoreService, jCoreService.changeEmail], action.payload);
    yield put(setEmailProperty(action.payload));
    yield put(setEmailSuccess(action.payload));

    const notificationText = (contentCommon.manager_app_notification_email_changed || "").replace(
      "%EMAIL%",
      action.payload
    );
    notificationService.addNotification({
      iconProps: { name: "Ok" },
      text: <ReactMarkdown>{notificationText}</ReactMarkdown>
    });
  } catch {
    yield put(setEmailError());

    const notificationText = contentCommon.manager_app_notification_email_changed_error;
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      text: <ReactMarkdown>{notificationText}</ReactMarkdown>
    });
  }
}

export function* setPassword(context: IAppContext, action: ISetPassword) {
  const { jCoreService, notificationService } = context;
  const content = yield select(getCurrentContent);
  const contentCommon = content ? content.managerAppCommon : null;

  try {
    yield call(
      [jCoreService, jCoreService.changePassword],
      action.payload.oldPassword,
      action.payload.password
    );
    yield put(setPasswordSuccess());

    const notificationText = contentCommon.manager_app_notification_password_changed;
    notificationService.addNotification({
      iconProps: { name: "Ok" },
      text: <ReactMarkdown>{notificationText}</ReactMarkdown>
    });
  } catch {
    yield put(setPasswordError());

    const notificationText = contentCommon.manager_app_notification_password_changed_error;
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      text: <ReactMarkdown>{notificationText}</ReactMarkdown>
    });
  }
}

export function* deleteProfile(jCoreService: IJCoreService, action: IDeleteProfile) {
  try {
    yield call([jCoreService, jCoreService.deleteProfile], action.payload);
    yield put(deleteProfileSuccess());
    history.push(Routes.logout);
  } catch {
    yield put(deleteProfileError("Error"));
  }
}

export function* updateEmailFrequency(context: IAppContext, action: IUpdateEmailFrequency) {
  const { jCoreService, notificationService } = context;
  const content = yield select(getCurrentContent);
  const contentCommon = content ? content.managerAppCommon : null;

  try {
    yield call([jCoreService, jCoreService.updateEmailFrequency], action.payload);
    yield put(updateEmailFrequencySuccess());

    const notificationText = contentCommon.manager_app_notification_email_frequency_changed;
    notificationService.addNotification({
      iconProps: { name: "Ok" },
      text: <ReactMarkdown>{notificationText}</ReactMarkdown>
    });
  } catch {
    yield put(updateEmailFrequencyError());

    const notificationText = contentCommon.manager_app_notification_email_frequency_changed_error;
    notificationService.addNotification({
      iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
      text: <ReactMarkdown>{notificationText}</ReactMarkdown>
    });
  }
}

export function* profileWatcher(context: IAppContext) {
  // yield fork(getProfile, context.jCoreService);
  yield takeLatest(CREATE_PROFILE, createProfile, context.jCoreService);
  yield takeLatest(
    [GET_PROFILE, UPDATE_EMAIL_FREQUENCY_ERROR],
    getProfile,
    context.jCoreService,
    context.placesService
  );
  yield takeLatest(SET_NAME, setName, context);
  yield takeLatest(SET_EMAIL, setEmail, context);
  yield takeLatest(SET_PASSWORD, setPassword, context);
  yield takeLatest(DELETE_PROFILE, deleteProfile, context.jCoreService);
  yield takeLatest(UPDATE_EMAIL_FREQUENCY, updateEmailFrequency, context);
}

export default profileWatcher;
