import {
  IRequestAggregator,
  IRequestAggregatorError,
  IRequestAggregatorSuccess,
  ISetOffHourHighlighting,
  REQUEST_AGGREGATOR,
  REQUEST_AGGREGATOR_ERROR,
  REQUEST_AGGREGATOR_SUCCESS,
  SET_OFF_HOUR_HIGHLIGHTING
} from "./types";
import { IFilterState } from "../filter/types";

export const requestAggregator = (id: string, filter: IFilterState): IRequestAggregator => ({
  type: REQUEST_AGGREGATOR,
  payload: {
    id,
    filter
  }
});

export const requestAggregatorSuccess = (id: string, data: any): IRequestAggregatorSuccess => ({
  type: REQUEST_AGGREGATOR_SUCCESS,
  id,
  data
});

export const requestAggregatorError = (id: string): IRequestAggregatorError => ({
  type: REQUEST_AGGREGATOR_ERROR,
  id
});

export const setOffHourHighlighting = (value: boolean): ISetOffHourHighlighting => ({
  type: SET_OFF_HOUR_HIGHLIGHTING,
  payload: value
});
