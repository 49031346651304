import React from "react";

const SVGButtonPress = () => (
  <svg width="73" height="73" viewBox="0 0 108 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.7327 44.5186L58.6306 48.6206C58.3789 48.8724 57.9707 48.8724 57.719 48.6206L53.6169 44.5186C53.3652 44.2668 53.3652 43.8587 53.6169 43.607C53.8687 43.3553 54.2768 43.3553 54.5285 43.607L57.5302 46.6087L57.5302 29.4658L58.8194 29.4658L58.8194 46.6087L61.8211 43.607C62.0728 43.3553 62.4809 43.3553 62.7327 43.607C62.9844 43.8587 62.9844 44.2668 62.7327 44.5186Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.5 65H83H23H22.5V64H23H83H83.5V65Z"
      fill="#CCCCCC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47 61V61.5V62H59V61.5V61H47ZM59 61.5V61L59.0131 61L59.054 60.9999C59.0904 60.9997 59.1447 60.9994 59.216 60.9988C59.3585 60.9976 59.5689 60.9952 59.8387 60.9904C60.3783 60.9806 61.1557 60.9612 62.1045 60.9223C64.0029 60.8445 66.584 60.689 69.3186 60.3782C72.0555 60.0672 74.933 59.6022 77.4287 58.9089C79.8401 58.2391 81.8231 57.3749 82.9995 56.2877C83.05 56.241 83.099 56.194 83.1465 56.1465L83.8536 56.8536C83.6001 57.107 83.3142 57.3469 83 57.5741C81.6451 58.5539 79.7643 59.298 77.6963 59.8724C75.1296 60.5854 72.1945 61.0579 69.4315 61.3718C66.666 61.6861 64.0596 61.843 62.1455 61.9215C61.188 61.9607 60.4029 61.9804 59.8567 61.9902C59.5835 61.9951 59.37 61.9976 59.2245 61.9988C59.1517 61.9994 59.0959 61.9997 59.0582 61.9999L59.0152 62L59.0011 62C59.0011 62 59 62 59 61.5ZM47 61.5C47 62 47 62 47 62L46.9848 62L46.9419 61.9999C46.9041 61.9997 46.8483 61.9994 46.7756 61.9988C46.63 61.9976 46.4165 61.9951 46.1434 61.9902C45.5971 61.9804 44.812 61.9607 43.8545 61.9215C41.9404 61.843 39.334 61.6861 36.5686 61.3718C33.8055 61.0579 30.8705 60.5854 28.3037 59.8724C26.2357 59.298 24.3549 58.5539 23 57.5741C22.6858 57.3469 22.3999 57.107 22.1465 56.8536L22.8536 56.1465C22.901 56.194 22.95 56.241 23.0005 56.2877C24.1769 57.3749 26.1599 58.2391 28.5713 58.9089C31.0671 59.6022 33.9445 60.0672 36.6815 60.3782C39.416 60.689 41.9971 60.8445 43.8955 60.9223C44.8443 60.9612 45.6217 60.9806 46.1613 60.9904C46.4312 60.9952 46.6415 60.9976 46.784 60.9988C46.822 60.9992 46.8551 60.9994 46.8833 60.9996C46.9081 60.9997 46.929 60.9998 46.946 60.9999L46.9869 61L47 61V61.5Z"
      fill="#CCCCCC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 58C49.1046 58 50 57.1046 50 56C50 54.8954 49.1046 54 48 54C46.8954 54 46 54.8954 46 56C46 57.1046 46.8954 58 48 58ZM48 59C49.6569 59 51 57.6569 51 56C51 54.3431 49.6569 53 48 53C46.3431 53 45 54.3431 45 56C45 57.6569 46.3431 59 48 59Z"
      fill="#070707"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58 58C59.1046 58 60 57.1046 60 56C60 54.8954 59.1046 54 58 54C56.8954 54 56 54.8954 56 56C56 57.1046 56.8954 58 58 58ZM58 59C59.6569 59 61 57.6569 61 56C61 54.3431 59.6569 53 58 53C56.3431 53 55 54.3431 55 56C55 57.6569 56.3431 59 58 59Z"
      fill="#070707"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47 51V50.5V50H59V50.5V51H47ZM59 50.5C59 50 59 50 59 50L59.0141 50L59.054 50.0001C59.089 50.0003 59.1408 50.0006 59.2084 50.0011C59.3435 50.0022 59.5419 50.0044 59.7962 50.0088C60.3047 50.0175 61.0367 50.0351 61.933 50.07C63.7249 50.14 66.1764 50.2799 68.812 50.5601C71.4458 50.8401 74.2739 51.2611 76.8159 51.8958C79.345 52.5272 81.6457 53.383 83.1824 54.5616C83.7531 54.9993 84 55.6792 84 56.3284V112.25C84 113.631 82.8807 112.25 81.5 112.25L24.5 111.25C23.1193 111.25 22 112.631 22 111.25V56.3284C22 55.6792 22.2469 54.9993 22.8176 54.5616C24.3543 53.383 26.655 52.5272 29.1841 51.8958C31.7261 51.2611 34.5542 50.8401 37.188 50.5601C39.8236 50.2799 42.2751 50.14 44.067 50.07C44.9633 50.0351 45.6953 50.0175 46.2038 50.0088C46.4581 50.0044 46.6565 50.0022 46.7916 50.0011C46.8592 50.0006 46.911 50.0003 46.946 50.0001L46.9859 50L47 50C47 50 47 50 47 50.5V51L46.9879 51L46.95 51.0001C46.9163 51.0003 46.8659 51.0005 46.7998 51.0011C46.6675 51.0021 46.4721 51.0043 46.221 51.0086C45.7189 51.0173 44.9942 51.0346 44.106 51.0693C42.3291 51.1386 39.901 51.2774 37.2937 51.5545C34.6846 51.8318 31.9066 52.2467 29.4264 52.866C26.9333 53.4884 24.796 54.3045 23.4261 55.3551C23.164 55.5561 23.0104 55.8935 23.0005 56.2877C23.0002 56.3012 23 56.3148 23 56.3284V57.5741V64V65V112.328V111.25C23 112.078 23.6716 111.25 24.5 111.25L81.5 112.25C82.3284 112.25 83 113.078 83 112.25V112.328V65V64V57.5741V56.3284C83 56.3148 82.9998 56.3012 82.9995 56.2877C82.9896 55.8935 82.836 55.5561 82.5739 55.3551C81.204 54.3045 79.0667 53.4884 76.5736 52.866C74.0934 52.2467 71.3154 51.8318 68.7063 51.5545C66.099 51.2774 63.6709 51.1386 61.894 51.0693C61.0058 51.0346 60.2811 51.0173 59.779 51.0086C59.5279 51.0043 59.3325 51.0021 59.2002 51.0011C59.1341 51.0005 59.0837 51.0003 59.05 51.0001L59.0121 51L59 51V50.5Z"
      fill="#070707"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51 56C51 57.6569 49.6569 59 48 59C46.3431 59 45 57.6569 45 56C45 54.3431 46.3431 53 48 53C49.6569 53 51 54.3431 51 56ZM61 56C61 57.6569 59.6569 59 58 59C56.3431 59 55 57.6569 55 56C55 54.3431 56.3431 53 58 53C59.6569 53 61 54.3431 61 56ZM50 56C50 57.1046 49.1046 58 48 58C46.8954 58 46 57.1046 46 56C46 54.8954 46.8954 54 48 54C49.1046 54 50 54.8954 50 56ZM58 58C59.1046 58 60 57.1046 60 56C60 54.8954 59.1046 54 58 54C56.8954 54 56 54.8954 56 56C56 57.1046 56.8954 58 58 58Z"
      fill="#070707"
    />
    <circle cx="53.5316" cy="68.5316" r="52.5316" stroke="#E5EBE8" strokeWidth="2" />
  </svg>
);

export default SVGButtonPress;
