import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import classnames from "classnames";
import { Icon, IconNames } from "@ddm-design-system/icon";
import { LabelMedium } from "@ddm-design-system/typography";

interface IProps extends NavLinkProps {
  title: string;
  icon: IconNames;
  disabled?: boolean;
}

const BottombarNavigationButton: React.FC<IProps> = ({ title, icon, disabled, ...props }) => {
  return (
    <NavLink
      className={classnames("bottombar-item", disabled && "disabled")}
      activeClassName="selected"
      {...props}
    >
      <Icon name={icon} color={disabled ? "var(--color-grey-grey50)" : "var(--color-black)"} />
      <LabelMedium className="bottombar-item-label">{title}</LabelMedium>
    </NavLink>
  );
};

export default BottombarNavigationButton;
