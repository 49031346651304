import React, { forwardRef } from "react";
import classnames from "classnames";
import { DescriptionMedium } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import { IInsightsNotification, EInsightItemType } from "../../../store/insights/types";
import InsightComponentMap, { InsightComponentTestMap } from "./InsightItemMap";
import { getOutletById } from "../../../store/outlet/selectors";
import { getIsInsightSeen } from "../../../store/insights/selectors";

// const testOutlets = [
//   "dc9458f5-d3fe-4261-a778-5d58bfe5cd03", //  La Relais de la bouee
//   "5d2cff0d-3aa7-400e-beea-04949019058c", // L'ile de java
//   "b05dc3ad-d964-45f8-9ad2-a7ea28c507f7", // L'incontournable
//   "84d34186-4e8f-4e22-b095-921415ce9b4e", // Villa Luca
//   "3f092be3-aa5c-4741-951a-906c41a2bc08" // Café de La Loire
// ];

interface IProps {
  insight: IInsightsNotification;
  chosenOutletId?: string;
}

const InsightItem = forwardRef<HTMLDivElement, IProps & { [key: string]: unknown }>(
  ({ insight, chosenOutletId, ...props }, ref) => {
    const outlet = useSelector(getOutletById(insight.locationId));
    const insightSeen = useSelector(getIsInsightSeen(insight.locationId, insight.id));
    const InsightComponent =
      (InsightComponentMap as any)[insight.type] || (InsightComponentTestMap as any)[insight.type];

    return InsightComponentMap && InsightComponentTestMap && InsightComponent ? (
      <div
        data-test-id="insight-item"
        className={classnames(
          "flex flex-1 flex-col items-start justify-start text-left p-md border border-grey-grey100 rounded-10 mt-lg xs:p-0 xs:border-none",
          { "opacity-70": insightSeen }
        )}
        ref={ref}
      >
        {!chosenOutletId && (
          <DescriptionMedium className="!text-grey-grey100 ml-xxxl mb-xs">
            {outlet?.name}
          </DescriptionMedium>
        )}

        <InsightComponent insight={insight} itemType={EInsightItemType.INSIGHT_ITEM} {...props} />
      </div>
    ) : (
      <>{insight.type}</>
    );
  }
);

export default InsightItem;
