import {
  ADD_TARGET,
  DELETE_TARGET,
  SET_CURRENT_TARGET,
  ITargetParams,
  IAddTarget,
  IDeleteTarget,
  ISetCurrentTarget
} from "./types";

export const addTarget = (target: ITargetParams): IAddTarget => ({
  type: ADD_TARGET,
  payload: target
});

export const deleteTarget = (month: string): IDeleteTarget => ({
  type: DELETE_TARGET,
  payload: month
});

export const setCurrentTarget = (target?: ITargetParams): ISetCurrentTarget => ({
  type: SET_CURRENT_TARGET,
  payload: target
});
