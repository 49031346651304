import React from "react";
import styled, { keyframes } from "styled-components";
import { ddmTheme } from "@ddm-design-system/tokens";

const ldsRing = () => keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoadingSpinner = styled.div`
  width: ${ddmTheme.spaceUnit.lg};
  height: ${ddmTheme.spaceUnit.lg};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${ddmTheme.spaceUnit.lg};
    height: ${ddmTheme.spaceUnit.lg};
    border: 4px solid transparent;
    border-radius: 50%;
    animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-top-color: ${ddmTheme.colors.black};
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export const LoadingSpinner: React.FC = () => (
  <StyledLoadingSpinner>
    <div />
    <div />
    <div />
    <div />
  </StyledLoadingSpinner>
);
