import React, { useCallback, useMemo } from "react";
import { IconNames } from "@ddm-design-system/icon";
import useContent from "../../../../hooks/useContent";
import { EInsightsNotificationType, EInsightItemType, IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";


export interface IInsightPeerComparisonProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

type IInsightValues = {
    icon: IconNames;
    positive: boolean;
    title: string;
    description: string;
};

const InsightPeerComparison: React.FC<IInsightPeerComparisonProps> = ({ insight, itemType }) => {
  const {
    managerAppInsights: content
  } = useContent();
  

  const insightValues = useMemo<IInsightValues>(() => {
      const { differenceInPerformance, lastPerformanceInGroup, performanceInGroup }: any = insight.customFields;
      const positive = differenceInPerformance > 0;
      const moreThanPeers = performanceInGroup > 0;
      switch (insight.type) {
        case EInsightsNotificationType.PEER_COMPARISON_NEARBY:
            return {
                icon: "Outlets",
                positive,
                title: positive ?
                  content.manager_app_insights_peer_comparison_nearby_title_up :
                  content.manager_app_insights_peer_comparison_nearby_title_down,
                description: (positive ?
                  moreThanPeers ?
                    content.manager_app_insights_peer_comparison_nearby_desc_more_up :
                    content.manager_app_insights_peer_comparison_nearby_desc_less_up :
                  moreThanPeers ?
                    content.manager_app_insights_peer_comparison_nearby_desc_more_down :
                    content.manager_app_insights_peer_comparison_nearby_desc_less_down)
                .replace("%DIFF%", `${Math.round(Math.abs(performanceInGroup || 0))}%`)
                .replace("%FROM%", `${Math.round(Math.abs(lastPerformanceInGroup || 0))}%`)
            };
        case EInsightsNotificationType.PEER_COMPARISON_SIMILAR:
            return {
                icon: "Store",
                positive,
                title: positive ?
                  content.manager_app_insights_peer_comparison_similar_title_up :
                  content.manager_app_insights_peer_comparison_similar_title_down,
                description: (positive ?
                  moreThanPeers ?
                    content.manager_app_insights_peer_comparison_similar_desc_more_up :
                    content.manager_app_insights_peer_comparison_similar_desc_less_up :
                  moreThanPeers ?
                    content.manager_app_insights_peer_comparison_similar_desc_more_down :
                    content.manager_app_insights_peer_comparison_similar_desc_less_down)
                .replace("%DIFF%", `${Math.round(Math.abs(performanceInGroup || 0))}%`)
                .replace("%FROM%", `${Math.round(Math.abs(lastPerformanceInGroup || 0))}%`)
            };
        case EInsightsNotificationType.PEER_COMPARISON_CHAIN:
            return {
                icon: "Store",
                positive,
                title: positive ?
                  content.manager_app_insights_peer_comparison_chain_title_up :
                  content.manager_app_insights_peer_comparison_chain_title_down,
                description: (positive ?
                  moreThanPeers ?
                    content.manager_app_insights_peer_comparison_chain_desc_more_up :
                    content.manager_app_insights_peer_comparison_chain_desc_less_up :
                  moreThanPeers ?
                    content.manager_app_insights_peer_comparison_chain_desc_more_down :
                    content.manager_app_insights_peer_comparison_chain_desc_less_down)
                .replace("%DIFF%", `${Math.round(Math.abs(performanceInGroup || 0))}%`)
                .replace("%FROM%", `${Math.round(Math.abs(lastPerformanceInGroup || 0))}%`)
            };
        default:
            return {
                icon: "Store",
                positive: false,
                title: "",
                description: ""
            };
      }
  }, [insight, content]);

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={insightValues.title}
          description={insightValues.description}
          icon={insightValues.icon}
          iconOptions={{ name: insightValues.icon, className: "text-white fill-current" }}
          insight={insight}
          avatarOptions={{
            backgroundColor: insightValues.positive
              ? "var(--color-success-success100)"
              : "var(--color-alert-alert100)"
          }}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={insightValues.title}
          icon={insightValues.icon}
          iconOptions={{ name: insightValues.icon, className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: insightValues.positive
              ? "var(--color-success-success100)"
              : "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={insightValues.title}
          description={insightValues.description}
          icon={insightValues.icon}
          iconOptions={{ name: insightValues.icon, className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: insightValues.positive
              ? "var(--color-success-success100)"
              : "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightPeerComparison;
