import { useState, useEffect } from "react";

export function useScroll(
  element?: Element,
  options: { isHorizontal: boolean } = { isHorizontal: false }
) {
  const scrollingElement = element || document.getElementById("root") || document.body;
  const [lastScroll, setLastScroll] = useState(0);
  const [scroll, setScroll] = useState(
    options.isHorizontal ? scrollingElement.scrollLeft : scrollingElement.scrollTop
  );
  const [scrollDirection, setScrollDirection] = useState<string>();
  const hasScrolledToEnd = options.isHorizontal
    ? scroll + scrollingElement.clientWidth === scrollingElement.scrollWidth
    : scroll + scrollingElement.clientHeight === scrollingElement.scrollHeight;

  const listener = () => {
    const { scrollLeft, scrollTop } = scrollingElement;
    setScroll(options.isHorizontal ? scrollLeft : scrollTop);
    setScrollDirection(
      options.isHorizontal
        ? lastScroll < scrollLeft
          ? "right"
          : "left"
        : lastScroll < scrollTop
        ? "down"
        : "up"
    );
    setLastScroll(options.isHorizontal ? scrollLeft : scrollTop);
  };

  useEffect(() => {
    scrollingElement.addEventListener("scroll", listener);
    return () => {
      scrollingElement.removeEventListener("scroll", listener);
    };
  });

  return {
    hasScrolledToEnd,
    scroll,
    scrollDirection,
    scrollingElement
  };
}

export default useScroll;
