import React, { useCallback } from "react";
import { Card, CardBody } from "@ddm-design-system/card";
import { BodyHighlight } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import { Keg } from "../common/keg/keg";
import PressureChamberBadges from "./PressureChamberBadges";
import PressureChamberDescription from "./PressureChamberDescription";

interface IProps {
  pressureChamber: IPressureChamber;
  onClick: (pressureChamber: { pressureChamber: IPressureChamber }) => void;
}

export const PressureChamberCard: React.FC<IProps> = ({ pressureChamber, onClick }) => {
  const { managerAppCommon: contentCommon } = useContent();
  const handleClick = useCallback(() => onClick({ pressureChamber }), [pressureChamber, onClick]);

  pressureChamber.status = pressureChamber.offline ? "offline" :
    pressureChamber.dangerTags > 0 && !pressureChamber.isOpen ? "danger" : 
    pressureChamber.warningTags > 0 || pressureChamber.isOpen ? "warning" : "ok";

  return (
    <Card
      className={`bg-white !rounded-[4px] box-border !border-[3px] justify-between w-[240px] h-[310px] relative cursor-pointer hover:z-[1] xs:w-full xs:max-w-none 
      ${
        pressureChamber.status === "warning"
          ? "!border-warning-warning100 !bg-warning-warning25"
          : pressureChamber.status === "danger"
          ? "!border-alert-alert100 !bg-alert-alert25"
          : pressureChamber.status === "offline"
          ? "!border-grey-grey50 !bg-grey-grey25"
          : "hover:!border-black !border-grey-greenish"
      }
      ${pressureChamber.isBeerDrive && "max-w-[364px] min-w-[364px] xs:max-w-none xs:min-w-[256px]"}
      `}
      key={pressureChamber.id}
      onClick={handleClick}
    >
      <CardBody className="flex flex-col !p-md">
        {pressureChamber.isBeerDrive ? (
          <div className="flex flex-col blank-beer-drive">
            <BodyHighlight className="mb-lg">
              {contentCommon.common_tank} {pressureChamber.position || "--"}
            </BodyHighlight>
            <Keg pressureChamber={pressureChamber} />
            <div className="flex flex-col items-center min-w-0 xs:ml-0 xs:mt-md">
              <PressureChamberDescription pressureChamber={pressureChamber} />
              <PressureChamberBadges pressureChamber={pressureChamber} />
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center">
              <BodyHighlight className="mb-md">
                {contentCommon.common_pressure_chamber} {pressureChamber.position || "--"}
              </BodyHighlight>
              <Keg pressureChamber={pressureChamber} />
              <PressureChamberDescription pressureChamber={pressureChamber} />
            </div>
            <PressureChamberBadges pressureChamber={pressureChamber} />
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default PressureChamberCard;
