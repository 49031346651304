import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ModalTooltip } from "@ddm-design-system/modal";
import { Body } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import {
  getOutletPressureChambersTags,
  getAllPressureChambersTags
} from "../../store/installation/selectors";
import useContent from "../../hooks/useContent";
import { getChosenOutletId } from "../../store/filter/selectors";
import { IOutletTags } from "../../store/installation/types";
import { AnalyticsContext } from "../../services/analytics";

const InstallationStatusTooltip: React.FC = () => {
  const { managerAppCommon: common, managerAppOverview: content } = useContent();
  const analytics = useContext(AnalyticsContext);

  const selectedOutletId: string = useSelector(getChosenOutletId);
  const { okTags, warningTags, dangerTags, offlineTags }: IOutletTags = useSelector(
    selectedOutletId ? getOutletPressureChambersTags : getAllPressureChambersTags
  );

  const okDescription = (content.manager_app_pressure_chambers_status_description_ok || "").split(
    "%SPLIT%"
  );
  const warningDescription = (
    content.manager_app_pressure_chambers_status_description_warning || ""
  ).split("%SPLIT%");
  const errorDescription = (
    content.manager_app_pressure_chambers_status_description_error || ""
  ).split("%SPLIT%");
  const offlineDescription = (
    content.manager_app_pressure_chambers_status_description_offline || ""
  ).split("%SPLIT%");
  const noneOfflineDescription = (content.manager_app_pressure_chambers_none_offline || "").split(
    "%SPLIT%"
  );

  return (
    <ModalTooltip
      iconClassName="ml-xs !p-0"
      titleIcon={<Icon name="Installation" className="text-grey-grey50 fill-current" />}
      title={content.manager_app_pressure_chambers_status}
      onAfterOpen={() => analytics.logEvent("TOOLTIP", "Installation Status")}
    >
      <Body className="flex flex-col">
        <div>{content.manager_app_pressure_chambers_status_description}</div>
        <div className="pt-lg">
          <b>{okTags}&nbsp;</b>
          {okDescription[0]}
          <span className="capitalize font-bold text-success-success100">
            {(common.common_ok || "").toLocaleLowerCase()}
          </span>
          {okDescription[1]}
        </div>

        <div className="pt-lg">
          <b>{warningTags}&nbsp;</b>
          {warningDescription[0]}
          <span className="capitalize font-bold text-warning-warning100">
            {(common.common_warning || "").toLocaleLowerCase()}
          </span>
          {warningDescription[1]}
        </div>

        <div className="pt-lg">
          <b>{dangerTags}&nbsp;</b>
          {errorDescription[0]}
          <span className="capitalize font-bold text-alert-alert100">
            {(common.common_error || "").toLocaleLowerCase()}
          </span>
          {errorDescription[1]}
        </div>

        {offlineTags > 0 ? (
          <div className="pt-lg">
            <b>{offlineTags}&nbsp;</b>
            {offlineDescription[0]}
            <span className="capitalize font-bold text-grey-grey50">
              {(common.common_offline || "").toLocaleLowerCase()}
            </span>
            {offlineDescription[1]}
          </div>
        ) : (
          <div className="pt-lg">
            {noneOfflineDescription[0]}
            <span className="capitalize font-bold text-grey-grey50">
              {(common.common_offline || "").toLocaleLowerCase()}
            </span>
            {noneOfflineDescription[1]}
          </div>
        )}
      </Body>
    </ModalTooltip>
  );
};

export default InstallationStatusTooltip;
