import React from "react";
import styled, { css } from "styled-components";
import { Infoicon, BaseInfoiconStyles } from "@ddm-design-system/infoicon";
import { Body } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";
import { getBeverageLogoOptions } from "../../helpers";

const StyledInfoIcon = styled(Infoicon)(
  ({ theme }) => css`
    ${BaseInfoiconStyles.StyledAvatar} {
      border-width: 0;
      height: ${theme.spaceUnit.xxxl};
      width: ${theme.spaceUnit.xxxl};
    }
    ${BaseInfoiconStyles.Title} {
      font-size: var(--font-size);
      font-weight: 400;
      color: var(--color-black);
    }
  `
);

interface IProps {
  pressureChamber: IPressureChamber;
}

export const PressureChamberSummary: React.FC<IProps> = ({ pressureChamber }) => {
  const { managerAppCommon: content } = useContent();

  const title = (
    <div className="flex flex-row mb-xs">
      <div className="mr-md">
        <Body className="text-grey-grey100">{content.common_keg} </Body>
        {pressureChamber.position}
      </div>
      <div>
        <Body className="text-grey-grey100">{content.common_tap} </Body>
        {pressureChamber.tapPosition}
      </div>
    </div>
  );

  return (
    <StyledInfoIcon
      className="items-center !p-lg bg-grey-grey25 border !border-grey-grey25"
      title={pressureChamber.beverage.name}
      withPadding
      avatarOptions={getBeverageLogoOptions(pressureChamber.beverage)}
    >
      {title}
    </StyledInfoIcon>
  );
};

export default PressureChamberSummary;
