import { createSelector } from "reselect";
import { IAppState } from "..";
import { BeverageTranslation } from "./types";
import { getChosenOutlet } from "../filter/selectors";
import { IOutlet as IOutletType } from "../outlet/types";
import { getOutletsFromGroup } from "../../helpers";
import {
  IOutletPickerGroupItem,
  IOutletPickerItem
} from "../../components/common/outlet_picker/OutletPicker";
import { IPressureChamber } from "../installation/types";

export const getBeverages = (state: IAppState) => state.beers.beers;

const getOutlets = (state: IAppState) => state.installation.outlets;

export const getFilteredBeverages = createSelector(
  getBeverages,
  getOutlets,
  getChosenOutlet,
  (beverages, outlets, chosenOutlet) => {
    if (!chosenOutlet) return beverages;

    let outletsT: (IOutletType | IOutletPickerItem)[] = [chosenOutlet];

    if (chosenOutlet.type) {
      outletsT = getOutletsFromGroup(chosenOutlet as IOutletPickerGroupItem);
    }

    const bevIds = outletsT
      .reduce((acc: IPressureChamber[], outlet: any) => {
        return [
          ...acc,
          ...(outlets?.[outlet.id]?.beerDrives || []),
          ...(outlets?.[outlet.id]?.pressureChambers || [])
        ];
      }, [])
      .map((pc: IPressureChamber) => pc.beverageId);

    return beverages.filter(b => bevIds.includes(b.id));
  }
);

export const getBeverage = (state: IAppState, beverageId: string) =>
  state.beers.beers.find(beer => beer.id === beverageId);

export const getBeverageName = (state: IAppState, beverageId: string) =>
  getBeverage(state, beverageId)?.name;

export const getBeveragesByIds = (state: IAppState, beverageIds: string[]): BeverageTranslation[] =>
  beverageIds
    .map(beverageId => getBeverage(state, beverageId))
    .filter(Boolean) as BeverageTranslation[];

export const getBeersLoading = (state: IAppState) => state.beers.loading;
