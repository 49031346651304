import React from "react";
import numeral from "numeral";
import { Card, CardBody } from "@ddm-design-system/card";
import { BodyHighlight, DescriptionMedium } from "@ddm-design-system/typography";
import { Divider } from "@ddm-design-system/divider";
import { Icon } from "@ddm-design-system/icon";
import useContent from "../../hooks/useContent";
import { ICoolingUnit } from "../../store/installation/types";
import SVGCoolingUnit from "./cleaning-unit-flow/assets/cooling-unit";

interface IProps {
  coolingUnit: ICoolingUnit;
}

export const CoolingUnitCard: React.FC<IProps> = ({ coolingUnit }) => {
  const { managerAppCommon: contentCommon } = useContent();

  const status =
    !coolingUnit.alive ||
    coolingUnit.hasTempError ||
    coolingUnit.faulty ||
    !coolingUnit.sensorConnected
      ? "danger"
      : "ok";

  return (
    <Card
      className={`bg-white !rounded-[4px] box-border !border-[3px] justify-between w-[240px]  relative cursor-pointer hover:z-[1] xs:w-full xs:max-w-none 
      ${
        status === "danger"
          ? "!border-alert-alert100 !bg-alert-alert25"
          : "hover:!border-black !border-grey-greenish"
      }
      `}
      key={coolingUnit.id}
    >
      <CardBody className="flex flex-col !p-md">
        <div className="flex flex-col items-center">
          <BodyHighlight className="pb-xs">
            {contentCommon.common_cooling_unit} {coolingUnit.position || "--"}
          </BodyHighlight>
          <DescriptionMedium className="pb-lg">
            <span className="text-grey-grey100">{contentCommon.common_cooling_line} </span>
            {coolingUnit.position || "--"}
          </DescriptionMedium>
          <SVGCoolingUnit
            fill={`${
              status === "danger" ? "var(--color-alert-alert100)" : "var(--color-grey-grey50)"
            } `}
          />
          <DescriptionMedium className="pt-md">
            <span className="text-grey-grey100 pr-xxs">
              {contentCommon.common_python_temperature}
            </span>
            <b>
              {coolingUnit.pythonTemp ? numeral(coolingUnit.pythonTemp / 100).format("0") : "--"}ºC
            </b>
          </DescriptionMedium>
        </div>
        <Divider
          className={` ${status === "danger" ? "!bg-alert-alert100" : "!bg-grey-greenish"}
                mt-lg
        `}
        />
        <div className="flex flex-row w-full justify-center pt-md xs:gap-x-md h-lg items-center">
          {status === "ok" ? (
            <DescriptionMedium className="text-success-success100">
              {contentCommon.common_ok}
            </DescriptionMedium>
          ) : !coolingUnit.alive ? (
            <DescriptionMedium className="text-grey-grey50">
              {contentCommon.common_offline}
            </DescriptionMedium>
          ) : (
            <div className="flex flex-row">
              {coolingUnit.hasTempError && (
                <Icon
                  fill="var(--color-alert-alert100)"
                  className="mr-xs"
                  name="Temperature"
                  size={24}
                />
              )}
              {!coolingUnit.sensorConnected && (
                <Icon
                  fill="var(--color-alert-alert100)"
                  className="mr-xs"
                  name="Sensor"
                  size={24}
                />
              )}
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default CoolingUnitCard;
