import React, { useCallback, useEffect, useMemo } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { TextDropdown } from "@ddm-design-system/dropdown";
import { Icon, IconNames } from "@ddm-design-system/icon";
import { BodyHighlight, Description, DescriptionMedium } from "@ddm-design-system/typography";
import { useIsMobile, useIsTablet } from "@ddm-design-system/hooks";
import useContent from "../../../hooks/useContent";
import { IAppState } from "../../../store";
import usePrevious from "../../../hooks/usePrevious";
import { PERFORMANCE_TYPE } from "../../../store/filter/types";
import { getAllOutlets } from "../../../store/outlet/selectors";
import { getFilteredBeverages } from "../../../store/beers/selectors";
import { formatNumber } from "../../../helpers";
import { setCompareOutlet } from "../../../store/filter/actions";
import { IOutlet } from "../../../store/outlet/types";
import {
  allOutletsWithActiveTargets,
  getChosenOutlet,
  getFilter,
  getFilterBeers,
  getOutletCompare,
  getTimeFrame,
  isOutletChosen
} from "../../../store/filter/selectors";
import VsTargetsTooltip from "./VsTargetsTooltip";
import "./performance-card.scss";
import { ETimeFrame } from "../../../lib/Time/types";

interface ISalesData {
  volume: number;
  speciality: number;
}

interface IProps {
  data?: ISalesData;
  error?: boolean;
  active?: boolean;
  type: PERFORMANCE_TYPE;
  loading: boolean;
  dark?: boolean;
  horizontal?: boolean;
  selected?: boolean;
  detailedError?: boolean;
  onSelected?: (performanceType: PERFORMANCE_TYPE) => void;
  useMobileVersion?: boolean;
}

const iconMap = (type: PERFORMANCE_TYPE): IconNames => {
  switch (type) {
    case PERFORMANCE_TYPE.AVG: {
      return "AveragePositive";
    }
    case PERFORMANCE_TYPE.TARGET: {
      return "Target";
    }
    case PERFORMANCE_TYPE.OUTLET: {
      return "Compare";
    }
    case PERFORMANCE_TYPE.SIMILAR: {
      return "Store";
    }
    case PERFORMANCE_TYPE.NEAR: {
      return "Outlets";
    }
    default:
      return "Keg";
  }
};

const NoTargetView: React.FC<{ error?: boolean; detailedError?: boolean }> = ({
  error,
  detailedError = true
}) => {
  const { managerAppOverview: content } = useContent();

  const filterBeer = !useSelector(getFilter).allBeerIdsSelected;
  const customTimeFrame = useSelector(getTimeFrame).type === ETimeFrame.CUSTOM;

  const text = filterBeer
    ? content.manager_app_recent_sales_target_single_beer
    : customTimeFrame
    ? content.manager_app_recent_sales_target_custom_timeframe
    : error
    ? content.manager_app_recent_sales_load_error
    : content.manager_app_recent_sales_no_target;

  return (
    <div>
      <Description className={classnames("no-target-text")}>
        {detailedError ? text : content.manager_app_recent_sales_no_target}
      </Description>
    </div>
  );
};

const NoDataView: React.FC<{
  error?: boolean;
  detailedError?: boolean;
  type: keyof typeof PERFORMANCE_TYPE;
}> = ({ error, detailedError = true, type }) => {
  const { managerAppOverview: content } = useContent();

  const chosenOutlet = useSelector(getChosenOutlet);
  const multiOutlet = !useSelector(isOutletChosen) || chosenOutlet?.type;
  const filterBeer = !useSelector(getFilter).allBeerIdsSelected;
  const typeLowerCase = type.toLocaleLowerCase();

  const text = multiOutlet
    ? content[`manager_app_recent_sales_${typeLowerCase}_all`]
    : filterBeer
    ? content[`manager_app_recent_sales_${typeLowerCase}_filter_beer`]
    : error
    ? content.manager_app_recent_sales_load_error
    : type === PERFORMANCE_TYPE[PERFORMANCE_TYPE.OUTLET]
    ? content.manager_app_recent_sales_choose_outlet
    : content[`manager_app_recent_sales_${typeLowerCase}_no_data`];

  return (
    <Description className="no-outlet-text">
      {type === PERFORMANCE_TYPE[PERFORMANCE_TYPE.OUTLET] && !detailedError
        ? content.manager_app_recent_sales_choose_outlet
        : text}
    </Description>
  );
};

const OutletPicker: React.FC = () => {
  const dispatch = useDispatch();
  const { managerAppOverview: content } = useContent();
  const outlets = useSelector(getAllOutlets);
  const outletCompare = useSelector(getOutletCompare);

  const primaryOutlet = useSelector<IAppState, IOutlet[] | any>(getChosenOutlet);
  const selectedBeer = useSelector<IAppState, IOutlet[] | any>(getFilterBeers);

  const beerList = useSelector(getFilteredBeverages);

  const filteredOutlets = useMemo(
    () => outlets.filter(o => o.id !== primaryOutlet?.id),
    [outlets, primaryOutlet]
  );

  const [items, selectedItem] = useMemo(
    () => [
      filteredOutlets
        .filter((outlet: IOutlet) => !primaryOutlet || primaryOutlet.type || outlet.id)
        .map(o => ({
          id: o.id,
          text: o.name,
          disabled:
            !primaryOutlet ||
            primaryOutlet.type ||
            !(selectedBeer.length === 0 || selectedBeer.length === beerList.length)
        })),
      outletCompare ? { id: outletCompare.id, text: outletCompare.name } : undefined
    ],
    [filteredOutlets, outletCompare, primaryOutlet, selectedBeer, beerList]
  );

  const setOutlet = useCallback(
    (item: any) => {
      const outlet = filteredOutlets.find(o => o.id === item.id);
      dispatch(setCompareOutlet(outlet ?? null));
    },
    [dispatch, filteredOutlets]
  );
  return (
    <TextDropdown
      className="text-dropdown"
      items={items}
      selectedItem={selectedItem}
      onItemSelected={setOutlet}
      text={
        outletCompare ? `vs. ${outletCompare.name}` : content.manager_app_recent_sales_outlet_title
      }
    />
  );
};

const PerformanceCard: React.FC<IProps> = ({
  data,
  type,
  loading,
  active,
  dark,
  horizontal,
  selected,
  onSelected,
  error,
  detailedError = true,
  useMobileVersion = false // TODO consider changing this to a more generic name since it is used in desktop in performance page
}) => {
  const { managerAppOverview: content, managerAppCommon: common } = useContent();
  const chosenOutlet = useSelector(getChosenOutlet);
  const isOutletSet = useSelector(isOutletChosen) && !chosenOutlet?.type;
  const allActiveTargets = useSelector(allOutletsWithActiveTargets);
  const outletCompare = useSelector(getOutletCompare);
  const filterBeer = !useSelector(getFilter).allBeerIdsSelected;
  const customTimeFrame = useSelector(getTimeFrame).type === ETimeFrame.CUSTOM;
  const { volume, speciality } = data ?? {};
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const prevLoading = usePrevious(loading);
  const noTarget = type === PERFORMANCE_TYPE.TARGET && ((!loading && !data) || customTimeFrame);
  const noData =
    (type === PERFORMANCE_TYPE.OUTLET ||
      type === PERFORMANCE_TYPE.SIMILAR ||
      type === PERFORMANCE_TYPE.NEAR) &&
    !loading &&
    !data;
  const disabled =
    loading ||
    noTarget ||
    (type === PERFORMANCE_TYPE.TARGET && (filterBeer || !allActiveTargets)) ||
    (type === PERFORMANCE_TYPE.OUTLET && (!isOutletSet || filterBeer)) ||
    (type === PERFORMANCE_TYPE.SIMILAR && ((!loading && !data) || !isOutletSet || filterBeer)) ||
    (type === PERFORMANCE_TYPE.NEAR && ((!loading && !data) || !isOutletSet || filterBeer));

  // reset selected "compare vs" to average if it is disabled for the current outlet
  useEffect(() => {
    if (type !== PERFORMANCE_TYPE.CURRENT && selected && prevLoading && !loading) {
      if (disabled) {
        onSelected?.(PERFORMANCE_TYPE.AVG);
      }
    }
  }, [disabled, loading, onSelected, prevLoading, selected, type]);

  return (
    <div
      className={classnames(
        "performance-card",
        active && "active",
        loading && "loading",
        (useMobileVersion || isTablet) && "tablet",
        isMobile && "mobile",
        dark && "dark",
        horizontal ? "horizontal" : "vertical",
        selected && "selected",
        (selected || !!onSelected) && "selectable",
        disabled && "disabled",
        `performance-card-${PERFORMANCE_TYPE[type].toLowerCase()}`
      )}
      onClick={
        type === PERFORMANCE_TYPE.OUTLET && (!outletCompare || selected)
          ? undefined
          : () => !disabled && onSelected?.(type)
      }
    >
      <Icon name={iconMap(type)} className="icon" />
      <div className="performance-card-content">
        <div className="performance-card-title-wrapper">
          <DescriptionMedium className="performance-card-title">
            {type === PERFORMANCE_TYPE.OUTLET ? (
              <OutletPicker />
            ) : type === PERFORMANCE_TYPE.CURRENT ? (
              isOutletSet ? (
                common.your_outlet
              ) : (
                common.all_outlets
              )
            ) : (
              content[
                `manager_app_recent_sales_${PERFORMANCE_TYPE[type].toLocaleLowerCase()}_title`
              ]
            )}
          </DescriptionMedium>
          {!isOutletSet && type === PERFORMANCE_TYPE.TARGET && (
            <VsTargetsTooltip isPerformancePage={!!onSelected} />
          )}
        </div>
        <div className="performance-card-info">
          {error ? (
            <Description className="error-text">
              {content.manager_app_recent_sales_load_error}
            </Description>
          ) : noTarget ? (
            <NoTargetView error={error} detailedError={detailedError} />
          ) : noData ? (
            <NoDataView
              error={error}
              detailedError={detailedError}
              type={PERFORMANCE_TYPE[type] as keyof typeof PERFORMANCE_TYPE}
            />
          ) : (
            <>
              <div className="performance-card-group">
                <DescriptionMedium className="performance-card-label">
                  {content.manager_app_recent_sales_volume}
                </DescriptionMedium>
                <BodyHighlight
                  className={classnames(
                    "performance-card-value",
                    type !== PERFORMANCE_TYPE.CURRENT && volume
                      ? volume < 0
                        ? "negative"
                        : "positive"
                      : ""
                  )}
                >
                  {volume !== undefined &&
                    formatNumber(volume, "L", type !== PERFORMANCE_TYPE.CURRENT)}
                </BodyHighlight>
              </div>
              <div className="performance-card-group">
                <DescriptionMedium className="performance-card-label">
                  {horizontal && !isMobile
                    ? content.manager_app_recent_sales_speciality_short
                    : content.manager_app_recent_sales_speciality}
                </DescriptionMedium>
                <BodyHighlight
                  className={classnames(
                    "performance-card-value",
                    type !== PERFORMANCE_TYPE.CURRENT && speciality
                      ? speciality < 0
                        ? "negative"
                        : "positive"
                      : ""
                  )}
                >
                  {speciality !== undefined &&
                    formatNumber(speciality, "%", type !== PERFORMANCE_TYPE.CURRENT)}
                </BodyHighlight>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PerformanceCard;
