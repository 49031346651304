import React from "react";
import { Divider } from "@ddm-design-system/divider";
import { ModalTooltip } from "@ddm-design-system/modal";
import { Description, DescriptionMedium } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import "./product-info.scss";

export const ProductInfoTooltip: React.FC = () => {
  const { managerAppProduct: content, managerAppOverview: contentOverview } = useContent();

  return (
    <ModalTooltip title={contentOverview.manager_app_product_info_title}>
      <div>
        <div className="target-tooltip-section">
          <DescriptionMedium>{content.manager_app_product_mainstream_brands}</DescriptionMedium>
          <Description>
            {contentOverview.manager_app_product_info_mainstream_brands_desc}
          </Description>
        </div>
        <Divider />
        <div className="target-tooltip-section">
          <DescriptionMedium>{content.manager_app_product_speciality_brands}</DescriptionMedium>
          <Description>
            {contentOverview.manager_app_product_info_speciality_brands_desc}
          </Description>
        </div>
        <Divider />
        <div className="target-tooltip-section">
          <DescriptionMedium>{content.manager_app_product_number_of_taps}</DescriptionMedium>
          <Description>{contentOverview.manager_app_product_info_taps_desc}</Description>
        </div>
        <Divider />
        <div className="target-tooltip-section">
          <DescriptionMedium>{content.manager_app_product_kegs_per_week}</DescriptionMedium>
          <Description>{contentOverview.manager_app_product_info_kegs_desc}</Description>
        </div>
      </div>
    </ModalTooltip>
  );
};

export default ProductInfoTooltip;
