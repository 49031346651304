import produce from "immer";
import { Reducer } from "redux";
import {
  mapOutletBeerDrives,
  mapOutletPressureChambers,
  mapOutletSteelKegUnits
} from "../installation/utils";
import {
  GET_OUTLET_LOCATION_DATA_SUCCESS,
  GET_OUTLET_LOCATION_DETAILS,
  GET_OUTLET_LOCATION_DETAILS_ERROR,
  GET_OUTLET_LOCATION_DETAILS_SUCCESS,
  GET_OUTLET_LOCATION_STATUS,
  GET_OUTLET_LOCATION_STATUS_ERROR,
  GET_OUTLET_LOCATION_STATUS_SUCCESS,
  IOutletState,
  OutletActionTypes,
  REQUEST_OUTLETS,
  REQUEST_OUTLETS_ERROR,
  SET_OUTLET_FAVOURITE,
  SET_OUTLET_FAVOURITE_ERROR,
  SET_OUTLET_PICKER_TAB,
  SET_OUTLETS
} from "./types";

export const initialState: IOutletState = {
  currentOutletPickerTab: 0,
  outlets: [],
  loadingOutlets: false,
  loadingOutletsStatus: false,
  loadingOutletDetails: false,
  outletsError: false,
  outletDetailsError: false,
  outletsLocationData: {},
  outletsStatus: [],
  outletDetails: null
};

const reducer: Reducer<IOutletState, OutletActionTypes> = (
  state = initialState,
  action: OutletActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_OUTLET_PICKER_TAB:
        draft.currentOutletPickerTab = action.payload;
        break;
      case REQUEST_OUTLETS:
        draft.loadingOutlets = true;
        break;
      case SET_OUTLETS:
        const outlets: any = action.payload;
        draft.outlets = outlets.sort((a: any, b: any) =>
          a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
        );
        draft.loadingOutlets = false;
        draft.outletsError = false;
        break;
      case REQUEST_OUTLETS_ERROR:
        draft.loadingOutlets = false;
        draft.outletsError = true;
        break;
      case GET_OUTLET_LOCATION_DATA_SUCCESS:
        const newData = { ...state.outletsLocationData };
        newData[action.payload.outletId] = action.payload.data;
        draft.outletsLocationData = newData;
        break;
      case GET_OUTLET_LOCATION_STATUS:
        draft.loadingOutletsStatus = true;
        break;
      case GET_OUTLET_LOCATION_STATUS_SUCCESS:
        draft.loadingOutletsStatus = false;
        draft.outletsStatus = action.payload.data;
        break;
      case GET_OUTLET_LOCATION_STATUS_ERROR:
        draft.loadingOutletsStatus = false;
        break;
      case GET_OUTLET_LOCATION_DETAILS:
        if (draft.outletDetails?.locationId === action.payload.outletId) {
          break;
        }
        draft.loadingOutletDetails = true;
        break;
      case GET_OUTLET_LOCATION_DETAILS_SUCCESS:
        if (action.payload.data) {
          const pressureChambers = mapOutletPressureChambers(
            action.payload.data.pressureChambers,
            action.payload.data.locationName,
            action.payload.data.locationCountryCode
          );
          const beerDrives = mapOutletBeerDrives(
            action.payload.data.beerDrives,
            action.payload.data.locationName
          );
          const steelKegUnits = mapOutletSteelKegUnits(
            action.payload.data.steelKegUnits,
            action.payload.data.locationName
          );
          draft.loadingOutletDetails = false;
          draft.outletDetailsError = false;

          const controlUnits = action.payload.data.controlUnits.sort(
            (a, b) => a.position - b.position
          );
          draft.outletDetails = {
            ...action.payload.data,
            controlUnits,
            pressureChambers,
            beerDrives,
            steelKegUnits
          };
        } else {
          draft.loadingOutletDetails = false;
          draft.outletDetails = null;
        }
        break;
      case GET_OUTLET_LOCATION_DETAILS_ERROR:
        draft.outletDetails = null;
        draft.outletDetailsError = true;
        draft.loadingOutletDetails = false;
        break;
      case SET_OUTLET_FAVOURITE:
        draft.outlets = state.outlets.map(o =>
          o.id !== action.payload.outletId ? o : { ...o, favourite: action.payload.favourite }
        );
        break;
      case SET_OUTLET_FAVOURITE_ERROR:
        draft.outlets = state.outlets.map(o =>
          o.id !== action.payload.outletId ? o : { ...o, favourite: !action.payload.favourite }
        );
        break;
      default:
        break;
    }
  });

export default reducer;
