import { DEFAULT_LANG } from "../../constants";
import { IEntries } from "./index";

export function mapAvailableEmailDomains(entries: IEntries) {
  const emailDomains: any = entries.valueList.ordered.find(
    f => f.fields.name[DEFAULT_LANG] === "emailDomains"
  );
  const emails = emailDomains ? emailDomains.fields.values[DEFAULT_LANG] : [];

  return {
    emailDomains: emails[0].fields.contentLong[DEFAULT_LANG]
  };
}
