import { useCallback, useContext, useEffect, useState } from "react";
import { NotificationContext } from "@ddm-design-system/notification";
import { useLocation } from "react-router";
import { AnalyticsContext } from "../services/analytics";
import AuthContext from "../components/auth/AuthContext";
import useContent from "./useContent";

export function useParamTokens() {
  const analytics = useContext(AnalyticsContext);
  const auth = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const { managerAppLogin: content } = useContent();
  const location = useLocation();
  const [recoveryToken, setRecoveryToken] = useState<string | null>("");
  const [verifyToken, setVerifyToken] = useState<string | null>("");
  const [resetApp, setResetApp] = useState<string>("");
  const [azureToken, setAzureToken] = useState<string | null>(null);

  // validate invitation token within manager app ( invite staff members )
  // if valid -> login with isFirstLogin
  // if not valid -> notification error
  const validateInvitationToken = useCallback(
    (urlToken = "") => {
      const run = async () => {
        try {
          const userId = await auth.validateToken(urlToken);
          analytics.logEvent("VALIDATE TOKEN", urlToken);
          analytics.setAuthenticatedUserContext(userId);
        } catch {
          notificationContext.addNotification({
            iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
            displayDuration: 6000,
            text: content.manager_app_invalid_token
          });
        }
      };
      run();
    },
    [analytics, auth, content.manager_app_invalid_token, notificationContext]
  );

  // validate recovery password token
  // if valid -> show recovery password page
  // if not valid -> notification error
  const validateRecoveryToken = useCallback(
    (rToken = "", resetApp = "manager_app") => {
      const run = async () => {
        try {
          await auth.validateRecoveryToken(rToken);
          analytics.logEvent("VALIDATE RECOVERY TOKEN", rToken);
          setRecoveryToken(rToken);
          setResetApp(resetApp);
        } catch (e) {
          notificationContext.addNotification({
            iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
            displayDuration: 6000,
            text: content.manager_app_invalid_token
          });
        }
      };
      run();
    },
    [analytics, auth, content.manager_app_invalid_token, notificationContext]
  );

  // validate verify account token
  // if valid -> show verify account page
  // if not valid -> notification error
  const validateVerifyToken = useCallback(
    (rToken = "", resetApp = "manager_app") => {
      const run = () => {
        try {
          analytics.logEvent("VALIDATE VERIFY TOKEN", rToken);
          setVerifyToken(rToken);
          setResetApp(resetApp);
        } catch (e) {
          notificationContext.addNotification({
            iconProps: { name: "Warning", fill: "var(--color-alert-alert100)" },
            displayDuration: 6000,
            text: content.manager_app_invalid_token
          });
        }
      };
      run();
    },
    [analytics, auth, content.manager_app_invalid_token, notificationContext]
  );

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);

    // sets password reset form
    const recoveryTokenVar = params.get("passwordresettoken");
    if (recoveryTokenVar) {
      validateRecoveryToken(recoveryTokenVar || "", params.get("app"));
      return;
    }

    // sets verify acccount form
    const verifyTokenVar = params.get("verifyaccounttoken");
    if (verifyTokenVar) {
      validateVerifyToken(verifyTokenVar || "", params.get("app"));
      return;
    }

    // invitation token to create new account
    const urlToken = params.get("invitationtoken");
    if (urlToken) {
      validateInvitationToken(urlToken);
    }

    // azure invitation token for sales rep account.
    // Save it in local storage so it knows he should link
    // instead of just try login with adal on next azure refresh
    const aToken = params.get("azureinvitationtoken");
    if (aToken) {
      if (!auth.adal?.getToken()) {
        // setAzureToken(aToken);
        localStorage.setItem("ddm-azureinvitationtoken", aToken);
        auth.adal?.login();
        // validateAzureInvitationToken(azureToken);
      } else {
        setAzureToken(aToken);
      }
    } else {
      setAzureToken(null);
      localStorage.removeItem("ddm-azureinvitationtoken");
    }
  }, [location]);

  return {
    recoveryToken,
    resetApp,
    verifyToken,
    azureToken
  };
}

export default useParamTokens;
