import React, { forwardRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import * as d3 from "d3";
import numeral from "numeral";
import { EInsightsDetailType, IInsightsDetail } from "../../../store/insights/types";
import { getBeverages } from "../../../store/beers/selectors";
import useContent from "../../../hooks/useContent";
import { getCurrentLanguage } from "../../../store/content/selectors";
import Time from "../../../lib/Time/Time";

interface IProps {
  details: IInsightsDetail[];
}

const InsightDetails = forwardRef<HTMLDivElement, IProps & { [key: string]: unknown }>(
  ({details}, ref) => {

      const beverages = useSelector(getBeverages);
      const {
          managerAppInsights: content,
          languageInfo: languageInfoContent,
          units: {timeStrings: unitsContent}
      } = useContent();
      const zeroVolDaysUp: number[] = [];
      const zeroVolDays: number[] = [];

      const detailsFiltered = useMemo(() => {
          return details.filter(d => {
              if (d.detailType === EInsightsDetailType.DAYS_WITH_ZERO_CONSUMPTION) {
                  if (d.customFields?.currentWeekHasZeroVol) {
                      zeroVolDays.push(d.customFields?.day);

                      if (zeroVolDays.length > 1) {
                          return;
                      }
                  } else {
                      zeroVolDaysUp.push(d.customFields?.day);
                      if (zeroVolDaysUp.length > 1) {
                          return;
                      }
                  }
              }
              return d;
          });
      }, [details, zeroVolDaysUp, zeroVolDays]);

      const currentLanguage = useSelector(getCurrentLanguage);
      const language: any = languageInfoContent.find(l => l.code === currentLanguage);

      const mapDetailValues = (detail: IInsightsDetail) => {
          const positive = detail.customFields?.variationVol > 0;
          switch (detail.detailType) {
              case EInsightsDetailType.PORTFOLIO_CHANGE:
                  return {
                      message: content.manager_app_insights_details_portfolio_change,
                      positive: true
                  };
              case EInsightsDetailType.BRAND_WITH_BIGGEST_CHANGE:
              case EInsightsDetailType.CS_MIX_BRAND_WITH_BIGGEST_CHANGE: {
                  const beverage = beverages.find(beer => beer.id === detail.customFields?.beverage);
                  let variation = detail.customFields?.variationVol || 0;
                  variation = !positive ? variation * -1 : variation;
                  const text = positive ?
                    content.manager_app_insights_details_brand_with_biggest_change_up :
                    content.manager_app_insights_details_brand_with_biggest_change_down;
                  return {
                      message: text.replace("%BRAND%", `${beverage?.name}`).replace("%VOL%", `${numeral((variation) / 1000).format("0")}L`),
                      positive
                  };
              }
              case EInsightsDetailType.DAYS_WITH_ZERO_CONSUMPTION: {
                  const zeroConsumption = detail.customFields?.currentWeekHasZeroVol;
                  const text = zeroConsumption ?
                    content.manager_app_insights_details_days_with_zero_consumption_zero :
                    content.manager_app_insights_details_days_with_zero_consumption_up;
                  const weekdays = zeroConsumption ? zeroVolDays.map(d => language.d3TimeFormat.days[d]) :
                    zeroVolDaysUp.map(d => language.d3TimeFormat.days[d]);
                  return {
                      message: text.replace("%WEEKDAY%", weekdays.join(", ")),
                      positive
                  };
              }
              case EInsightsDetailType.DAY_WITH_BIGGEST_CHANGE:
              case EInsightsDetailType.CS_MIX_DAY_WITH_BIGGEST_CHANGE: {
                  const text = (detail.detailType === EInsightsDetailType.DAY_WITH_BIGGEST_CHANGE ? (
                      positive ?
                        content.manager_app_insights_details_day_with_biggest_change_up :
                        content.manager_app_insights_details_day_with_biggest_change_down
                    ) : (
                      positive ?
                        content.manager_app_insights_details_cs_mix_day_with_biggest_change_up :
                        content.manager_app_insights_details_cs_mix_day_with_biggest_change_down
                    )
                  );
                  return {
                      message: text.replace("%WEEKDAY%", language.d3TimeFormat.days[detail.customFields?.day]),
                      positive
                  };
              }
              case EInsightsDetailType.HOUR_WITH_HIGH_CONSUMPTION: {
                  const time = new Time(new Date());
                  time.set({hour: detail.customFields?.hour, minute: 0});
                  const formatTime = d3.timeFormat("%H:%M");
                  const period = `${formatTime(time.toJSDate())}-${formatTime(time.add({hour: 1}).toJSDate())}`;
                  return {
                      message: content.manager_app_insights_details_hour_with_high_consumption.replace("%INTERVAL%", period),
                      positive
                  };
              }
              case EInsightsDetailType.TIMEZONE_WITH_BIGGEST_CHANGE:
              case EInsightsDetailType.CS_MIX_TIMEZONE_WITH_BIGGEST_CHANGE: {
                  const timezone = detail.customFields?.timezone;
                  const text = (detail.detailType === EInsightsDetailType.TIMEZONE_WITH_BIGGEST_CHANGE ? (
                      positive ?
                        content.manager_app_insights_details_timezone_with_biggest_change_up :
                        content.manager_app_insights_details_timezone_with_biggest_change_down
                    ) : (
                      positive ?
                        content.manager_app_insights_details_cs_mix_timezone_with_biggest_change_up :
                        content.manager_app_insights_details_cs_mix_timezone_with_biggest_change_down
                    )
                  );
                  const period = timezone === "morning" ? unitsContent.unit_morning : timezone === "afternoon" ? unitsContent.unit_afternoon : unitsContent.unit_night;
                  return {
                      message: text.replace("%PERIOD%", period),
                      positive
                  };
              }

              default:
                  break;
          }
      };
      return (
        <div data-test-id="insight-details" className="flex flex-col space-y-xs">
          {detailsFiltered.map(d => {
                const detailValues = mapDetailValues(d);
                return (
                  <div key={d.id} ref={ref} className="flex flex-row text-white">
                    <div className="pr-sm mt-xxs">
                      {/* As requested by the design team the logic of the icons was removed to test a better approach */}
                      {/* { detailValues?.positive ? <Icon name="Ok" /> : <Icon name="Warning" className="text-warning-warning100 fill-current" /> } */}
                      <Icon name="Warning" className="text-white fill-current" />
                    </div>
                    <div>{(detailValues?.message)}</div>
                  </div>
                );
            })}
        </div>
      );
  }
);

export default InsightDetails;
