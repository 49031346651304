import React, {useCallback} from "react";
import {Icon} from "@ddm-design-system/icon";
import {Body} from "@ddm-design-system/typography";
import classnames from "classnames";
import {IOutlet} from "../../../store/outlet/types";
import useContent from "../../../hooks/useContent";
import "./outlet-picker.scss";
import InsightsIcon from "../insights/InsightsIcon";

interface IProps {
  outlet?: IOutlet;
  selected: boolean;
  onClick: () => void;
  onFavourite?: (outletId: string, favourite: boolean) => void;
  insightsLength?: number;
  inside?: number;
}

const OutletPickerItem = React.forwardRef<HTMLDivElement, IProps>(
  ({ outlet, selected, onClick, onFavourite, insightsLength = 0, inside = 0 }, ref) => {
    const { managerAppCommon: content } = useContent();

    const handlePressEnterKey = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          onClick();
        }
      },
      [onClick]
    );

    return (
      <div
        ref={ref}
        className={classnames("outlet-item-container", {
          selected,
          "all-outlets": !outlet
        })}
        data-test-id="outlet-item-container"
        tabIndex={0} // make items focusable through sequential keyboard navigation (w/ tab and arrow key)
        title={outlet?.name || content.all_outlets}
        onClick={onClick}
        onKeyPress={handlePressEnterKey}
      >
        <Body className="outlet-item" style={{ paddingLeft: inside * 24 }}>
          {outlet?.name || content.all_outlets}
        </Body>

        {onFavourite && outlet && (
          <Icon
            className={`favourite-icon ${outlet.favourite && "selected"}`}
            data-test-id="outlet-item-favourite-icon"
            name={outlet.favourite ? "StarFilled" : "Star"}
            onClick={e => {
              onFavourite(outlet.id, !outlet.favourite);
              e.stopPropagation();
            }}
          />
        )}
        <InsightsIcon className="xs:!relative" insightsLength={insightsLength} />
      </div>
    );
  }
);

export default OutletPickerItem;
