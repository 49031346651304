import React, { useEffect, useState } from "react";
import { Description } from "@ddm-design-system/typography";
import { SecondaryButton } from "@ddm-design-system/button";
import { ExpandablePanel } from "@ddm-design-system/expandable-panel";
import { MultipleDropdown } from "@ddm-design-system/dropdown";
import { useSelector } from "react-redux";
import classnames from "classnames";
import {
  ICompressor,
  IControlUnit,
  ICoolingUnit,
  IPressureChamber,
  ISteelKegUnit
} from "../../store/installation/types";
import PressureChamberCard from "./PressureChamberCard";
import useContent from "../../hooks/useContent";
import { ILine } from "../../store/outlet/types";
import ErrorList from "./ErrorList";
import CoolingUnitCard from "./CoolingUnitCard";
import ControlUnitCard from "./ControlUnitCard";
import { isNCUOutlet } from "../../store/installation/selectors";
import CompressorCard from "./CompressorCard";
import SteelKegUnitCard from "./SteelKegUnitCard";

interface IProps {
  outletId: string;
  cleaningDueDate?: string;
  setSelectedPC: (pressureChamber: { pressureChamber: IPressureChamber }) => void | undefined;
  setSelectedCU: (controlUnit: { controlUnit: IControlUnit }) => void | undefined;
  setSelectedSK: (steelKegUnit: { steelKegUnit: ISteelKegUnit }) => void | undefined;
  line: ILine;
  initialExpanded: boolean;
}

export const InstallationLine: React.FC<IProps> = ({
  outletId,
  cleaningDueDate,
  setSelectedPC,
  setSelectedCU,
  setSelectedSK,
  line,
  initialExpanded
}) => {
  const { managerAppCommon: content } = useContent();
  const [unitsFilter, setUnitsFilter] = useState<string>("all");
  const [errorsFilter, setErrorsFilter] = useState<any[]>([]);
  const [filteredPcs, setFilteredPcs] = useState<IPressureChamber[] | undefined>([]);
  const [filteredBds, setFilteredBds] = useState<IPressureChamber[] | undefined>([]);
  const [filteredSks, setFilteredSks] = useState<ISteelKegUnit[] | undefined>([]);
  const [filteredControlUnits, setFilteredControlUnits] = useState<IControlUnit[] | undefined>([]);
  const [filteredCoolingUnits, setFilteredCoolingUnits] = useState<ICoolingUnit[] | undefined>([]);
  const [filteredCompressors, setFilteredCompressors] = useState<ICompressor[] | undefined>([]);
  const isNCUOnly = useSelector(isNCUOutlet);

  const errorsFilterItems = [
    { id: "Ok", text: content.common_ok },
    { id: "Warning", text: content.common_warning },
    { id: "Error", text: content.common_error },
    { id: "Offline", text: content.common_offline }
  ];

  const filterPcsFunc = (pc: IPressureChamber) => {
    if (
      errorsFilter?.find(f => f.id === "Ok") &&
      !pc.offline &&
      !pc.isOpen &&
      pc.dangerTags === 0 &&
      pc.warningTags === 0
    )
      return true;
    if (errorsFilter?.find(f => f.id === "Warning") && pc.status === "warning") return true;
    if (errorsFilter?.find(f => f.id === "Error") && pc.status === "danger") return true;
    if (errorsFilter?.find(f => f.id === "Offline") && pc.offline) return true;
    return false;
  };

  const filterSksFunc = (pc: ISteelKegUnit) => {
    if (
      errorsFilter?.find(f => f.id === "Ok") &&
      !pc.offline &&
      pc.dangerTags === 0 &&
      pc.warningTags === 0
    )
      return true;
    if (errorsFilter?.find(f => f.id === "Warning") && pc.status === "warning") return true;
    if (errorsFilter?.find(f => f.id === "Error") && pc.status === "danger") return true;
    if (errorsFilter?.find(f => f.id === "Offline") && pc.offline) return true;
    return false;
  };

  const filterControlUnitsFunc = (cu: IControlUnit) => {
    if (errorsFilter?.find(f => f.id === "Ok") && cu.alive && !cu.softFault && !cu.hardFault)
      return true;
    if (errorsFilter?.find(f => f.id === "Error") && (cu.softFault || cu.hardFault)) return true;
    if (errorsFilter?.find(f => f.id === "Offline") && !cu.alive) return true;
    return false;
  };

  const filterCoolingUnitsFunc = (cu: ICoolingUnit) => {
    if (
      errorsFilter?.find(f => f.id === "Ok") &&
      cu.alive &&
      !cu.hasTempError &&
      !cu.faulty &&
      cu.sensorConnected
    )
      return true;
    if (
      errorsFilter?.find(f => f.id === "Error") &&
      (cu.hasTempError || cu.faulty || !cu.sensorConnected)
    )
      return true;
    if (errorsFilter?.find(f => f.id === "Offline") && !cu.alive) return true;
    return false;
  };

  const filterCompressorsFunc = (cu: ICompressor) => {
    if (errorsFilter?.find(f => f.id === "Ok") && cu.alive) return true;
    if (errorsFilter?.find(f => f.id === "Error")) return true;
    if (errorsFilter?.find(f => f.id === "Offline") && !cu.alive) return true;
    return false;
  };

  useEffect(() => {
    if (errorsFilter.length > 0) {
      setFilteredPcs(line.pressureChambers?.filter(filterPcsFunc));
      setFilteredBds(line.beerDrives?.filter(filterPcsFunc));
      setFilteredSks(line.steelKegUnits?.filter(filterSksFunc));
      setFilteredControlUnits(line.controlUnits?.filter(filterControlUnitsFunc));
      setFilteredCoolingUnits(line.coolingUnits?.filter(filterCoolingUnitsFunc));
      setFilteredCompressors(line.compressors?.filter(filterCompressorsFunc));
    } else {
      setFilteredPcs(line.pressureChambers);
      setFilteredBds(line.beerDrives);
      setFilteredSks(line.steelKegUnits);
      setFilteredControlUnits(line.controlUnits);
      setFilteredCoolingUnits(line.coolingUnits);
      setFilteredCompressors(line.compressors);
    }
  }, [errorsFilter, line]);

  return (
    // @ts-ignore
    <ExpandablePanel
      key={line.index}
      title={
        isNCUOnly
          ? ""
          : line.index !== 0
          ? content.common_compressor_line.concat(` ${line.index?.toString() || ""}`)
          : content.common_other_components
      }
      disabled={isNCUOnly}
      className={classnames("rounded-10 !border-0 md:p-md xs:p-md", {
        "bg-grey-grey25 mt-lg": !isNCUOnly,
        "bg-white -mt-xl": isNCUOnly
      })}
      initialExpanded={isNCUOnly ? true : initialExpanded}
      renderHeaderDetails={() => (isNCUOnly ? <></> : <ErrorList errors={line.errors} />)}
    >
      <div
        className="flex flex-col w-full text-left px-lg xs:px-0"
        data-test-id="installation-line"
      >
        {!isNCUOnly && (
          <div className="flex flex-row justify-between md:flex-col md:pb-md">
            <div
              className="flex flex-row pb-lg overflow-auto scrollbar-hide"
              data-test-id="components-filter"
            >
              <SecondaryButton
                className={`${
                  unitsFilter === "all" && "!border-0 underline !py-0"
                } !px-xs !rounded-10`}
                onClick={() => setUnitsFilter("all")}
              >
                <span className="whitespace-nowrap">{content.common_all_units}</span>
              </SecondaryButton>
              {line?.controlUnits && line?.controlUnits.length > 0 && (
                <SecondaryButton
                  className={`${
                    unitsFilter === "controlUnits" && "!border-0 underline !py-0"
                  } !px-xs !rounded-10`}
                  onClick={() => setUnitsFilter("controlUnits")}
                >
                  <span className="whitespace-nowrap">{content.common_control_units}</span>
                </SecondaryButton>
              )}
              {line?.pressureChambers && line?.pressureChambers.length > 0 && (
                <SecondaryButton
                  className={`${
                    unitsFilter === "pressureChambers" && "!border-0 underline !py-0"
                  } !px-xs !rounded-10`}
                  onClick={() => setUnitsFilter("pressureChambers")}
                >
                  <span className="whitespace-nowrap">{content.common_pressure_chambers}</span>
                </SecondaryButton>
              )}
              {line?.coolingUnits && line?.coolingUnits.length > 0 && (
                <SecondaryButton
                  className={`${
                    unitsFilter === "coolingUnits" && "!border-0 underline !py-0"
                  } !px-xs !rounded-10`}
                  onClick={() => setUnitsFilter("coolingUnits")}
                >
                  <span className="whitespace-nowrap">{content.common_cooling_units}</span>
                </SecondaryButton>
              )}
              {line?.compressors && line?.compressors.length > 0 && (
                <SecondaryButton
                  className={`${
                    unitsFilter === "compressors" && "!border-0 underline !py-0"
                  } !px-xs !rounded-10`}
                  onClick={() => setUnitsFilter("compressors")}
                >
                  <span className="whitespace-nowrap">{content.common_ecolabs}</span>
                </SecondaryButton>
              )}
              {line?.beerDrives && line?.beerDrives.length > 0 && (
                <SecondaryButton
                  className={`${
                    unitsFilter === "beerTanks" && "!border-0 underline !py-0"
                  } !px-xs !rounded-10`}
                  onClick={() => setUnitsFilter("beerTanks")}
                >
                  <span className="whitespace-nowrap">{content.common_beer_tanks}</span>
                </SecondaryButton>
              )}
              {line?.steelKegUnits && line?.steelKegUnits.length > 0 && (
                <SecondaryButton
                  className={`${
                    unitsFilter === "steelKegs" && "!border-0 underline !py-0"
                  } !px-xs !rounded-10`}
                  onClick={() => setUnitsFilter("steelKegs")}
                >
                  <span className="whitespace-nowrap">{content.common_steel_kegs}</span>
                </SecondaryButton>
              )}
            </div>
            <div data-test-id="components-state-filter">
              {/* @ts-ignore */}
              <MultipleDropdown
                label={`${content.common_filters}:`}
                items={errorsFilterItems}
                selectedItems={errorsFilter}
                className="!bg-transparent ddl-components-state"
                onItemsSelected={(item: any) => {
                  setErrorsFilter(item);
                }}
                placeholder={content.common_all}
              />
            </div>
          </div>
        )}
        {(filteredPcs || []).length === 0 &&
          (filteredControlUnits || []).length === 0 &&
          (filteredCoolingUnits || []).length === 0 &&
          (filteredCompressors || []).length === 0 &&
          (filteredSks || []).length === 0 &&
          (filteredBds || []).length === 0 && (
            <div className="flex w-full py-lg justify-center">
              <div>{content.no_components_found}</div>
            </div>
          )}
        {filteredControlUnits &&
          filteredControlUnits.length > 0 &&
          (unitsFilter === "all" || unitsFilter === "controlUnits") && (
            <div className="flex flex-col" data-test-id="components-controlUnits">
              <Description className="text-grey-grey100 pb-lg">
                {content.common_control_units}
              </Description>
              {filteredControlUnits.map(cu => (
                <ControlUnitCard
                  key={cu.id}
                  controlUnit={cu}
                  outletId={outletId}
                  cleaningDueDate={cleaningDueDate}
                  setSelectedCU={setSelectedCU}
                />
              ))}
            </div>
          )}
        {filteredCompressors &&
          filteredCompressors.length > 0 &&
          (unitsFilter === "all" || unitsFilter === "compressors") && (
            <div className="flex flex-col" data-test-id="components-compressors">
              <Description className="text-grey-grey100 pb-lg">
                {content.common_ecolabs}
              </Description>
              <div className="flex flex-wrap gap-xxl mb-xxl">
                {filteredCompressors.map(cu => (
                  <CompressorCard
                    id="compressor_card"
                    componentId={cu.id}
                    key={cu.id}
                    compressor={cu}
                  />
                ))}
              </div>
            </div>
          )}
        {filteredPcs &&
          filteredPcs.length > 0 &&
          (unitsFilter === "all" || unitsFilter === "pressureChambers") && (
            <div className="flex flex-col" data-test-id="components-pressureChambers">
              <Description className="text-grey-grey100 pb-lg">
                {content.common_pressure_chambers}
              </Description>
              <div className="flex flex-wrap gap-xxl mb-xxl xs:gap-xs">
                {filteredPcs?.map(pc => (
                  <PressureChamberCard key={pc.id} pressureChamber={pc} onClick={setSelectedPC} />
                ))}
              </div>
            </div>
          )}
        {filteredBds &&
          filteredBds.length > 0 &&
          (unitsFilter === "all" || unitsFilter === "beerTanks") && (
            <div className="flex flex-col" data-test-id="components-beerDrives">
              <Description className="text-grey-grey100 pb-lg">
                {content.common_beer_tanks}
              </Description>
              <div className="flex flex-wrap gap-xxl mb-xxl">
                {filteredBds.map(bd => (
                  <PressureChamberCard key={bd.id} pressureChamber={bd} onClick={setSelectedPC} />
                ))}
              </div>
            </div>
          )}

        {filteredSks &&
          filteredSks.length > 0 &&
          (unitsFilter === "all" || unitsFilter === "steelKegs") && (
            <div className="flex flex-col" data-test-id="components-steelKegs">
              <Description className="text-grey-grey100 pb-lg">
                {content.common_steel_kegs}
              </Description>
              <div className="flex flex-wrap gap-xxl mb-xxl">
                {filteredSks.map(sk => (
                  <SteelKegUnitCard key={sk.id} steelKegUnit={sk} onClick={setSelectedSK} />
                ))}
              </div>
            </div>
          )}
        {filteredCoolingUnits &&
          filteredCoolingUnits.length > 0 &&
          (unitsFilter === "all" || unitsFilter === "coolingUnits") && (
            <div className="flex flex-col" data-test-id="components-coolingUnits">
              <Description className="text-grey-grey100 pb-lg">
                {content.common_cooling_units}
              </Description>
              <div className="flex flex-wrap gap-xxl mb-xxl">
                {filteredCoolingUnits.map(cu => (
                  <CoolingUnitCard key={cu.id} coolingUnit={cu} />
                ))}
              </div>
            </div>
          )}
      </div>
    </ExpandablePanel>
  );
};
