import { ICountry } from "../beers/types";

export const GET_USERS = "users/GET_USERS";
export const GET_USERS_SUCCESS = "users/GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "users/GET_USERS_ERROR";
export const SET_PERMISSIONS = "users/SET_PERMISSIONS";
export const SET_PERMISSIONS_SUCCESS = "users/SET_PERMISSIONS_SUCCESS";
export const SET_PERMISSIONS_ERROR = "users/SET_PERMISSIONS_ERROR";

export enum EUserRankNames {
  Staff = "Staff",
  Owner = "Owner",
  Reader = "Reader",
  SalesRep = "Sales Rep"
}

export enum ERole {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_SUPERVISOR = "ROLE_SUPERVISOR",
  ROLE_SUB_SUPERVISOR = "ROLE_SUB_SUPERVISOR",
  ROLE_PURCHASING_GROUP = "ROLE_PURCHASING_GROUP",
  ROLE_DISTRIBUTOR = "ROLE_DISTRIBUTOR",
  ROLE_SALES_REP = "ROLE_SALES_REP",
  ROLE_OWNER = "ROLE_OWNER",
  ROLE_STAFF = "ROLE_STAFF",
  ROLE_READER = "ROLE_READER",
  ROLE_IOT = "ROLE_IOT"
}

export interface IUser {
  id: string;
  createDateTime: string;
  name?: string;
  username: string;
  rank: string;
  role: ERole;
  locationIds?: string[];
  country?: ICountry;
  emailDigestFrequencies: string[];
  azure: boolean;
}

export interface ISetPermissionsParams {
  outletId: string;
  username: string;
  name?: string;
  rank: EUserRankNames;
  add?: boolean;
}

export interface IOutletUsers {
  outletId: string;
  outletName?: string;
  users: IUser[];
}

export interface IUsersState {
  users: IUser[];
  requestingUsers: boolean;
  requestingUsersError: boolean;
}

export interface IGetUsers {
  type: typeof GET_USERS;
}

export interface IGetUsersSuccess {
  type: typeof GET_USERS_SUCCESS;
  payload: IUser[];
}

export interface IGetUsersError {
  type: typeof GET_USERS_ERROR;
}

export interface ISetPermissions {
  type: typeof SET_PERMISSIONS;
  payload: ISetPermissionsParams;
}

export interface ISetPermissionsSuccess {
  type: typeof SET_PERMISSIONS_SUCCESS;
}

export interface ISetPermissionsError {
  type: typeof SET_PERMISSIONS_ERROR;
}

export interface IRoleInOutlet {
  outletId: string;
  role: string;
}

export type UsersActionTypes =
  | IGetUsers
  | IGetUsersSuccess
  | IGetUsersError
  | ISetPermissions
  | ISetPermissionsSuccess
  | ISetPermissionsError;
