import React from "react";
import { Description } from "@ddm-design-system/typography";
import SVGTapPressure from "./assets/tap-pressure";
import { IControlUnit } from "../../../store/installation/types";
import useContent from "../../../hooks/useContent";

export interface IPressureTestProps {
  controlUnit: IControlUnit;
}

const PressureTest: React.FC<IPressureTestProps> = () => {
  const { managerAppControlUnit: content } = useContent();

  return (
    <div className="flex flex-col gap-md items-center justify-center h-full">
      <SVGTapPressure />
      <Description>{content.manager_app_cu_step_pressure_test_desc}</Description>
    </div>
  );
};

export default PressureTest;
