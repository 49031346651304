import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "@ddm-design-system/icon";
import { Body, Description } from "@ddm-design-system/typography";
import classnames from "classnames";
import "./outlet-picker.scss";
import useContent from "../../../hooks/useContent";
import { IOutletPickerDistributorItem, IOutletPickerPGItem } from "./OutletPicker";

interface IProps {
  purchasingGroup?: IOutletPickerPGItem;
  selected: boolean;
  onClick: () => void;
  renderChildItem?: (d: IOutletPickerDistributorItem) => void;
  selectedOutletId?: string;
}

const PurchasingGroupItem = React.forwardRef<HTMLDivElement, IProps>(
  ({ purchasingGroup, selected, onClick, renderChildItem, selectedOutletId }, ref) => {
    const { managerAppCommon: content } = useContent();
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
      if (
        purchasingGroup?.distributors?.some(
          o => o.id === selectedOutletId || o.outlets?.some(o2 => o2.id === selectedOutletId)
        )
      ) {
        setOpen(true);
      }
    }, [purchasingGroup, selectedOutletId]);

    const handlePressEnterKey = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          onClick();
        }
      },
      [onClick]
    );

    return (
      <>
        <div
          ref={ref}
          className={classnames("relative outlet-item-container purchasing-group group", {
            selected
          })}
          data-test-id="outlet-item-container"
          tabIndex={0} // make items focusable through sequential keyboard navigation (w/ tab and arrow key)
          title={purchasingGroup?.name}
          onClick={onClick}
          onKeyPress={handlePressEnterKey}
        >
          <Body className="outlet-item group-hover:underline">{purchasingGroup?.name}</Body>
          <Icon
            className="absolute right-xs p-md transition-all cursor-pointer rounded-[50%] hover:bg-grey-grey25"
            name="ChevronDown"
            style={{
              transform: open ? "rotateZ(180deg)" : ""
            }}
            onClick={e => {
              setOpen(!open);
              e.stopPropagation();
            }}
          />
        </div>

        <div
          className={classnames("transition-all overflow-hidden", {
            "max-h-[1px] opacity-0 pointer-events-none ": !open,
            "max-h-auto opacity-100 pointer-events-initial": open
          })}
        >
          <div className="text-grey-grey100 mb-xs pl-[40px]">
            <Description>{content.common_distributors}</Description>
          </div>
          {purchasingGroup?.distributors?.map(d => (
            <>{renderChildItem?.(d)}</>
          ))}
        </div>
      </>
    );
  }
);

export default PurchasingGroupItem;
