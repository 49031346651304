import { call, put, takeEvery } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IRequestAggregator, REQUEST_AGGREGATOR } from "../store/aggregator/types";
import { requestAggregatorError, requestAggregatorSuccess } from "../store/aggregator/actions";
import { IAggregatorService } from "../services/aggregator";

export function* requestAggregator(
  aggregatorService: IAggregatorService,
  action: IRequestAggregator
) {
  try {
    const result: any = yield call(
      [aggregatorService, aggregatorService.requestAggregatorForId],
      action.payload.id,
      action.payload.filter
    );
    yield put(requestAggregatorSuccess(action.payload.id, result.data ? result.data : result));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("aggregator error during process", e);
    yield put(requestAggregatorError(action.payload.id));
  }
}

export function* aggregatorWatcher(context: IAppContext) {
  yield takeEvery(REQUEST_AGGREGATOR, requestAggregator, context.aggregatorService);
}

export default aggregatorWatcher;
