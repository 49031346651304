import React from "react";
import "./bottombar.scss";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { getHasOutlets } from "../../../store/outlet/selectors";
import Routes from "../../../routes";
import useContent from "../../../hooks/useContent";
import BottombarNavigationButton from "./BottombarNavigationButton";

export const Bottombar: React.FC = props => {
  const { managerAppSidebar: content } = useContent();
  const noOutlets = !useSelector(getHasOutlets);

  return (
    <div data-test-id="bottombar" className={classnames("bottombar")} {...props}>
      <BottombarNavigationButton
        to={Routes.overviewAll}
        icon="Home"
        title={content.manager_app_overview}
      />
      <BottombarNavigationButton
        to={Routes.sales}
        icon="Sales"
        title={content.manager_app_sales}
        disabled={noOutlets}
      />
      <BottombarNavigationButton
        to={Routes.products}
        icon="Keg"
        title={content.manager_app_product}
        disabled={noOutlets}
      />
      <BottombarNavigationButton
        to={Routes.installations}
        icon="Installation"
        title={content.manager_app_installation}
        disabled={noOutlets}
      />
      <BottombarNavigationButton
        to={Routes.settings}
        icon="Settings"
        title={content.manager_app_settings}
      />
    </div>
  );
};
export default Bottombar;
