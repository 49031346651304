import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TertiaryButton } from "@ddm-design-system/button";
import { Divider } from "@ddm-design-system/divider";
import { Icon, IconNames } from "@ddm-design-system/icon";
import { Menu, MenuItem } from "@ddm-design-system/menu";
import { ModalTooltip } from "@ddm-design-system/modal";
import { MessageTooltip } from "@ddm-design-system/tooltip";
import { Body, ButtonText } from "@ddm-design-system/typography";

import useContent from "../../hooks/useContent";
import { getUser } from "../../store/profile/reducer";
import { setPermissions } from "../../store/users/actions";
import { ERole, EUserRankNames, IUser } from "../../store/users/types";

interface IProps {
  user: IUser;
  outletId: string;
  removeMe: () => void;
}

interface IAction {
  id: string;
  text: string;
  icon: IconNames;
  execute?: () => void;
  tooltipTitle?: string;
  tooltipDescription?: string;
}

const UserListItem: React.FC<IProps> = ({ user, outletId, removeMe }) => {
  const { managerAppSettings: contentSettings } = useContent();
  const dispatch = useDispatch();

  const me: IUser | null = useSelector(getUser);
  const himself = useMemo(() => me?.username === user.username, [user]);

  const possibleActions: { [key: string]: IAction } = useMemo(
    () => ({
      Staff: {
        id: "view",
        text: contentSettings.manager_app_settings_staff,
        icon: "Eye",
        tooltipTitle: contentSettings.manager_app_settings_staff,
        tooltipDescription: contentSettings.manager_app_settings_staff_description
      },
      RemoveFromOutlet: {
        id: "remove",
        text: contentSettings.manager_app_settings_remove_outlet,
        icon: "Delete",
        execute: () =>
          dispatch(
            setPermissions({
              outletId,
              rank: EUserRankNames.Staff,
              username: user.username,
              add: false
            })
          )
      },
      RemoveMeFromOutlet: {
        id: "remove",
        text: contentSettings.manager_app_settings_remove_outlet_me,
        icon: "Delete",
        execute: () => removeMe?.()
      },
      Admin: {
        id: "Admin",
        text: contentSettings.manager_app_settings_admin,
        icon: "User",
        tooltipTitle: contentSettings.manager_app_settings_admin_title,
        tooltipDescription: contentSettings.manager_app_settings_admin_description
      }
    }),
    [user, contentSettings, removeMe, outletId]
  );

  function getDefaultActions(role: string) {
    switch (role) {
      case ERole.ROLE_READER:
      case ERole.ROLE_STAFF:
        return [possibleActions.Staff];
      case ERole.ROLE_OWNER:
      case ERole.ROLE_ADMIN:
        return [possibleActions.Admin];
      default:
        return [];
    }
  }

  function getPossibleActionsAsStaff(role: string) {
    const actions = getDefaultActions(role);
    switch (role) {
      case ERole.ROLE_READER:
      case ERole.ROLE_STAFF:
        if (himself) {
          actions.push(possibleActions.RemoveMeFromOutlet);
        }
        break;
      default:
        break;
    }
    return actions;
  }

  function getPossibleActionsAsOwner(role: string) {
    const actions = getDefaultActions(role);
    switch (role) {
      case ERole.ROLE_READER:
      case ERole.ROLE_STAFF:
        actions.push(possibleActions.RemoveFromOutlet);
        break;
      default:
        break;
    }
    return actions;
  }

  function getPossibleActionsAsAdmin(role: string) {
    const actions = getDefaultActions(role);
    switch (role) {
      case ERole.ROLE_READER:
      case ERole.ROLE_STAFF:
        actions.push(possibleActions.RemoveFromOutlet);
        break;
      case ERole.ROLE_OWNER:
        actions.push(possibleActions.RemoveFromOutlet);
        break;
      default:
        break;
    }
    return actions;
  }

  function getActions(): IAction[] {
    const myRank: string = me?.role || ERole.ROLE_READER;
    const userRank = user?.role;
    switch (myRank) {
      case ERole.ROLE_READER:
      case ERole.ROLE_STAFF:
        return getPossibleActionsAsStaff(userRank);
      case ERole.ROLE_OWNER:
        return getPossibleActionsAsOwner(userRank);
      case ERole.ROLE_ADMIN:
        return getPossibleActionsAsAdmin(userRank);
      default:
        return getDefaultActions(userRank);
    }
  }

  const actions = getActions();

  const renderIndicator = useMemo(() => {
    const isAccountActive = user.name && user.name.length > 0;
    return (
      <MessageTooltip
        icon={isAccountActive ? "Ok" : "Error"}
        renderHeader={() => (
          <div
            className={`rounded-full h-xs my-2.5 mr-xs w-xs ${
              isAccountActive ? "bg-success-success100" : "bg-grey-grey50"
            }`}
          />
        )}
      >
        {isAccountActive
          ? contentSettings.manager_app_settings_account_active
          : contentSettings.manager_app_settings_account_inactive}
      </MessageTooltip>
    );
  }, [contentSettings, user]);

  const userInfo = useMemo(() => {
    const isAccountActive = user.name && user.name.length > 0;
    return isAccountActive ? (
      <>
        <div className="flex items-center">
          {renderIndicator}
          <Body className="mr-xs">{user.name}</Body>
        </div>
        <Body className="text-grey-grey100 md:mt-xxs">{user.username}</Body>
      </>
    ) : (
      <div className="flex items-center">
        {renderIndicator}
        <Body className="text-grey-grey100 md:mt-xxs">{user.username}</Body>
      </div>
    );
  }, [user, renderIndicator]);

  return actions.length > 0 ? (
    <div
      className="flex items-center justify-between flex-1 min-h-[72px] md:flex-col md:py-xxs"
      data-test-id="outlet-user-list-item"
    >
      <div className="flex items-center md:flex-col">{userInfo}</div>

      <div className="flex items-center">
        <ModalTooltip title={actions[0].tooltipTitle || ""} titleIcon="user">
          {actions[0].tooltipDescription}
        </ModalTooltip>
        <Menu
          direction="bottom-right"
          renderHeader={() => (
            <ButtonText>
              <div className="flex items-center">
                {actions[0].text}&nbsp;
                {actions.length > 1 && <Icon name="ChevronDown" />}
              </div>
            </ButtonText>
          )}
          disabled={actions.length <= 1}
        >
          {actions.slice(1).map((item, i) => (
            <React.Fragment key={item.id}>
              {actions.length > 1 && i === actions.length - 1 && <Divider />}
              <MenuItem>
                <TertiaryButton
                  icon={item.icon}
                  danger={item.id === "remove"}
                  onClick={() => {
                    if (item.execute) {
                      item.execute();
                    }
                  }}
                >
                  {item.text}
                </TertiaryButton>
              </MenuItem>
            </React.Fragment>
          ))}
        </Menu>
      </div>
    </div>
  ) : (
    <span />
  );
};

export default UserListItem;
