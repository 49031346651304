import React, { useCallback, useContext } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Card, CardBody, CardFooter } from "@ddm-design-system/card";
import { Icon } from "@ddm-design-system/icon";
import { Infoicon } from "@ddm-design-system/infoicon";
import { LinkButton } from "@ddm-design-system/button";
import { Description, SectionTitle } from "@ddm-design-system/typography";
import { useIsMobile } from "@ddm-design-system/hooks";
import styles from "./kegs_info.module.scss";
import Routes from "../../routes";
import useContent from "../../hooks/useContent";
import {
  getAllFaultyPressureChambers,
  getAllPressureChambersTags,
  getHasErrorComponents,
  getOutletFaultyPressureChambers,
  getOutletPressureChambersTags
} from "../../store/installation/selectors";
import InstallationStatusTooltip from "../installation/InstallationStatusTooltip";
import { getChosenOutletId } from "../../store/filter/selectors";
import { IOutletTags } from "../../store/installation/types";
import { showFaultySensorModal as showFaultySensorModalAction } from "../../store/modals/actions";
import { AnalyticsContext } from "../../services/analytics";

interface IProps extends RouteComponentProps {}

export const KegsInfo: React.FC<IProps> = (props: IProps) => {
  const {
    managerAppCommon: common,
    managerAppOverview: content,
    managerAppError: contentError
  } = useContent();
  const analytics = useContext(AnalyticsContext);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const selectedOutletId: string = useSelector(getChosenOutletId);
  const { okTags, warningTags, dangerTags, offlineTags }: IOutletTags = useSelector(
    selectedOutletId ? getOutletPressureChambersTags : getAllPressureChambersTags
  );

  const faultyPressureChambers = useSelector(
    selectedOutletId ? getOutletFaultyPressureChambers : getAllFaultyPressureChambers
  );

  const showFaultySensorModal = useCallback(() => {
    analytics.logEvent("FAULTY_SENSOR", "Kegs Info");
    dispatch(showFaultySensorModalAction(faultyPressureChambers.map(p => p.position)));
  }, [dispatch, faultyPressureChambers, analytics]);

  const hasErrorComponents: boolean = useSelector(getHasErrorComponents);

  function goToInstallation() {
    props.history.push(Routes.installations);
  }

  const renderTitle = () => (
    <div className={styles.kegsInfoTitle}>
      <SectionTitle>{common.manager_app_installation_status}</SectionTitle>
      <InstallationStatusTooltip />
    </div>
  );

  return content && common ? (
    <div className={styles.kegsInfo}>
      {renderTitle()}
      <Card className={classnames("card", "card-chart", styles.card)}>
        {hasErrorComponents && (
          <div className="card-error-message">
            <h3>{contentError.cardTitle}</h3>
            <p>{contentError.cardSubtitle}</p>
          </div>
        )}
        <CardBody className={styles.kegInfoBody}>
          <div className={styles.kegInfoItem}>
            <Infoicon
              title={common.common_ok}
              avatarOptions={{
                backgroundColor: "var(--color-success-success100)",
                children: <Icon name="Ok" fill="var(--color-white)" />
              }}
            >
              {`${okTags}`}
            </Infoicon>
          </div>

          <div className={styles.kegInfoItem}>
            <Infoicon
              title={common.common_warning}
              avatarOptions={{
                backgroundColor: "var(--color-warning-warning100)",
                children: <Icon name="Warning" fill="var(--color-white)" />
              }}
            >
              {`${warningTags}`}
            </Infoicon>
          </div>

          <div className={styles.kegInfoItem}>
            <Infoicon
              title={common.common_error}
              avatarOptions={{
                backgroundColor: "var(--color-alert-alert100)",
                children: <Icon name="Error" fill="var(--color-white)" />
              }}
            >
              {`${dangerTags}`}
            </Infoicon>
          </div>

          <div className={styles.kegInfoItem}>
            <Infoicon
              title={common.common_offline}
              avatarOptions={{
                backgroundColor: "var(--color-grey-grey50)",
                children: <Icon name="Offline" fill="var(--color-white)" />
              }}
            >
              {`${offlineTags}`}
            </Infoicon>
          </div>
          {!isMobile && (
            <LinkButton onClick={goToInstallation}>
              {content.manager_app_check_pressure_chambers}
            </LinkButton>
          )}
        </CardBody>
        {!!faultyPressureChambers.length && (
          <CardFooter className={styles.hardwareIssues}>
            <div className={styles.hardwareIssuesContainer}>
              <Icon name="Warning" fill="var(--color-alert-alert100" size={20} />
              <Description className={styles.hardwareIssuesMessage}>
                {content.manager_app_hardware_issues}
              </Description>
            </div>
            <LinkButton onClick={showFaultySensorModal} className={styles.knowMoreButton}>
              {common.know_more}
            </LinkButton>
          </CardFooter>
        )}
        {isMobile && (
          <CardFooter className={styles.kegsInfoFooter}>
            <LinkButton onClick={goToInstallation}>
              {content.manager_app_check_pressure_chambers}
            </LinkButton>
          </CardFooter>
        )}
      </Card>
    </div>
  ) : (
    <></>
  );
};

export default withRouter(KegsInfo);
