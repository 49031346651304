import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import numeral from "numeral";
import classnames from "classnames";
import { Icon } from "@ddm-design-system/icon";
import { Infoicon } from "@ddm-design-system/infoicon";
import { useInfiniteScrollList } from "@ddm-design-system/hooks";
import { useHistory } from "react-router";
import { getChosenOutlet, getChosenOutletId } from "../../store/filter/selectors";
import {
  getAllAvgKegsPerWeek,
  getAllMainstreamBrands,
  getAllSpecialityBrands,
  getAllTapsCount,
  getAvgKegsPerWeek,
  getHasError,
  getIsLoading,
  getMainstreamBrands,
  getSortedProducts,
  getSpecialityBrands,
  getTapCount
} from "../../store/product/selectors";
import useContent from "../../hooks/useContent";
import ProductSummaryGroup from "./ProductSummaryGroup";
import { ProductCard } from "./ProductCard";
import { IAppState } from "../../store";

const intersectionOptions = {
  root: document.querySelector("#root"),
  rootMargin: "0px 0px 100px 0px"
};

const INITIAL_OUTLET_NR_LIMIT = 10;

const Product: React.FC = () => {
  const { managerAppProduct: content } = useContent();
  const history = useHistory();

  const selectedOutletId = useSelector(getChosenOutletId);
  const selectedOutlet = useSelector(getChosenOutlet);

  const loading = useSelector(getIsLoading);
  const hasError = useSelector(getHasError);

  const products = useSelector(getSortedProducts);

  const mainstreamBrands = useSelector((state: IAppState) =>
    selectedOutletId
      ? getMainstreamBrands(state, selectedOutletId)
      : getAllMainstreamBrands(state)()
  );
  const specialityBrands = useSelector((state: IAppState) =>
    selectedOutletId
      ? getSpecialityBrands(state, selectedOutletId)
      : getAllSpecialityBrands(state)()
  );
  const tapCount = useSelector((state: IAppState) =>
    selectedOutletId ? getTapCount(state, selectedOutletId) : getAllTapsCount(state)
  );
  const kegsPerWeek = useSelector((state: IAppState) =>
    selectedOutletId ? getAvgKegsPerWeek(state, selectedOutletId) : getAllAvgKegsPerWeek(state)
  );
  const hasData = useMemo(
    () => !loading && products && Object.keys(products).length > 0,
    [loading, products]
  );

  useEffect(() => {
    if (selectedOutletId && !selectedOutlet?.type) {
      history.push(`/product/${selectedOutletId}`);
    }
  }, [selectedOutletId, selectedOutlet, history]);

  const outletsToShow = useMemo(() => {
    return Object.keys(products).map(outletId => {
      return (
        <div key={outletId}>
          <ProductCard outletId={outletId} />
        </div>
      );
    });
  }, [products]);

  const infiniteOutlets = useInfiniteScrollList(outletsToShow, {
    initialLimit: INITIAL_OUTLET_NR_LIMIT,
    intersectionOptions
  });

  const renderLoadingCards = useCallback(() => {
    const cards = [];
    for (let i = 0; i < 6; i++) {
      cards.push(
        <div
          key={i}
          className="rounded-[4px] box-border justify-between w-full relative
          cursor-pointer !bg-grey-grey25 opacity-0 min-h-[76px] xs:w-full xs:max-w-none animate-pulse-fade-in"
        />
      );
    }
    return cards;
  }, []);
  return (
    <div className="pt-lg">
      {hasError ? (
        <span>Error</span>
      ) : (
        <>
          <div
            className={classnames("flex flex-1 justify-between lg:flex-wrap xs:mt-lg lg:gap-y-lg", {
              loading
            })}
          >
            <ProductSummaryGroup
              title={content.manager_app_product_mainstream_brands}
              brands={mainstreamBrands}
              dataTestId="mainstream-brands"
            />
            <ProductSummaryGroup
              title={content.manager_app_product_speciality_brands}
              brands={specialityBrands}
              dataTestId="speciality-brands"
            />
            <Infoicon
              className={classnames(
                "border border-solid border-grey-greenish rounded-10 box-border py-md px-lg w-[calc(25%-(3*var(--space-unit-xxl)/4))] lg:w-[calc(50%-(var(--space-unit-lg)/2))] xs:w-full numberOfTaps",
                { loading }
              )}
              title={content.manager_app_product_number_of_taps}
              avatarOptions={{
                children: <Icon name="TapDouble" />
              }}
            >
              {loading ? "" : `${tapCount}`}
            </Infoicon>
            <Infoicon
              className={classnames(
                "border border-solid border-grey-greenish rounded-10 box-border py-md px-lg w-[calc(25%-(3*var(--space-unit-xxl)/4))] lg:w-[calc(50%-(var(--space-unit-lg)/2))] xs:w-full kegsPerWeek",
                { loading }
              )}
              title={content.manager_app_product_kegs_per_week}
              avatarOptions={{
                children: <Icon name="Keg" />
              }}
            >
              {loading ? "" : numeral(kegsPerWeek).format("0.[00]")}
            </Infoicon>
          </div>
          <div className="mt-lg" data-test-id="outlet-products">
            <div className="flex flex-col" data-test-id="products-list">
              <div className={!hasData ? "flex flex-col gap-xxl mb-xxl" : ""}>
                {!hasData ? renderLoadingCards() : infiniteOutlets}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
