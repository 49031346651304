import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Icon } from "@ddm-design-system/icon";
import { useIsMobile } from "@ddm-design-system/hooks";
import { useSelector } from "react-redux";
import useContent from "./useContent";
import { getContentfulError } from "../store/content/selectors";

interface IBGProps {
  media: string[];
  currentImage: number;
}

const BackgroundImage: React.FC<IBGProps> = ({ media, currentImage }) => (
  <img
    className="bg-image"
    src={`${media[currentImage]}?fm=jpg&fl=progressive`}
    alt="background img"
  />
);

// rotate between login page background pictures
export function useLoginPictures() {
  const { mediaContainer: media } = useContent();
  const isMobile = useIsMobile();
  const contentfulError = useSelector(getContentfulError);
  const [currentPicture, setCurrentPicture] = useState(0);

  useEffect(() => {
    if (!media) {
      return;
    }
    setCurrentPicture(Math.floor(Math.random() * media.length));

    let timeout: any;

    if (media && media.length > 0) {
      timeout = setInterval(() => {
        if (media) {
          setCurrentPicture(picture => (picture + 1) % media.length);
        }
      }, 15000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [media]);

  return !isMobile ? (
    <div className="login-background">
      <div className={classnames("login-background-image frame", { error: contentfulError })}>
        <SwitchTransition mode="in-out">
          <CSSTransition key="picture" classNames="image-anim" timeout={2000}>
            {media ? <BackgroundImage media={media} currentImage={currentPicture} /> : <></>}
          </CSSTransition>
        </SwitchTransition>
        <div className="logo-container">
          <Icon
            name="DmFullLogo"
            height={32}
            fill={isMobile ? "var(--color-black)" : "var(--color-white)"}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default useLoginPictures;
