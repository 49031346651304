import React, { useContext } from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import AuthContext from "../auth/AuthContext";
import Routes from "../../routes";
import FirstLogin from "../auth/FirstLogin";
import Home from "./Home";

export const HomeContainer: React.FC<RouteComponentProps> = ({ location }) => {
  const auth = useContext(AuthContext);

  return auth.isFirstLogin ? (
    <Switch location={location}>
      <Route component={FirstLogin} path={Routes.firstLogin} />
      <Route render={() => <Redirect to={Routes.firstLogin} />} />
    </Switch>
  ) : (
    <>
      <Switch location={location}>
        <Route component={Home} path={Routes.home} />
        <Route render={() => <Redirect to={Routes.home} />} />
      </Switch>
    </>
  );
};
export default withRouter(HomeContainer);
