import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "@ddm-design-system/icon";
import { Body, Description } from "@ddm-design-system/typography";
import classnames from "classnames";
import "./outlet-picker.scss";
import useContent from "../../../hooks/useContent";
import { IOutletPickerDistributorItem } from "./OutletPicker";
import { IOutlet } from "../../../store/outlet/types";

interface IProps {
  distributor?: IOutletPickerDistributorItem;
  selected: boolean;
  onClick: () => void;
  renderChildItem?: (d: IOutlet) => void;
  inside?: number;
  selectedOutletId?: string;
}

const DistributorItem = React.forwardRef<HTMLDivElement, IProps>(
  ({ distributor, selected, onClick, renderChildItem, inside = 0, selectedOutletId }, ref) => {
    const { managerAppCommon: content } = useContent();
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
      if (distributor?.outlets?.some(o => o.id === selectedOutletId)) {
        setOpen(true);
      }
    }, [distributor, selectedOutletId]);

    const handlePressEnterKey = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          onClick();
        }
      },
      [onClick]
    );

    return (
      <>
        <div
          ref={ref}
          className={classnames("relative outlet-item-container distributor group", {
            selected
          })}
          data-test-id="outlet-item-container"
          tabIndex={0} // make items focusable through sequential keyboard navigation (w/ tab and arrow key)
          title={distributor?.name}
          onClick={onClick}
          onKeyPress={handlePressEnterKey}
        >
          <Body className="outlet-item group-hover:underline" style={{ paddingLeft: inside * 24 }}>
            {distributor?.name}
          </Body>
          <Icon
            className="absolute right-xs p-md transition-all cursor-pointer rounded-[50%] hover:bg-grey-grey25"
            name="ChevronDown"
            style={{
              transform: open ? "rotateZ(180deg)" : ""
            }}
            onClick={e => {
              setOpen(!open);
              e.stopPropagation();
            }}
          />
        </div>

        <div
          className={classnames("transition-all overflow-hidden", {
            "max-h-[0] opacity-0 pointer-events-none": !open,
            "max-h-full opacity-100 pointer-events-initial": open
          })}
        >
          <div className="text-grey-grey100 mb-xs" style={{ paddingLeft: (inside + 1) * 24 + 16 }}>
            <Description>{content.common_outlets}</Description>
          </div>
          {distributor?.outlets?.map(d => (
            <>{renderChildItem?.(d)}</>
          ))}
        </div>
      </>
    );
  }
);

export default DistributorItem;
