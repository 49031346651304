export const SHOW_FAULTY_SENSOR_MODAL = "modals/SHOW_FAULTY_SENSOR_MODAL";
export const HIDE_FAULTY_SENSOR_MODAL = "modals/HIDE_FAULTY_SENSOR_MODAL";
export const SHOW_ADD_TARGET_MODAL = "modals/SHOW_ADD_TARGET_MODAL";
export const HIDE_ADD_TARGET_MODAL = "modals/HIDE_ADD_TARGET_MODAL";

export interface IHomescreenModalState {
  faultySensorModal: { isVisible: boolean; pressureChambers?: number[] };
  addTargetModal: {
    isVisible: boolean;
  };
}

export interface IShowFaultySensorModal {
  type: typeof SHOW_FAULTY_SENSOR_MODAL;
  payload: number[];
}

export interface IHideFaultySensorModal {
  type: typeof HIDE_FAULTY_SENSOR_MODAL;
}

export interface IShowAddTargetModal {
  type: typeof SHOW_ADD_TARGET_MODAL;
}

export interface IHideAddTargetModal {
  type: typeof HIDE_ADD_TARGET_MODAL;
}

export type modalsActionTypes =
  | IShowFaultySensorModal
  | IHideFaultySensorModal
  | IShowAddTargetModal
  | IHideAddTargetModal;
