import {
  ICompressor,
  IControlUnit,
  ICoolingUnit,
  IPressureChamber,
  ISteelKegUnit
} from "../installation/types";

export const SET_OUTLET_PICKER_TAB = "outlet/SET_OUTLET_PICKER_TAB";
export const SET_OUTLETS = "outlet/SET_OUTLETS";
export const REQUEST_OUTLETS = "outlet/REQUEST_OUTLETS";
export const REQUEST_OUTLETS_SUCCESS = "outlet/REQUEST_OUTLET_SUCCESS";
export const REQUEST_OUTLETS_ERROR = "outlet/REQUEST_OUTLET_ERROR";
export const GET_OUTLET_LOCATION_DATA = "outlet/GET_OUTLET_LOCATION_DATA";
export const GET_OUTLET_LOCATION_DATA_SUCCESS = "outlet/GET_OUTLET_LOCATION_DATA_SUCCESS";
export const GET_OUTLET_LOCATION_STATUS = "outlet/GET_OUTLET_LOCATION_STATUS";
export const GET_OUTLET_LOCATION_STATUS_SUCCESS = "outlet/GET_OUTLET_LOCATION_STATUS_SUCCESS";
export const GET_OUTLET_LOCATION_STATUS_ERROR = "outlet/GET_OUTLET_LOCATION_STATUS_ERROR";
export const GET_OUTLET_LOCATION_DETAILS = "outlet/GET_OUTLET_LOCATION_DETAILS";
export const GET_OUTLET_LOCATION_DETAILS_SUCCESS = "outlet/GET_OUTLET_LOCATION_DETAILS_SUCCESS";
export const GET_OUTLET_LOCATION_DETAILS_ERROR = "outlet/GET_OUTLET_LOCATION_DETAILS_ERROR";
export const SET_OUTLET_GOOGLE_LOCATION_DATA = "outlet/SET_OUTLET_GOOGLE_LOCATION_DATA";
export const SET_OUTLET_MANUAL_LOCATION_DATA = "outlet/SET_OUTLET_MANUAL_LOCATION_DATA";
export const SET_OUTLET_FAVOURITE = "outlet/SET_OUTLET_FAVOURITE";
export const SET_OUTLET_FAVOURITE_SUCCESS = "outlet/SET_OUTLET_FAVOURITE_SUCCESS";
export const SET_OUTLET_FAVOURITE_ERROR = "outlet/SET_OUTLET_FAVOURITE_ERROR";

export interface IDistributor {
  email: string;
  id: string;
  name: string;
  outletCount?: number;
  outlets: { id: string; name: string }[];
  purchasingGroup: {
    id: string;
    name: string;
  };
}

export interface IOutlet {
  timezone: string;
  id: string;
  children: string[];
  master: string;
  name: string;
  userAssociationTime?: string;
  favourite?: boolean;
  stockAccess?: boolean;
  country: IOutletCountry;
  distributors: IDistributor[];
  type?: "OUTLET"; // just for typescript purpose
  owners?: Array<{ name: string; username: string }>;
}

export interface IOutletCountry {
  id: string;
  code: string;
  name: string;
}

export interface IOutletState {
  currentOutletPickerTab: number;
  outlets: IOutlet[];
  loadingOutlets: boolean;
  loadingOutletsStatus: boolean;
  loadingOutletDetails: boolean;
  outletsError: boolean;
  outletDetailsError: boolean;
  outletsLocationData: { [key: string]: ILocationData };
  outletsStatus: ILocationStatus[];
  outletDetails: ILocationDetails | null;
}

export interface ILocationData {
  googleBusinessName?: string;
  googleBusinessId?: string;
  address?: string;
  openingHoursList: ILocationOpeningHour[];
}

export interface ILocationStatus {
  name: string;
  id: string;
  controlUnitId?: string;
  isCleaning?: string;
  errors: IGroupErrors;
  cleaningDueDate?: string;
  cleaningStatus?: string;
}

export interface ILocationDetails {
  locationId: string;
  locationName: string;
  locationCountryCode: string;
  pressureChambers: IPressureChamber[];
  coolingUnits: ICoolingUnit[];
  controlUnits: IControlUnit[];
  beerDrives: IPressureChamber[];
  steelKegUnits: ISteelKegUnit[];
  compressors: ICompressor[];
  groupings: IGrouping[];
  componentsWithoutCompressorGroupings: string[];
  componentsWithoutCompressorGroupingsErrors: IGroupErrors;
  cleaningDueDate?: string;
  cleaningStatus?: string;
  lines: ILine[];
}

export interface ILine {
  pressureChambers?: IPressureChamber[];
  coolingUnits?: ICoolingUnit[];
  controlUnits?: IControlUnit[];
  compressors?: ICompressor[];
  beerDrives?: IPressureChamber[];
  steelKegUnits?: ISteelKegUnit[];
  errors?: IGroupErrors;
  index?: number;
}

export interface IGrouping {
  unitId: string;
  type: string;
  pressureChamberIds: string[];
  errors: IGroupErrors;
}

export interface IGroupErrors {
  cleaningError?: number;
  existsLeak?: number;
  offline?: number;
  open?: number;
  sensorError?: number;
  temperatureError?: number;
  timeOnKeg?: number;
}

export interface ILocationOpeningHour {
  startTime: number;
  endTime: number;
  weekday: number;
}

export interface IGoogleLocationDataInput {
  googleBusinessName?: string;
  googleBusinessId: string;
  address: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  locationOpeningHours: ILocationOpeningHour[];
}

export interface IManualLocationDataInput {
  address: string;
  locationOpeningHours: ILocationOpeningHour[];
}

export type LocationDataInput = IGoogleLocationDataInput | IManualLocationDataInput;

export interface ISetOutletPickerTab {
  type: typeof SET_OUTLET_PICKER_TAB;
  payload: number;
}

export interface ISetOutlets {
  type: typeof SET_OUTLETS;
  payload: IOutlet[];
}

export interface IRequestOutlets {
  type: typeof REQUEST_OUTLETS;
}

export interface IRequestOutletsError {
  type: typeof REQUEST_OUTLETS_ERROR;
}

export interface IGetOutletLocationStatus {
  type: typeof GET_OUTLET_LOCATION_STATUS;
}

export interface IGetOutletLocationStatusSuccess {
  type: typeof GET_OUTLET_LOCATION_STATUS_SUCCESS;
  payload: {
    data: [];
  };
}

export interface IGetOutletLocationStatusError {
  type: typeof GET_OUTLET_LOCATION_STATUS_ERROR;
}

export interface IGetOutletLocationDetails {
  type: typeof GET_OUTLET_LOCATION_DETAILS;
  payload: {
    outletId: string;
  };
}

export interface IGetOutletLocationDetailsSuccess {
  type: typeof GET_OUTLET_LOCATION_DETAILS_SUCCESS;
  payload: {
    data: ILocationDetails;
  };
}

export interface IGetOutletLocationDetailsError {
  type: typeof GET_OUTLET_LOCATION_DETAILS_ERROR;
}

export interface IGetOutletLocationData {
  type: typeof GET_OUTLET_LOCATION_DATA;
  payload: {
    outletId: string;
    force?: boolean;
  };
}

export interface IGetOutletLocationDataSuccess {
  type: typeof GET_OUTLET_LOCATION_DATA_SUCCESS;
  payload: {
    outletId: IOutlet["id"];
    data: ILocationData;
  };
}

export interface ISetOutletGoogleLocationData {
  type: typeof SET_OUTLET_GOOGLE_LOCATION_DATA;
  payload: {
    outletId: IOutlet["id"];
    outletName: string;
    placeId: string;
  };
}

export interface ISetOutletManualLocationData {
  type: typeof SET_OUTLET_MANUAL_LOCATION_DATA;
  payload: {
    outletId: IOutlet["id"];
    outletName: string;
    data: IManualLocationDataInput;
  };
}

export interface ISetOutletFavourite {
  type: typeof SET_OUTLET_FAVOURITE;
  payload: {
    outletId: IOutlet["id"];
    favourite: boolean;
  };
}

export interface ISetOutletFavouriteSuccess {
  type: typeof SET_OUTLET_FAVOURITE_SUCCESS;
  payload: {
    outletId: IOutlet["id"];
    favourite: boolean;
  };
}

export interface ISetOutletFavouriteError {
  type: typeof SET_OUTLET_FAVOURITE_ERROR;
  payload: {
    outletId: IOutlet["id"];
    favourite: boolean;
  };
}

export type OutletActionTypes =
  | ISetOutletPickerTab
  | ISetOutlets
  | IRequestOutlets
  | IRequestOutletsError
  | IGetOutletLocationData
  | IGetOutletLocationDataSuccess
  | IGetOutletLocationStatus
  | IGetOutletLocationStatusSuccess
  | IGetOutletLocationStatusError
  | IGetOutletLocationDetails
  | IGetOutletLocationDetailsSuccess
  | IGetOutletLocationDetailsError
  | ISetOutletGoogleLocationData
  | ISetOutletManualLocationData
  | ISetOutletFavourite
  | ISetOutletFavouriteSuccess
  | ISetOutletFavouriteError;
