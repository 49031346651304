import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Dropdown } from "@ddm-design-system/dropdown";
import { Body } from "@ddm-design-system/typography";
import Modal, { ModalHeader, ModalBody, ModalFooter } from "@ddm-design-system/modal";
import { Divider } from "@ddm-design-system/divider";
import useContent from "../../hooks/useContent";
import { IBeer } from "../../store/beers/types";
import { editBeer } from "../../store/beers/actions";
import { getBeverages } from "../../store/beers/selectors";
import { IPressureChamber } from "../../store/installation/types";
import PressureChamberSummary from "./PressureChamberSummary";
import { AnalyticsContext } from "../../services/analytics";

interface IProps {
  outletId: string;
  pressureChamber: IPressureChamber;
  onClose: () => void;
  isSteelKeg?: boolean;
}

const EditBeverageModal: React.FC<IProps> = ({
  outletId,
  pressureChamber,
  isSteelKeg,
  onClose
}) => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const { managerAppInstallation: content, managerAppCommon: common } = useContent();

  const beerList = useSelector(getBeverages);
  const filteredBeerList = useMemo(() => {
    const currentBeverage = pressureChamber?.beverage;
    if (currentBeverage?.id && !beerList.find(b => b.id === currentBeverage.id)) {
      beerList.push(currentBeverage);
    }
    return beerList
      .filter(beer => beer.brand !== "NONE" && beer.brand !== "empty")
      .map((beer: IBeer) => ({
        id: beer.id,
        text: beer.name
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1))
      .concat(
        beerList
          .filter(beer => beer.brand === "empty")
          .map((beer: IBeer) => ({
            id: beer.id,
            text: beer.name
          }))
      );
  }, [pressureChamber, beerList]);
  const [initialBeverage, setInitialBeverage] = useState<{
    id: string;
    text: string;
  }>();
  const [selectedBeverage, setSelectedBeverage] = useState<{
    id: string;
    text: string;
  }>();

  useEffect(() => {
    const firstBeverage = filteredBeerList.find(beer => beer.id === pressureChamber?.beverage.id);
    setInitialBeverage(firstBeverage);
    setSelectedBeverage(firstBeverage);
  }, [filteredBeerList, pressureChamber]);

  const handleSave = useCallback(() => {
    analytics.logEvent("CHANGE_BEVERAGE", selectedBeverage?.id);
    dispatch(
      editBeer({
        beverageId: selectedBeverage?.id || "",
        outletId,
        thingId: pressureChamber.thingId,
        pressureChamberId: pressureChamber.id,
        isBeerDrive: pressureChamber.isBeerDrive,
        isSteelKeg
      })
    );

    onClose();
  }, [
    analytics,
    selectedBeverage,
    dispatch,
    outletId,
    pressureChamber.thingId,
    pressureChamber.id,
    onClose
  ]);

  if (!pressureChamber) {
    return null;
  }

  return (
    <Modal isOpen={!!pressureChamber} onRequestClose={onClose}>
      <ModalHeader headerTitle={content.manager_app_product_change_beverage} />
      <Divider className="mx-lg !w-auto" />
      <ModalBody>
        <Body>{content.manager_app_product_replacing_beverage}</Body>
        <div data-test-id="pressureChamber-info-wrapper" className="mt-md mb-lg">
          <PressureChamberSummary pressureChamber={pressureChamber} />
        </div>
        <Dropdown
          items={filteredBeerList}
          isLabelOnTop
          label={content.manager_app_product_beverage}
          selectedItem={selectedBeverage}
          onItemSelected={(item: any) => {
            setSelectedBeverage(item);
          }}
          className="w-full"
        />
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onClose}>{common.common_cancel}</SecondaryButton>
        <PrimaryButton onClick={handleSave} disabled={selectedBeverage?.id === initialBeverage?.id}>
          {common.manager_app_save}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};

export default EditBeverageModal;
