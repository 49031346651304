import React, { useCallback } from "react";
import useContent from "../../../../hooks/useContent";
import { EInsightsNotificationType, EInsightItemType, IInsightsNotification } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";

export interface IInsightWeeklyVolumeProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightWeeklyVolume: React.FC<IInsightWeeklyVolumeProps> = ({ insight, itemType }) => {
  const {
    managerAppInsights: content
  } = useContent();

  const { variationPercentage }: any = insight.customFields;
  const positive = variationPercentage > 0;

  const title = insight.type === EInsightsNotificationType.WEEKLY_VOLUME ? 
  positive ? content.manager_app_insights_weekly_volume_up : content.manager_app_insights_weekly_volume_down : 
  positive ? content.manager_app_insights_weekly_mix_cs_volume_up : content.manager_app_insights_weekly_mix_cs_volume_down;

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={title?.replace("%PERC%", `${Math.round(Math.abs(variationPercentage || 0))}%`)}
          icon="Chart"
          iconOptions={{ name: "Chart", className: "text-white fill-current" }}
          insight={insight}
          avatarOptions={{
            backgroundColor: positive
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={title?.replace("%PERC%", `${Math.round(Math.abs(variationPercentage || 0))}%`)}
          icon="Chart"
          iconOptions={{ name: "Chart", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: positive
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={title?.replace("%PERC%", `${Math.round(Math.abs(variationPercentage || 0))}%`)}
          icon="Chart"
          iconOptions={{ name: "Chart", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: positive
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightWeeklyVolume;
