import { translate, translateImage, translateString } from "./translate";
import { IEntries } from "./index";
import { DEFAULT_LANG } from "../../constants";

export function mapMediaContainer(entries: IEntries) {
  let mediaContainer = entries.mediaContainer.ordered;
  mediaContainer = mediaContainer.filter(m => {
    const app = translateString(m.fields.app);
    return app === "Manager App";
  });

  const media = translate(mediaContainer[0]?.fields.media);
  return {
    mediaContainer: media.map((d: any) => translateImage(d, DEFAULT_LANG, true).path)
  };
}
