import React from "react";
import { IUser } from "../store/users/types";

export interface IAnalyticsService {
  setup(me?: IUser | null): void;

  logPageView(name: string): void;

  logEvent(category: string, action?: string, label?: string, value?: number): void;

  setAuthenticatedUserContext(userId: string): void;

  clearAuthenticatedUserContext(): void;
}

export class AnalyticsService implements IAnalyticsService {
  parseProperties(properties?: { [name: string]: any }): { [name: string]: string } {
    const parsedProperties: { [name: string]: string } = {};
    if (properties) {
      Object.keys(properties).forEach(key => {
        const value = properties[key];
        parsedProperties[key] = JSON.stringify(value);
      });
    }
    return parsedProperties;
  }

  getCookieConsent() {
    const cc = localStorage.getItem("cookieConsent");
    return cc === "true";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(me: IUser) {
    // TODO setup a new analytics if needed
    // Google Analytics old key:
    // const gaKey = "UA-148825622-1";
  }

  logPageView(name: string) {
    if (this.getCookieConsent()) {
      // send page view
    }
  }

  logEvent(category: string, action?: string, label?: string, value?: number) {
    if (this.getCookieConsent()) {
      // send event
    }
  }

  public setAuthenticatedUserContext(userId: string) {
    if (this.getCookieConsent()) {
      // set user
    }
  }

  public clearAuthenticatedUserContext() {
    // remove user
  }
}

export const AnalyticsContext = React.createContext<IAnalyticsService>(new AnalyticsService());
export const AnalyticsProvider = AnalyticsContext.Provider;
export const AnalyticsConsumer = AnalyticsContext.Consumer;
