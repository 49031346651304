import { Reducer } from "redux";
import produce from "immer";
import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  IUsersState,
  UsersActionTypes
} from "./types";

export const initialState: IUsersState = {
  users: [],
  requestingUsers: false,
  requestingUsersError: false
};
const reducer: Reducer<IUsersState, UsersActionTypes> = (
  state = initialState,
  action: UsersActionTypes
) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_USERS:
        draft.requestingUsers = true;
        draft.requestingUsersError = false;
        break;
      case GET_USERS_SUCCESS:
        draft.requestingUsers = false;
        draft.requestingUsersError = false;
        draft.users = action.payload;
        break;
      case GET_USERS_ERROR:
        draft.requestingUsers = false;
        draft.requestingUsersError = true;
        break;
      default:
        break;
    }
  });

export default reducer;
