import React, { HTMLAttributes } from "react";
import { Icon } from "@ddm-design-system/icon";
import { useIsMobile } from "@ddm-design-system/hooks";
import classnames from "classnames";
import { LoadingSpinner } from "./common.styles";

export interface IInsightsIconProps extends HTMLAttributes<HTMLElement> {
  insightsLength: number;
  loading?: boolean;
}

export const InsightsIcon: React.FC<IInsightsIconProps> = ({
  insightsLength,
  loading,
  className,
  ...props
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      data-test-id="insights-icon"
      className={classnames(
        "flex items-center justify-center h-lg w-lg relative xs:static",
        className
      )}
      {...props}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Icon name="Bell" size={isMobile ? 24 : 16} />
          {insightsLength > 0 && (
            <span
              data-test-id="insights-number"
              className="flex items-center justify-center absolute p-xxs top-[-6px] right-[-6px] rounded-10 bg-success-success100 text-white text-[10px] leading-[10px]"
            >
              {insightsLength.toString().padStart(2, "0")}
            </span>
          )}
        </>
      )}
    </div>
  );
};
export default InsightsIcon;
