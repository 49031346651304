import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { PrimaryButton } from "@ddm-design-system/button";
import { Body } from "@ddm-design-system/typography";
import styles from "./cookies_form.module.scss";
import { AnalyticsContext } from "../../../services/analytics";
import useContent from "../../../hooks/useContent";
import { getUser } from "../../../store/profile/reducer";
import { getContentfulLoaded, getCurrentLanguage } from "../../../store/content/selectors";
import Time from "../../../lib/Time/Time";

const CookiesForm: React.FC = () => {
  const AnalyticsService = useContext(AnalyticsContext);
  const { managerAppCommon: contentCommon } = useContent();
  const me = useSelector(getUser);
  const createDateTime = me?.createDateTime;
  const contentfulLoaded = useSelector(getContentfulLoaded);
  const loaded = contentfulLoaded && me !== null;
  const language = useSelector(getCurrentLanguage);
  const [consent, setConsent] = useState(localStorage.getItem("cookieConsent"));

  const saveConsent = useCallback((consentVar: boolean) => {
    const consentText = consentVar ? "true" : "false";
    localStorage.setItem("cookieConsent", consentText);
    setConsent(consentText);
  }, []);

  const setupTracking = useCallback(() => {
    AnalyticsService.setup(me);
  }, [AnalyticsService, me]);

  useEffect(() => {
    if (consent === "true" && me) {
      setupTracking();
    }
  }, [me, setupTracking, consent]);

  const optOutTokens = (contentCommon.manager_app_cookies_opt_out || "").split("%SPLIT%");
  const isVisible = Object.keys(contentCommon || {}).length && consent === null;

  return isVisible ? (
    <div className={styles.cookies}>
      <Body>
        <ReactMarkdown
          components={{
            a: props => (
              <a
                className={styles.link}
                href={props.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.children}
              </a>
            )
          }}
        >
          {contentCommon.manager_app_cookies_text}
        </ReactMarkdown>
        {optOutTokens.length >= 3 && (
          <p>
            {optOutTokens[0]}
            <span
              className={styles.link}
              onClick={() => {
                saveConsent(false);
              }}
            >
              {optOutTokens[1]}
            </span>
            {optOutTokens[2]}
          </p>
        )}
      </Body>
      <div className={styles.buttonsContainer}>
        <PrimaryButton
          className={styles.buttonYes}
          onClick={() => {
            saveConsent(true);
            setupTracking();
          }}
        >
          {contentCommon.manager_app_cookies_yes}
        </PrimaryButton>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CookiesForm;
