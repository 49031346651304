import React, { useCallback, useContext } from "react";
import * as d3 from "d3";
import numeral from "numeral";
import { LinkButton } from "@ddm-design-system/button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Routes from "../../../../routes";
import { AnalyticsContext } from "../../../../services/analytics";
import { setInsightsNotificationsClose, setInsightsStoriesClose } from "../../../../store/insights/actions";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import Time from "../../../../lib/Time/Time";
import { day8 } from "../../../../lib/Time/TimeFunctions";
import { setFilterOutlets, setTimeFrame } from "../../../../store/filter/actions";
import { getOutletById } from "../../../../store/outlet/selectors";
import { ETimeFrame } from "../../../../lib/Time/types";
import InsightStory from "../InsightStory";

export interface IInsightBestPerformanceHourProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightBestPerformanceHour: React.FC<IInsightBestPerformanceHourProps> = ({ insight, itemType }) => {
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const outlet = useSelector(getOutletById(insight.locationId));
  const { managerAppInsights: content } = useContent();
  const { timestamp, volume }: any = insight.customFields;

  const callToAction = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS_WITH_CALL_TO_ACTION", insight.type);
    dispatch(setInsightsNotificationsClose(insight.locationId));
    dispatch(setInsightsStoriesClose());
    if (outlet) {
      dispatch(setFilterOutlets([outlet]));
      dispatch(
        setTimeFrame({
          from: day8(timestamp),
          to: day8(timestamp).add({
            day: 1
          }),
          type: ETimeFrame.CUSTOM
        })
      );
    }
    history.push(Routes.sales);
  }, [analytics, dispatch, history, insight.locationId, insight.type, outlet, timestamp]);

  const time = new Time(new Date(timestamp));

  const description = content.manager_app_insights_best_perf_hour_desc
            .replace("%WEEKDAY%", d3.timeFormat("%A")(day8(time).toJSDate()))
            .replace("%START_HOUR%", d3.timeFormat("%H:%M")(time.toJSDate()))
            .replace("%END_HOUR%", d3.timeFormat("%H:%M")(time.add({ hour: 1 }).toJSDate()))
            .replace("%LITERS_POURED%", `${numeral((volume || 0) / 1000).format("0.00")}L`);

const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={content.manager_app_insights_best_perf_hour_title}
          description={description}
          icon="Hour"
          insight={insight}
          footer={
            <div className="flex items-center justify-end">
              <LinkButton onClick={callToAction}>
                {content.manager_app_insights_common_see_more}
              </LinkButton>
            </div>
      }
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={content.manager_app_insights_best_perf_hour_title}
          icon="Hour"
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={content.manager_app_insights_best_perf_hour_title}
          description={description}
          icon="Hour"
          insight={insight}
          callToAction={callToAction}
          actionTitle={content.manager_app_insights_common_see_more}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightBestPerformanceHour;
