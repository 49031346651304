import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { DangerButton, SecondaryButton, TertiaryButton } from "@ddm-design-system/button";
import { getUser } from "../../../store/profile/reducer";
import useContent from "../../../hooks/useContent";
import history from "../../../history";
import { IUser } from "../../../store/users/types";
import Routes from "../../../routes";
import "./profile.scss";

export const ProfileMobile: React.FC = () => {
  const { managerAppCommon: content } = useContent();

  const [logoutModal, setLogoutModal] = useState(false);

  const me: IUser | null = useSelector(getUser);

  return (
    <div className="profile-mobile">
      <div className="profile-mobile-title">
        <SectionTitle>{me?.name || me?.username}</SectionTitle>
        <Body className="profile-mobile-email">{me?.username}</Body>
      </div>
      <TertiaryButton
        icon="Exit"
        onClick={() => setLogoutModal(true)}
        className="profile-mobile-button"
      >
        {content.manager_app_logout}
      </TertiaryButton>

      <Modal
        isOpen={logoutModal}
        className="profile-logout-modal"
        onRequestClose={() => setLogoutModal(false)}
      >
        <ModalHeader icon={<Icon name="Exit" />} headerTitle={content.manager_app_logout} />
        <ModalBody>
          <Body>{content.manager_app_logout_message}</Body>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={() => setLogoutModal(false)}>
            {content.common_cancel}
          </SecondaryButton>
          <DangerButton
            onClick={() => {
              history.push(Routes.logout);
              setLogoutModal(false);
            }}
          >
            {content.manager_app_logout_confirm}
          </DangerButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ProfileMobile;
