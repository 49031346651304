import React from "react";
import { Link } from "react-router-dom";
import { Description, DescriptionMedium } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";

export interface IBreadCrumb {
  link: string;
  name: string;
}

interface IProps {
  current?: string;
  items?: IBreadCrumb[];
}

const Breadcrumbs: React.FC<IProps> = ({ current, items, ...props }) => (
  <div className="flex flex-row items-center" {...props}>
    {items?.map((item: IBreadCrumb, itemIndex: number) => (
      <React.Fragment key={itemIndex}>
        <Description className="hover:underline" as={Link} to={item.link}>
          {item.name}
        </Description>
        <Icon name="ChevronRight" className="text-grey-grey100 w-xs px-xs fill-current" />
      </React.Fragment>
    ))}
    <DescriptionMedium className="cursor-default">{current}</DescriptionMedium>
  </div>
);

export default Breadcrumbs;
