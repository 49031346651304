import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import classnames from "classnames";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";
import {SplashLoading} from "@ddm-design-system/icon";
import {useIsMobile} from "@ddm-design-system/hooks";
import Sidebar from "../common/sidebar/Sidebar";
import Routes from "../../routes";
import CUStatusHistory from "../installation/CUStatusHistory";
import {Installation} from "../installation/Installation";
import {InstallationDetail} from "../installation/InstallationDetail";
import Overview from "../overview/Overview";
import Sales from "../sales/Sales";
import Product from "../product/Product";
import ScrollToTop from "../common/scroll_to_top/ScrollToTop";
import Bottombar from "../common/bottombar/Bottombar";
import ErrorBoundary from "../common/error_boundary/ErrorBoundary";
import BrowserUpdate from "../common/browser_update/BrowserUpdate";
import {Settings} from "../settings/Settings";
import {getProfile} from "../../store/profile/actions";
import {requestOutlets} from "../../store/outlet/actions";
import {requestBeers} from "../../store/beers/actions";
import {requestComponents} from "../../store/installation/actions";
import {requestProducts} from "../../store/product/actions";
import useContent from "../../hooks/useContent";
import BaseLayout from "../common/base_layout/BaseLayout";
import {requestInsightsNotifications} from "../../store/insights/actions";
import Insights from "../common/insights/Insights";
import "./home.scss";
import {ProductDetail} from "../product/ProductDetail";

type Params = {
  id: string;
  controlUnitId: string;
};

type MatchParams = RouteComponentProps<Params>;

export const Home: React.FC<RouteComponentProps> = ({location}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const content = useContent();

  useEffect(() => {
    dispatch(requestOutlets());
    dispatch(getProfile());
    dispatch(requestBeers());
    dispatch(requestComponents());
    dispatch(requestProducts());
    dispatch(requestInsightsNotifications());
  }, [dispatch]);

  const renderProduct = ({match}: MatchParams) => <ProductDetail outletId={match.params.id} />;

  const renderInstallation = ({match}: MatchParams) => (
    <InstallationDetail outletId={match.params.id} />
  );

  const renderStatusHistory = ({match}: MatchParams) => (
    <CUStatusHistory outletId={match.params.id} controlUnitId={match.params.controlUnitId} />
  );

  return content ? (
    <div className="app-parent">
      <div className="app-container">
        {isMobile ? (
          <Bottombar />
        ) : (
          <Sidebar mode="fixed" isOpen={false} toggleSidebar={() => null} />
        )}
        <ScrollToTop
          className={classnames("router-container", {
            "mobile-router-container": isMobile
          })}
        >
          <TransitionGroup className="transition-group">
            <CSSTransition classNames="slide" timeout={300}>
              <>
                <div className="router-inner-container">
                  <ErrorBoundary>
                    <BaseLayout>
                      <Switch location={location}>
                        <Route component={Overview} path={Routes.overview} />
                        <Route component={Sales} path={Routes.sales} />
                        <Route path={Routes.product} render={renderProduct} />
                        <Route component={Product} path={Routes.products} />
                        <Route path={Routes.CUStatusHistory} render={renderStatusHistory} />
                        <Route path={Routes.installation} render={renderInstallation} />
                        <Route component={Installation} path={Routes.installations} />
                        <Route component={Settings} path={Routes.settings} />
                        <Route render={() => <Redirect to={Routes.overviewAll} />} />
                      </Switch>
                    </BaseLayout>
                  </ErrorBoundary>
                </div>
              </>
            </CSSTransition>
          </TransitionGroup>
        </ScrollToTop>
        <Insights />
        <BrowserUpdate />
      </div>
    </div>
  ) : (
    <SplashLoading infinite />
  );
};

export default Home;
