import {
  CREATE_PROFILE,
  CREATE_PROFILE_ERROR,
  CREATE_PROFILE_SUCCESS,
  DELETE_PROFILE,
  DELETE_PROFILE_ERROR,
  DELETE_PROFILE_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  ICreateProfile,
  ICreateProfileError,
  ICreateProfileSuccess,
  ICreateUser,
  IDeleteProfile,
  IDeleteProfileError,
  IDeleteProfileSuccess,
  IGetProfile,
  IGetProfileError,
  IGetProfileSuccess,
  ISetEmail,
  ISetEmailError,
  ISetEmailProperty,
  ISetEmailSuccess,
  ISetName,
  ISetNameError,
  ISetNameProperty,
  ISetNameSuccess,
  ISetPassword,
  ISetPasswordError,
  ISetPasswordSuccess,
  IUpdateEmailFrequency,
  IUpdateEmailFrequencyError,
  IUpdateEmailFrequencySuccess,
  SET_EMAIL,
  SET_EMAIL_ERROR,
  SET_EMAIL_PROPERTY,
  SET_EMAIL_SUCCESS,
  SET_NAME,
  SET_NAME_ERROR,
  SET_NAME_PROPERTY,
  SET_NAME_SUCCESS,
  SET_PASSWORD,
  SET_PASSWORD_ERROR,
  SET_PASSWORD_SUCCESS,
  UPDATE_EMAIL_FREQUENCY,
  UPDATE_EMAIL_FREQUENCY_ERROR,
  UPDATE_EMAIL_FREQUENCY_SUCCESS
} from "./types";
import { IUser } from "../users/types";

export const createProfile = (user: ICreateUser): ICreateProfile => ({
  type: CREATE_PROFILE,
  payload: user
});

export const createProfileSuccess = (): ICreateProfileSuccess => ({
  type: CREATE_PROFILE_SUCCESS
});

export const createProfileError = (): ICreateProfileError => ({
  type: CREATE_PROFILE_ERROR
});

export const getProfile = (): IGetProfile => ({
  type: GET_PROFILE
});
export const getProfileSuccess = (user: IUser): IGetProfileSuccess => ({
  type: GET_PROFILE_SUCCESS,
  payload: user
});
export const getProfileError = (): IGetProfileError => ({
  type: GET_PROFILE_ERROR
});

export const setName = (name: string): ISetName => ({
  type: SET_NAME,
  payload: name
});

export const setNameSuccess = (name: string): ISetNameSuccess => ({
  type: SET_NAME_SUCCESS,
  payload: name
});

export const setNameError = (): ISetNameError => ({
  type: SET_NAME_ERROR
});

export const setPassword = (oldPassword: string, password: string): ISetPassword => ({
  type: SET_PASSWORD,
  payload: {
    oldPassword,
    password
  }
});

export const setPasswordSuccess = (): ISetPasswordSuccess => ({
  type: SET_PASSWORD_SUCCESS
});

export const setPasswordError = (): ISetPasswordError => ({
  type: SET_PASSWORD_ERROR
});

export const setEmail = (email: string): ISetEmail => ({
  type: SET_EMAIL,
  payload: email
});

export const setEmailSuccess = (name: string): ISetEmailSuccess => ({
  type: SET_EMAIL_SUCCESS,
  payload: name
});

export const setEmailError = (): ISetEmailError => ({
  type: SET_EMAIL_ERROR
});

export const setNameProperty = (name: string): ISetNameProperty => ({
  type: SET_NAME_PROPERTY,
  payload: name
});
export const setEmailProperty = (email: string): ISetEmailProperty => ({
  type: SET_EMAIL_PROPERTY,
  payload: email
});

export const deleteProfile = (password: string): IDeleteProfile => ({
  type: DELETE_PROFILE,
  payload: password
});

export const deleteProfileSuccess = (): IDeleteProfileSuccess => ({
  type: DELETE_PROFILE_SUCCESS
});

export const deleteProfileError = (error: string): IDeleteProfileError => ({
  type: DELETE_PROFILE_ERROR,
  payload: error
});

export const updateEmailFrequency = (frequencies: string[]): IUpdateEmailFrequency => ({
  type: UPDATE_EMAIL_FREQUENCY,
  payload: frequencies
});

export const updateEmailFrequencySuccess = (): IUpdateEmailFrequencySuccess => ({
  type: UPDATE_EMAIL_FREQUENCY_SUCCESS
});

export const updateEmailFrequencyError = (): IUpdateEmailFrequencyError => ({
  type: UPDATE_EMAIL_FREQUENCY_ERROR
});
