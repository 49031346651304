import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { Icon, IconNames, IIconProps } from "@ddm-design-system/icon";
import { Infoicon } from "@ddm-design-system/infoicon";
import { LinkButton } from "@ddm-design-system/button";
import { useIsMobile } from "@ddm-design-system/hooks";
import InsightDetails from "./InsightDetails";
import { IInsightsNotification } from "../../../store/insights/types";
import { getOutletById } from "../../../store/outlet/selectors";
import { setInsightFeedback } from "../../../store/insights/actions";
import { AnalyticsContext } from "../../../services/analytics";


interface IProps {
  insight: IInsightsNotification;
  title: string;
  description?: JSX.Element | string;
  icon?: IconNames;
  iconOptions?: IIconProps;
  avatarOptions?: any;
  callToAction?: any;
  actionTitle?: string;
}

export const InsightStory: React.FC<IProps> = ({
  title,
  description,
  icon,
  insight,
  iconOptions,
  avatarOptions = { backgroundColor: "var(--color-background-white)" },
  callToAction,
  actionTitle,
  children
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const outlet = useSelector(getOutletById(insight.locationId));
  const analytics = useContext(AnalyticsContext);

  const setFeedback = useCallback(
    (f: boolean) => {
      dispatch(setInsightFeedback(insight.locationId, insight.id, f));
      analytics.logEvent("SET_INSIGHT_FEEDBACK", insight.id);
    },
    [insight, dispatch]
  );

  const mobileHandlers = useSwipeable({
    onSwipedUp: () => callToAction(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    trackTouch: true
  });
  const feedbackBtnStyle =
    "flex items-center justify-around cursor-pointer border border-white text-white rounded-[6px] xs:w-[150px] xs:h-[48px] w-[45vh] h-[4vh]";

  return (
    <div
      className="flex flex-col justify-items-center gap-1 w-full h-full"
      {...(isMobile && callToAction ? { ...mobileHandlers } : {})}
    >
      <span className="text-background-white text-left mt-[8%] xs:text-[2vh] md:text-[1.2vh] text-[0.8vw] leading-[120%]">
        {outlet?.name}
      </span>
      <div className="mt-[10%]">
        <Infoicon
          className="text-white"
          avatarOptions={{
            children: icon && (
              <Icon
                name={icon}
                className="text-background-black fill-current"
                {...iconOptions}
                size={(window.innerHeight * 2.5) / 100}
              />
            ),
            ...avatarOptions,
            size: (window.innerHeight * 6) / 100
          }}
        />
      </div>
      <span className="text-background-white text-left mt-[5%] xs:text-[5.3vh] md:text-[2.5vh] text-[1.9vw] font-semibold leading-[110%]">
        {title}
      </span>
      <span className="text-background-white text-left mt-[8%] xs:text-[2.7vh] md:text-[1.5vh] text-[1vw] leading-[110%]">
        {description}
      </span>
      {insight.details.length > 0 && (
        <div className="max-h-[30vh] overflow-auto scrollbar-hide xs:text-[1.8vh] md:text-[1.2vh] text-[0.7vw] leading-[120%] text-left">
          <InsightDetails details={insight.details} />
        </div>
      )}
      {children ? <>{children}</> : <></>}
      <div data-test-id="insight-story-feedback" className="my-auto">
        <div className="flex flex-row justify-center items-center gap-lg">
          <div
            className={`${feedbackBtnStyle} ${
              insight.feedback === true &&
              "bg-success-success100 !border-success-success100 text-black"
            } hover:bg-success-success100 hover:border-success-success100 hover:text-black z-10`}
            onClick={() => setFeedback(true)}
          >
            <Icon
              className="fill-current"
              name="ThumbsUp"
              size={isMobile ? 35 : (window.innerHeight * 3) / 100}
            />
          </div>
          <div
            className={`${feedbackBtnStyle} ${
              insight.feedback === false && "bg-alert-alert100 !border-alert-alert100 text-black"
            } hover:bg-alert-alert100 hover:border-alert-alert100 hover:text-black z-10`}
            onClick={() => setFeedback(false)}
          >
            <Icon
              className="fill-current"
              name="ThumbsDown"
              size={isMobile ? 35 : (window.innerHeight * 3) / 100}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-around">
        {callToAction && (
          <LinkButton className="z-10" onClick={callToAction}>
            <div className="flex flex-col items-center justify-around">
              <Icon
                className="text-white fill-current"
                name="ChevronUpDouble"
                size={(window.innerHeight * 2.5) / 100}
              />
              <span className="text-white xs:text-[2vh] md:text-[1.2vh] text-[0.8vw]">
                {actionTitle}
              </span>
            </div>
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default InsightStory;
