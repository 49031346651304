/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { DescriptionMedium } from "@ddm-design-system/typography";
import * as d3 from "d3";
import Time from "../../../lib/Time/Time";
import useContent from "../../../hooks/useContent";
import { IControlUnit } from "../../../store/installation/types";
import CleanInfo from "./CleanInfo";
import CUInfoSwitcher from "./CUInfoSwitcher";

interface IBeerModeProps {
  controlUnit: IControlUnit;
}

const BeerMode: React.FC<IBeerModeProps> = ({ controlUnit }) => {
  const { managerAppCommon: common } = useContent();
  const { id } = controlUnit;

  return (
    <CUInfoSwitcher
      outerChildren={
        <div className="flex flex-col gap-md text-center">
          <DescriptionMedium className="text-grey-grey100">
            {common.common_cleaning_due}
          </DescriptionMedium>
          <DescriptionMedium>
            {d3.timeFormat("%d %B %Y")(
              new Time().add({ second: controlUnit.cleaningDue }).toJSDate()
            )}
          </DescriptionMedium>
        </div>
      }
      innerChildren={<CleanInfo controlUnit={controlUnit} />}
      label={common.common_clean_info}
      controlUnitId={id}
      labelAtBottom
    />
  );
};

export default BeerMode;
