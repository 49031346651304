import React from "react";

interface IOutletReviewCardPlaceholderProps {
  style: any;
}
export const OutletReviewCardLoading: React.FC<IOutletReviewCardPlaceholderProps> = ({ style }) => {
  return (
    <div className="outlet-review-card outlet-review-card-placeholder" style={style}>
      <div className="outlet-placeholder-header">
        <div className="outlet-placeholder-column" style={{ flex: 0.5 }}>
          <div className="outlet-placeholder" style={{ height: "17px" }} />
          <div className="outlet-placeholder" style={{ height: "17px", width: "60%" }} />
        </div>
        <div className="outlet-placeholder-column" style={{ flex: 0.5, alignItems: "flex-end" }}>
          <div className="outlet-placeholder" style={{ height: "12px", width: "60%" }} />
          <div className="outlet-placeholder" style={{ height: "24px", width: "80%" }} />
        </div>
      </div>
      <div className="outlet-review-card-header-2 outlet-placeholder-row">
        <div className="horizontal-line" />
        <div className="outlet-placeholder" style={{ height: "14px", flex: 0.2 }} />
      </div>
      <div className="outlet-placeholder-lines">
        <div className="outlet-line-current" />
        <div className="outlet-line-before" />
      </div>
    </div>
  );
};

export default OutletReviewCardLoading;
