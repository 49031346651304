import {
  IRequestInsightsNotifications,
  IRequestInsightsNotificationsError,
  IRequestInsightsNotificationsSuccess,
  ISetInsightFeedback,
  ISetInsightsNotificationsOpen,
  REQUEST_INSIGHTS_NOTIFICATIONS,
  REQUEST_INSIGHTS_NOTIFICATIONS_ERROR,
  REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS,
  SET_INSIGHT_FEEDBACK,
  SET_INSIGHT_SEEN,
  SET_INSIGHTS_NOTIFICATIONS_OPEN,
  SET_INSIGHTS_NOTIFICATIONS_CLOSE,
  SET_INSIGHTS_STORIES_OPEN,
  SET_INSIGHTS_STORIES_CLOSE,
  ISetInsightsNotificationsClose,
  IAPIInsightsNotifications,
  IDeleteStaleKegNotification,
  DELETE_STALE_KEG_NOTIFICATION,
  IInsightsNotifications,
  ISetInsightsStoriesOpen,
  ISetInsightsStoriesClose,
  IInsightStoryData,
  ISetInsightSeen
} from "./types";

export const requestInsightsNotifications = (): IRequestInsightsNotifications => ({
  type: REQUEST_INSIGHTS_NOTIFICATIONS
});

export const requestInsightsNotificationsError = (): IRequestInsightsNotificationsError => ({
  type: REQUEST_INSIGHTS_NOTIFICATIONS_ERROR
});

export const requestInsightsNotificationsSuccess = (
  data: IAPIInsightsNotifications,
  userId?: string
): IRequestInsightsNotificationsSuccess => ({
  type: REQUEST_INSIGHTS_NOTIFICATIONS_SUCCESS,
  payload: { data, userId }
});

export const setInsightsNotificationsOpen = (): ISetInsightsNotificationsOpen => ({
  type: SET_INSIGHTS_NOTIFICATIONS_OPEN
});

export const setInsightsNotificationsClose = (
  outletId?: string,
  insights?: IInsightsNotifications
): ISetInsightsNotificationsClose => ({
  type: SET_INSIGHTS_NOTIFICATIONS_CLOSE,
  payload: { outletId, insights }
});

export const setInsightsStoriesOpen = (
  insightStoryData: IInsightStoryData
): ISetInsightsStoriesOpen => ({
  type: SET_INSIGHTS_STORIES_OPEN,
  payload: insightStoryData
});

export const setInsightsStoriesClose = (): ISetInsightsStoriesClose => ({
  type: SET_INSIGHTS_STORIES_CLOSE
});

export const setInsightSeen = (locationId: string, insightId: string): ISetInsightSeen => ({
  type: SET_INSIGHT_SEEN,
  payload: { locationId, insightId }
});

export const setInsightFeedback = (
  locationId: string,
  insightId: string,
  feedback: boolean
): ISetInsightFeedback => ({
  type: SET_INSIGHT_FEEDBACK,
  payload: { locationId, insightId, feedback }
});

export const deleteStaleKegNotification = (
  locationId: string,
  pressureChamberId: string
): IDeleteStaleKegNotification => ({
  type: DELETE_STALE_KEG_NOTIFICATION,
  payload: {
    locationId,
    pressureChamberId
  }
});
