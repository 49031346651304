import React, { useCallback, useState } from "react";
import { DescriptionMedium } from "@ddm-design-system/typography";
import { PrimaryButton } from "@ddm-design-system/button";
import { useDispatch } from "react-redux";
import { ECleaningUnitButtons, IControlUnit } from "../../../store/installation/types";
import { requestButtonPress } from "../../../store/installation/actions";
import useContent from "../../../hooks/useContent";
import CUInfoSwitcher from "./CUInfoSwitcher";
import CleanInfo from "./CleanInfo";

export interface IStandbyModeProps {
  controlUnit: IControlUnit;
}

const StandbyMode: React.FC<IStandbyModeProps> = ({ controlUnit }) => {
  const { managerAppControlUnit: content, managerAppCommon: common } = useContent();
  const dispatch = useDispatch();
  const [loadingButtonPress, setLoadingButtonPress] = useState(false);
  const { id, alive } = controlUnit;

  const cleaningDue = Math.floor((controlUnit?.cleaningDue || 0) / (3600 * 24));

  const handleBeerMode = useCallback(() => {
    setLoadingButtonPress(true);
    dispatch(requestButtonPress({ button: ECleaningUnitButtons.BEER, controlUnitId: id }));
  }, [id, dispatch]);

  return (
    <CUInfoSwitcher
      outerChildren={
        cleaningDue <= 0 ? (
          <>
            <DescriptionMedium className="text-center">
              {content.manager_app_cu_step_hard_detergent_not_cleared_info}
            </DescriptionMedium>
          </>
        ) : (
          <>
            <DescriptionMedium className="text-center">
              {content.manager_app_cu_step_standby_mode_desc}
            </DescriptionMedium>
            <PrimaryButton
              disabled={!alive || cleaningDue <= 0 || loadingButtonPress}
              onClick={handleBeerMode}
            >
              {content.manager_app_cu_step_standby_mode_action}
            </PrimaryButton>
          </>
        )
      }
      innerChildren={
        // cleaningDue <= 0 ? (
        <CleanInfo controlUnit={controlUnit} />
        // ) : (
        //   <StandbyInfo controlUnit={controlUnit} />
        // )
      }
      label={common.common_clean_info}
      controlUnitId={id}
    />
  );
};

export default StandbyMode;
