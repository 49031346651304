import {
  IApiProducts,
  IRequestProducts,
  IRequestProductsError,
  IRequestProductsSuccess,
  ISetStock,
  ISetStockSuccess,
  IRequestInsights,
  IRequestInsightsSuccess,
  IRequestInsightsError,
  IApiInsights,
  REQUEST_PRODUCTS,
  REQUEST_PRODUCTS_ERROR,
  REQUEST_PRODUCTS_SUCCESS,
  SET_STOCK,
  SET_STOCK_ERROR,
  SET_STOCK_SUCCESS,
  REQUEST_INSIGHTS,
  REQUEST_INSIGHTS_SUCCESS,
  REQUEST_INSIGHTS_ERROR
} from "./types";

export const requestProducts = (): IRequestProducts => ({
  type: REQUEST_PRODUCTS
});

export const requestProductsError = (): IRequestProductsError => ({
  type: REQUEST_PRODUCTS_ERROR
});

export const requestProductsSuccess = (data: IApiProducts): IRequestProductsSuccess => ({
  type: REQUEST_PRODUCTS_SUCCESS,
  payload: data
});

export const setStock = (
  locationId: string,
  stock: { beverageId: string; numberKegs: number }[],
  updatedToday: boolean
): ISetStock => ({
  type: SET_STOCK,
  payload: { locationId, stock, updatedToday }
});

export const setStockError = () => ({
  type: SET_STOCK_ERROR
});

export const setStockSuccess = (data: {
  locationId: string;
  stock: { beverageId: string; numberKegs: number }[];
}): ISetStockSuccess => ({
  type: SET_STOCK_SUCCESS,
  payload: data
});

export const requestInsights = (): IRequestInsights => ({
  type: REQUEST_INSIGHTS
});

export const requestInsightsError = (): IRequestInsightsError => ({
  type: REQUEST_INSIGHTS_ERROR
});

export const requestInsightsSuccess = (data: IApiInsights): IRequestInsightsSuccess => ({
  type: REQUEST_INSIGHTS_SUCCESS,
  payload: data
});
