import { call, put, select, takeLatest } from "redux-saga/effects";
import { IAppContext } from "../services";
import { IJCoreService } from "../services/jcore";
import {
  ISetInsightFeedback,
  REQUEST_INSIGHTS_NOTIFICATIONS,
  SET_INSIGHT_FEEDBACK
} from "../store/insights/types";
import {
  requestInsightsNotificationsError,
  requestInsightsNotificationsSuccess
} from "../store/insights/actions";
import Time from "../lib/Time/Time";
import { IAppState } from "../store";
import { sleep } from "../helpers";

export function* requestInsightsNotificationsSaga(jCoreService: IJCoreService) {
  try {
    const lastTimestamp = new Time().sub({ day: 30 }).toJSDate();
    const result = yield call(
      [jCoreService, jCoreService.requestInsightsNotifications],
      lastTimestamp.toISOString()
    );
    yield sleep(2000);
    const user = yield select((state: IAppState) => state.profile?.user);

    if (result.data) {
      yield put(requestInsightsNotificationsSuccess(result.data, user?.id));
    }
  } catch {
    yield put(requestInsightsNotificationsError());
  }
}

export function* setInsightFeedbackSaga(jCoreService: IJCoreService, action: ISetInsightFeedback) {
  try {
    yield call(
      [jCoreService, jCoreService.setInsightFeedback],
      action.payload.insightId,
      action.payload.feedback
    );
    // eslint-disable-next-line no-empty
  } catch {}
}

export function* insightsWatcher(context: IAppContext) {
  yield takeLatest(
    REQUEST_INSIGHTS_NOTIFICATIONS,
    requestInsightsNotificationsSaga,
    context.jCoreService
  );
  yield takeLatest(SET_INSIGHT_FEEDBACK, setInsightFeedbackSaga, context.jCoreService);
}

export default insightsWatcher;
