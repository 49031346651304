import React from "react";
import { SectionTitle } from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import { Carousel, GlobalCarouselStyles } from "@ddm-design-system/carousel";
import { BeverageTranslation } from "../../../store/beers/types";
import { IProductInfo } from "../../../store/product/types";
import BrandRecommendationItem from "./BrandRecommendationItem";
import { getOutletCountryInfoById } from "../../../store/outlet/selectors";
import useContent from "../../../hooks/useContent";
import {
  getAllBeveragesIdsFromOutletById,
  getOutletTypeById
} from "../../../store/installation/selectors";
import { getBeveragesByIds } from "../../../store/beers/selectors";
import { IAppState } from "../../../store";

export interface IBrandRecommendationProps {
  beverage: IProductInfo & { beverage: BeverageTranslation };
  outletId?: string;
}

interface IRecommendation {
  type: string;
  taps: string[][];
  fixedTapNumber: boolean;
}

const BrandRecommendation: React.FC<IBrandRecommendationProps> = ({
  beverage: { tapPositions },
  outletId
}) => {
  const { jsonContent, managerAppProduct: content } = useContent();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const outletCountry = useSelector(getOutletCountryInfoById(outletId!));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const outletCategory = useSelector(getOutletTypeById(outletId!));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const outletBeverageList = useSelector(getAllBeveragesIdsFromOutletById(outletId!));

  const outletBarTypes = jsonContent.brand_recommendation[outletCountry?.id || 0];
  const recommendations: IRecommendation = outletBarTypes?.find(
    (recommendation: IRecommendation) =>
      recommendation.type === outletCategory || recommendation.type === "Other"
  );

  let beveragesRecommendation: string[] = [];
  if (recommendations) {
    let tapRecommendation: string[] = [];
    if (tapPositions.length > 0) {
      tapRecommendation = recommendations.taps[tapPositions[0] + 1] || [];
    }
    if (tapRecommendation.length === 0 && !recommendations.fixedTapNumber) {
      tapRecommendation = recommendations.taps[recommendations.taps.length - 1];
    }
    beveragesRecommendation = tapRecommendation.filter(bev => !outletBeverageList.includes(bev));
  }

  const beverages = useSelector((state: IAppState) =>
    getBeveragesByIds(state, beveragesRecommendation)
  );

  return beverages.length > 0 ? (
    <div className="relative w-full overflow-hidden flex flex-col items-center">
      <SectionTitle className="flex items-center justify-center">
        {content.manager_app_product_brand_recommendation_section_title}
      </SectionTitle>
      <div className="w-full box-border mt-lg py-md px-lg overflow-hidden">
        <GlobalCarouselStyles />
        <Carousel
          className="flex flex-row py-md h-auto self-center relative"
          infinite
          responsive={{
            all: {
              breakpoint: { max: 4000, min: 0 },
              items: 1
            }
          }}
        >
          {beverages &&
            beverages.map(beverage => (
              <BrandRecommendationItem
                key={beverage.id}
                beverage={beverage}
                outletCountryId={outletCountry?.id || ""}
              />
            ))}
        </Carousel>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BrandRecommendation;
