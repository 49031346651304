import { EInsightsNotificationType } from "../../../store/insights/types";
import InsightBrandConfirmation from "./items/InsightBrandConfirmation";
import InsightFaultySensor from "./items/InsightFaultySensor";
import InsightLeakLine from "./items/InsightLeakLine";
import InsightLeakChamber from "./items/InsightLeakChamber";
import InsightOpeningHours from "./items/InsightOpeningHours";
import InsightTargets from "./items/InsightTargets";
import InsightStaleKeg from "./items/InsightStaleKeg";
import InsightWeeklyTarget from "./items/InsightWeeklyTarget";
import InsightWeeklyVolume from "./items/InsightWeeklyVolume";
import InsightPeerComparison from "./items/InsightPeerComparison";
import InsightSpecialityMixPerformance from "./items/InsightSpecialityMixPerformance";
import InsightBestPerformanceHour from "./items/InsightBestPerformanceHour";
import InsightTapsPerformance from "./items/InsightTapsPerformance";
import InsightTemperature from "./items/InsightTemperature";
import InsightPouringQuality from "./items/InsightPouringQuality";
import InsightNoHeartbeat from "./items/InsightNoHeartbeat";
import InsightDisconnectedSensor from "./items/InsightDisconnectedSensor";
import InsightSystemAirLeak from "./items/InsightSystemAirLeak";
import InsightCleaningDue from "./items/InsightCleaningDue";
import InsightNfcTagUnavailable from "./items/InsightNfcTagUnavailable";

const InsightComponentMap = {
  [EInsightsNotificationType.BRAND_CONFIRMATION]: InsightBrandConfirmation,
  [EInsightsNotificationType.FAULTY_SENSOR]: InsightFaultySensor,
  [EInsightsNotificationType.LEAK_LINE]: InsightLeakLine,
  [EInsightsNotificationType.LEAK_CHAMBER]: InsightLeakChamber,
  [EInsightsNotificationType.MISSING_OPENING_HOURS]: InsightOpeningHours,
  [EInsightsNotificationType.MISSING_TARGET]: InsightTargets,
  [EInsightsNotificationType.STALE_KEG]: InsightStaleKeg,
  [EInsightsNotificationType.SPECIALITY_MIX_PERFORMANCE]: InsightSpecialityMixPerformance,
  [EInsightsNotificationType.BEST_PERFORMANCE_HOUR]: InsightBestPerformanceHour,
  [EInsightsNotificationType.POUR_QUALITY_SCORE]: InsightPouringQuality,
  [EInsightsNotificationType.WEEKLY_TARGET]: InsightWeeklyTarget,
  [EInsightsNotificationType.WEEKLY_TARGET_MIX_CS]: InsightWeeklyTarget,
  [EInsightsNotificationType.WEEKLY_VOLUME]: InsightWeeklyVolume,
  [EInsightsNotificationType.WEEKLY_MIX_CS_VOLUME]: InsightWeeklyVolume,
  [EInsightsNotificationType.PEER_COMPARISON_NEARBY]: InsightPeerComparison,
  [EInsightsNotificationType.PEER_COMPARISON_SIMILAR]: InsightPeerComparison,
  [EInsightsNotificationType.PEER_COMPARISON_CHAIN]: InsightPeerComparison,
  [EInsightsNotificationType.PRESSURIZING_TIME_DRIFT_INCREASE]: InsightTemperature,
  [EInsightsNotificationType.TAPS_PERFORMANCE]: InsightTapsPerformance,
  [EInsightsNotificationType.TEMPERATURE_ABOVE_LIMIT_WATER_TUBE]: InsightTemperature,
  [EInsightsNotificationType.TEMPERATURE_BELOW_LIMIT_WATER_TUBE]: InsightTemperature,
  [EInsightsNotificationType.TEMPERATURE_ABOVE_LIMIT_ROOM]: InsightTemperature,
  [EInsightsNotificationType.TEMPERATURE_FAN_ABOVE_LIMIT]: InsightTemperature,
  [EInsightsNotificationType.TEMPERATURE_DRIFT_FAN_DECREASE]: InsightTemperature,
  [EInsightsNotificationType.TEMPERATURE_DRIFT_FAN_INCREASE]: InsightTemperature,
  [EInsightsNotificationType.TEMPERATURE_DRIFT_WATER_TUBE_DECREASE]: InsightTemperature,
  [EInsightsNotificationType.TEMPERATURE_DRIFT_WATER_TUBE_INCREASE]: InsightTemperature,
  [EInsightsNotificationType.NO_HEARTBEATS]: InsightNoHeartbeat,
  [EInsightsNotificationType.DISCONNECTED_SENSOR]: InsightDisconnectedSensor,
  [EInsightsNotificationType.CLEANING_DUE_DATE_REACHED]: InsightCleaningDue,
  [EInsightsNotificationType.NFC_TAG_UNAVAILABLE]: InsightNfcTagUnavailable,
  [EInsightsNotificationType.SYSTEM_AIR_LEAK]: InsightSystemAirLeak
};

export const InsightComponentTestMap = {};

export default InsightComponentMap;
