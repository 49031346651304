import Time from "./Time";

export enum ETimeFrame {
  MINUTE = "MINUTE",
  HOUR = "HOUR",
  DAY = "DAY",
  YESTERDAY = "YESTERDAY",
  WEEK = "WEEK",
  LAST_4WEEKS = "4WEEKS",
  MONTH = "MONTH",
  YEAR = "YEAR",
  CUSTOM = "CUSTOM"
}

export type ITimeRange =
  | ETimeFrame.MINUTE
  | ETimeFrame.HOUR
  | ETimeFrame.DAY
  | ETimeFrame.YESTERDAY
  | ETimeFrame.WEEK
  | ETimeFrame.LAST_4WEEKS
  | ETimeFrame.MONTH
  | ETimeFrame.YEAR
  | ETimeFrame.CUSTOM;

export interface ITimeFrame {
  from: Time;
  to: Time;
  type?: ITimeRange;
}
