import React from "react";

interface IProps {
  fill?: string;
}

const SVGCompressor: React.FC<IProps> = ({ fill = "var(--color-grey-grey50)" }) => (
  <svg width="74" height="106" viewBox="0 0 74 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.6172 105.073L60.6172 38.6074H13.3829L13.3829 105.073H60.6172Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M48.0195 65.7572V38.6074H60.6153V105.073H48.0195"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path d="M25.9786 105.073H13.3828V38.6074H17.7192" stroke={fill} strokeMiterlimit="10" />
    <path d="M25.9766 44.8643V65.195" stroke={fill} strokeMiterlimit="10" />
    <path
      d="M69.3439 0.925781H4.65605C2.63687 0.925781 1 2.62122 1 4.71264V35.1389C1 37.2304 2.63687 38.9258 4.65605 38.9258H69.3439C71.3631 38.9258 73 37.2304 73 35.1389V4.71264C73 2.62122 71.3631 0.925781 69.3439 0.925781Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M58.6929 6.92578H15.3071C12.9284 6.92578 11 9.01601 11 11.5944V28.2571C11 30.8356 12.9284 32.9258 15.3071 32.9258H58.6929C61.0716 32.9258 63 30.8356 63 28.2571V11.5944C63 9.01601 61.0716 6.92578 58.6929 6.92578Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M49.1279 11.2393H25.6382C24.7334 11.2393 24 11.9938 24 12.9245V21.2833C24 22.214 24.7334 22.9685 25.6382 22.9685H49.1279C50.0327 22.9685 50.7661 22.214 50.7661 21.2833V12.9245C50.7661 11.9938 50.0327 11.2393 49.1279 11.2393Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M18.9062 41.9258V45.0712C18.9062 45.8246 19.5215 46.4355 20.2802 46.4355H21.858C22.6167 46.4355 23.2319 45.8246 23.2319 45.0712V44.7326C21.5066 44.2647 20.0021 43.2668 18.9062 41.9258Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M27.6875 44.7326V45.0712C27.6875 45.8246 28.3027 46.4355 29.0614 46.4355H30.6393C31.3979 46.4355 32.0132 45.8246 32.0132 45.0712V41.9258C30.9173 43.2668 29.4129 44.2647 27.6875 44.7326Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M21 51.9258C21.4731 54.076 24.5 60.4258 23.5 64.9258"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M30 51.9258C31.5231 56.299 41.0369 60.5185 48 54.4258C52 50.9258 52 41.9258 52 38.9258"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M25.5823 44.863C29.5571 44.863 32.8972 42.2056 33.8484 38.6074H17.3164C18.2673 42.2053 21.6077 44.863 25.5825 44.863H25.5823Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M46.7141 28.5605C47.6199 28.5605 48.3541 27.8314 48.3541 26.9321C48.3541 26.0328 47.6199 25.3037 46.7141 25.3037C45.8084 25.3037 45.0742 26.0328 45.0742 26.9321C45.0742 27.8314 45.8084 28.5605 46.7141 28.5605Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M40.3509 28.5605C41.2566 28.5605 41.9908 27.8314 41.9908 26.9321C41.9908 26.0328 41.2566 25.3037 40.3509 25.3037C39.4452 25.3037 38.7109 26.0328 38.7109 26.9321C38.7109 27.8314 39.4452 28.5605 40.3509 28.5605Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M34.0032 28.5605C34.9089 28.5605 35.6431 27.8314 35.6431 26.9321C35.6431 26.0328 34.9089 25.3037 34.0032 25.3037C33.0975 25.3037 32.3633 26.0328 32.3633 26.9321C32.3633 27.8314 33.0975 28.5605 34.0032 28.5605Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M27.6399 28.5605C28.5456 28.5605 29.2799 27.8314 29.2799 26.9321C29.2799 26.0328 28.5456 25.3037 27.6399 25.3037C26.7342 25.3037 26 26.0328 26 26.9321C26 27.8314 26.7342 28.5605 27.6399 28.5605Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M19.2541 46.9258H22.7459C22.8862 46.9258 23 47.0826 23 47.2759V50.1753C23 51.1416 22.4308 51.9258 21.7295 51.9258H20.2705C19.5692 51.9258 19 51.1416 19 50.1753V47.2759C19 47.0826 19.1138 46.9258 19.2541 46.9258Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M28.2541 46.9258H31.7459C31.8862 46.9258 32 47.0826 32 47.2759V50.1753C32 51.1416 31.4308 51.9258 30.7295 51.9258H29.2705C28.5692 51.9258 28 51.1416 28 50.1753V47.2759C28 47.0826 28.1138 46.9258 28.2541 46.9258Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M52.6009 65.1943H21.4048C20.4542 65.1943 19.6836 65.9486 19.6836 66.879V103.389C19.6836 104.32 20.4542 105.074 21.4048 105.074H52.6009C53.5515 105.074 54.3221 104.32 54.3221 103.389V66.879C54.3221 65.9486 53.5515 65.1943 52.6009 65.1943Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
  </svg>
);

export default SVGCompressor;
