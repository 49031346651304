import { IFilterState } from "../filter/types";

export const REQUEST_AGGREGATOR = "aggregator/REQUEST_AGGREGATOR";
export const REQUEST_AGGREGATOR_SUCCESS = "aggregator/REQUEST_AGGREGATOR_SUCCESS";
export const REQUEST_AGGREGATOR_ERROR = "aggregator/REQUEST_AGGREGATOR_ERROR";
export const SET_OFF_HOUR_HIGHLIGHTING = "filter/SET_OFF_HOUR_HIGHLIGHTING";

export interface IRequestAggregatorState {
  aggregators: { [key: string]: any };
  aggregatorsPending: { [key: string]: boolean };
  aggregatorsError: { [key: string]: boolean };
  offHourHighlighting: boolean;
}

export interface IRequestAggregator {
  type: typeof REQUEST_AGGREGATOR;
  payload: {
    id: string;
    filter: IFilterState;
  };
}
export interface IRequestAggregatorSuccess {
  type: typeof REQUEST_AGGREGATOR_SUCCESS;
  id: string;
  data: any;
}

export interface IRequestAggregatorError {
  type: typeof REQUEST_AGGREGATOR_ERROR;
  id: string;
}
export interface ISetOffHourHighlighting {
  type: typeof SET_OFF_HOUR_HIGHLIGHTING;
  payload: boolean;
}

export type RequestAggregatorActionTypes =
  | IRequestAggregator
  | IRequestAggregatorSuccess
  | IRequestAggregatorError
  | ISetOffHourHighlighting;
