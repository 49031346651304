import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { useIntersectionObserver } from "@ddm-design-system/hooks";
import { requestAggregator } from "../../../store/aggregator/actions";
import { getFilter } from "../../../store/filter/selectors";
import { getAggregator } from "../../../store/aggregator/reducer";
import "./withAggRequest.scss";

export interface IWithAggRequestProps {
  id: string;
  componentId?: string;
  error?: boolean;
  loading?: boolean;
  data?: {
    [key: string]: any;
  };
  className?: string;
  errorClassName?: string;
  noLoading?: boolean;
  render?: (props: any) => React.ReactElement;
  lazy?: boolean;
  intersectionOptions?: IntersectionObserverInit;
  forceReload?: () => void;
}

const DEFAULT_INTERSECTION_OPTIONS: IntersectionObserverInit = {
  root: document.querySelector("#root"),
  rootMargin: "0px 0px 200px 0px"
};

export const withAggRequest = <P extends IWithAggRequestProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const Component: React.FC<P> = ({
    id,
    componentId,
    className,
    noLoading,
    lazy = !!IntersectionObserver,
    intersectionOptions = DEFAULT_INTERSECTION_OPTIONS,
    ...props
  }) => {
    const dispatch = useDispatch();
    const filter = useSelector(getFilter);
    const { aggregatorsPending, aggregatorsError, aggregators } = useSelector(getAggregator);
    const [isVisible, setVisible] = useState(!lazy);
    const ref = useRef(null);

    const isLoading = aggregatorsPending && aggregatorsPending[id];
    const isError = aggregatorsError && aggregatorsError[id];
    const data = aggregators && aggregators[id];

    const loadData = useCallback(() => {
      dispatch(
        requestAggregator(id, { ...filter, componentIds: componentId ? [componentId] : [] })
      );
    }, [dispatch, filter, id, componentId]);

    useEffect(() => {
      if (isVisible && filter) {
        loadData();
      }
    }, [filter, isVisible, loadData]);

    const callback = useCallback(
      (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        if (entries.pop()?.isIntersecting) {
          setVisible(true);
          observer.disconnect();
        }
      },
      []
    );

    useIntersectionObserver(
      callback,
      lazy && ref.current ? ref.current || undefined : undefined,
      intersectionOptions
    );

    return (
      <div
        ref={ref}
        className={classnames(
          componentId ? "flex w-full" : "with-agg-container",
          isLoading && !noLoading && "loading",
          isError && "error",
          className
        )}
      >
        <WrappedComponent
          {...(props as any)}
          data={data}
          error={isError}
          loading={!noLoading && isLoading}
          forceReload={loadData}
        />
      </div>
    );
  };
  return Component;
};
