// import { DEFAULT_LANG } from "../../constants/CustomVariables";

import { translate, translateString } from "./translate";
import { IEntries } from "./index";

const DEFAULT_LANG = "en-GB";
export default function mapLanguageInfo(entries: IEntries, lang = DEFAULT_LANG) {
  const parsedLanguages = entries.languageInfo.ordered.map(languageInfo => ({
    code: translateString(languageInfo.fields.languageCode, lang),
    name: translateString(languageInfo.fields.languageName, DEFAULT_LANG),
    country: translateString(languageInfo.fields.languageCountry, lang),
    d3TimeFormat: translate(languageInfo.fields.d3TimeFormat, lang)
  }));

  return {
    languageInfo: parsedLanguages
  };
}
