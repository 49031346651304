import numeral from "numeral";
import { IconNames } from "@ddm-design-system/icon";
import {
  EControlUnitState,
  EHardFaults,
  ESoftFaults,
  IControlUnit,
  IPressureChamber,
  ISteelKegUnit
} from "./store/installation/types";
import { ERROR_STALE_BEER, WARNING_STALE_BEER } from "./constants";
import Time from "./lib/Time/Time";
import { BeverageTranslation } from "./store/beers/types";
import { EPerformanceLevel, IProductInfo } from "./store/product/types";
import BeerMode from "./components/installation/cleaning-unit-flow/BeerMode";
import CleaningStartMode from "./components/installation/cleaning-unit-flow/CleaningStartMode";
import PreRinseMode from "./components/installation/cleaning-unit-flow/PreRinseMode";
import CleanMode from "./components/installation/cleaning-unit-flow/CleanMode";
import SoakMode from "./components/installation/cleaning-unit-flow/SoakMode";
import PostRinseMode from "./components/installation/cleaning-unit-flow/PostRinseMode";
import PressureTest from "./components/installation/cleaning-unit-flow/PressureTest";
import AirFlushMode from "./components/installation/cleaning-unit-flow/AirFlushMode";
import WaterFlushMode from "./components/installation/cleaning-unit-flow/WaterFlushMode";
import SoftWaterNotDetected from "./components/installation/cleaning-unit-flow/faults/SoftWaterNotDetected";
import SoftDetergentNotDetected from "./components/installation/cleaning-unit-flow/faults/SoftDetergentNotDetected";
import SoftCleanStop from "./components/installation/cleaning-unit-flow/faults/SoftCleanStop";
import SoftHighPressure from "./components/installation/cleaning-unit-flow/faults/SoftHighPressure";
import StandbyMode from "./components/installation/cleaning-unit-flow/StandbyMode";
import HardDetergentNotCleared from "./components/installation/cleaning-unit-flow/faults/HardDetergentNotCleared";
import { IContent } from "./store/content/types";
import { IOutlet } from "./store/outlet/types";
import {
  IOutletPickerDistributorItem,
  IOutletPickerGroupItem
} from "./components/common/outlet_picker/OutletPicker";
import HardDdmPowerFault from "./components/installation/cleaning-unit-flow/faults/HardDdmPowerFault";
import HardPressureTooHigh from "./components/installation/cleaning-unit-flow/faults/HardPressureTooHigh";

export const colorFromCSSClass = (className: string) => {
  const tmp = document.createElement("div");
  tmp.style.cssText = "position:fixed;left:-100px;top:-100px;width:1px;height:1px";
  tmp.className = className;
  document.body.appendChild(tmp); // required in some browsers
  const color = getComputedStyle(tmp).getPropertyValue("color");
  document.body.removeChild(tmp);
  return color;
};

export const composeStyles = (
  baseStyles: { [key: string]: string },
  customStyles?: { [key: string]: string },
  composeStyle = "merge"
) => {
  if (!customStyles) {
    return baseStyles;
  }

  const composedStyles: { [key: string]: string } = {};
  const keys = Object.keys(baseStyles);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    let newValue = baseStyles[key];

    if (customStyles[key]) {
      if (composeStyle === "overwrite") {
        newValue = customStyles[key];
      } else {
        newValue += ` ${customStyles[key]}`;
      }
    }
    composedStyles[key] = newValue;
  }
  return composedStyles;
};

export function calculatePercentageChange(
  last: number | undefined = 0,
  first: number | undefined = 0
) {
  let val = 0;
  if (!(last === 0 && first === 0)) {
    val = first === 0 ? 100 : ((last - first) / first) * 100;
  }
  return val;
}

export function mapNotificationBadges(
  pressureChamber: IPressureChamber,
  outletCountry?: string
): IPressureChamber {
  const pressureChamberTag: Array<{
    icon: IconNames;
    type: string;
    level: "default" | "warning" | "danger" | "success" | "disabled";
    desc: string;
    replaceValue?: number;
    replaceValue2?: number;
  }> = [];

  if (pressureChamber.offline) {
    pressureChamberTag.push({
      icon: "Offline",
      type: "connection",
      level: "danger",
      desc: "connection_danger"
    });
  } else {
    pressureChamberTag.push({
      icon: "Offline",
      type: "connection",
      level: "success",
      desc: "connection_ok"
    });
  }

  let sensorProblem = false;
  if (!pressureChamber.isBeerDrive) {
    if (pressureChamber.offline) {
      pressureChamberTag.push({
        icon: "Sensor",
        type: "sensor",
        level: "success",
        desc: "sensor_offline"
      });
    } else if (pressureChamber.isFaulty) {
      sensorProblem = true;
      pressureChamberTag.push({
        icon: "Sensor",
        type: "sensor",
        level: "danger",
        desc: "sensor_danger"
      });
    } else if (!pressureChamber.sensorConnected) {
      sensorProblem = true;
      pressureChamberTag.push({
        icon: "Sensor",
        type: "sensor",
        level: "danger",
        desc: "sensor_not_connected"
      });
    } else {
      pressureChamberTag.push({
        icon: "Sensor",
        type: "sensor",
        level: "success",
        desc: "sensor_ok"
      });
    }

    if (
      !sensorProblem &&
      !pressureChamber.offline &&
      pressureChamber.existsLeak &&
      !pressureChamber.isOpen
    ) {
      pressureChamberTag.push({
        icon: "Leak",
        type: "exists_leak",
        level: "danger",
        desc: "leaking_danger"
      });
    }

    const diffStale = new Time(pressureChamber.lastMounted).diff(new Time());

    if (pressureChamber.offline) {
      pressureChamberTag.push({
        icon: "Sleep",
        type: "stale",
        level: "disabled",
        desc: "stale_offline"
      });
    } else if (sensorProblem) {
      pressureChamberTag.push({
        icon: "Sleep",
        type: "stale",
        level: "disabled",
        desc: "sensor_not_connected"
      });
    } else if (pressureChamber.isOpen) {
      pressureChamberTag.push({
        icon: "Sleep",
        type: "stale",
        level: "disabled",
        desc: "stale_open"
      });
    } else if (pressureChamber.volumeLeft === 0) {
      pressureChamberTag.push({
        icon: "Sleep",
        type: "stale",
        level: "disabled",
        desc: "stale_empty"
      });
    } else if (pressureChamber.hasPressureSwitch && diffStale >= WARNING_STALE_BEER) {
      if (diffStale >= ERROR_STALE_BEER) {
        pressureChamberTag.push({
          icon: "Sleep",
          type: "stale",
          level: "danger",
          desc: "stale_danger",
          replaceValue: diffStale,
          replaceValue2: diffStale - ERROR_STALE_BEER
        });
      } else {
        pressureChamberTag.push({
          icon: "Sleep",
          type: "stale",
          level: "warning",
          desc: "stale_warning",
          replaceValue: diffStale,
          replaceValue2: ERROR_STALE_BEER - diffStale
        });
      }
    } else {
      pressureChamberTag.push({
        icon: "Sleep",
        type: "stale",
        level: "success",
        desc: "stale_ok",
        replaceValue: diffStale
      });
    }

    // expiration date only used in Italy
    if (outletCountry === "IT" && pressureChamber.bestBefore) {
      const diffExpiration = new Time().diff(new Time(pressureChamber.bestBefore));
      if (pressureChamber.offline) {
        pressureChamberTag.push({
          icon: "CalendarCircle",
          type: "expiration",
          level: "disabled",
          desc: "expiration_offline"
        });
      } else if (sensorProblem) {
        pressureChamberTag.push({
          icon: "CalendarCircle",
          type: "expiration",
          level: "disabled",
          desc: "sensor_not_connected"
        });
      } else if (pressureChamber.isOpen) {
        pressureChamberTag.push({
          icon: "CalendarCircle",
          type: "expiration",
          level: "disabled",
          desc: "expiration_open"
        });
      } else if (diffExpiration <= 0) {
        pressureChamberTag.push({
          icon: "CalendarCircle",
          type: "expiration",
          level: "danger",
          desc: "expiration_danger",
          replaceValue: Math.abs(diffExpiration)
        });
      } else if (diffExpiration <= 7) {
        pressureChamberTag.push({
          icon: "CalendarCircle",
          type: "expiration",
          level: "warning",
          desc: "expiration_warning",
          replaceValue: diffExpiration
        });
      } else {
        pressureChamberTag.push({
          icon: "CalendarCircle",
          type: "expiration",
          level: "success",
          desc: "expiration_ok",
          replaceValue: diffExpiration
        });
      }
    }
  }

  return {
    ...pressureChamber,
    dangerTags: pressureChamberTag.filter(t => t.level === "danger").length,
    successTags: pressureChamberTag.filter(t => t.level === "success").length,
    tags: pressureChamberTag,
    warningTags: pressureChamberTag.filter(t => t.level === "warning").length
  };
}

export function mapNotificationBadgesSK(steelKegUnit: ISteelKegUnit): ISteelKegUnit {
  const pressureChamberTag: Array<{
    icon: IconNames;
    type: string;
    level: "default" | "warning" | "danger" | "success" | "disabled";
    desc: string;
    replaceValue?: number;
    replaceValue2?: number;
  }> = [];

  if (steelKegUnit.offline) {
    pressureChamberTag.push({
      icon: "Offline",
      type: "connection",
      level: "danger",
      desc: "connection_danger"
    });
  } else {
    pressureChamberTag.push({
      icon: "Offline",
      type: "connection",
      level: "success",
      desc: "connection_ok"
    });
  }

  return {
    ...steelKegUnit,
    dangerTags: pressureChamberTag.filter(t => t.level === "danger").length,
    successTags: pressureChamberTag.filter(t => t.level === "success").length,
    tags: pressureChamberTag,
    warningTags: pressureChamberTag.filter(t => t.level === "warning").length
  };
}

export function accumulate(arr: any[]) {
  let sum = 0;
  const newArr: any = [];
  arr.forEach((d: any) => {
    sum += +d[1];
    if (d[0] <= new Time().getTime()) {
      newArr.push([d[0], sum]);
    }
  });
  return newArr;
}

export function generateInitials(brand: string | undefined, subbrand: string | undefined) {
  let initials = brand ? brand[0] : "";

  const words = subbrand?.split(" ");
  const firstWord = words?.[0];
  const secondWord = words?.[1];

  if (firstWord) {
    initials += Number.isInteger(parseInt(firstWord[0]))
      ? firstWord[firstWord.length - 1]
      : firstWord[0];

    if (secondWord) {
      initials += Number.isInteger(parseInt(secondWord[0]))
        ? secondWord[secondWord.length - 1]
        : secondWord[0];
    }
  } else {
    initials += brand?.[1] || "";
    initials += brand?.[2] || "";
  }

  return initials;
}

export function getBeverageLogoOptions(beverage?: BeverageTranslation) {
  if (!beverage) {
    return {
      backgroundColor: undefined,
      img: {
        src: "",
        alt: undefined
      },
      circleClassName: "image-avatar-circle",
      fallbackText: "--"
    };
  }

  const backgroundColor = beverage.mainColor || "var(--color-grey-grey50)";
  return {
    backgroundColor,
    img: {
      alt: beverage.name,
      src: beverage.logoUrl || ""
    },
    circleClassName: "image-avatar-circle",
    fallbackText: beverage.initials || generateInitials(beverage.brand, beverage.subbrand)
  };
}

export function validateEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
    email
  );
}

export function isInViewport(elem: Element | null, isMobile: boolean) {
  if (!elem) {
    return false;
  }

  const bounding = elem.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // for mobile, the bottom bar's height must be taken into account
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (isMobile ? windowHeight - 55 : windowHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function formatNumber(value: number, unit = "L", usePlus = true, useDecimals = false) {
  if (value === 0) {
    return `${numeral(value).format(`0${useDecimals ? ".00" : ""}`)}${unit ?? ""}`;
  }
  if (!usePlus && value > 0 && value < 1) {
    return `<1${useDecimals ? ".00" : ""}${unit ?? ""}`;
  }

  return `${numeral(value).format(`${usePlus ? "+" : ""}0${useDecimals ? ".00" : ""}`)}${
    unit ?? ""
  }`;
}

export function isInteger(
  value: any,
  options: { allowNegative?: boolean; allowZero?: boolean } = {
    allowNegative: false,
    allowZero: false
  }
) {
  const { allowNegative = false, allowZero = false } = options;

  if (allowNegative) {
    return allowZero ? /^-?(0|[1-9]\d*)$/.test(value) : /^-?[1-9]\d*$/.test(value);
  }

  return allowZero ? /^(0|[1-9]\d*)$/.test(value) : /^[1-9]\d*$/.test(value);
}

export function getFeedbackFormUrl(lang: string) {
  return `https://forms.office.com/Pages/ResponsePage.aspx?id=9kctiPRQVEWapkOkZBaw8HdW6w0TtHVOi89irqvg41VUMDBCMzA5VDcyVVVSTEJTTzhXTTJBMVU3SCQlQCN0PWcu&lang=${lang}`;
}

export function getPerformanceBadgeIcon(
  performance: IProductInfo["performance"] | undefined,
  validVolumePercentage: number | null
) {
  return !validVolumePercentage
    ? "defective"
    : performance === EPerformanceLevel.overperforming
    ? "success"
    : performance === EPerformanceLevel.good
    ? "default"
    : "warning";
}

export const sleep = (milliseconds = 0) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve();
    }, milliseconds)
  );
};

export const formatReadableNumbersToLiters = (value: string, format = "0.[0]a") =>
  `${numeral(value).format(format)} L`;

export const isDifferentHours = (
  array1: { weekday: number; startTime: number; endTime: number }[],
  array2: { weekday: number; startTime: number; endTime: number }[]
) => {
  if (array1.length !== array2.length) return true;

  return array1.some(day => {
    return !array2.some(
      a2 =>
        a2.weekday === day.weekday && a2.startTime === day.startTime && a2.endTime === day.endTime
    );
  });
};

export const getValuesFromCUState = (content: IContent, controlUnit: IControlUnit) => {
  let Component;
  let step = 0;
  let mode = "";
  let showStatus = false;
  let faultyClass = "";
  switch (controlUnit.state) {
    case EControlUnitState.BEER_POUR:
      Component = BeerMode;
      mode = content.manager_app_cu_step_beer_mode_title;
      showStatus = true;
      break;
    case EControlUnitState.CLEAN_CONFIRM:
      Component = CleaningStartMode;
      mode = content.manager_app_cu_step_cleaning_start_mode_title;
      step = 0;
      break;
    case EControlUnitState.CLEAN_PRE_RINSE_WATER:
      Component = PreRinseMode;
      mode = content.manager_app_cu_step_prerinse_mode_title;
      step = 1;
      break;
    case EControlUnitState.CLEAN_LOAD_DETERGENT:
    case EControlUnitState.CLEAN_PRIME_DETERGENT:
    case EControlUnitState.CLEAN_DISPENSE:
      mode = content.manager_app_cu_step_clean_mode_title;
      Component = CleanMode;
      step = 2;
      break;
    case EControlUnitState.CLEAN_SOAK:
      Component = SoakMode;
      mode = content.manager_app_cu_step_soak_mode_title;
      step = 3;
      break;

    case EControlUnitState.CLEAN_POST_RINSE_DETERGENT:
    case EControlUnitState.CLEAN_POST_RINSE_DETERGENT_SAFETY:
    case EControlUnitState.CLEAN_POST_RINSE_WATER:
      Component = PostRinseMode;
      mode = content.manager_app_cu_step_postrinse_mode_title;
      step = 4;
      break;
    case EControlUnitState.PRESSURE_TEST:
      Component = PressureTest;
      mode = content.manager_app_cu_step_pressure_test_title;
      showStatus = true;
      break;
    case EControlUnitState.AIR_FLUSH:
      Component = AirFlushMode;
      mode = content.manager_app_cu_step_air_flush_title;
      showStatus = true;
      break;
    case EControlUnitState.WATER_FLUSH:
      Component = WaterFlushMode;
      mode = content.manager_app_cu_step_water_flush_title;
      showStatus = true;
      break;
    case EControlUnitState.SOFT_FAULT:
      faultyClass = "!border-warning-warning100";
      showStatus = true;
      mode = "--";
      switch (controlUnit.softFault) {
        case ESoftFaults.WATER_NOT_DETECTED:
          Component = SoftWaterNotDetected;
          break;
        case ESoftFaults.DETERGENT_NOT_DETECTED:
          Component = SoftDetergentNotDetected;
          break;
        case ESoftFaults.STOP_CLEAN:
          Component = SoftCleanStop;
          break;
        case ESoftFaults.LINE_PRESSURISED:
          Component = SoftHighPressure;
          break;
        default:
          Component = StandbyMode;
          mode = content.manager_app_cu_step_standby_mode_title;
          break;
      }
      break;
    case EControlUnitState.HARD_FAULT:
      faultyClass = "!border-alert-alert100";
      showStatus = true;
      mode = "--";
      switch (controlUnit.hardFault) {
        case EHardFaults.DETERGENT_NOT_CLEARED:
          Component = HardDetergentNotCleared;
          break;
        case EHardFaults.PRESSURE_TOO_HIGH:
          Component = HardPressureTooHigh;
          break;
        case EHardFaults.DDM_POWER_FAULT:
          Component = HardDdmPowerFault;
          break;
        default:
          Component = StandbyMode;
          mode = content.manager_app_cu_step_standby_mode_title;
      }
      break;
    default:
      Component = StandbyMode;
      mode = content.manager_app_cu_step_standby_mode_title;
      showStatus = true;
  }

  return {
    Component,
    step,
    mode,
    showStatus,
    faultyClass
  };
};

export const getOutletsFromGroup = (group: IOutletPickerGroupItem): IOutlet[] => {
  let outletsT: IOutlet[] = [];
  if (group?.type === "DISTRIBUTOR") {
    outletsT = group?.outlets;
  } else if (group?.type === "PG") {
    outletsT = group?.distributors.reduce(
      (agg: IOutlet[], d: IOutletPickerDistributorItem) => agg.concat(d.outlets),
      []
    );
  }
  return outletsT;
};
