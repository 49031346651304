import React from "react";
import { Divider } from "@ddm-design-system/divider";
import { DescriptionMedium } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber } from "../../store/installation/types";

interface IProps {
  pressureChamber: IPressureChamber;
}

export const PressureChamberDescription: React.FC<IProps> = ({ pressureChamber }) => {
  const { managerAppCommon: content } = useContent();

  return (
    <div
      data-test-id="pressureChamber-description"
      className={`flex flex-1 flex-col justify-between w-full mt-md ${
        pressureChamber.isBeerDrive && "pl-0 max-w-none"
      }`}
    >
      <div className="flex justify-center relative w-full mb-md">
        <DescriptionMedium>
          <span className="text-grey-grey100 pr-xxs">{content.common_keg}</span> 
          {pressureChamber.position || "--"}
          <span 
            className={
            `px-xxs ${pressureChamber.status === "danger" ? "text-alert-alert100" : 
                      pressureChamber.status === "warning" ? "text-warning-warning100" :
                      "text-grey-greenish"} 
        `}>
            |
          </span>
          <span className="text-grey-grey100 pr-xxs">{content.common_tap}</span>
          {pressureChamber.tapPosition?.filter((v, i, a) => a.indexOf(v) === i).join(", ") || "--"}
        </DescriptionMedium>
      </div>
      <Divider className={
            ` ${pressureChamber.status === "danger" ? "!bg-alert-alert100" : 
                pressureChamber.status === "warning" ? "!bg-warning-warning100" :
                "!bg-grey-greenish"} 
        `}
      />
    </div>
  );
};

export default PressureChamberDescription;
