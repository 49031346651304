import { LinkButton } from "@ddm-design-system/button";
import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Routes from "../../../../routes";
import { AnalyticsContext } from "../../../../services/analytics";
import { setInsightsNotificationsClose, setInsightsStoriesClose } from "../../../../store/insights/actions";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import { setFilterOutlets, setTimeFrame } from "../../../../store/filter/actions";
import { getOutletById } from "../../../../store/outlet/selectors";
import { ETimeFrame } from "../../../../lib/Time/types";
import { setStartEndTimeRange } from "../../../../lib/Time/TimeFunctions";
import InsightStory from "../InsightStory";

export interface IInsightSpecialityMixPerformanceProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightSpecialityMixPerformance: React.FC<IInsightSpecialityMixPerformanceProps> = ({
  insight, 
  itemType
}) => {
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const outlet = useSelector(getOutletById(insight.locationId));
  const { managerAppInsights: content } = useContent();
  const { percentage }: any = insight.customFields;
  const positive = percentage >= 0;

  const callToAction = useCallback(() => {
    analytics.logEvent("CLOSE_INSIGHTS_WITH_CALL_TO_ACTION", insight.type);
    dispatch(setInsightsNotificationsClose(insight.locationId));
    dispatch(setInsightsStoriesClose());
    if (outlet) {
      dispatch(setFilterOutlets([outlet]));
      dispatch(
        setTimeFrame({
          from: setStartEndTimeRange(ETimeFrame.WEEK).start,
          to: setStartEndTimeRange(ETimeFrame.WEEK).end,
          type: ETimeFrame.WEEK
        })
      );
    }
    history.push(Routes.sales);
  }, [analytics, dispatch, history, insight.locationId, insight.type, outlet]);

  const title = positive
    ? content.manager_app_insights_speciality_mix_title_up
    : content.manager_app_insights_speciality_mix_title_down;

  const desc = positive
    ? content.manager_app_insights_speciality_mix_desc_up
    : content.manager_app_insights_speciality_mix_desc_down;

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={title.replace("%PERC%", `${Math.round(Math.abs(percentage || 0))}%`)}
          description={desc}
          icon="Chart"
          iconOptions={{ name: "Chart", className: "text-white fill-current" }}
          insight={insight}
          avatarOptions={{
            backgroundColor: positive
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
          footer={
            <div className="flex items-center justify-end">
              <LinkButton onClick={callToAction}>
                {content.manager_app_insights_speciality_mix_cta}
              </LinkButton>
            </div>
      }
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={title.replace("%PERC%", `${Math.round(Math.abs(percentage || 0))}%`)}
          icon="Chart"
          iconOptions={{ name: "Chart", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: positive
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={title.replace("%PERC%", `${Math.round(Math.abs(percentage || 0))}%`)}
          description={desc}
          icon="Chart"
          iconOptions={{ name: "Chart", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: positive
            ? "var(--color-success-success100)"
            : "var(--color-alert-alert100)"
          }}
          insight={insight}
          callToAction={callToAction}
          actionTitle={content.manager_app_insights_speciality_mix_cta}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightSpecialityMixPerformance;
