import { mapNotificationBadges, mapNotificationBadgesSK } from "../../helpers";
import Time from "../../lib/Time/Time";
import { BeverageTranslation } from "../beers/types";
import {
  IApiBeerDrive,
  IApiPressureChamber,
  IApiSteelKegUnit,
  IPressureChamber,
  ISteelKegUnit
} from "./types";

export const mapPressureChambers = (installation: any) =>
  (installation.pressureChambers || [])
    .map(
      ({
        alive,
        existsLeak,
        faulty: isFaulty = false,
        id,
        keg,
        lastMounted,
        lastUsed,
        name,
        open: isOpen,
        position,
        sensorConnected,
        taps,
        thingId,
        hasPressureSwitch
      }: IApiPressureChamber) => {
        const mappedPressureChamber: IPressureChamber = {
          bestBefore: keg?.bestBefore ? new Time(keg.bestBefore).getTime() : undefined,
          beverage: new BeverageTranslation(),
          beverageId: keg?.beverage?.id,
          existsLeak,
          dangerTags: 0,
          id,
          isBeerDrive: false,
          isFaulty,
          isOpen: sensorConnected && !isFaulty && alive && isOpen,
          lastMounted: new Time(lastMounted).getTime(),
          lastUsed: lastUsed ? new Time(lastUsed).getTime() : 0,
          name: name || "No Name",
          noKeg: false,
          // turn pressure chamber online if it is the only one offline due to being faulty
          offline:
            isFaulty && !alive
              ? installation?.pressureChambers.filter((p: IApiPressureChamber) => !p.alive)
                  .length !== 1
              : !alive,
          parentName: installation.outletName,
          position,
          sensorConnected,
          successTags: 0,
          tags: [],
          tapPosition: taps.map(tp => tp.position).sort(),
          thingId,
          volume: keg?.volume ? keg.volume / 1000 : 0,
          volumeLeft: keg?.volumeLeft ? keg.volumeLeft / 1000 : 0,
          warningTags: 0,
          hasPressureSwitch
        };

        return mapNotificationBadges(mappedPressureChamber, installation.outletCountry);
      }
    )
    .sort((pcA: IPressureChamber, pcB: IPressureChamber) => {
      return pcA.position < pcB.position ? -1 : 1;
    });

export const mapOutletPressureChambers = (
  pressureChambers: any,
  outletName: string,
  country?: string
) =>
  (pressureChambers || [])
    .map(
      ({
        alive,
        existsLeak,
        faulty: isFaulty = false,
        id,
        keg,
        lastMounted,
        lastUsed,
        name,
        open: isOpen,
        position,
        sensorConnected,
        taps,
        thingId,
        hasPressureSwitch
      }: IApiPressureChamber) => {
        const mappedPressureChamber: IPressureChamber = {
          bestBefore: keg?.bestBefore ? new Time(keg.bestBefore).getTime() : undefined,
          beverage: new BeverageTranslation(),
          beverageId: keg?.beverage?.id,
          existsLeak,
          dangerTags: 0,
          id,
          isBeerDrive: false,
          isFaulty,
          isOpen: sensorConnected && !isFaulty && alive && isOpen,
          lastMounted: new Time(lastMounted).getTime(),
          lastUsed: lastUsed ? new Time(lastUsed).getTime() : 0,
          name: name || "No Name",
          noKeg: false,
          // turn pressure chamber online if it is the only one offline due to being faulty
          offline:
            isFaulty && !alive
              ? pressureChambers.filter((p: IApiPressureChamber) => !p.alive).length !== 1
              : !alive,
          parentName: outletName,
          position,
          sensorConnected,
          successTags: 0,
          tags: [],
          tapPosition: taps.map(tp => tp.position).sort(),
          thingId,
          volume: keg?.volume ? keg.volume / 1000 : 0,
          volumeLeft: keg?.volumeLeft ? keg.volumeLeft / 1000 : 0,
          warningTags: 0,
          hasPressureSwitch
        };

        return mapNotificationBadges(mappedPressureChamber, country);
      }
    )
    .sort((pcA: IPressureChamber, pcB: IPressureChamber) => {
      return pcA.position < pcB.position ? -1 : 1;
    });

export const mapOutletBeerDrives = (beerDrives: any, outletName: string) =>
  (beerDrives || [])
    .map(({ alive, id, name, position, tank, taps, thingId }: IApiBeerDrive) => {
      const mappedBeerDrive: IPressureChamber = {
        beverage: new BeverageTranslation(),
        beverageId: tank?.beverage?.id,
        dangerTags: 0,
        id,
        isBeerDrive: true,
        lastMounted: tank?.lastRefill ? new Time(tank.lastRefill).getTime() : 0,
        lastUsed: tank?.lastDraft ? new Time(tank.lastDraft).getTime() : 0,
        name: name || "No Name",
        offline: !alive,
        parentName: outletName,
        position,
        successTags: 0,
        tags: [],
        tapPosition: taps.map(tp => tp.position).sort(),
        thingId,
        volume: tank?.capacity ? tank.capacity / 1000 : 0,
        volumeLeft: tank?.volumeLeft ? tank.volumeLeft / 1000 : 0,
        warningTags: 0
      };

      return mapNotificationBadges(mappedBeerDrive);
    })
    .sort((bdA: IPressureChamber, bdB: IPressureChamber) => {
      return bdA.position < bdB.position ? -1 : 1;
    });

export const mapBeerDrives = (installation: any) =>
  (installation.beerDrives || [])
    .map(({ alive, id, name, position, tank, taps, thingId }: IApiBeerDrive) => {
      const mappedBeerDrive: IPressureChamber = {
        beverage: new BeverageTranslation(),
        beverageId: tank?.beverage?.id,
        dangerTags: 0,
        id,
        isBeerDrive: true,
        lastMounted: tank?.lastRefill ? new Time(tank.lastRefill).getTime() : 0,
        lastUsed: tank.lastDraft ? new Time(tank.lastDraft).getTime() : 0,
        name: name || "No Name",
        offline: !alive,
        parentName: installation.outletName,
        position,
        successTags: 0,
        tags: [],
        tapPosition: taps.map(tp => tp.position).sort(),
        thingId,
        volume: tank?.capacity ? tank.capacity / 1000 : 0,
        volumeLeft: tank?.volumeLeft ? tank.volumeLeft / 1000 : 0,
        warningTags: 0
      };

      return mapNotificationBadges(mappedBeerDrive);
    })
    .sort((bdA: IPressureChamber, bdB: IPressureChamber) => {
      return bdA.position < bdB.position ? -1 : 1;
    });

export const mapOutletSteelKegUnits = (steelKegUnits: any, outletName: string) =>
  (steelKegUnits || [])
    .map(({ alive, id, name, position, steelKeg, taps, thingId }: IApiSteelKegUnit) => {
      const mappedSteelKegUnit: ISteelKegUnit = {
        beverage: new BeverageTranslation(),
        beverageId: steelKeg?.beverage?.id,
        steelKeg,
        dangerTags: 0,
        id,
        lastUsed: steelKeg?.lastDraft ? new Time(steelKeg.lastDraft).getTime() : 0,
        name: name || "No Name",
        offline: !alive,
        parentName: outletName,
        position,
        successTags: 0,
        tags: [],
        tapPosition: taps.map(tp => tp.position).sort(),
        thingId,
        warningTags: 0
      };

      return mapNotificationBadgesSK(mappedSteelKegUnit);
    })
    .sort((bdA: ISteelKegUnit, bdB: ISteelKegUnit) => {
      return bdA.position < bdB.position ? -1 : 1;
    });

export const mapSteelKegUnits = (installation: any) =>
  (installation.steelKegUnits || [])
    .map(({ alive, id, name, position, steelKeg, taps, thingId }: IApiSteelKegUnit) => {
      const mappedSteelKegUnit: ISteelKegUnit = {
        beverage: new BeverageTranslation(),
        beverageId: steelKeg?.beverage?.id,
        dangerTags: 0,
        id,
        lastUsed: steelKeg.lastDraft ? new Time(steelKeg.lastDraft).getTime() : 0,
        name: name || "No Name",
        steelKeg,
        offline: !alive,
        parentName: installation.outletName,
        position,
        successTags: 0,
        tags: [],
        tapPosition: taps.map(tp => tp.position).sort(),
        thingId,
        warningTags: 0
      };

      return mapNotificationBadgesSK(mappedSteelKegUnit);
    })
    .sort((bdA: ISteelKegUnit, bdB: ISteelKegUnit) => {
      return bdA.position < bdB.position ? -1 : 1;
    });
