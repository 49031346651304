import React, { useState } from "react";
import { LinkButton } from "@ddm-design-system/button";

interface IInfoSwitcherProps {
  controlUnitId: string;
  outerChildren?: React.ReactNode;
  innerChildren?: React.ReactNode;
  label: string;
  labelAtBottom?: boolean;
}

const InfoSwitcher: React.FC<IInfoSwitcherProps> = ({
  controlUnitId,
  outerChildren,
  innerChildren,
  label,
  labelAtBottom = false
}) => {
  const [checked, setChecked] = useState(false);

  const handleSwitch = () => {
    setChecked(!checked);
  };

  const LinkLabel = () => (
    <label className="cursor-pointer" htmlFor={controlUnitId}>
      <LinkButton className="pointer-events-none" icon="ChevronDown">
        {label}
      </LinkButton>
    </label>
  );

  return (
    <div className="h-full">
      <input
        type="checkbox"
        id={controlUnitId}
        checked
        onChange={handleSwitch}
        className="hidden"
      />
      <div
        className={`flex flex-col gap-md items-center !h-full opacity-100 duration-500 ease-in-out
        ${checked && "!opacity-0 -translate-y-full"}`}
      >
        <div className="flex flex-1 flex-col gap-md items-center justify-between">
          {outerChildren}
          {!labelAtBottom && <LinkLabel />}
        </div>
        {labelAtBottom && <LinkLabel />}
      </div>
      <div
        className={`flex flex-col gap-md items-center !h-full opacity-0 duration-500 ease-in-out
        ${checked && "!opacity-100 -translate-y-full"}`}
      >
        {innerChildren}
      </div>
    </div>
  );
};

export default InfoSwitcher;
