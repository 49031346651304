import React, { useEffect, useMemo, useState } from "react";
import { ESidebarDirection, ESidebarModes } from "@ddm-design-system/sidebar";
import {
  Description,
  DescriptionMedium,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { Divider } from "@ddm-design-system/divider";
import ReactMarkdown from "react-markdown";
import useContent from "../../hooks/useContent";
import { IControlUnit } from "../../store/installation/types";
import { StyledSideBar } from "./PressureChamberDetailsSidebar";
import SVGControlUnit from "./cleaning-unit-flow/assets/control-unit";
import { StyledStepper } from "./cleaning-unit-flow/common.styles";
import { getValuesFromCUState } from "../../helpers";

export interface IProps {
  controlUnit: IControlUnit | undefined;
  isVisible: boolean;
  handleClose: () => void;
}

const ControlUnitDetailsSidebar: React.FC<IProps> = ({ controlUnit, isVisible, handleClose }) => {
  const {
    managerAppControlUnit: content,
    managerAppInstallation: contentInstallation,
    managerAppCommon: common
  } = useContent();
  const [fixedBeverageInfo, setFixedBeverageInfo] = useState(false);
  const { position, alive } = controlUnit || {};

  const { step, mode } = useMemo(() => {
    if (!controlUnit)
      return { Component: () => <></>, step: 0, mode: "", showStatus: false, faultyClass: "" };

    return getValuesFromCUState(content, controlUnit);
  }, [controlUnit]);

  const cleaningDue = Math.floor((controlUnit?.cleaningDue || 0) / (3600 * 24));

  const sidebarRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (sidebarRef?.current) {
      const sidebarElement = sidebarRef.current;
      const onScroll = () => {
        const shouldShowFixedBeverageInfo = sidebarElement.scrollTop > 130;
        if (shouldShowFixedBeverageInfo !== fixedBeverageInfo) {
          setFixedBeverageInfo(shouldShowFixedBeverageInfo);
        }
      };
      sidebarElement.addEventListener("scroll", onScroll);
      return () => sidebarElement.removeEventListener("scroll", onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarRef.current, fixedBeverageInfo]);

  return (
    <StyledSideBar
      ref={sidebarRef}
      className="!fixed overflow-auto max-h-screen !w-[432px] xs:!w-full xs:mt-xxxl xs:rounded-t-10 xs:!h-[calc(100%-64px)]"
      open={isVisible}
      onClose={handleClose}
      mode={ESidebarModes.OVER}
      direction={ESidebarDirection.RIGHT}
    >
      {isVisible && controlUnit ? (
        <>
          <div
            className="flex flex-col items-center text-center pb-xxl relative"
            data-test-id="controlUnit-sidebar"
          >
            <div className="flex items-center justify-between sticky box-border bg-white p-lg top-0 w-full border-b border-grey-grey50 z-[1] ">
              <Subtitle>
                {(content.manager_app_cu_control_unit_title || "").replace(
                  "%POS%",
                  (position || "--").toString()
                )}
              </Subtitle>
              <Icon
                data-test-id="close-controlUnit-sidebar"
                name="Close"
                className="cursor-pointer"
                size={16}
                onClick={handleClose}
              />
            </div>
            <div className="flex flex-col items-center text-center box-border w-full px-lg">
              <div className="my-xxl transform-13">
                <SVGControlUnit />
              </div>
              <SectionTitle>
                <DescriptionMedium className="text-grey-grey100 mr-xxs">
                  {content.manager_app_cu_mode}
                </DescriptionMedium>
                <DescriptionMedium data-test-id="sidebar-ncu-mode">{mode}</DescriptionMedium>
              </SectionTitle>
            </div>
            <Divider className="my-xl !bg-grey-grey50" />
            <div className="w-full px-xxl box-border">
              <SectionTitle>{content.manager_app_cu_cleaning_flow}</SectionTitle>
              <StyledStepper steps={5} activeStep={step} className="pt-md" />
            </div>
            <Divider className="my-xl !bg-grey-grey50" />
            <div className="flex flex-col box-border items-center text-center w-full px-lg">
              <SectionTitle>{contentInstallation.manager_app_general_status}</SectionTitle>

              <div
                className={`border border-grey-greenish rounded-10 w-[calc(100%-48px)] m-lg mb-0 p-lg 
                        ${!alive ? "opacity-50" : "opacity-100"} 
                        ${!alive && "border-alert-alert50"} 
                      `}
              >
                <span className="flex items-center justify-center mb-sm">
                  <Icon
                    className={`border border-transparent rounded-[50%] mr-xs 
                            ${
                              alive &&
                              "text-white fill-current border-success-success100 bg-success-success100"
                            } 
                           
                            ${
                              !alive &&
                              "text-white fill-current border-alert-alert100 bg-alert-alert100"
                            }  
                          `}
                    name="Offline"
                    size={16}
                  />
                  <DescriptionMedium>
                    {contentInstallation.manager_app_connection_title}
                  </DescriptionMedium>
                </span>
                <Description>
                  {!alive ? (
                    common.common_offline
                  ) : (
                    <ReactMarkdown>
                      {contentInstallation.main_status_details_connection_ok || ""}
                    </ReactMarkdown>
                  )}
                </Description>
              </div>

              <div
                className={`border border-grey-greenish rounded-10 w-[calc(100%-48px)] m-lg mb-0 p-lg 
                        ${!alive ? "opacity-50" : "opacity-100"} 
                        ${alive && cleaningDue <= 0 && "border-alert-alert50 text-alert-alert100"} 
                        ${
                          alive && cleaningDue > 0 && cleaningDue <= 3 && "border-warning-warning50"
                        } 
                      `}
              >
                <span className="flex items-center justify-center mb-sm">
                  <Icon
                    className={`border border-transparent rounded-[50%] mr-xs text-black fill-current 
                            ${cleaningDue <= 0 ? "text-alert-alert100 fill-current" : ""} 
                          `}
                    name="Cleaning"
                    size={16}
                  />
                  <DescriptionMedium>
                    {contentInstallation.manager_app_cleaning_title}
                  </DescriptionMedium>
                </span>
                <Description>
                  {!alive
                    ? common.common_offline
                    : cleaningDue < 0
                    ? (content.manager_app_cu_cleaning_due_danger || "").replace(
                        "%DAYS%",
                        Math.abs(cleaningDue).toString()
                      )
                    : (content.manager_app_cu_cleaning_due_default || "").replace(
                        "%DAYS%",
                        Math.abs(cleaningDue).toString()
                      )}
                </Description>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </StyledSideBar>
  );
};

export default ControlUnitDetailsSidebar;
