import React from "react";
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from "react-router";
import { TertiaryButton } from "@ddm-design-system/button";
import { Body, SectionTitle } from "@ddm-design-system/typography";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Tab, TabGroup } from "@ddm-design-system/tab";
import { Icon } from "@ddm-design-system/icon";
import { useIsMobile } from "@ddm-design-system/hooks";
import { useSelector } from "react-redux";
import useContent from "../../hooks/useContent";
import Routes from "../../routes";
import ProfileMobile from "../common/profile/ProfileMobile";
import { SettingsProfile } from "../settings-profile/SettingsProfile";
import SettingsOutlets from "../settings-outlets/SettingsOutlets";
import { SettingsUser } from "../settings-users/SettingsUser";
import { getUserRole } from "../../store/profile/reducer";
import { ERole } from "../../store/users/types";

export const Settings: React.FC<RouteComponentProps> = ({ location }) => {
  const { managerAppSettings: settingsContent } = useContent();
  const history = useHistory();
  const isMobile = useIsMobile();
  const userRole = useSelector(getUserRole);

  const isPGOrDistributor =
    userRole === ERole.ROLE_DISTRIBUTOR || userRole === ERole.ROLE_PURCHASING_GROUP;

  const tabs = [
    {
      title: settingsContent.manager_app_settings_account_tab,
      description: settingsContent.manager_app_settings_account_tab_desc,
      route: Routes.profile
    },
    {
      title: settingsContent.manager_app_settings_user_management_tab,
      description: settingsContent.manager_app_settings_user_management_tab_desc,
      route: Routes.users
    },
    {
      title: settingsContent.manager_app_settings_outlet_info_tab,
      description: settingsContent.manager_app_settings_outlet_info_tab_desc,
      route: Routes.outlets
    }
  ];

  const selectedTab = tabs.findIndex(t => t.route === location.pathname);

  const renderTabs = () => (
    <TabGroup
      selectedTab={selectedTab}
      onTabClick={index => history.push(tabs[index as number]?.route)}
    >
      {(isPGOrDistributor ? tabs.filter(tab => tab.route !== Routes.users) : tabs).map(tab => (
        <Tab key={tab.title} label={tab.title} />
      ))}
    </TabGroup>
  );

  const renderList = () => (
    <div className="py-xl px-0 mb-xxl">
      {tabs.map(tab => (
        <div
          key={tab.title}
          data-test-id={tab.route}
          className="cursor-pointer text-left py-lg px-0 border-grey-grey25 border-solid border-b"
          onClick={() => history.push(tab.route)}
        >
          <div className="mb-xs w-full flex justify-between items-center">
            <SectionTitle>{tab.title}</SectionTitle>
            <Icon name="ChevronRight" />
          </div>
          <Body className="text-grey-grey100">{tab.description}</Body>
        </div>
      ))}
    </div>
  );

  // workaround for delay on isMobile and prevent redirect
  const renderProfile = () =>
    isMobile || window?.innerWidth < 700 ? (
      <div>
        <ProfileMobile />
        {renderList()}
      </div>
    ) : (
      <Redirect to={Routes.profile} />
    );

  return settingsContent ? (
    <div className="settings-page">
      {!isMobile && !!userRole && renderTabs()}
      <TransitionGroup>
        <CSSTransition classNames="fade" timeout={300}>
          <Switch>
            <Route component={SettingsProfile} path={Routes.profile} />
            {isPGOrDistributor ? (
              <Redirect to={Routes.profile} />
            ) : (
              <Route component={SettingsUser} path={Routes.users} />
            )}
            <Route component={SettingsOutlets} path={Routes.outlets} />
            <Route render={renderProfile} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  ) : (
    <span>No Content</span>
  );
};

export const SettingsBackButton: React.FC<{ className?: string }> = ({ className }) => {
  const { managerAppCommon: content } = useContent();
  const history = useHistory();

  return (
    <TertiaryButton
      icon="ChevronLeft"
      iconColor="var(--color-grey-grey100)"
      onClick={() => history.push(Routes.settings)}
      className={className}
    >
      <span className="text-grey-grey100">{content.common_back}</span>
    </TertiaryButton>
  );
};
