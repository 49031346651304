import Time from "../../lib/Time/Time";

export const ADD_TARGET = "targets/ADD_TARGET";
export const DELETE_TARGET = "targets/DELETE_TARGET";
export const SET_CURRENT_TARGET = "targets/SET_CURRENT_TARGET";

export interface ITargetData {
  goal: number;
  current: number;
  expected: number | null;
}

export interface ITarget {
  date: Time;
  isRecurring?: boolean;
  remainingDays: number | null;
  speciality: ITargetData | null;
  volume: ITargetData | null;
}

export interface ITargetParams {
  month: string;
  totalVolume: number;
  craftAndSpecialityPercentage: number;
  recurring?: boolean;
  allActiveTargets?: boolean;
  outletsWithTargetsCount?: number;
}

export interface IAddTarget {
  type: typeof ADD_TARGET;
  payload: ITargetParams;
}

export interface IDeleteTarget {
  type: typeof DELETE_TARGET;
  payload: string;
}

export interface ISetCurrentTarget {
  type: typeof SET_CURRENT_TARGET;
  payload?: ITargetParams;
}

export type TargetActionTypes = IAddTarget | IDeleteTarget | ISetCurrentTarget;
