import React, { useState } from "react";
import { Description } from "@ddm-design-system/typography";
import { Menu, MenuItem } from "@ddm-design-system/menu";
import { Icon } from "@ddm-design-system/icon";
import useContent from "../../hooks/useContent";

interface IAction {
  id: string;
  text: string;
  execute?: () => void;
}

interface ISortingContainerProps {
  sortingOptions: { [key: string]: IAction };
  sortingCallback: (id: any) => void;
}
export const OutletReviewSortingContainer: React.FC<ISortingContainerProps> = ({
  sortingOptions,
  sortingCallback
}) => {
  const { managerAppOverview: content } = useContent();

  const [selectedAction, selectAction] = useState(sortingOptions.mostVolume);
  return (
    content && (
      <div className="outlet-review-sort-container">
        <Description className="sort-span">{content.manager_app_sort_by}</Description>
        <Menu
          direction="bottom-right"
          renderHeader={() => (
            <Description className="outlet-review-sort-selected flex items-center">
              <strong>&nbsp;{selectedAction.text}&nbsp;</strong>
              <Icon name="ChevronDown" size={8} />
            </Description>
          )}
        >
          {Object.values(sortingOptions).map(item => (
            <MenuItem
              key={item.id}
              onClick={() => {
                sortingCallback(item);
                if (item.id !== "remove") {
                  selectAction(item);
                }
              }}
            >
              {selectedAction.id === item.id ? <strong>{item.text}</strong> : item.text}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  );
};

export default OutletReviewSortingContainer;
