import React, { useCallback } from "react";
import { Card, CardBody } from "@ddm-design-system/card";
import { BodyHighlight } from "@ddm-design-system/typography";
import useContent from "../../hooks/useContent";
import { IPressureChamber, ISteelKegUnit } from "../../store/installation/types";
import { Keg } from "../common/keg/keg";
import PressureChamberBadges from "./PressureChamberBadges";
import PressureChamberDescription from "./PressureChamberDescription";

interface IProps {
  steelKegUnit: ISteelKegUnit;
  onClick: (steelKegUnit: { steelKegUnit: ISteelKegUnit }) => void;
}

export const SteelKegUnitCard: React.FC<IProps> = ({ steelKegUnit, onClick }) => {
  const { managerAppCommon: contentCommon } = useContent();
  const handleClick = useCallback(() => onClick({ steelKegUnit }), [steelKegUnit, onClick]);

  steelKegUnit.status = steelKegUnit.offline
    ? "offline"
    : steelKegUnit.dangerTags > 0
    ? "danger"
    : steelKegUnit.warningTags > 0
    ? "warning"
    : "ok";

  return (
    <Card
      className={`bg-white !rounded-[4px] box-border !border-[3px] justify-between w-[240px] h-[310px] relative cursor-pointer hover:z-[1] xs:w-full xs:max-w-none 
      ${
        steelKegUnit.status === "warning"
          ? "!border-warning-warning100 !bg-warning-warning25"
          : steelKegUnit.status === "danger"
          ? "!border-alert-alert100 !bg-alert-alert25"
          : steelKegUnit.status === "offline"
          ? "!border-grey-grey50 !bg-grey-grey25"
          : "hover:!border-black !border-grey-greenish"
      }`}
      key={steelKegUnit.id}
      onClick={handleClick}
    >
      <CardBody className="flex flex-col !p-md">
        <>
          <div className="flex flex-col items-center">
            <BodyHighlight className="mb-md">
              {contentCommon.common_steel_keg} {steelKegUnit.position || "--"}
            </BodyHighlight>
            <Keg steelKegUnit={steelKegUnit} />
            <PressureChamberDescription pressureChamber={steelKegUnit as IPressureChamber} />
          </div>
          <PressureChamberBadges pressureChamber={steelKegUnit as IPressureChamber} />
        </>
      </CardBody>
    </Card>
  );
};

export default SteelKegUnitCard;
