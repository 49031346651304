import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import useContent from "../../../../hooks/useContent";
import { IInsightsNotification, EInsightItemType } from "../../../../store/insights/types";
import { getPositionsFromOutletByThingIds } from "../../../../store/installation/selectors";
import InsightItemContent from "../InsightItemContent";
import InsightCard from "../InsightCard";
import InsightStory from "../InsightStory";

export interface IInsightNoHeartbeatProps {
  insight: IInsightsNotification;
  itemType: EInsightItemType;
}

const InsightNoHeartbeat: React.FC<IInsightNoHeartbeatProps> = ({ insight, itemType }) => {
  const { managerAppInsights: content } = useContent();
  const { allComponentsOffline, primaries, secondaries }: any = insight.customFields;
  let title = "";let description = "";


  const secPositions = useSelector(getPositionsFromOutletByThingIds(insight.locationId,secondaries));
  const primPositions = useSelector(getPositionsFromOutletByThingIds(insight.locationId,primaries));

  if (allComponentsOffline) {
    title = content.manager_app_insights_no_heartbeat_all_components_offline_title;
    description = content.manager_app_insights_no_heartbeat_all_components_offline_desc;
  } else if (primaries.length + secondaries.length > 1) {
      title = content.manager_app_insights_no_heartbeat_units_title.replace("%TOTAL%", `${primaries.length + secondaries.length}`);
      description = primaries.length > 0 ? 
      content.manager_app_insights_no_heartbeat_units_primaries_secondaries_desc.replace("%TOTALS%", secPositions?.join(", ") || "").replace("%TOTALP%", primPositions?.join(", ") || "")
      : content.manager_app_insights_no_heartbeat_units_secondaries_desc.replace("%TOTAL%", secPositions?.join(", ") || "");
      
  } else {
      title = content.manager_app_insights_no_heartbeat_unit_title;
      description = content.manager_app_insights_no_heartbeat_unit_desc.replace("%POS%", secPositions?.join(", ") || "");
  }

  const renderInsight = useCallback(itemType => {
    switch(itemType) {
    case  EInsightItemType.INSIGHT_ITEM:
      return (
        <InsightItemContent
          className="w-full"
          title={title}
          description={description}
          icon="NoHeartbeat"
          iconOptions={{ name: "NoHeartbeat", className: "text-white fill-current" }}
          insight={insight}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
        />
      );
    case  EInsightItemType.INSIGHT_CARD:
      return (
        <InsightCard
          title={title}
          icon="NoHeartbeat"
          iconOptions={{ name: "NoHeartbeat", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    case  EInsightItemType.INSIGHT_STORY:
      return (
        <InsightStory
          title={title}
          description={description}
          icon="NoHeartbeat"
          iconOptions={{ name: "NoHeartbeat", className: "text-white fill-current" }}
          avatarOptions={{
            backgroundColor: "var(--color-alert-alert100)"
          }}
          insight={insight}
        />
      );
    default:
      return <></>;
  }
  }, [insight, itemType]);

  return renderInsight(itemType);
};

export default InsightNoHeartbeat;
